import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import { useShowAgentQuery, useUpdateAgentThemeColorsMutation } from "services/api";
import GradientButton from "../GradientButton/GradientButton";
import CustomColorPicker from "../CustomColorPicker/CustomColorPicker";
import { useSelector } from "react-redux";
import { APP_CONFIG_PROJECT } from "constants/config";
import { resetAgentTheme } from "services/functions/resetAgentTheme";
import { setAgentTheme } from "services/functions/setAgentTheme";

const AgentThemeColors = ({ setLoading }) => {
  const [updateAgentColors] = useUpdateAgentThemeColorsMutation();
  const userData = useSelector((state) => state.userData);
  const [colors, setColors] = useState({
    backgroundDefault: "",
    backgroundPaper: "",
    primaryMain: "",
    primaryLight: "",
    secondaryMain: "",
    secondaryLight: "",
    menuBackgroundItem: "",
    menuBackgroundItemSelected: "",
    menuIcon: "",
    menuIconSelected: "",
    menuText: "",
    typographyColor: "",
    collectionItemBackground: "",
    collectionItemIcon: "",
    gradientMainPrimary: "",
    gradientMainSecondary: "",
    gradientLightPrimary: "",
    gradientLightSecondary: "",
  });

  const {
    data: agent,
    refetch,
    isLoading,
    isError,
    isUninitialized,
  } = useShowAgentQuery(
    {
      agent_id: userData.agent.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !userData.agent.id
    }
  );

  const handleUpdateThemeColors = async () => {
    setLoading(true);
    try {
      await updateAgentColors({
        agent_id: agent.id,
        colors
      }).unwrap();
      setAgentTheme({
        agent: {
          colors
        }
      });
      toast.success("Tema de cores atualizado com sucesso!");
    } catch (e) {
      console.log({e});
      toast.error("Erro ao atualizar tema de cores!");
    }
    refetch();
    setLoading(false);
  };

  useEffect(() => {
    if(!isLoading && !isError && !isUninitialized) {
      setColors({...agent.colors});
    }
  }, [agent, isLoading, isError, isUninitialized])

  const handleResetThemeColors = async () => {
    setLoading(true);
    try {
      await updateAgentColors({
        agent_id: agent.id,
        colors: null
      }).unwrap();
      resetAgentTheme({
        justColors: true
      });
      toast.success("Tema de cores atualizado com sucesso!");
    } catch (e) {
      toast.error("Erro ao atualizar tema de cores!");
    }
    refetch();
    setLoading(false);
  };

	return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center" width="100%">
        <Typography>Background</Typography>
        <Box display="flex" justifyContent="space-around" width="100%" marginBottom="20px">
            <Box display="flex" width="300px">
              <CustomColorPicker
                value={colors.backgroundDefault ?? APP_CONFIG_PROJECT.theme.palette.background.default}
                label={"Default"}
                colors={colors}
                setColors={setColors}
                keyString={"backgroundDefault"}
              />
          </Box>
          <Box display="flex" width="300px">
            <CustomColorPicker
              value={colors.backgroundPaper ?? APP_CONFIG_PROJECT.theme.palette.background.paper}
              label={"Papel"}
              colors={colors}
              setColors={setColors}
              keyString={"backgroundPaper"}
            />
          </Box>
        </Box>

        <Typography>Primária</Typography>
        <Box display="flex" justifyContent="space-around" width="100%" marginBottom="20px">
          <Box display="flex" width="300px">
            <CustomColorPicker
              value={colors.primaryMain ?? APP_CONFIG_PROJECT.theme.palette.primary.main}
              label={"Principal"}
              colors={colors}
              setColors={setColors}
              keyString={"primaryMain"}
            />
          </Box>
          <Box display="flex" width="300px">
            <CustomColorPicker
              value={colors.primaryLight ?? APP_CONFIG_PROJECT.theme.palette.primary.light}
              label={"Claro"}
              colors={colors}
              setColors={setColors}
              keyString={"primaryLight"}
            />
          </Box>
        </Box>

        <Typography>Secundária</Typography>
        <Box display="flex" justifyContent="space-around" width="100%" marginBottom="20px">
          <Box display="flex" width="300px">
            <CustomColorPicker
              value={colors.secondaryMain ?? APP_CONFIG_PROJECT.theme.palette.secondary.main}
              label={"Principal"}
              colors={colors}
              setColors={setColors}
              keyString={"secondaryMain"}
            />
          </Box>
          <Box display="flex" width="300px">
            <CustomColorPicker
              value={colors.secondaryLight ?? APP_CONFIG_PROJECT.theme.palette.secondary.light}
              label={"Claro"}
              colors={colors}
              setColors={setColors}
              keyString={"secondaryLight"}
            />
          </Box>
        </Box>

        <Typography>Menu</Typography>
        <Box display="flex" alignItems="center" flexDirection="column" marginBottom="20px">
          <CustomColorPicker
            value={colors.menuBackgroundItem ?? APP_CONFIG_PROJECT.theme.palette.menu.backgroundItem}
            label={"Bg do ícone"}
            colors={colors}
            setColors={setColors}
            keyString={"menuBackgroundItem"}
          />
          <CustomColorPicker
            value={colors.menuBackgroundItemSelected ?? APP_CONFIG_PROJECT.theme.palette.menu.backgroundItemSelected}
            label={"Bg ícone selecionado"}
            colors={colors}
            setColors={setColors}
            keyString={"menuBackgroundItemSelected"}
          />
          <CustomColorPicker
            value={colors.menuIcon ?? APP_CONFIG_PROJECT.theme.palette.menu.icon}
            label={"Ícone"}
            colors={colors}
            setColors={setColors}
            keyString={"menuIcon"}
          />
          <CustomColorPicker
            value={colors.menuIconSelected ?? APP_CONFIG_PROJECT.theme.palette.menu.iconSelected}
            label={"Ícone selecionado"}
            colors={colors}
            setColors={setColors}
            keyString={"menuIconSelected"}
          />
          <CustomColorPicker
            value={colors.menuText ?? APP_CONFIG_PROJECT.theme.palette.menu.text}
            label={"Texto"}
            colors={colors}
            setColors={setColors}
            keyString={"menuText"}
          />
        </Box>

        <Typography>Texto</Typography>
        <Box display="flex" justifyContent="space-around" width="100%" marginBottom="20px">
          <Box display="flex" width="300px">
            <CustomColorPicker
              value={colors.typographyColor ?? APP_CONFIG_PROJECT.theme.typography.color}
              label={"Fundo"}
              colors={colors}
              setColors={setColors}
              keyString={"typographyColor"}
            />
          </Box>
        </Box>

        <Typography>Cards</Typography>
        <Box display="flex" justifyContent="space-around" width="100%" marginBottom="20px">
          <Box display="flex" width="300px">
            <CustomColorPicker
              value={colors.collectionItemBackground ?? APP_CONFIG_PROJECT.theme.collectionItem.background}
              label={"Fundo"}
              colors={colors}
              setColors={setColors}
              keyString={"collectionItemBackground"}
            />
          </Box>
          <Box display="flex" width="300px">
            <CustomColorPicker
              value={colors.collectionItemIcon ?? APP_CONFIG_PROJECT.theme.collectionItem.icon}
              label={"Ícone"}
              colors={colors}
              setColors={setColors}
              keyString={"collectionItemIcon"}
            />
          </Box>
        </Box>

        <Typography>Gradiente</Typography>
        <Box display="flex" justifyContent="space-around" width="100%" marginBottom="20px">
          <Box display="flex" width="300px">
            <CustomColorPicker
              value={colors.gradientMainSecondary ?? APP_CONFIG_PROJECT.theme.gradient.main.secondary}
              label={"Início"}
              colors={colors}
              setColors={setColors}
              keyString={"gradientMainSecondary"}
            />
          </Box>
          <Box display="flex" width="300px">
            <CustomColorPicker
              value={colors.gradientMainPrimary ?? APP_CONFIG_PROJECT.theme.gradient.main.primary}
              label={"Fim"}
              colors={colors}
              setColors={setColors}
              keyString={"gradientMainPrimary"}
            />
          </Box>
        </Box>

        <Typography>Gradiente claro</Typography>
        <Box display="flex" justifyContent="space-around" width="100%" marginBottom="20px">
          <Box display="flex" width="300px">
            <CustomColorPicker
              value={colors.gradientLightSecondary ?? APP_CONFIG_PROJECT.theme.gradient.light.secondary}
              label={"Início"}
              colors={colors}
              setColors={setColors}
              keyString={"gradientLightSecondary"}
            />
          </Box>
          <Box display="flex" width="300px">
            <CustomColorPicker
              value={colors.gradientLightPrimary ?? APP_CONFIG_PROJECT.theme.gradient.light.primary}
              label={"Fim"}
              colors={colors}
              setColors={setColors}
              keyString={"gradientLightPrimary"}
            />
          </Box>
        </Box>
      </Box>

      <Box display="flex" width="100%" justifyContent="center" marginTop="16px">
        <Button
					variant="contained"
					style={{
						margin: "5px",
						borderRadius: "27px",
					}}
					onClick={handleResetThemeColors}
				>
					Resetar
				</Button>
        <GradientButton
          variant="contained"
          style={{
            margin: "5px",
            borderRadius: "27px",
            color: "white",
          }}
          buttonText="Atualizar"
          onClick={handleUpdateThemeColors}
        />
      </Box>
		</Box>
	);
};

export default AgentThemeColors;
