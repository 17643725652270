import {
  Box,
  LinearProgress,
  Typography,
  makeStyles,
  useMediaQuery,
  Button,
  useTheme,
} from "@material-ui/core";
import { generatePath, useHistory } from "react-router";
import { Pagination } from "@material-ui/lab";
import { useIndexZoopSalesPlanQuery } from "services/api";
import CustomTable from "../../reusable/CustomTable/CustomTable";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useState } from "react";
import SearchBar from "components/reusable/SearchBar/SearchBar";
import useDebounce from "hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

const columns = [
  { headerText: "Nome", key: "name" },
  { headerText: "Id", key: "id" },
];

const ZoopSalesPlanList = () => {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(1);
  const history = useHistory();
  const [filters, setFilters] = useState({
    planName: "",
  });

  const debouncedPlanName = useDebounce(filters.planName, 800);

  const {
    data: zoopPlanList,
    isLoading,
    isError,
    isUninitialized,
    refetch
  } = useIndexZoopSalesPlanQuery(
    {
      page,
      zoop_plan_name: debouncedPlanName,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleClickRow = (row) => {
    const path = generatePath("/dashboard/plano-vendas-zoop/:id/detalhes", {
      id: row.id,
    });
    history.push(path);
  };

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  return (
    <Box className={classes.root}>
      <CustomBreadcrumbs path1="Planos de Vendas" to1="goBack" path2="Zoop" />
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Box marginBottom={2}>
          <Typography
            style={{ marginTop: "8px", marginBottom: "12px" }}
            variant="h4"
          >
            Planos de Vendas Zoop
          </Typography>
        </Box>
        <Button
            variant="outlined"
            style={{ marginTop: "8px", marginBottom: "12px" }}
            onClick={refetch}
          >
            Atualizar
          </Button>
      </Box>
      <Box marginBottom="16px">
        <SearchBar
          fullWidth
          placeholder="Pesquisar por nome do plano de vendas"
          value={filters.planName}
          onChange={(e) =>
            setFilters({
              ...filters,
              planName: e.target.value,
            })
          }
        />
      </Box>
      <>
        {!isLoading && !isError && !isUninitialized ? (
          <>
            <CustomTable
              columns={columns}
              data={zoopPlanList.items}
              handleClickRow={handleClickRow}
            />
            <Box alignSelf="flex-end" marginTop="8px">
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={zoopPlanList.last_page}
                onChange={handleChangePage}
                page={page}
              />
            </Box>
          </>
        ) : (
          <LinearProgress />
        )}
      </>
    </Box>
  );
};

export default ZoopSalesPlanList;
