import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  InputLabel,
  Select,
  Dialog,
  FormControl,
  FormHelperText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  faCalendarAlt,
  faInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loadBoletosFilter, loadContaId } from "../../../actions/actions";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "../../reusable/CustomTable/CustomTable";
import GradientButton from "../../reusable/GradientButton/GradientButton";
import SearchBar from "../../reusable/SearchBar/SearchBar";
import useDebounce from "../../../hooks/useDebounce";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import { useUpdateAccountLimitBoletoMutation } from "services/api";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input";
import { currencyStyle } from "styles";
import { TRANSACTION_STATUS } from "constants/status";
import TransactionStatusColumn from "components/reusable/TableColumns/TransactionStatusColumn";
import DialogCancelMultipleBills from "./DialogCancelMultipleBills";

const useStyles = makeStyles((theme) => ({
  SplitModal: {
    padding: "20px",
  },
  saqueHeader: {
    background: theme.gradient.main,
    color: "white",
  },
  currency: currencyStyle,
}));

const columns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (data) => {
      const p = data.split(/\D/g);
      const dataFormatada = [p[2], p[1], p[0]].join("/");
      return (
        <Box display="flex" justifyContent="center">
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
          <Typography style={{ marginLeft: "6px" }}>{dataFormatada}</Typography>
        </Box>
      );
    },
  },
  {
    headerText: "Situação",
    key: "status",
    CustomValue: (status) => {
      return <TransactionStatusColumn status={status} />;
    },
  },
  {
    headerText: "Pagador",
    key: "pagador",
    CustomValue: (pagador) => {
      return (
        <Box>
          <Typography>
            {pagador.nome ? pagador.nome : pagador.razao_social}
          </Typography>
          <Typography>
            {pagador.documento ? pagador.documento : null}
          </Typography>
        </Box>
      );
    },
  },
  {
    headerText: "Descrição",
    key: "descricao",
    CustomValue: (descricao) => {
      return (
        <Tooltip title={descricao}>
          <Box marginLeft="12px">
            <FontAwesomeIcon icon={faInfo} />
          </Box>
        </Tooltip>
      );
    },
  },
  {
    headerText: "Ações",
    key: "menu",
  },
];

const Editar = ({ row }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <>
        <Button
          style={{ height: "15px", width: "10px" }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          ...
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            <Link
              style={{
                color: theme.palette.text.primary,
              }}
              to={`/dashboard/detalhes-transacao/${row.transaction_id}/ver`}
            >
              Ver detalhes
            </Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              window.open(`${row.url}`, "Boleto", "height=1000,width=1000");
            }}
          >
            Visualizar boleto
          </MenuItem>
        </Menu>
      </>
    </Box>
  );
};

const PaymentSlipList = () => {
  const boletos = useSelector((state) => state.boletos);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const history = useHistory();
  const userData = useSelector((state) => state.userData);
  const slipLimit = useSelector((state) => state.conta?.valor_limite_boleto);
  const { id } = useParams();
  const [newLimit, setNewLimit] = useState(slipLimit);
  const [updateLimit, updateLimitStatus] =
    useUpdateAccountLimitBoletoMutation();
  const updateLimitError = useMemo(
    () =>
      updateLimitStatus.error ? updateLimitStatus.error.data.errors : null,
    [updateLimitStatus]
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);

  const classes = useStyles();

  const [filters, setFilters] = useState({
    like: "",
    order: "",
    mostrar: "",
    status: "",
  });

  const debouncedLike = useDebounce(filters.like, 800);

  useEffect(() => {
    if (id) {
      dispatch(loadContaId(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    setNewLimit(slipLimit);
  }, [slipLimit]);

  useEffect(() => {
    dispatch(
      loadBoletosFilter(
        page,
        debouncedLike,
        filters.order,
        filters.mostrar,
        filters.status,
        id ?? userData.id
      )
    );
  }, [
    dispatch,
    page,
    debouncedLike,
    filters.order,
    filters.mostrar,
    filters.status,
    id,
    userData.id,
  ]);

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const handleNewBoleto = () => {
    history.push("/dashboard/gerar-boleto");
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewLimit(slipLimit);
  };

  const handleChangeLimit = async () => {
    try {
      await updateLimit({ value: newLimit, conta_id: id }).unwrap();
      toast.success("Limite atualizado!");
      handleCloseDialog();
      dispatch(loadContaId(id));
    } catch (e) {
      toast.error("Erro ao atualizar limite!");
    }
  };

  return (
    <>
      <Dialog
        onClose={handleCloseDialog}
        open={openDialog}
        className={classes.SplitModal}
      >
        <Box display="flex" flexDirection="column" width="500px">
          <LoadingScreen isLoading={updateLimitStatus.isLoading} />
          <DialogTitle className={classes.saqueHeader}>
            <Typography align="center" variant="h6">
              Atualizar limite do valor máximo por boleto
            </Typography>
          </DialogTitle>

          <Box margin="20px">
            <FormControl fullWidth>
              <Typography variant="h6">
                Novo limite (padrão R$10000,00)
              </Typography>
              <CurrencyInput
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
                className={classes.currency}
                value={newLimit}
                onChangeEvent={(event, maskedvalue, floatvalue) =>
                  setNewLimit(floatvalue)
                }
                style={{
                  marginBottom: "6px",
                  width: "100%",
                }}
              />
              {updateLimitError ? (
                <FormHelperText
                  style={{
                    marginBottom: "6px",
                    width: "60%",
                    color: "red",
                  }}
                >
                  {updateLimitError.valor_limite_boleto
                    ? updateLimitError.valor_limite_boleto[0]
                    : null}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>

          <Box
            width="50%"
            alignSelf="end"
            display="flex"
            justifyContent="space-around"
            padding="12px 24px"
          >
            <Box>
              <Button
                style={{ borderRadius: "37px", margin: "6px 0" }}
                variant="outlined"
                onClick={handleCloseDialog}
              >
                Cancelar
              </Button>
            </Box>
            <Box margin="6px 0">
              <GradientButton
                buttonText={"Atualizar"}
                onClick={handleChangeLimit}
              />
            </Box>
          </Box>
        </Box>
      </Dialog>

      <Box display="flex" flexDirection="column">
        {id ? (
          <CustomBreadcrumbs
            path1="Gerenciar Listas"
            to1="goBack"
            path2="Boletos"
          />
        ) : (
          <CustomBreadcrumbs path1="Boletos" />
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={matches ? "column" : null}
        >
          <Typography style={{ marginTop: "8px" }} variant="h4">
            Boletos
          </Typography>

          {id && userData.id == null ? (
            <Box>
              <Button
                style={{ borderRadius: "27px", marginRight: "12px" }}
                variant="outlined"
                onClick={handleOpenDialog}
              >
                Alterar Limite
              </Button>
              <Button
                style={{ borderRadius: "27px", marginRight: "12px" }}
                variant="outlined"
              >
                <Link
                  style={{
                    color: theme.palette.text.primary,
                  }}
                  to={`/dashboard/contas/${id}/configuracao-splits-boletos`}
                >
                  Configurações de Split
                </Link>
              </Button>
            </Box>
          ) : null}
          {id ? null : (
            <Box>
              <Button
                style={{ borderRadius: "27px", marginRight: "12px" }}
                variant="outlined"
              >
                Boleto em Lote
              </Button>
              <Button
                style={{ borderRadius: "27px", marginRight: "12px" }}
                variant="outlined"
              >
                <Link
                  style={{
                    color: theme.palette.text.primary,
                  }}
                  to={`/dashboard/configuracao-splits-boletos`}
                >
                  Configurações de Split
                </Link>
              </Button>
              <Button
                style={{ borderRadius: "27px", marginRight: "12px" }}
                variant="outlined"
                onClick={() => setOpenUploadDialog(true)}
              >
                Cancelar em Lote
              </Button>
              <GradientButton
                onClick={handleNewBoleto}
                buttonText="Nova Cobrança"
              />
            </Box>
          )}
        </Box>

        <DialogCancelMultipleBills
          open={openUploadDialog}
          handleClose={() => setOpenUploadDialog(false)}
        />
        <Box display="flex" marginTop="16px" marginBottom="16px">
          <Box flexGrow="1">
            <SearchBar
              fullWidth
              placeholder="Procurar por documento, nome..."
              value={filters.like}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  like: e.target.value,
                })
              }
            />
          </Box>
          <Box ml="20px" flexGrow="1" alignItems="center">
            <InputLabel>Situação</InputLabel>
            <Select
              fullWidth
              value={filters.status}
              onChange={(e) =>
                setFilters({ ...filters, status: e.target.value })
              }
            >
              <MenuItem value="">TODOS</MenuItem>
              <MenuItem value={TRANSACTION_STATUS.pending.status}>
                {TRANSACTION_STATUS.pending.text}
              </MenuItem>
              <MenuItem value={TRANSACTION_STATUS.succeeded.status}>
                {TRANSACTION_STATUS.succeeded.text}
              </MenuItem>
              <MenuItem value={TRANSACTION_STATUS.failed.status}>
                {TRANSACTION_STATUS.failed.text}
              </MenuItem>
              <MenuItem value={TRANSACTION_STATUS.canceled.status}>
                {TRANSACTION_STATUS.canceled.text}
              </MenuItem>
              <MenuItem value={TRANSACTION_STATUS.requested.status}>
                {TRANSACTION_STATUS.requested.text}
              </MenuItem>
              <MenuItem value={TRANSACTION_STATUS.refused.status}>
                {TRANSACTION_STATUS.refused.text}
              </MenuItem>
              <MenuItem value={TRANSACTION_STATUS.rejected.status}>
                {TRANSACTION_STATUS.rejected.text}
              </MenuItem>
              <MenuItem value={TRANSACTION_STATUS.error.status}>
                {TRANSACTION_STATUS.error.text}
              </MenuItem>
              <MenuItem value={TRANSACTION_STATUS.finished.status}>
                {TRANSACTION_STATUS.finished.text}
              </MenuItem>
            </Select>
          </Box>
          <Box ml="20px">
            <Tooltip title="Limpar Filtros">
              <IconButton
                onClick={() =>
                  setFilters({
                    like: "",
                    order: "",
                    mostrar: "",
                    status: "",
                  })
                }
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <>
          {boletos.data && boletos.per_page ? (
            <CustomTable
              columns={columns}
              data={boletos.data}
              Editar={Editar}
            />
          ) : (
            <LinearProgress />
          )}
          <Box alignSelf="flex-end" marginTop="8px">
            <Pagination
              variant="outlined"
              color="secondary"
              size="large"
              count={boletos.last_page}
              onChange={handleChangePage}
              page={page}
            />
          </Box>
        </>
      </Box>
    </>
  );
};

export default PaymentSlipList;
