import { Box, Button, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import InputMask from 'react-input-mask';
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { postUsuarioConta } from "actions/actions";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import CustomBreadcrumbs from "../CustomBreadcrumbs/CustomBreadcrumbs";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
	},
	paper: {
		marginTop: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: "27px",
		width: "60%",
		alignSelf: "center",
		[theme.breakpoints.down("sm")]: {
			width: "80%",
		},
	},
}));

const NewUser = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const { id } = useParams();
  const userData = useSelector((state) => state.userData);
	const dispatch = useDispatch();
	const history = useHistory();
  const [postUserError, setPostUserError] = useState({});

	const [usuario, setUsuario] = useState({
		conta_id: id ?? userData.id,
		nome: "",
    email: "",
    documento: "",
	});

	const handleCreateUser = async () => {
		setLoading(true);
    setPostUserError({});
		const resUser = await dispatch(postUsuarioConta(usuario));

		if (resUser) {
      setPostUserError(resUser);
		} else {
			toast.success("Usuario cadastrado com sucesso!");
			history.goBack();
		}
    setLoading(false);
	};

	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />
      {id ? 
        <CustomBreadcrumbs
          path1="Contas"
          to1="/dashboard/contas"
          path2= "Usuários"
          to2={`/dashboard/contas/${id}/users`}
          path3="Novo Usuário"
          to3="#"
        />
      :
        <CustomBreadcrumbs
          path1= "Meus Usuários"
          to1="/dashboard/my-users"
          path2="Novo Usuário"
          to2="#"
        />
      }
			<Typography style={{ marginTop: "8px" }} variant="h4">
				Criar Novo Usuário
			</Typography>
      <Paper className={classes.paper}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <TextField
            label="Nome"
            value={usuario.nome}
            onChange={(e) =>
              setUsuario({
                ...usuario,
                nome: e.target.value,
              })
            }
            fullWidth
            error={postUserError.nome}
            helperText={postUserError.nome ? postUserError.nome.join(" ") : null}
          />
          <TextField
            label="E-mail"
            value={usuario.email}
            onChange={(e) =>
              setUsuario({
                ...usuario,
                email: e.target.value,
              })
            }
            fullWidth
            type="email"
            error={postUserError.email}
            helperText={postUserError.email ? postUserError.email.join(" ") : null}
          />
          <InputMask
            mask={'999.999.999-99'}
            value={usuario.documento}
            onChange={(e) =>
              setUsuario({
                ...usuario,
                documento: e.target.value,
              })
            }
          >
            {() => (
              <TextField
                label={'CPF'}
                error={postUserError.documento}
                helperText={postUserError.documento ? postUserError.documento.join(" ") : null}
                // name="documento"
                fullWidth
              />
            )}
          </InputMask>
          <Box alignSelf="center" marginTop="12px">
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "12px", color: "white" }}
              onClick={handleCreateUser}
            >
              Criar usuário
            </Button>
          </Box>
        </Box>
      </Paper>
		</Box>
	);
};

export default NewUser;
