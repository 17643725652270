import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { useUpdateFeeMutation } from "services/api";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import { toast } from "react-toastify";

const BoletoFeesBox = ({ boletoFee, label, editable }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [feeValue, setFeeValue] = useState(0);
  const [percentFeeValue, setPercentFeeValue] = useState(0);
  const [changed, setChanged] = useState(false);
  const [updateCardFee, updateCardFeeStatus] = useUpdateFeeMutation();
  const updateFeeError = useMemo(() => updateCardFeeStatus.error ? updateCardFeeStatus.error.data.errors : false, [updateCardFeeStatus]);

  useEffect(() => {
    setFeeValue((boletoFee.dollar_amount / 100).toFixed(2));
    setPercentFeeValue((boletoFee.percent_amount / 100).toFixed(2));
  }, [boletoFee]);

  const handleChange = (event) => {
    setChanged(true);
    setFeeValue(event.target.value.replace(",", "."));
  };

  const handleChangePercentFeeValue = (event) => {
    setChanged(true);
    setPercentFeeValue(event.target.value.replace(",", "."));
  };

  const handleUpdateCardFee = async () => {
    if(changed) {
      setIsLoading(true);
      try {
        await updateCardFee({
          fee_id: boletoFee.id,
          dollar_amount: (feeValue*100).toFixed(0),
          percent_amount: (percentFeeValue*100).toFixed(0),
        }).unwrap();
        toast.success("Taxa atualizada!");
      } catch (e) {
        toast.error("Erro ao atualizar taxa!");
      }
      setIsLoading(false);
      setChanged(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <LoadingScreen isLoading={isLoading}/>
      <Typography variant="h6">{label}</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography style={{ paddingRight:"10px" }}>
          Valor percentual:
        </Typography>
        <TextField
          id={boletoFee.id}
          disabled={!editable}
          value={percentFeeValue}
          onChange={handleChangePercentFeeValue}
          error={updateFeeError?.percent_amount}
          onBlur={handleUpdateCardFee}
          InputProps={{
            endAdornment: <InputAdornment>%</InputAdornment>,
          }}
          style={{
            width:"7ch",
            justifyContent:"center"
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography style={{ paddingRight:"10px" }}>
          Valor fixo:
        </Typography>
        <TextField
          id={boletoFee.id}
          disabled={!editable}
          value={feeValue}
          onChange={handleChange}
          error={updateFeeError?.dollar_amount}
          onBlur={handleUpdateCardFee}
          InputProps={{
            endAdornment: <InputAdornment>R$</InputAdornment>,
          }}
          style={{
            width:"7ch",
            justifyContent:"center"
          }}
        />
      </Box>
    </Box>
  );
};

export default BoletoFeesBox;
