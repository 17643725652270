import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { getTheme, getCssVariables } from "constants/theme";
import { APP_CONFIG } from "constants/config";
import store from "store";
import MetaTags from "react-meta-tags";
import Root from "components/Root/Root";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const App = () => {
  const [theme, setTheme] = useState(() => getTheme());
  const [cssVariables, setCssVariables] = useState(() => getCssVariables());

  useEffect(() => {
    const listener = () => {
      setTheme(getTheme());
      setCssVariables(getCssVariables());
    }
    window.addEventListener("themeChanges", listener);
    return () => {window.removeEventListener("themeChanges", listener)}
  }, []);

	return (
		<Provider store={store}>
			<MetaTags>
				<title>{APP_CONFIG().name}</title>
				<meta name="description" content={APP_CONFIG().description} />
				<link rel="shortcut icon" href={APP_CONFIG().assets.favicon} />
			</MetaTags>
			<MuiThemeProvider theme={{ ...theme, ...cssVariables }}>
				<Root />
				<ToastContainer autoClose={3000} />
			</MuiThemeProvider>
		</Provider>
	);
};

export default App;
