export const getSlipSplitConfigQuery = ({ page, conta_id }) => ({
  url: `/slip-split-config`,
  method: "get",
  params: { page, conta_id },
});

export const storeSlipSplitConfigMutation = ({ splitConfig }) => {
  return {
    url: `/slip-split-config`,
    method: "post",
    data: {
      ...splitConfig,
    },
  };
};

export const deleteSlipSplitConfigMutation = ({ slip_split_config_id }) => ({
  url: `/slip-split-config/${slip_split_config_id}`,
  method: "delete",
  data: {
    slip_split_config_id,
  },
});
