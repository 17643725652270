import {
	Box,
	FormControlLabel,
	makeStyles,
	Paper,
	Radio,
	RadioGroup,
	Step,
	StepLabel,
	Stepper,
	TextField,
	Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import {
	postNewConta,
	postUsuarioConta,
} from '../../../actions/actions';
import NewAccount from '../../reusable/Account/NewAccount/NewAccount';
import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import GradientButton from '../../reusable/GradientButton/GradientButton';
import LoadingScreen from '../../reusable/LoadingScreen/LoadingScreen';
import CreateBankAccount from '../BankAccounts/CreateBankAccount/CreateBankAccount';
import InputMask from 'react-input-mask';
import getResponseError from 'helpers/getResponseError';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '27px',
		width: '850px',
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}));

const STEPS = {
	// TIPO_DE_CADASTRO: 'Tipo de Cadastro',
	CADASTRO_DA_CONTA: 'Dados da Conta',
	ACESSO_PORTAL: 'Acesso ao portal',
	CONTA_BANCARIA: 'Dados da Conta Bancária',
};

const AccountRegistration = () => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const steps = [
		// STEPS.TIPO_DE_CADASTRO,
		STEPS.CADASTRO_DA_CONTA,
		STEPS.ACESSO_PORTAL,
		STEPS.CONTA_BANCARIA,
	];
	const [activeStep, setActiveStep] = useState(0);
	const [loading, setLoading] = useState(false);

	//state de contas
	const [errosConta, setErrosConta] = useState({});
	const [conta, setConta] = useState({
    is_digital_account: true,
		documento: '',
		nome: '',
		razao_social: '',
		cnpj: '',
		celular: '',
		data_nascimento: '',
		email: '',
		site: '',
		renda: '',
		endereco: {
			cep: '',
			rua: '',
			numero: '',
			complemento: '',
			bairro: '',
			cidade: '',
			estado: '',
		},
	});

	const GeneratedConta = useSelector((state) => state.conta);

	//state de gerar usuario
	const [portalAcess, setPortalAcess] = useState('0');
	const [dadosUser, setDadosUser] = useState({
		conta_id: '',
		email: '',
    nome: '',
    documento: '',
	});
	useEffect(() => {
		setDadosUser({
			...dadosUser,
			conta_id: GeneratedConta.id,
			email: GeneratedConta.email,
			nome: GeneratedConta.nome,
			documento: GeneratedConta.documento,
		});
	}, [GeneratedConta]);

	//state de conta bancaria
	const [contaBancaria, setContaBancaria] = useState({
		banco: '',
		agencia: '',
		tipo: '1',
		conta: '',
	});

	const STEPS_COMPONENTS = {
		[STEPS.CADASTRO_DA_CONTA]: <NewAccount conta={conta} setConta={setConta} errosConta={errosConta} />,
		[STEPS.ACESSO_PORTAL]: (
			<>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Typography>Gerar acesso ao portal?</Typography>
					<RadioGroup row value={portalAcess} onChange={(e) => setPortalAcess(e.target.value)}>
						<FormControlLabel value="0" control={<Radio />} label="Não" />
						<FormControlLabel value="1" control={<Radio />} label="Sim" />
					</RadioGroup>
					{portalAcess === '1' ? (
            <>
              <TextField
                style={{ width: '50%' }}
                type="email"
                value={dadosUser.email}
                label="E-mail"
                disabled
              />
              <InputMask
                mask={"999.999.999-99"}
                value={dadosUser.documento}
                onChange={(e) =>
                  setDadosUser({
                    ...dadosUser,
                    documento: e.target.value,
                  })
                }
              >
                {() => (
                  <TextField
                    label="Documento"
                    style={{ width: '50%' }}
                  />
                )}
              </InputMask>
              <TextField
                style={{ width: '50%' }}
                value={dadosUser.nome}
                label="Nome"
                onChange={(e) =>
                  setDadosUser({
                    ...dadosUser,
                    nome: e.target.value,
                  })
                }
              />
            </>
					) : null}
				</Box>
			</>
		),
		[STEPS.CONTA_BANCARIA]: (
			<CreateBankAccount
        contaId={GeneratedConta.id}
				contaBancaria={contaBancaria}
				setContaBancaria={setContaBancaria}
			/>
		),
	};

	const handleClick = async () => {
		setLoading(true);
    setErrosConta({});

		if (activeStep === 0) {
      let newConta = conta;
      const resConta = await dispatch(postNewConta(newConta));
      if (resConta) {
        setErrosConta(getResponseError(resConta));
      } else {
        toast.success('Conta cadastrada com sucesso!');
        setActiveStep(activeStep + 1);
      }
		}
		if (activeStep === 1) {
			if (portalAcess === '1') {
				const resUser = await dispatch(postUsuarioConta(dadosUser));
				if (resUser) {
					toast.error('Erro ao cadastrar novo usuario');
				} else {
					toast.success('Usuario cadastrado com sucesso');
					setActiveStep(activeStep + 1);
				}
			} else {
				setActiveStep(activeStep + 1);
			}
		}
		if (activeStep === steps.length - 1) {
			toast.success('Cadastro de conta digital concluido com sucesso');
			history.push('/dashboard/contas-digitais');
		}

    setLoading(false);
	};

	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />
			<CustomBreadcrumbs path1="Nova Conta Digital" />
			<Paper className={classes.paper}>
				<Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				{STEPS_COMPONENTS[steps[activeStep]]}
				<Box alignSelf="flex-end" marginTop="16px">
					<GradientButton
						buttonText={activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
						onClick={handleClick}
					/>
				</Box>
			</Paper>
		</Box>
	);
};

export default AccountRegistration;
