import {
	Box,
	makeStyles,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React from 'react';
import { toast } from 'react-toastify';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		display: 'flex',
		padding: '12px',
		backgroundColor: '#EDEDF4',
		color: '#35322f',
		transition: `${theme.transitions.create(
			['background-color', 'transform'],
			{
				duration: theme.transitions.duration.standard,
			}
		)}`,
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: theme.palette.secondary.light,
			transform: 'scale(1.05)',
		},
		height: '100px',
		animation: `$myEffect 500ms ${theme.transitions.easing.easeInOut}`,
	},
	'@keyframes myEffect': {
		'0%': {
			opacity: 1,
			transform: 'translateX(20%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateX(0)',
		},
	},

	textContainer: {
		display: 'flex',
		alignItems: 'center',
		fontWeight: '400',
		[theme.breakpoints.up('md')]: {
			fontWeight: '500',
		},
	},

	subTextContainer: {
		display: 'flex',
    alignItems: 'center',
		fontWeight: '400',
		[theme.breakpoints.up('md')]: {
			fontWeight: '500',
		},
	},
}));

const CustomCard = ({
	icon,
	link,
	text,
	subtext,
	children,
	aprovada,
	rejeitada,
	...rest
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box>
			<Box
				style={{ borderRadius: 20 }}
				display="flex"
        alignItems="center"
				onClick={
					link === null
						? () =>
								toast.warning(
									'Sem permissão para acessar essa funcionalidade'
								)
						: null
				}
				className={classes.cardContainer}
				{...rest}
			>
				{matches ? (
					''
				) : (
					<Box
						style={{
							display: 'flex',
							borderRadius: 32,
							minWidth: '64px',
              height: '64px',
							backgroundColor: 'white',
							alignItems: 'center',
							justifyContent: 'center',
              marginRight: "8px"
						}}
					>
						<PersonIcon
							color={'primary'}
							style={{
								fontSize: 40,
								color: aprovada ? 'green' : rejeitada ? 'red' : null,
							}}
						/>
					</Box>
				)}

				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
            alignItems: 'center',
					}}
				>
					<Typography
						variant="h6"
						className={classes.textContainer}
						align="center"
						style={{ color: '#9D9CC6' }}
					>
						{text}
					</Typography>
					<Typography
						variant="h6"
						className={classes.subTextContainer}
						style={{ color: '#4C4B97' }}
					>
						{children}
						{subtext}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default CustomCard;
