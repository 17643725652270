import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	Button,
	CircularProgress,
	LinearProgress,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import useDebounce from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
	loadContas,
	loadContasFilter,
  loadContasFilhas,
	loadUserData,
	postTransferenciaAction,
} from '../../../actions/actions';
import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../reusable/CustomTable/CustomTable';
import LoadingScreen from '../../reusable/LoadingScreen/LoadingScreen';
import SearchBar from '../../reusable/SearchBar/SearchBar';
import MakeTransferModal from './MakeTransferModal/MakeTransferModal';

const columns = [
	{
		headerText: 'Conta',
		key: 'custom_conta',
		FullCustom: (row) => {
			return (
				<Typography>{row.razao_social ?? row.nome}</Typography>
			);
		},
	},
	{ headerText: 'Tipo', key: 'tipo' },
	{
		headerText: 'Documento',
		key: 'custom_documento',
		FullCustom: (row) => {
			return (
				<Typography>{row.cnpj ?? row.documento}</Typography>
			);
		},
	},
	{ headerText: 'E-mail', key: 'email' },
	{ headerText: 'Contato', key: 'celular' },
	{
		headerText: '',
		key: 'menu',
	},
];

const MakeTransfer = () => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const contas = useSelector((state) => state.contas);
	useEffect(() => {
		dispatch(loadContas(page));
	}, [page]);
	const userData = useSelector((state) => state.userData);
	const [errosTransferencia, setErrosTransferencia] = useState({});

	useEffect(() => {
		dispatch(loadUserData());
	}, [userData.saldo.valor]);

	const [filters, setFilters] = useState({
		like: ''
	});

  const debouncedLike = useDebounce(filters.like, 800);

	useEffect(() => {
		dispatch(loadContasFilhas({ page, like: debouncedLike }));
	}, [dispatch, page, debouncedLike]);

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	const Editar = ({ row }) => {
		const [transferenciaInfos, setTransferenciaInfos] = useState({
			conta_destino_id: null,
			valor: 0,
			descricao: '',
		});

		const [open, setOpen] = useState(false);

		const handleTransferencia = async () => {
			setLoading(true);
			if (transferenciaInfos.conta_destino_id) {
				const resTransferencia = await dispatch(postTransferenciaAction(transferenciaInfos));
				console.log(resTransferencia);
				if (resTransferencia) {
					toast.error(
						resTransferencia.descricao
							? resTransferencia.descricao.join(' ')
							: resTransferencia.valor
							? resTransferencia.valor.join(' ')
							: 'Erro na transferência'
					);
					setErrosTransferencia(resTransferencia);
					setLoading(false);
				} else {
					setLoading(false);
					toast.success('Transferencia relizada com sucesso!');
					setOpen(false);
					await dispatch(loadUserData());
				}
			}
		};

		return (
			<Box display="flex">
				<Button
					variant="outlined"
					onClick={() => {
						setTransferenciaInfos({
							...transferenciaInfos,
							conta_destino_id: row.id,
						});
						setOpen(true);
					}}
				>
					<Typography style={{ marginRight: '6px' }} variant="overline">
						Transferir
					</Typography>
					<FontAwesomeIcon icon={faArrowAltCircleRight} />
				</Button>
				<MakeTransferModal
					open={open}
					onClose={() => setOpen(false)}
					transferenciaInfos={transferenciaInfos}
					setTransferenciaInfos={setTransferenciaInfos}
					nome={row.nome}
					documento={row.documento}
					razaoSocial={row.razao_social}
					cnpj={row.cnpj}
					handleTransferencia={handleTransferencia}
					errosTransferencia={errosTransferencia}
				/>
			</Box>
		);
	};

	return (
		<Box display="flex" flexDirection="column">
			<LoadingScreen isLoading={loading} />
			<CustomBreadcrumbs path1="Realizar Transferência" />
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? 'column' : null}>
				<Box alignSelf="center">
					<Typography style={{ marginTop: '8px' }} variant="h4">
						Realizar Transferência
					</Typography>
				</Box>
				<Box display="flex" borderRadius="27px" padding="8px" boxShadow={3}>
					<Box display="flex" flexDirection="column" justifyContent="center" padding="6px" width="100%">
						<Typography
							align="center"
							variant="h4"
							style={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}
						>
							{userData.saldo.valor ? (
								<CurrencyFormat
									value={userData.saldo.valor.replace('.', ',')}
									displayType={'text'}
									thousandSeparator={'.'}
									decimalSeparator={','}
									prefix={'R$ '}
									renderText={(value) => <div>{value}</div>}
								/>
							) : (
								<CircularProgress style={{ width: '25px' }} />
							)}
						</Typography>
						<Typography variant="overline" align="center">
							Saldo disponível
						</Typography>
					</Box>
				</Box>
			</Box>

			<Box marginTop="16px" marginBottom="16px">
				<SearchBar
					fullWidth
					placeholder="Pesquisar por nome, documento, e-mail..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
			</Box>

			<>
				{contas.data && contas.per_page ? (
					<CustomTable columns={columns} data={contas.data} Editar={Editar} />
				) : (
					<LinearProgress />
				)}
				<Box alignSelf="flex-end" marginTop="8px">
					{
						<Pagination
							variant="outlined"
							color="secondary"
							size="large"
							count={contas.last_page}
							onChange={handleChangePage}
							page={page}
						/>
					}
				</Box>
			</>
		</Box>
	);
};

export default MakeTransfer;
