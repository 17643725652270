import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
	button: {
    height: '15px',
    width: '10px'
	},
}));

const OptionMenuButton = (props) => {
  const classes = useStyles();

	return (
    <Button
      {...props}
      className={classes.button}
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={props.onClick}
    >
      ...
    </Button>
	);
};

export default OptionMenuButton;
