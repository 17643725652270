import { Typography } from "@material-ui/core";
import TransactionStatusColumn from "components/reusable/TableColumns/TransactionStatusColumn";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import React from "react";
import { formatMoney } from "utils/money";
import TransactionValidationColumn from "components/reusable/TableColumns/TransactionValidationColumn";

const columns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (created_at) => {
      return <DateTimeColumn dateTime={created_at} />
    },
  },
  {
    headerText: "Situação",
    key: "transaction.status",
    CustomValue: (status) => {
      return <TransactionStatusColumn status={status} />
    },
  },
  {
    headerText: "Validação",
    key: "transaction",
    CustomValue: (transaction) => {
      return <TransactionValidationColumn transaction={transaction} />
    }
  },
  {
    headerText: "Valor",
    key: "transaction.amount",
    CustomValue: (amount) => {
      if (amount < 0) {
        return (
          <Typography
            style={{ fontSize: 17, fontWeight: 600, color: "red" }}
          >
            {formatMoney(amount)}
          </Typography>
        );
      } else {
        return (
          <Typography
            style={{ fontSize: 17, fontWeight: 600, color: "green" }}
          >
            {formatMoney(amount)}
          </Typography>
        );
      }
    },
  },
  {
    headerText: "Tipo",
    key: "transaction",
    CustomValue: (transaction) => {
      const type = transaction.payment_type;
      if (type === "credit") {
        const installments = transaction.installment_plan
          ? transaction.installment_plan.number_installments
          : 1;
        const flag = transaction.payment_method.card_brand;
        return (
          <Typography>
            Crédito {installments}x - {flag}
          </Typography>
        );
      }
      if (type === "debit") {
        return <Typography>Débito</Typography>;
      }
      if (type === "boleto") {
        return <Typography>Boleto</Typography>;
      }
      if (type === "commission") {
        return <Typography>Comissão</Typography>;
      }
      if (type === "pix") {
        return <Typography>Pix</Typography>;
      }
    },
  },
];

export default columns;
