import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";

const UserAccountId = ({ textColor }) => {
  const userData = useSelector((state) => state.userData);

  return (
    <Box display="flex">
      <Typography style={{ color: textColor }}>Seu ID</Typography>
      <Tooltip title="Copiar">
        <CopyToClipboard text={userData.id}>
          <Button
            aria="Copiar"
            style={{
              marginLeft: "8px",
              padding: 0,
              minWidth: 0,
              width: "20px",
              height: "20px",
              color: "green",
            }}
            onClick={(e) => {
              toast.success("Id da conta copiado!", {
                autoClose: 2000,
              });
            }}
          >
            <FontAwesomeIcon
              style={{
                width: "20px",
                height: "20px",
              }}
              icon={faCopy}
            />
          </Button>
        </CopyToClipboard>
      </Tooltip>
    </Box>
  );
};

export default UserAccountId;
