import { Box, makeStyles, Paper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { EditPagador, loadPagadorId } from '../../../../actions/actions';
import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import GradientButton from '../../../reusable/GradientButton/GradientButton';
import NewPayer from '../../../reusable/Payer/NewPayer/NewPayer';
import LoadingScreen from '../../../reusable/LoadingScreen/LoadingScreen';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},

	paper: {
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '27px',
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column',
		width: '800px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}));

const EditPayer = ({ disableBreadcrumbs }) => {
	const classes = useStyles();
	const history = useHistory();
	const { id, subsection } = useParams();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [pagador, setPagador] = useState({
		conta_id: null,
		documento: '',
    tipo: '',
		nome: '',
		celular: '',
		email: '',
		data_nascimento: '',
		endereco: {
			cep: ' ',
			rua: '',
			numero: '',
			complemento: '',
			bairro: '',
			cidade: '',
			estado: '',
		},
	});
	useEffect(() => {
		dispatch(loadPagadorId(id));
	}, []);

	const pagadorId = useSelector((state) => state.pagador);

	useEffect(() => {
		setPagador({ ...pagadorId });
	}, [pagadorId]);
	const [errosPagador, setErrosPagador] = useState({});

	const disableEditarAll = true;

	const handleAlterar = async () => {
		setLoading(true);
		const resPagador = await dispatch(EditPagador(pagador, id));

		if (resPagador) {
			setErrosPagador(resPagador);
			toast.error('Erro ao alterar dados');
			setLoading(false);
		} else {
			toast.success('Dados alterados com sucesso!');
			// history.push('/dashboard/pagadores');
			history.goBack()
			setLoading(false);
		}
	};
	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />
			{disableBreadcrumbs ? null : (
				<CustomBreadcrumbs path1="Pagadores" to1="/dashboard/pagadores" path2="Editar Pagador" />
			)}

			<Paper className={classes.paper}>
				<NewPayer
					pagador={pagador}
					setPagador={setPagador}
					errosPagador={errosPagador}
					disableEditar={true}
					disableEditarAll={subsection === 'cobrar' ? disableEditarAll : null}
				/>
				{subsection === 'cobrar' ? null : (
					<Box alignSelf="flex-end" marginTop="16px">
						<GradientButton
							buttonText={pagador.id ? "Atualizar" : "Cadastrar"}
							disabled={subsection === 'cobrar' ? disableEditarAll : null}
							onClick={handleAlterar}
						/>
					</Box>
				)}
			</Paper>
		</Box>
	);
};

export default EditPayer;
