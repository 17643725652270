import { Box, Typography } from "@material-ui/core";
import React from "react";
import GradientButton from "../GradientButton/GradientButton";
import { generatePath, useHistory } from "react-router";
import BookletDetails from "./BookletDetails";

const SlipOriginDetails = ({ boleto }) => {
  const history = useHistory();

  const pushToFatherShipmentFile = () => {
    const path = generatePath("/dashboard/arquivo-remessa/:id/itens", {
      id: boleto.item_remessa.arquivo_remessa_id,
    });
    history.push(path);
  };

  if (boleto.item_remessa) {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">Arquivo Remessa</Typography>
        <GradientButton
          buttonText="Visualizar"
          onClick={pushToFatherShipmentFile}
          style={{ marginLeft: "6px", background: "green" }}
        />
      </Box>
    );
  }
  if (boleto.carne_id) {
    return (
      <Box>
        <Typography variant="h6">Carne</Typography>
        {boleto?.carne_id && (
          <BookletDetails slip={boleto} booklet={boleto.carne} />
        )}
      </Box>
    );
  }
  return <Typography variant="h6">Manual</Typography>;
};

export default SlipOriginDetails;
