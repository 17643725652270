import { Box, Button, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentBookletFinished from '../PaymentBookletFinished/PaymentBookletFinished';
import EditPayer from '../../Payers/EditPayer/EditPayer';
import PaymentBookletCadastro from '../PaymentBookletCadastro/PaymentBookletCadastro';
import { postCarneAction } from '../../../../actions/actions';
import { toast } from 'react-toastify';
import LoadingScreen from '../../../reusable/LoadingScreen/LoadingScreen';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '800px',
		marginLeft: 'auto',
		marginRight: 'auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},

	paper: {
		marginBottom: theme.spacing(6),
		marginTop: theme.spacing(3),
		padding: theme.spacing(3),
		borderRadius: '27px',
		width: '850px',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
		height: '60px',
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
		color: 'white',
	},
	headerPaper: {
		padding: theme.spacing(3),
		borderRadius: '27px 0 0 0 ',
		display: 'flex',
		justifyContent: 'space-between',
	},
}));

const STEPS = {
	DADOS_DO_PAGADOR: 'Dados do pagador', //payer registration
	DADOS_DO_CARNE: 'Dados do Carnê', // slip registration
	CONCLUSAO: 'Conclusão',
};

const PaymentBookletRegistration = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const [activeStep, setActiveStep] = useState(0);
	const [steps] = useState([STEPS.DADOS_DO_PAGADOR, STEPS.DADOS_DO_CARNE, STEPS.CONCLUSAO]);
	const [errosCarne, setErrosCarne] = useState({});

	const [carne, setCarne] = useState({
		pagador_id: '',
		valor: '',
		parcelas: '',
		descricao: '',
		instrucao1: '',
		instrucao2: '',
		instrucao3: '',
		data_vencimento: '',
	});

	const pagadorID = useSelector((state) => state.pagador);

	useEffect(() => {
		setCarne({ ...carne, pagador_id: pagadorID.id });
	}, [pagadorID]);

	const handleFirstClick = () => {
		setActiveStep(activeStep + 1);
	};

	const handleSecondClick = async () => {
		setLoading(true);
		let newCarne = carne;
		setCarne(newCarne);

		const resCarne = await dispatch(postCarneAction(newCarne));

		if (resCarne) {
      if(resCarne.response?.status === 403) toast.error(resCarne.response.data.message);
			setErrosCarne(resCarne);
			setActiveStep(1);
			setLoading(false);
		} else {
			toast.success('Carnê criado com sucesso');
			setActiveStep(activeStep + 1);
			setLoading(false);
		}
	};

	const carneConcluido = useSelector((state) => state.carne);

	const STEPS_COMPONENTS = {
		[STEPS.DADOS_DO_PAGADOR]: <EditPayer diableBreadcrumbs />,
		[STEPS.DADOS_DO_CARNE]: (
			<PaymentBookletCadastro carne={carne} setCarne={setCarne} errosCarne={errosCarne} />
		),
		[STEPS.CONCLUSAO]: <PaymentBookletFinished carneConcluido={carneConcluido} />,
	};

	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />

			<Box className={classes.layout}>
				{STEPS_COMPONENTS[steps[activeStep]]}
				<Box className={classes.buttons}>
					{activeStep !== 2 ? (
						<Button
							style={{ borderRadius: '27px' }}
							variant="contained"
							color="secondary"
							onClick={activeStep === 0 ? handleFirstClick : handleSecondClick}
							className={classes.button}
						>
							Próximo
						</Button>
					) : null}
				</Box>
			</Box>
		</Box>
	);
};

export default PaymentBookletRegistration;
