import {
  Box,
  LinearProgress,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTable from "../../reusable/CustomTable/CustomTable";
import SearchBar from "../../reusable/SearchBar/SearchBar";
import GradientButton from "../../reusable/GradientButton/GradientButton";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { loadLinkPagamentoFilter } from "../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Link, useHistory, useParams } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDebounce from "../../../hooks/useDebounce";
import { APP_CONFIG } from "constants/config";
import CustomCopyToClipboard from "components/reusable/Text/CustomCopyToClipboard";

const columns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (data) => {
      const date = new Date(data);
      const option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      const formatted = date.toLocaleDateString("pt-br", option);
      return <Typography align="center"> {formatted}</Typography>;
    },
  },
  {
    headerText: "Link de acesso",
    key: "id",
    CustomValue: (id) => {
      return (
        <Box display="flex" justifyContent="center">
          <CustomCopyToClipboard
            value={APP_CONFIG().linkApp + "/link-pagamento/" + id + "/pagar"}
            title="Copiar"
            message="Link copiado com sucesso"
          />
        </Box>
      );
    },
  },

  {
    headerText: "Situação",
    key: "status_string",
    CustomValue: (status_string) => {
      return status_string === "Ativo" ? (
        <Typography
          style={{
            color: "green",
            fontWeight: "bold",
            borderRadius: "27px",
          }}
        >
          {status_string}
        </Typography>
      ) : (
        <Typography
          style={{
            color: "#dfad06",
            fontWeight: "bold",
            borderRadius: "27px",
          }}
        >
          {status_string}
        </Typography>
      );
    },
  },
  { headerText: "Parcelas", key: "limite_parcelas" },
  {
    headerText: "Limite",
    key: "quantidade_utilizacoes",
    CustomValue: (value) => {
      if (value === 0) return "-";
      return value;
    },
  },
  { headerText: "Pagos", key: "pagamento_count" },
  {
    headerText: "Vencimento",
    key: "vencimento",
    CustomValue: (data) => {
      if (data !== null) {
        const p = data.split(/\D/g);
        const dataFormatada = [p[2], p[1], p[0]].join("/");
        return <Typography align="center">{dataFormatada}</Typography>;
      }
    },
  },
  {
    headerText: "Valor",
    key: "valor",
    CustomValue: (value) => <p>R$ {value}</p>,
  },
  {
    headerText: "Descrição",
    key: "descricao",
    CustomValue: (descricao) => {
      return (
        <Tooltip title={descricao ? descricao : "Sem descrição"}>
          <Box>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </Box>
        </Tooltip>
      );
    },
  },
];

const AccountStatement = () => {
  const [filters, setFilters] = useState({
    like: "",
    order: "",
    mostrar: "",
  });
  const debouncedLike = useDebounce(filters.like, 800);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const theme = useTheme();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const userData = useSelector((state) => state.userData);
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      loadLinkPagamentoFilter(
        page,
        debouncedLike,
        filters.order,
        filters.mostrar,
        id ?? userData.id
      )
    );
  }, [
    dispatch,
    page,
    debouncedLike,
    filters.order,
    filters.mostrar,
    id,
    userData.id,
  ]);

  const linkPagamentos = useSelector((state) => state.linkPagamentos);

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const handleClickRow = (row) => {
    const path = generatePath("/dashboard/detalhes-link/:id/ver", {
      id: row.id,
    });
    history.push(path);
  };

  return (
    <Box display="flex" flexDirection="column">
      {id ? (
        <CustomBreadcrumbs
          path1="Gerenciar Listas"
          to1="goBack"
          path2="Link de pagamento"
          to2="#"
        />
      ) : (
        <CustomBreadcrumbs path1="Link de pagamento" to1="#" />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Typography style={{ marginTop: "8px" }} variant="h4">
          Link de Pagamento
        </Typography>

        {id ? null : (
          <Link to="novo-link-pagamento">
            <GradientButton buttonText="+Novo Link" />
          </Link>
        )}
      </Box>
      <Box marginTop="16px" marginBottom="16px">
        <SearchBar
          fullWidth
          placeholder="Pesquisar por valor, descrição, número do pedido..."
          value={filters.like}
          onChange={(e) =>
            setFilters({
              ...filters,
              like: e.target.value,
            })
          }
        />
      </Box>

      {linkPagamentos.data && linkPagamentos.per_page ? (
        <CustomTable
          columns={columns}
          data={linkPagamentos.data}
          handleClickRow={handleClickRow}
        />
      ) : (
        <LinearProgress />
      )}
      <Box alignSelf="flex-end" marginTop="8px">
        <Pagination
          variant="outlined"
          color="secondary"
          size="large"
          count={linkPagamentos.last_page}
          onChange={handleChangePage}
          page={page}
        />
      </Box>
    </Box>
  );
};

export default AccountStatement;
