export const getAuthToken = () => {
  const auth = JSON.parse(localStorage.getItem("@auth"));

  return auth ? auth.access_token : null;
};

export const setAuthToken = async (authToken) => {
  const auth = JSON.parse(localStorage.getItem("@auth"));

  localStorage.setItem(
    "@auth", JSON.stringify({
      ...auth,
      access_token: authToken,
      login_time: new Date().getTime()
    })
  );
};
