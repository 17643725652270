export const indexPosQuery = ({ page, accountId }) => ({
  url: `/point_of_sales`,
  method: "get",
  params: {
    page,
    conta_id: accountId,
  },
});

export const showPosQuery = ({ posId }) => ({
  url: `/point_of_sales/${posId}`,
  method: "get",
});

export const posTransactionsQuery = ({ posId }) => ({
  url: `/point_of_sales/${posId}/transactions`,
  method: "get",
});

export const storePosMutation = ({ token, conta_id }) => ({
  url: `/point_of_sales`,
  method: "post",
  data: {
    token,
    conta_id,
  },
});

export const updatePosMutation = ({ posId, name }) => ({
  url: `/point_of_sales/${posId}`,
  method: "put",
  data: {
    name,
  },
});

export const deletePosMutation = ({ posId }) => ({
  url: `/point_of_sales/${posId}`,
  method: "delete",
});
