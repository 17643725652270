import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomCurrencyInput from 'components/reusable/Text/CustomCurrencyInput';
import CustomFormHelperText from 'components/reusable/Text/CustomFormHelperText';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	layout: {
		width: '800px',
		marginLeft: 'auto',
		marginRight: 'auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	paper: {
		marginBottom: theme.spacing(6),
		marginTop: theme.spacing(3),
		padding: theme.spacing(3),
		borderRadius: '27px',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
		height: '60px',
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
		color: 'white',
	},
	headerPaper: {
		padding: theme.spacing(3),
		borderRadius: '27px 0 0 0 ',
		display: 'flex',
		justifyContent: 'space-between',
	},
	currency: {
		font: 'inherit',
		color: 'currentColor',
		width: '100%',
		border: '0px',
		borderBottom: '1px solid gray',
		height: '1.1876em',
		margin: 0,
		display: 'block',
		padding: '6px 0 7px',
		minWidth: 0,
		background: 'none',
		boxSizing: 'content-box',
		animationName: 'mui-auto-fill-cancel',
		letterSpacing: 'inherit',
		animationDuration: '10ms',
		appearance: 'textfield',
		textAlign: 'start',
		paddingLeft: '5px',
	},
}));

const PaymentBookletCadastro = ({ carne, setCarne, errosCarne }) => {
	const classes = useStyles();
	const [arrayParcelas, setArrayParcelas] = useState([]);

	useEffect(() => {
		const arrayParcelasVar = [];
		for (var i = 2; i < 13; i++) {
			arrayParcelasVar.push(i);
		}
		setArrayParcelas(arrayParcelasVar);
	}, []);

	return (
		<Box className={classes.root}>
			<Typography variant="h4" align="center">
				Dados do Carnê
			</Typography>
			<Paper className={classes.paper}>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Box display="flex" flexDirection="column">
                <CustomCurrencyInput
									prefix="R$ "
									value={carne.valor}
                  label="Valor do carne"
									onChangeEvent={(event, maskedvalue, floatvalue) =>
										setCarne({ ...carne, valor: floatvalue })
									}
                  errorMessage={errosCarne.valor ? errosCarne.valor.join(' ') : null}
								/>
							</Box>
						</Grid>

						<Grid item xs={12} sm={8}>
							<InputLabel> Quantidade de parcelas</InputLabel>
							<Select
								fullWidth
								value={carne.parcelas}
								onChange={(e) =>
									setCarne({
										...carne,
										parcelas: e.target.value,
									})
								}
							>
								{arrayParcelas.map((parcela) => (
									<MenuItem value={parcela} key={parcela}>
										{parcela}
									</MenuItem>
								))}
							</Select>
              <CustomFormHelperText
                errorMessage={errosCarne.parcelas ? errosCarne.parcelas.join(' ') : null}
              />
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								fullWidth
								error={errosCarne.data_vencimento}
								helperText={errosCarne.data_vencimento ? errosCarne.data_vencimento.join(' ') : null}
								InputLabelProps={{
									shrink: true,
									pattern: 'd {4}- d {2}- d {2} ',
								}}
								type="date"
								label="Data de Vencimento"
								value={carne.data_vencimento}
								onChange={(e) =>
									setCarne({
										...carne,
										data_vencimento: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								error={errosCarne.instrucao1}
								helperText={errosCarne.instrucao1 ? errosCarne.instrucao1.join(' ') : null}
								label="Instruções linha 1"
								value={carne.instrucao1}
								onChange={(e) =>
									setCarne({
										...carne,
										instrucao1: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								error={errosCarne.instrucao2}
								helperText={errosCarne.instrucao2 ? errosCarne.instrucao2.join(' ') : null}
								label="Instruções linha 2"
								value={carne.instrucao2}
								onChange={(e) =>
									setCarne({
										...carne,
										instrucao2: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								error={errosCarne.instrucao3}
								helperText={errosCarne.instrucao3 ? errosCarne.instrucao3.join(' ') : null}
								label="Instruções linha 3"
								value={carne.instrucao3}
								onChange={(e) =>
									setCarne({
										...carne,
										instrucao3: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								error={errosCarne.descricao}
								helperText={errosCarne.descricao ? errosCarne.descricao.join(' ') : null}
								required
								label="Descrição do carne"
								value={carne.descricao}
								onChange={(e) =>
									setCarne({
										...carne,
										descricao: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography>Deseja aplicar multa após o vencimento do carne?</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box display="flex" flexDirection="column" width="100%">
										<FormControl component="fieldset">
											<FormLabel component="legend">Forma de cobrança</FormLabel>
											<RadioGroup
												row
												value={carne.tipo_multa}
												onChange={(e) =>
													setCarne({
														...carne,
														tipo_multa: e.target.value,
													})
												}
											>
												<FormControlLabel value="1" control={<Radio />} label="Fixo" />
												<FormControlLabel value="2" control={<Radio />} label="Percentual" />
											</RadioGroup>
                      <CustomFormHelperText
                        errorMessage={errosCarne.tipo_multa ? errosCarne.tipo_multa.join(' ') : null}
                      />
                      {carne.tipo_multa === '1' ? (
                        <CustomCurrencyInput
                          value={carne.valor_multa}
                          label="Valor da multa"
                          prefix="R$ "
                          onChangeEvent={(event, maskedvalue, floatvalue) =>
                            setCarne({
                              ...carne,
                              valor_multa: floatvalue,
                            })
                          }
                          errorMessage={errosCarne.valor_multa ? errosCarne.valor_multa.join(' ') : null}
                        />
                      ) : (
                        <CustomCurrencyInput
                          value={carne.valor_multa}
                          label="Valor da multa"
                          suffix=" %"
                          onChangeEvent={(event, maskedvalue, floatvalue) =>
                            setCarne({
                              ...carne,
                              valor_multa: floatvalue,
                            })
                          }
                          errorMessage={errosCarne.valor_multa ? errosCarne.valor_multa.join(' ') : null}
                        />
                      )}
										</FormControl>
									</Box>
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12}>
							<Accordion>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography>Deseja aplicar juros após o vencimento do carne?</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box display="flex" flexDirection="column" width="100%">
										<FormControl component="fieldset">
											<FormLabel component="legend">Forma de cobrança</FormLabel>
											<RadioGroup
												row
												value={carne.tipo_juros}
												onChange={(e) =>
													setCarne({
														...carne,
														tipo_juros: e.target.value,
													})
												}
											>
												<FormControlLabel value="1" control={<Radio />} label="Valor diário" />
												<FormControlLabel value="2" control={<Radio />} label="Percentual diário" />
												<FormControlLabel value="3" control={<Radio />} label="Percentual mensal" />
											</RadioGroup>
											<CustomFormHelperText 
                        errorMessage={errosCarne.tipo_juros ? errosCarne.tipo_juros.join(' ') : null}
                      />
											{carne.tipo_juros === '2' || carne.tipo_juros === '3' ? (
												<CustomCurrencyInput
													suffix=" %"
													value={carne.valor_juros}
                          label="Valor do juros"
													onChangeEvent={(event, maskedvalue, floatvalue) =>
														setCarne({
															...carne,
															valor_juros: floatvalue,
														})
													}
                          errorMessage={errosCarne.valor_juros ? errosCarne.valor_juros.join(' ') : null}
												/>
											) : (
												<CustomCurrencyInput
													prefix="R$ "
													value={carne.valor_juros}
                          label="Valor do juros"
													onChangeEvent={(event, maskedvalue, floatvalue) =>
														setCarne({
															...carne,
															valor_juros: floatvalue,
														})
													}
                          errorMessage={errosCarne.valor_juros ? errosCarne.valor_juros.join(' ') : null}
												/>
											)}
										</FormControl>
									</Box>
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12}>
							<Accordion>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography>Deseja aplicar desconto?</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box display="flex" flexDirection="column" width="100%">
										<FormControl component="fieldset">
											<FormLabel component="legend">Forma de desconto</FormLabel>
											<RadioGroup
												row
												value={carne.tipo_desconto}
												onChange={(e) =>
													setCarne({
														...carne,
														tipo_desconto: e.target.value,
													})
												}
											>
												<FormControlLabel value="1" control={<Radio />} label="Fixo" />
												<FormControlLabel value="2" control={<Radio />} label="Percentual" />
											</RadioGroup>
                      <CustomFormHelperText 
                        errorMessage={errosCarne.tipo_desconto ? errosCarne.tipo_desconto.join(' ') : null}
                      />
                      {carne.tipo_desconto === '1' ? (
                        <CustomCurrencyInput
                          prefix="R$ "
                          value={carne.valor_desconto}
                          label="Valor do desconto"
                          onChangeEvent={(event, maskedvalue, floatvalue) =>
                            setCarne({
                              ...carne,
                              valor_desconto: floatvalue,
                            })
                          }
                          errorMessage={errosCarne.valor_desconto ? errosCarne.valor_desconto.join(' ') : null}
                        />
                      ) : (
                        <CustomCurrencyInput
                          suffix=" %"
                          value={carne.valor_desconto}
                          label="Valor do desconto"
                          onChangeEvent={(event, maskedvalue, floatvalue) =>
                            setCarne({
                              ...carne,
                              valor_desconto: floatvalue,
                            })
                          }
                          errorMessage={errosCarne.valor_desconto ? errosCarne.valor_desconto.join(' ') : null}
                        />
                      )}
										</FormControl>

										<Box marginTop="12px">
											<TextField
												type="date"
												fullWidth
												label="Data Limite"
												error={errosCarne.data_limite_valor_desconto}
												helperText={errosCarne.data_limite_valor_desconto ? errosCarne.data_limite_valor_desconto.join(' ') : null}
												value={carne.data_limite_valor_desconto}
												onChange={(e) =>
													setCarne({
														...carne,
														data_limite_valor_desconto: e.target.value,
													})
												}
												InputLabelProps={{
													shrink: true,
													pattern: 'd {4}- d {2}- d {2} ',
												}}
											/>
										</Box>
									</Box>
								</AccordionDetails>
							</Accordion>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Box>
	);
};

export default PaymentBookletCadastro;
