import {
  Box,
  Grid,
  TextField,
  LinearProgress,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Button,
  Link,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import useDebounce from "hooks/useDebounce";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useIndexArquivoRetornoQuery } from "services/api";
import { toast } from "react-toastify";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import DialogExport from "./DialogExport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { downloadReturnFile } from "services/downloadReturnFile";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

const columns = [
  {
    headerText: "Nome",
    key: "name",
    CustomValue: (name) => {
      return <Typography>{name === "" ? "-" : name}</Typography>;
    },
  },
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (created_at) => {
      return <DateTimeColumn dateTime={created_at} />;
    },
  },
  {
    headerText: "Download",
    key: "url",
    CustomValue: (value, data) => (
      <Box textAlign="center">
        {value ? (
          <Button
            onClick={() =>
              downloadReturnFile({
                id: data.id,
                accountId: data.conta_id,
              })
            }
          >
            <Box>
              <FontAwesomeIcon icon={faDownload} size="lg" />
              <Typography>Download</Typography>
            </Box>
          </Button>
        ) : (
          <FontAwesomeIcon spin icon={faSpinner} size="lg" />
        )}
      </Box>
    ),
  },
];

const ArquivoRetornoIndex = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const accountId = useLocation().state?.accountId;
  const isAdm = useLocation().state?.isAdm;

  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    data_inicial: "",
    data_final: "",
  });

  const debouncedDataInicial = useDebounce(filters.data_inicial, 800);
  const debouncedDataFinal = useDebounce(filters.data_final, 800);

  const {
    data: returnFiles,
    isLoading,
    isError,
    isUninitialized,
  } = useIndexArquivoRetornoQuery(
    {
      page,
      data_inicial: debouncedDataInicial,
      data_final: debouncedDataFinal,
      conta_id: accountId,
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 5000,
    }
  );

  const handleArquivosRemessa = () => {
    const path = isAdm
      ? `/dashboard/contas/${accountId}/arquivo-remessa`
      : `/dashboard/arquivo-remessa`;
    history.push(path);
  };

  const handleCloseDialog = () => {
    setOpenExportDialog(false);
  };

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (isError) {
      toast.error("O usuário não está autenticado a entrar nessa página!");
      history.push("/dashboard/home");
    }
  }, [isError, history]);

  return (
    <Box className={classes.root}>
      <CustomBreadcrumbs path1="Arquivos de retorno" to1="" />
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            style={{ color: theme.palette.background.default }}
          >
            ARQUIVOS DE RETORNO
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection={matches ? "column" : null}
        >
          {isAdm ? null : (
            <Button
              variant="outlined"
              style={{
                marginTop: "8px",
                marginBottom: "12px",
                marginLeft: matches ? null : "10px",
              }}
              onClick={() => setOpenExportDialog(true)}
            >
              SOLICITAR NOVO ARQUIVO DE RETORNO
            </Button>
          )}
          <Button
            variant="outlined"
            style={{
              marginTop: "8px",
              marginBottom: "12px",
              marginLeft: matches ? null : "10px",
            }}
            onClick={handleArquivosRemessa}
          >
            ARQUIVOS DE REMESSA
          </Button>
        </Box>
      </Box>

      <DialogExport open={openExportDialog} handleClose={handleCloseDialog} />

      <Box marginBottom="16px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Inicial"
              value={filters.data_inicial}
              onChange={(e) =>
                setFilters({ ...filters, data_inicial: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Final"
              value={filters.data_final}
              onChange={(e) =>
                setFilters({ ...filters, data_final: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </Box>

      <>
        {!isLoading && !isError && !isUninitialized ? (
          <>
            <CustomTable columns={columns} data={returnFiles.data} />
            <Box alignSelf="flex-end" marginTop="8px">
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={returnFiles.last_page}
                onChange={handleChangePage}
                page={page}
              />
            </Box>
          </>
        ) : (
          <LinearProgress />
        )}
      </>
    </Box>
  );
};

export default ArquivoRetornoIndex;
