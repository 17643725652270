export const storeAdmMutation = ({ nome, documento, email }) => ({
  url: `/users/adm`,
  method: "post",
  data: {
    nome,
    documento,
    email,
  },
});

export const indexAdmQuery = ({ page, debouncedLike }) => ({
  url: `/users/adm`,
  method: "get",
  params: {
    page,
    like: debouncedLike,
  }
});

export const storeAgentUserMutation = ({ nome, documento, email, agent_id}) => ({
  url: `/users/agent`,
  method: "post",
  data: {
    nome,
    documento,
    email,
    agent_id
  },
});

export const indexAgentUsersQuery = ({ page, debouncedLike, agent_id }) => ({
  url: `/users/agent`,
  method: "get",
  params: {
    page,
    like: debouncedLike,
    agent_id
  }
});
