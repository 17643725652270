import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
  Toolbar,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import UserMenu from "../SideBar/UserMenu/UserMenu";
import ListItems from "../SideBar/ListItems/ListItems";
import { APP_CONFIG } from "constants/config";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: theme.palette.background.default, 
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    zIndex: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.default,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const DrawerSideBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("@auth");
    history.push("/login");
  };
  
  return (
    <>
      <Box className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar style={{ display: "flex" }}>
            <IconButton
              style={{ color: theme.palette.background.paper, alignSelf: "center" }}
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              {<MenuIcon />}
            </IconButton>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              margin="5px"
            >
              <img
                src={APP_CONFIG().assets.primaryLogo}
                style={{
                  height: "4rem",
                  alignSelf: "center",
                  justifyContent: "center",
                }}
                alt="Logo"
              ></img>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          style={{ zIndex: 2 }}
        >
          <Box className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose} style={{ color: theme.palette.background.paper }}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </Box>
          <Divider />
          <UserMenu />
          <ListItems />
          <Box alignSelf="center" marginBottom="40px">
            <Button style={{ color: theme.palette.menu.text }} onClick={handleLogout}>
              Sair
            </Button>
          </Box>
        </Drawer>
      </Box>
      <Box className={classes.drawerHeader}></Box>
    </>
  );
};

export default DrawerSideBar;
