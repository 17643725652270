export const getTotalFee = (row) => {
  if (
    row.transaction.payment_type === "boleto" &&
    row.transaction.status !== "succeeded"
  )
    return 0;

  return (
    parseFloat(row.transaction.fees) + parseFloat(row.mdr_fee_values)
  ).toFixed(2);
};
