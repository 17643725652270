import {
  Box,
  Grid,
  TextField,
  LinearProgress,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useHistory, generatePath, useParams } from "react-router";
import useDebounce from "hooks/useDebounce";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import {
  useIndexArquivoRemessaQuery,
  useLazyShowArquivoRemessaQuery,
} from "services/api";
import { toast } from "react-toastify";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import DialogUpload from "./DialogUpload";
import { useSelector } from "react-redux";
import useQuery from "hooks/useQuery";
import useQueryParams from "hooks/useQueryParams";
import { clearSearchParams } from "services/functions/clearSearchParams";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDynamicPagination from "hooks/useDynamicPagination";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
  dialogTitle: {
    background: theme.gradient.main,
    color: "white",
    textAlign: "center",
    marginBottom: 8,
  },
  dialogSelectFile: {
    margin: "10px auto",
  },
}));

const amountOfItems = (items) => (items ? items.length : "-");

const successfullyCreatedBills = (items) => {
  const bills = items?.filter((item) => item.boleto_id != null);

  return bills ? bills.length : "-";
};

const columns = [
  { headerText: "Nome", key: "name" },
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (created_at) => {
      return <DateTimeColumn dateTime={created_at} />;
    },
  },
  {
    headerText: "Qnt de detalhes",
    key: "data.details",
    CustomValue: (details) => {
      return <Typography> {amountOfItems(details)}</Typography>;
    },
  },
  {
    headerText: "Qnt de itens criados",
    key: "items",
    CustomValue: (items) => {
      return <Typography> {amountOfItems(items)}</Typography>;
    },
  },
  {
    headerText: "Boletos gerados",
    key: "items",
    CustomValue: (items) => {
      return <Typography> {successfullyCreatedBills(items)}</Typography>;
    },
  },
  { headerText: "Ações", key: "menu" },
];

const Editar = ({ row }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const { id: accountId } = useParams();

  const [showArquivoRemessa] = useLazyShowArquivoRemessaQuery();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowItems = () => {
    const path = generatePath(`/dashboard/arquivo-remessa/${row.id}/itens`);
    history.push(path, { accountId });
  };

  const handleShowShippingFile = async (row) => {
    try {
      const response = await showArquivoRemessa({ id: row.id }).unwrap();
      const path = generatePath(`/dashboard/detalhes-arquivo-remessa/:id/ver`, {
        id: row.id,
      });
      history.push(path, {
        response,
        isOnlyShow: true,
        shippingFileId: row.id,
        accountId,
      });
    } catch (e) {
      toast.error(e.data.error);
    }
  };

  return (
    <Box>
      <>
        <Button
          style={{ height: "15px", width: "10px" }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          ...
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleShowShippingFile(row)}>
            Visualizar Arquivo de Remessa
          </MenuItem>
          <MenuItem onClick={handleShowItems}>
            Visualizar Itens da Remessa
          </MenuItem>
        </Menu>
      </>
    </Box>
  );
};

const ArquivoRemessaIndex = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const query = useQuery();

  const userData = useSelector((state) => state.userData);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [openUploadDialog, setOpenUploadDialog] = useState(false);

  const [page, setPage] = useState(Number(query.get("page")) || 1);

  const initialFilters = {
    nome: query.get("nome"),
    data_inicial: query.get("data_inicial"),
    data_final: query.get("data_final"),
    boleto_id: query.get("boleto_id"),
    numero_documento: query.get("numero_documento"),
    item_remessa_id: query.get("item_remessa_id"),
  };
  const [filters, setFilters] = useState(initialFilters);

  const debounceNome = useDebounce(filters.nome, 800);
  const debouncedDataInicial = useDebounce(filters.data_inicial, 800);
  const debouncedDataFinal = useDebounce(filters.data_final, 800);
  const debouncedBoletoId = useDebounce(filters.boleto_id, 800);
  const debouncedNumeroDocumento = useDebounce(filters.numero_documento, 800);
  const debouncedItemRemessaId = useDebounce(filters.item_remessa_id, 800);

  const accountId = useMemo(() => id ?? userData.id, [id, userData]);
  const isAdm = useMemo(() => id !== undefined, [id]);

  const {
    data: shippingFiles,
    isLoading,
    isError,
    isUninitialized,
  } = useIndexArquivoRemessaQuery(
    {
      page,
      nome: debounceNome,
      data_inicial: debouncedDataInicial,
      data_final: debouncedDataFinal,
      boleto_id: debouncedBoletoId,
      numero_documento: debouncedNumeroDocumento,
      item_remessa_id: debouncedItemRemessaId,
      conta_id: accountId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleCloseDialog = () => {
    setOpenUploadDialog(false);
  };

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const handleArquivosRetorno = () => {
    const path = isAdm
      ? `/dashboard/contas/${accountId}/arquivo-retorno`
      : "/dashboard/arquivo-retorno";
    history.push(path, { accountId, isAdm });
  };

  useEffect(() => {
    if (isError) {
      toast.error("O usuário não está autenticado a entrar nessa página!");
      history.push("/dashboard/home");
    }
  }, [isError, history]);

  useQueryParams(query, filters, page, history);
  useDynamicPagination(page, shippingFiles?.last_page, setPage);

  return (
    <Box className={classes.root}>
      <CustomBreadcrumbs path1="Arquivos de remessa" to1="#" />
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            style={{ color: theme.palette.background.default }}
          >
            ARQUIVOS DE REMESSA
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection={matches ? "column" : null}
        >
          {isAdm ? null : (
            <Button
              variant="outlined"
              style={{ marginTop: "8px", marginBottom: "12px" }}
              onClick={() => setOpenUploadDialog(true)}
            >
              CARREGAR ARQUIVO DE REMESSA
            </Button>
          )}

          <Button
            variant="outlined"
            style={{
              marginTop: "8px",
              marginBottom: "12px",
              marginLeft: matches ? null : "10px",
            }}
            onClick={handleArquivosRetorno}
          >
            ARQUIVOS DE RETORNO
          </Button>
        </Box>
      </Box>

      <DialogUpload open={openUploadDialog} handleClose={handleCloseDialog} />

      <Box marginBottom="16px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar número do documento..."
              fullWidth
              label="Pesquisar"
              value={filters.numero_documento}
              onChange={(e) =>
                setFilters({ ...filters, numero_documento: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar id do item remessa..."
              fullWidth
              label="Pesquisar"
              value={filters.item_remessa_id}
              onChange={(e) =>
                setFilters({ ...filters, item_remessa_id: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar id do boleto..."
              fullWidth
              label="Pesquisar"
              value={filters.boleto_id}
              onChange={(e) =>
                setFilters({ ...filters, boleto_id: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar arquivo por nome..."
              fullWidth
              label="Pesquisar"
              value={filters.nome}
              onChange={(e) => setFilters({ ...filters, nome: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Inicial"
              value={filters.data_inicial}
              onChange={(e) =>
                setFilters({ ...filters, data_inicial: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Final"
              value={filters.data_final}
              onChange={(e) =>
                setFilters({ ...filters, data_final: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Tooltip title="Limpar Filtros">
              <IconButton
                onClick={() => clearSearchParams(filters, setFilters)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      <>
        {!isLoading && !isError && !isUninitialized ? (
          <>
            <CustomTable
              columns={columns}
              data={shippingFiles.data}
              Editar={Editar}
            />
            <Box alignSelf="flex-end" marginTop="8px">
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={shippingFiles.last_page}
                onChange={handleChangePage}
                page={page}
              />
            </Box>
          </>
        ) : (
          <LinearProgress />
        )}
      </>
    </Box>
  );
};

export default ArquivoRemessaIndex;
