import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { useStoreAgentSalesPlanMutation } from "services/api";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
}));

const StoreAgentSalesPlanModal = ({
  openDialog,
  setOpenDialog,
  refetchSalesPlanList,
  isLoading,
  setIsLoading
}) => {
  const classes = useStyles();
  const [storeAgentSalesPlan, storeStatus] = useStoreAgentSalesPlanMutation();
  const storeError = useMemo(() => storeStatus.error ? storeStatus.error.data.errors : false, [storeStatus]);
  const [newSalesPlan, setNewSalesPlan] = useState({
    name: "",
  });

  const handleNewAgentSalesPlan = async () => {
    setIsLoading(true);
    try {
      await storeAgentSalesPlan({
        name: newSalesPlan.name,
      }).unwrap();
      toast.success("Plano de Venda criado com sucesso!");
      setOpenDialog(false);
      refetchSalesPlanList();
    } catch (e) {
      toast.error("Erro ao criar Plano de Venda!");
    }
    setIsLoading(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ zIndex: "1" }}
    >
      <Box width="600px">
        <DialogTitle className={classes.dialogHeader}>
          <Typography align="center" variant="h6">
            Adicionar Plano de Venda
          </Typography>
        </DialogTitle>

        <Box display="flex" flexDirection="column" padding="6px 16px">
          <Typography>
            Escolha um nome para o novo Plano de Venda:
          </Typography>

          <TextField
            label="Name"
            error={storeError}
            helperText={storeError.name?.join(" ")}
            value={newSalesPlan.name}
            onChange={(event) => setNewSalesPlan({...newSalesPlan,  name: event.target.value})}
          />

          <DialogActions>
            <Button
              onClick={handleNewAgentSalesPlan}
              variant="outlined"
              color="secondary"
            >
              Adicionar
            </Button>
            <Button
              onClick={() => setOpenDialog(false)}
              color="default"
              variant="outlined"
              autoFocus
            >
              Cancelar
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default StoreAgentSalesPlanModal;
