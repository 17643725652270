import { Box, CircularProgress } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  clearTransacao,
  loadTransacaoId,
  loadRecebiveisId,
  loadContaId,
} from "../../../actions/actions";
import TransactionDetailsCard from "./TransactionDetailsCard/TransactionDetailsCard";
import TransactionDetailsCommission from "./TransactionDetailsCommission/TransactionDetailsCommission";
import TransactionDetailsSlip from "./TransactionDetailsSlip/TransactionDetailsSlip";
import TransactionDetailsPix from "./TransactionDetailsPix/TransactionDetailsPix";

const TransactionDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const transacaoId = useSelector((state) => state.transacao);
  const recebiveis = useSelector((state) => state.recebiveis);

  const loadTransaction = useCallback(() => {
    dispatch(loadTransacaoId(id));
    dispatch(loadRecebiveisId(id));
  }, [id, dispatch]);

  useEffect(() => {
    loadTransaction();
  }, [loadTransaction]);

  useEffect(() => {
    return () => {
      dispatch(clearTransacao());
    };
  }, []);

  useEffect(() => {
    if (transacaoId?.transaction_id === id && transacaoId?.conta_id) {
      dispatch(loadContaId(transacaoId?.conta_id));
    }
  }, [dispatch, transacaoId, id]);

  if (transacaoId === undefined || transacaoId.id === undefined) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  } else {
    const tipo = transacaoId.transaction.payment_type;
    if (tipo === "boleto") {
      return (
        <TransactionDetailsSlip
          transacaoId={transacaoId}
          recebiveis={recebiveis}
          reloadTransaction={loadTransaction}
        />
      );
    }
    if (tipo === "credit" || tipo === "debit") {
      return (
        <TransactionDetailsCard
          transacaoId={transacaoId}
          recebiveis={recebiveis}
          reloadTransaction={loadTransaction}
        />
      );
    }
    if (tipo === "pix") {
      return (
        <TransactionDetailsPix
          transacaoId={transacaoId}
          recebiveis={recebiveis}
          reloadTransaction={loadTransaction}
        />
      );
    }
    if (tipo === "commission") {
      return <TransactionDetailsCommission transacaoId={transacaoId} />;
    }
  }
};

export default TransactionDetails;
