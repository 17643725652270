export const ADM_PERMISSIONS = {
  ADMIN: "Administrador", // 13
  MANAGEMENT: "Administrativo", // 15
  ACCOUNTS_MANAGEMENT: "Operações - Gerenciamento de Contas", // 16
  ADMIN_MANAGEMENT: "Operações - Gerenciamento de ADMs", // 17
  TRANSACTIONS: "Operações - Transações", // 18
  STATEMENT: "Operações - Extrato", // 19
  CREDENTIALS: "Operações - Dados cadastrais", // 20
  LOGS: "Operações - Logs", // 21
  SALES_PLAN_MANAGEMENT: "Operações - Plano de Vendas", // 22
  INTTEGRAR: "Inttegrar", // 23
  AGENT: "Representante", // 24
  AGENT_MANAGEMENT: "Operações - Gerenciamento de Representantes", // 25
  USER_MANAGEMENT: "Operações - Gerenciamento de Usuários", // 27
  APP_SALES_PLAN_FEE_MANAGEMENT: "Edição de Taxas Plano de Vendas APP", // 27
  SHIPPING_FILE: "Operações - Arquivo de remessa", // 30
};

export const USER_PERMISSIONS = {
  BILLING_CARD: "Cobranca - Cartao", // 1
  BILLING_BOLETO: "Cobranca - Boleto", // 2
  BILLING_CARNE: "Cobranca - Carne", // 3
  BILLING_SUBSCRIPTION: "Cobranca - Assinatura", // 4
  BILLING_PAYMENT_LINK: "Cobranca - Link Pagamento", // 5
  FINANCE_STATEMENT: "Financa - Extrato", // 6
  FINANCE_TRANSACTION_HISTORY: "Financa - Historico Transacoes", // 7
  FINANCE_FUTURE_RELEASES: "Financa - Lancamento Funturos", // 8
  TRANSFER: "Transferencia - Realizar Transferencia", // 9
  TRANSFER_HISTORY: "Transferencia - Historico Transferencia", // 10
  OTHER_DIGITAL_ACCOUNTS: "Outros - Contas Digitais", // 11
  OTHER_PAYERS: "Outros - Pagadores", // 12
  OTHER_BLOCK_BALANCE: "Menu - Bloquear Saldo", // 14
  OTHER_MY_USERS: "Outros - Meus Usuários", // 26
  BILLING_SHIPPING_FILE: "Cobranca - Arquivo de remessa", // 29
};
