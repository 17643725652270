import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormHelperText,
  LinearProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { format } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import useAuth from "hooks/useAuth";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useIndexPosQuery, useStorePosMutation } from "services/api";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import GradientButton from "components/reusable/GradientButton/GradientButton";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  SplitModal: {
    padding: "20px",
  },
  saqueHeader: {
    background: theme.gradient.main,
    color: "white",
  },
}));

const columns = [
  {
    headerText: "Data",
    key: "created_at",
    CustomValue: (data) => {
      const dataFormatada = format(new Date(data), "dd/MM/yyyy HH:mm:ss");
      return (
        <Box display="flex" justifyContent="center">
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
          <Typography style={{ marginLeft: "6px" }}>{dataFormatada}</Typography>
        </Box>
      );
    },
  },
	{
    headerText: "Identificador do POS",
    key: "id",
    CustomValue: (value) => (
      <Box display="flex" justifyContent="center">
        <Typography>{value}</Typography>
      </Box>
    ),
  },
  {
    headerText: "Nome",
    key: "name",
    CustomValue: (name) => {
			return (
				<Typography
					style={{
						borderRadius: "27px",
					}}
				>
					<b>{name}</b>
				</Typography>
			);
    }
  },
];

const PosList = () => {
  const [page, setPage] = useState(1);
  const token = useAuth();
	const { id } = useParams();
  const history = useHistory();
	const userData = useSelector((state) => state.userData);
	const accountId = useMemo(() => id || userData.id, [id, userData]);
	const isAdmin = useMemo(() => userData === "", [userData]);
	const classes = useStyles();
	const [openDialog, setOpenDialog] = useState(false);
	const [storePos, storePosStatus] = useStorePosMutation();
	const storePosError = useMemo(() => storePosStatus.error ? storePosStatus.error.data.errors : null, [storePosStatus]);
	const [newPos, setNewPos] = useState({
		token: '',
		conta_id: accountId,
	})

	const {
    data: posList,
    isLoading,
    isError,
    isUninitialized,
  } = useIndexPosQuery(
    {
      page,
      accountId: accountId,
    },
    {
      skip: !accountId,
      refetchOnMountOrArgChange: true,
    }
  );

  const handleNewPos = () => {
    setOpenDialog(true);
  };
	
	const handleClose = () => {
		setOpenDialog(false);
	};

	const handleClickRow = (row) => {
		const path = generatePath('/dashboard/show-terminal-pos/:id/ver', {
			id: row.id,
		});
		history.push(path);
	};

  const handleChangePage = useCallback((e, value) => {
    setPage(value);
  }, []);

	useEffect(() => {
    if (isError) {
      toast.error("Aconteceu um erro tente novamente!");
      history.goBack();
    }
  }, [isError, history]);

	const handleStorePos = async () => {
    try {
			await storePos( newPos ).unwrap();
      toast.success("POS habilitado! Ele será visível após a primeira transação.");
			handleClose();
			setNewPos({
				...newPos,
				token: '',
			});

    } catch (e) {
      toast.error("Erro ao habilitar POS!");
    }
  };

	return (
		<>
			<Box display="flex" flexDirection="column">
				{token && isAdmin ? (
					<CustomBreadcrumbs
						path1="Gerenciar Listas"
						path2="Terminais POS"
						to1="goBack"
					/>
				) : (
					<CustomBreadcrumbs
						path1="Outros Serviços"
						path2="Terminais POS"
						to1="goBack"
					/>
				)}
				<Box
					display="flex"
					justifyContent="space-between"
					marginBottom={2}
				>
					<Typography variant="h4">Terminais POS</Typography>

					{token && userData === "" ? null : (
						<GradientButton
							onClick={handleNewPos}
							buttonText="Habilitar POS"
						/>
					)}
				</Box>

				{!isLoading && !isError && !isUninitialized ? (
					<>
						<CustomTable
							data={posList.data}
							columns={columns}
							handleClickRow={handleClickRow}
						/>
						<Box alignSelf="flex-end" marginTop={3}>			
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={posList.last_page}
                onChange={handleChangePage}
                page={page}
              />
						</Box>
					</>
				) : (
					<LinearProgress />
				)}
			</Box>
		
			<Dialog onClose={handleClose} open={openDialog} className={classes.SplitModal}>
				<Box display="flex" flexDirection="column" width="500px">
					<LoadingScreen isLoading={storePosStatus.isLoading} />
					<DialogTitle className={classes.saqueHeader}>
						<Typography align="center" variant="h6">
							Habilitar novo POS
						</Typography>
					</DialogTitle>

					<Box margin="20px">
						<FormControl fullWidth>
							<Box marginTop={2}>
								<Typography variant="h6">
									Token do terminal POS
								</Typography>
								<TextField
									className={classes.currency}
									value={newPos.token}
									onChange={(event) =>
										setNewPos({
											...newPos,
											token: event.target.value,
										})
									}
									style={{
										marginBottom: "6px",
										width: "100%",
									}}
								/>
								{
									storePosError ? (
										<FormHelperText
											style={{
												marginBottom: "6px",
												width: "60%",
												color: "red",
											}}
										>
											{storePosError.token ? storePosError.token[0] : null}
										</FormHelperText>
									) : null
								}
							</Box>
						</FormControl>
					</Box>
					
					<Box width="50%" alignSelf="end" display="flex" justifyContent="space-around" padding="12px 24px">
						<Box margin="6px 0">
							<GradientButton
								buttonText={"Habilitar"}
								onClick={handleStorePos}
							/>
						</Box>
						<Box>
							<Button
								style={{ borderRadius: "37px", margin: "6px 0" }}
								variant="outlined"
								onClick={handleClose}
							>
								Cancelar
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</>

	);
};

export default PosList;
