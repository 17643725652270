import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Box } from "@material-ui/core";
import TransactionSummary from "../../reusable/Content/TransactionsTypes/TransactionSummary";
import UserInfos from "../../reusable/Content/UserInfos/UserInfos";
import AccountCollections from "../../reusable/Content/AccountCollections/AccountCollections";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { USER_PERMISSIONS } from "constants/permissions";

const useStyles = makeStyles((theme) => ({
  responsiveWrapper: {
    display: "flex",
    [theme.breakpoints.down(850)]: {
      flexDirection: "column",
    },
  },
}));

const ContentAreas = () => {
  const { section } = useParams();
  const classes = useStyles();
  const [permissoes, setPermissoes] = useState([]);
  const userPermissao = useSelector((state) => state.userPermissao);
  const blockBalance = useMemo(
    () => permissoes.includes(USER_PERMISSIONS.OTHER_BLOCK_BALANCE),
    [permissoes]
  );
  const withoutPermissions = useMemo(
    () => permissoes.length === 0,
    [permissoes]
  );

  useEffect(() => {
    const { permissao } = userPermissao;
    setPermissoes(permissao.map((item) => item.tipo));
  }, [userPermissao]);

  return (
    <Box>
      {blockBalance ? null : <TransactionSummary />}
      <Box className={classes.responsiveWrapper}>
        {section === "home" ? (
          <AccountCollections
            cartao
            boleto
            carne
            assinaturas
            link
            cobranca
            extrato
            historicoTransacoes
            lancamentosFuturos
            historicoTransferencia
            criarContaDigital
            pagadores
            solicitarCartao
            cartoesPre
            terminais
            todos={true}
            area="Todos"
          />
        ) : null}
        {section === "solicitacoes-de-cobranca" ? (
          <AccountCollections
            cartao
            boleto
            carne
            assinaturas
            link
            cobranca
            area="Solicitações de Cobrança"
          />
        ) : null}
        {section === "financas" ? (
          <AccountCollections
            extrato
            historicoTransacoes
            lancamentosFuturos
            agenda
            historicoRecebiveis
            area="Finanças"
          />
        ) : null}
        {section === "transferencias" ? (
          <AccountCollections historicoTransferencia area="Transferências" />
        ) : null}
        {section === "outros-servicos" ? (
          <AccountCollections
            criarContaDigital
            pagadores
            solicitarCartao
            cartoesPre
            terminais
            exportacoesSolicitadas
            area="Outros serviços"
          />
        ) : null}
        {blockBalance || withoutPermissions ? null : <UserInfos />}
      </Box>
    </Box>
  );
};

export default ContentAreas;
