export const TRANSACTION_STATUS = {
  pending: {
    color: "#dfad06",
    text: "PENDENTE",
    status: "pending",
  },
  succeeded: {
    color: "green",
    text: "SUCESSO",
    status: "succeeded",
  },
  failed: {
    color: "red",
    text: "FALHADA",
    status: "failed",
  },
  canceled: {
    color: "red",
    text: "CANCELADA",
    status: "canceled",
  },
  new: {
    color: "green",
    text: "NOVO",
    status: "new",
  },
  pre_authorized: {
    color: "#dfad06",
    text: "PRÉ-AUTORIZADO",
    status: "pre_authorized",
  },
  reversed: {
    color: "#000",
    text: "REVERTIDO",
    status: "reversed",
  },
  refunded: {
    color: "#000",
    text: "REEMBOLSADO",
    status: "refunded",
  },
  paid: {
    color: "green",
    text: "PAGO",
    status: "paid",
  },
  dispute: {
    color: "#dfad06",
    text: "DISPUTA",
    status: "dispute",
  },
  charged_back: {
    color: "",
    text: "ESTORNADO",
    status: "charged_back",
  },
  requested: {
    color: "#dfad06",
    text: "CANC. SOLICITADO",
    status: "requested",
  },
  refused: {
    color: "red",
    text: "CANC. RECUSADO POR STATUS",
    status: "refused",
  },
  rejected: {
    color: "red",
    text: "CANC. REJEITADO",
    status: "rejected",
  },
  error: {
    color: "red",
    text: "ERRO CANCELAMENTO",
    status: "error",
  },
  finished: {
    color: "",
    text: "CANC. FINALIZADO",
    status: "finished",
  },
};

export const ACCOUNT_STATUS = {
  pending: {
    color: "#dfad06",
    text: "PENDENTE",
    status: "pending",
  },
  enabled: {
    color: "green",
    text: "HABILITADO",
    status: "enabled",
  },
  active: {
    color: "green",
    text: "ATIVO",
    status: "active",
  },
  deleted: {
    color: "red",
    text: "EXCLUÍDO",
    status: "deleted",
  },
  denied: {
    color: "red",
    text: "NEGADO",
    status: "denied",
  },
  disabled: {
    color: "red",
    text: "DESABILITADO",
    status: "disabled",
  },
};

export const BOOKLET_STATUS = {
  pending: {
    color: "#dfad06",
    text: "PENDENTE",
    status: "pending",
  },
};

export const RECEIVABLE_STATUS = {
  paid: {
    color: "green",
    text: "PAGO",
    status: "paid",
  },
  pending: {
    color: "#dfad06",
    text: "PENDENTE",
    status: "pending",
  },
  canceled: {
    color: "red",
    text: "CANCELADA",
    status: "canceled",
  },
  refunded: {
    color: "#000",
    text: "REEMBOLSADO",
    status: "refunded",
  },
};

export const OPERATION_STATUS = {
  paid: {
    color: "green",
    text: "PAGA",
    status: "paid",
  },
  created: {
    color: "gray",
    text: "CRIADA",
    status: "created",
  },
  pending: {
    color: "#dfad06",
    text: "PENDENTE",
    status: "pending",
  },
  canceled: {
    color: "red",
    text: "CANCELADA",
    status: "canceled",
  },
  expiration: {
    color: "red",
    text: "EXPIRAÇÃO",
    status: "expiration",
  },
  authorization: {
    color: "#dfad06",
    text: "AUTORIZAÇÃO",
    status: "authorization",
  },
  void: {
    color: "red",
    text: "EXTORNO",
    status: "void",
  },
};
