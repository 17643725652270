import { Box, Typography, makeStyles, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  faBarcode,
  faCheck,
  faSignOutAlt,
  faCopy,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faForward,
  faHandHoldingUsd,
  faHistory,
  faLink,
  faMobile,
  faUndo,
  faUsers,
  faArchive,
  faMoneyBill,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

import AccountCollectionItem from "./AccountCollectionItem/AccountCollectionItem";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { USER_PERMISSIONS } from "constants/permissions";

const useStyles = makeStyles((theme) => ({
  accountCollectionContainer: {
    width: "60%",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    [theme.breakpoints.down(850)]: {
      width: "100%",
    },
  },
}));

const AccountCollections = ({
  cartao,
  boleto,
  carne,
  assinaturas,
  cobranca,
  link,
  extrato,
  exportacoesSolicitadas,
  historicoTransacoes,
  lancamentosFuturos,
  historicoRecebiveis,
  historicoTransferencia,
  criarContaDigital,
  pagadores,
  solicitarCartao,
  cartoesPre,
  terminais,
  area,
  agenda,
  todos,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const userPermissao = useSelector((state) => state.userPermissao);
  const [permissoes, setPermissoes] = useState([]);

  useEffect(() => {
    const { permissao } = userPermissao;
    setPermissoes(permissao.map((item) => item.tipo));
  }, [userPermissao]);

  useEffect(() => {
    return () => {
      setPermissoes([]);
    };
  }, []);

  if (todos) {
    return (
      <Box className={classes.accountCollectionContainer}>
        <Typography style={{ color: theme.typography.color }} variant="h6">
          {area}
        </Typography>
        <Box display="flex">
          {cartao ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.BILLING_CARD)
                  ? "cobrancas-credito"
                  : null
              }
              text="Máquina Virtual"
              icon={faCreditCard}
            />
          ) : null}

          {boleto ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.BILLING_BOLETO)
                  ? "boleto"
                  : null
              }
              text="Boleto"
              icon={faBarcode}
            />
          ) : null}

          {carne ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.BILLING_CARNE)
                  ? "carne"
                  : null
              }
              text="Carnê"
              icon={faCopy}
            />
          ) : null}
        </Box>
        <Box display="flex">
          {link ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.BILLING_PAYMENT_LINK)
                  ? "link-pagamento"
                  : null
              }
              text="Link de Pagamento"
              icon={faLink}
            />
          ) : null}

          {lancamentosFuturos ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.FINANCE_FUTURE_RELEASES)
                  ? "lancamentos"
                  : null
              }
              text="Lançamentos"
              icon={faForward}
            />
          ) : null}

          {extrato ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.FINANCE_STATEMENT)
                  ? "extrato"
                  : null
              }
              text="Extrato"
              icon={faDollarSign}
            />
          ) : null}
        </Box>

        <Box display="flex">
          {assinaturas ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.BILLING_SUBSCRIPTION)
                  ? "assinaturas"
                  : null
              }
              text="Cobrança Recorrente"
              icon={faUndo}
            />
          ) : null}
          {historicoTransacoes ? (
            <AccountCollectionItem
              link={
                permissoes.includes(
                  USER_PERMISSIONS.FINANCE_TRANSACTION_HISTORY
                )
                  ? "historico-de-transacoes"
                  : null
              }
              text="Transações"
              icon={faHistory}
            />
          ) : null}
          <AccountCollectionItem
            link={"minhas-tarifas"}
            text="Tarifas"
            icon={faMoneyBill}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box className={classes.accountCollectionContainer}>
        <Typography style={{ color: theme.typography.color }} variant="h6">
          {area}
        </Typography>
        <Box display="flex">
          {cartao ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.BILLING_CARD)
                  ? "cobrancas-credito"
                  : null
              }
              text="Máquina Virtual"
              icon={faCreditCard}
            />
          ) : null}

          {boleto ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.BILLING_BOLETO)
                  ? "boleto"
                  : null
              }
              text="Boleto"
              icon={faBarcode}
            />
          ) : null}

          {carne ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.BILLING_CARNE)
                  ? "carne"
                  : null
              }
              text="Carnê"
              icon={faCopy}
            />
          ) : null}
        </Box>
        <Box display="flex">
          {assinaturas ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.BILLING_SUBSCRIPTION)
                  ? "assinaturas"
                  : null
              }
              text="Cobrança Recorrente"
              icon={faUndo}
            />
          ) : null}

          {link ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.BILLING_PAYMENT_LINK)
                  ? "link-pagamento"
                  : null
              }
              text="Link de Pagamento"
              icon={faLink}
            />
          ) : null}

          {cobranca ? (
            <AccountCollectionItem
              onClick={() => toast.warning("Em desenvolvimento")}
              text="Solicitações de Cobrança"
              icon={faHandHoldingUsd}
            />
          ) : null}
        </Box>

        <Box display="flex">
          {extrato ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.FINANCE_STATEMENT)
                  ? "extrato"
                  : null
              }
              text="Extrato"
              icon={faDollarSign}
            />
          ) : null}
          {lancamentosFuturos ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.FINANCE_FUTURE_RELEASES)
                  ? "lancamentos"
                  : null
              }
              text="Lançamentos"
              icon={faForward}
            />
          ) : null}
          {agenda ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.FINANCE_FUTURE_RELEASES)
                  ? "lancamentos"
                  : null
              }
              text="Agenda"
              state={true}
              icon={faCalendarAlt}
            />
          ) : null}
        </Box>
        <Box display="flex">
          {historicoTransacoes ? (
            <AccountCollectionItem
              link={
                permissoes.includes(
                  USER_PERMISSIONS.FINANCE_TRANSACTION_HISTORY
                )
                  ? "historico-de-transacoes"
                  : null
              }
              text="Transações"
              icon={faHistory}
            />
          ) : null}
          {historicoRecebiveis ? (
            <AccountCollectionItem
              link={
                permissoes.includes(
                  USER_PERMISSIONS.FINANCE_TRANSACTION_HISTORY
                )
                  ? "historico-de-recebiveis"
                  : null
              }
              text="Recebíveis"
              icon={faHistory}
            />
          ) : null}
          {lancamentosFuturos ? (
            <AccountCollectionItem
              link={"minhas-tarifas"}
              text="Tarifas"
              icon={faMoneyBill}
            />
          ) : null}
        </Box>

        <Box display="flex">
          {historicoTransferencia ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.TRANSFER_HISTORY)
                  ? "historico-transferencia"
                  : null
              }
              text="Histórico Transferências"
              icon={faCheck}
            />
          ) : null}
           {historicoTransferencia ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.TRANSFER)
                  ? "nova-transferencia"
                  : null
              }
              text="Realizar Transferências"
              icon={faSignOutAlt}
            />
          ) : null}
        </Box>

        <Box display="flex">
          {criarContaDigital ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.OTHER_DIGITAL_ACCOUNTS)
                  ? "contas-digitais"
                  : null
              }
              text="Contas Digitais"
              icon={faDesktop}
            />
          ) : null}
          {pagadores ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.OTHER_PAYERS)
                  ? "pagadores"
                  : null
              }
              text="Pagadores"
              icon={faUsers}
            />
          ) : null}
          {solicitarCartao ? (
            <AccountCollectionItem
              onClick={() => toast.warning("Em desenvolvimento")}
              text="Solicitar Cartão"
              icon={faCreditCard}
            />
          ) : null}
        </Box>
        <Box display="flex">
          {cartoesPre ? (
            <AccountCollectionItem
              onClick={() => toast.warning("Em desenvolvimento")}
              text="Cartões Pré Pagados"
              icon={faCreditCard}
            />
          ) : null}
          {terminais ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.BILLING_CARD)
                  ? "listar-terminais-pos"
                  : null
              }
              text="Terminais - POS"
              icon={faMobile}
            />
          ) : null}
          {exportacoesSolicitadas ? (
            <AccountCollectionItem
              link={
                permissoes.includes(USER_PERMISSIONS.FINANCE_STATEMENT)
                  ? "exportacoes-solicitadas"
                  : null
              }
              text="Exportações Solicitadas"
              icon={faArchive}
            />
          ) : null}
        </Box>
      </Box>
    );
  }
};

export default AccountCollections;
