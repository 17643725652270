import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
	button: {
    borderRadius: "27px"
	},
}));

const PrimaryButton = (props) => {
  const classes = useStyles();

	return (
    <Button
      {...props}
      className={classes.button}
      variant="contained"
      color="primary"
      onClick={props.onClick}
    >
      {props.text}
    </Button>
	);
};

export default PrimaryButton;
