import {
  LOAD_AUTH,
  LOAD_BOLETOS,
  LOAD_LINK_PAGAMENTOS,
  LOAD_USER_DATA,
  POST_LINK_PAGAMENTOS,
  LOAD_LINK_PAGAMENTOS_ID,
  POST_PAGADOR,
  PAGAR_LINK_PAGAMENTO,
  LOAD_PAGADOR_ID,
  LOAD_SIGN_OUT,
  LOAD_PAGADORES,
  DEL_PAGADOR,
  PUT_PAGADOR,
  SET_STATE,
  LOAD_CONTAS,
  LOAD_USER_CONTA,
  POST_USER_CONTA,
  POST_CONTA,
  LOAD_CONTA_ID,
  POST_BOLETOS,
  LOAD_EXTRATO,
  LOAD_LANCAMENTOS_FUTUROS,
  UPDATE_USER_CONTA,
  UPDATE_USER_PASS,
  LOAD_BANCOS,
  POST_CONTA_BANCARIA,
  LOAD_CONTA_BANCARIA,
  DEL_CONTA_BANCARIA,
  POST_PRIMEIRO_ACESSO,
  LOAD_HISTORICO_TRANSACAO,
  ENVIAR_BOLETO,
  AUTH_ME,
  POST_SAQUE,
  LOAD_HISTORICO_TRANSFERENCIA,
  POST_TRANSFERENCIA,
  DEL_CONTA,
  LOAD_CARNE,
  LOAD_CARNE_ID,
  DEL_CARNE,
  POST_CARNE,
  POST_COBRANCA_CARTAO,
  LOAD_COBRANCAS_CARTAO,
  POST_CAPTURA,
  LOAD_PLANOS,
  LOAD_PLANO_ID,
  POST_PLANO,
  PUT_PLANO,
  DEL_PLANO,
  POST_ASSINATURA,
  LOAD_ASSINATURAS,
  LOAD_ASSINATURA_ID,
  PUT_ASSINATURA,
  DEL_ASSINATURA,
  POST_CARTAO_PAGADOR,
  POST_SAQUE_AUTOMATICO,
  LOAD_TRANSACAO,
  CLEAR_TRANSACAO,
  POST_SLIPT,
  LOAD_RESUMO_TRANSACAO,
  LOAD_TRANSACOES_FUTUROS,
  POST_TOKENS,
  LOAD_TOKENS,
  DEL_TOKENS,
  CLEAR_TOKEN,
  POST_DOCUMENTO,
  LOAD_PERMISSAO,
  POST_AUTHME,
  LOAD_PERMISSAO_GERENCIAR,
  DEL_USER,
  LOAD_CONTA_DIGITAL_DOCUMENTO,
  LOAD_EXPORT_TRANSFERENCIA,
  LOAD_EXPORT_TRANSACAO,
  LOAD_EXPORT_EXTRATO,
  LOAD_DOCUMENTO,
  DELETE_DOCUMENTO,
  REENVIAR_TOKEN,
  LOAD_RECEBIVEIS_TRANSACAO,
  TRANSFER_DETAILS,
} from "../constants/actionsStrings";

export const INITIAL_STATE = {
  linkPagamentosPagar: {
    data: [{}],
  },
  linkPagamentos: {
    data: [{}],
  },
  linkPagamentoId: {
    data: [{}],
  },
  boletos: {
    data: [{}],
  },
  userData: {
    saldo: {
      valor: "",
    },
  },
  selectedMenu: {},
  pagadores: {
    data: [{}],
  },
  extrato: {
    data: [{}],
  },
  lancamentos: {},
  pagador: {
    id: "",
    tipo: "",
    documento: "",
    conta_id: "",
    nome: "",
    celular: "",
    data_nascimento: "",
    email: "",
    endereco: {
      cep: "",
      rua: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
    },
  },
  contas: {
    data: [{}],
  },
  lancamentosFuturos: {
    data: [{}],
  },
  conta: {
    documento: "",
    razao_social: "",
    celular: "",
    email: "",
    site: "",
    endereco: {
      cep: "",
      rua: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
    },
  },
  usuarios: {
    user: [{}],
  },
  bancos: {},
  contaBancaria: {},
  contasBancarias: [{}],
  primeiroUsuario: {},
  historicoTransacao: {
    data: [{}],
  },
  transacao: {},
  recebiveis: [],
  carneList: {
    data: [{}],
  },
  cobrancaCartao: {},
  carne: {},
  enviarBoleto: {},
  auth: {},
  saque: {},
  transferencia: {},
  historicoTransferencia: {
    data: [
      {
        origem: {},
        destino: {},
      },
    ],
  },
  cobrancaCartaoList: {
    data: [{}],
  },
  planosList: {
    data: [{}],
  },
  plano: {},
  assinaturasList: {
    data: [{}],
  },
  assinatura: {},
  cartaoPagador: {},
  saqueAutomatico: {},
  split: {},
  resumoTransacao: {},
  transacoesFuturas: {},
  publicTokensList: [{}],
  publicToken: {},
  documentoImagem: {},
  userPermissao: {
    permissao: [{}],
  },
  gerenciarPermissao: {
    permissao: [{}],
  },
  authMe: {},
  contaDigitalDocumento: {},
  exportTransferencia: {},
  exportTransacao: {},
  exportExtrato: {},
  arquivoDocumento: [{}],
  reenviarToken: {},
  transferDetails: {},
};

export const rootReducer = (state, action) => {
  switch (action.type) {
    case SET_STATE:
      return action.payload;

    case LOAD_AUTH:
      return { ...state };

    case LOAD_SIGN_OUT:
      return { ...state, token: action.payload };

    case LOAD_PAGADOR_ID:
      return { ...state, pagador: action.payload };

    case LOAD_DOCUMENTO:
      return { ...state, arquivoDocumento: action.payload };

    case DELETE_DOCUMENTO:
      return {
        ...state,
        arquivoDocumento: state.arquivoDocumento.filter(
          (item) => item.id !== action.payload
        ),
      };

    case LOAD_USER_DATA:
      return { ...state, userData: action.payload };

    case REENVIAR_TOKEN:
      return { ...state, reenviarToken: action.payload };

    case LOAD_LINK_PAGAMENTOS:
      return { ...state, linkPagamentos: action.payload };

    case POST_LINK_PAGAMENTOS:
      return { ...state, linkPagamentos: action.payload };

    case PAGAR_LINK_PAGAMENTO:
      return { ...state, linkPagamentosPagar: action.payload };

    case LOAD_LINK_PAGAMENTOS_ID:
      return { ...state, linkPagamentoId: action.payload };

    case LOAD_BOLETOS:
      return { ...state, boletos: action.payload };

    case POST_SLIPT:
      return { ...state, split: action.payload };

    case POST_PAGADOR:
      return { ...state, pagador: action.payload };

    case LOAD_PAGADORES:
      return { ...state, pagadores: action.payload };

    case LOAD_CONTAS:
      return { ...state, contas: action.payload };

    case LOAD_CONTA_DIGITAL_DOCUMENTO:
      return { ...state, contaDigitalDocumento: action.payload };

    case LOAD_USER_CONTA:
      return { ...state, usuarios: action.payload };

    case DEL_USER:
      return {
        ...state,
        usuarios: {
          ...state.usuarios,
          user: state.usuarios.user.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case POST_USER_CONTA:
      return { ...state, usuarios: action.payload };

    case POST_CONTA:
      return { ...state, conta: action.payload };

    case LOAD_CONTA_ID:
      return { ...state, conta: action.payload };

    case POST_TOKENS:
      return { ...state, publicToken: action.payload };

    case POST_DOCUMENTO:
      return { ...state, documentoImagem: action.payload };

    case LOAD_TOKENS:
      return { ...state, publicTokensList: action.payload };

    case DEL_TOKENS:
      return {
        ...state,
        publicTokensList: state.publicTokensList.filter(
          (item) => item.id !== action.payload
        ),
      };

    case CLEAR_TOKEN:
      return { ...state, publicToken: {} };

    case DEL_PAGADOR:
      return {
        ...state,
        pagadores: {
          ...state.pagadores,
          data: state.pagadores.data.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case DEL_CONTA:
      return {
        ...state,
        contas: {
          ...state.contas,
          data: state.contas.data.filter((item) => item.id !== action.payload),
        },
      };

    case PUT_PAGADOR:
      return { ...state, pagador: action.payload };

    case POST_BOLETOS:
      return { ...state, boletos: action.payload };

    case LOAD_EXTRATO:
      return { ...state, extrato: action.payload };

    case LOAD_LANCAMENTOS_FUTUROS:
      return { ...state, lancamentosFuturos: action.payload };

    case UPDATE_USER_CONTA:
      return { ...state, userData: action.payload };

    case POST_SAQUE_AUTOMATICO:
      return { ...state, saqueAutomatico: action.payload };

    case UPDATE_USER_PASS:
      return { ...state };

    case LOAD_BANCOS:
      return { ...state, bancos: action.payload };

    case POST_CONTA_BANCARIA:
      return { ...state, contaBancaria: action.payload };

    case LOAD_CONTA_BANCARIA:
      return { ...state, contasBancarias: action.payload };

    case DEL_CONTA_BANCARIA:
      return {
        ...state,
        contasBancarias: state.contasBancarias.filter(
          (item) => item.id !== action.payload
        ),
      };

    case POST_PRIMEIRO_ACESSO:
      return { ...state, primeiroUsuario: action.payload };

    case LOAD_HISTORICO_TRANSACAO:
      return { ...state, historicoTransacao: action.payload };

    case LOAD_TRANSACAO:
      return { ...state, transacao: action.payload };

    case LOAD_RECEBIVEIS_TRANSACAO:
      return { ...state, recebiveis: action.payload };

    case CLEAR_TRANSACAO:
      return { ...state, transacao: {} };

    case ENVIAR_BOLETO:
      return { ...state, enviarBoleto: action.payload };

    case AUTH_ME:
      return { ...state, auth: action.payload };

    case POST_SAQUE:
      return { ...state, saque: action.payload };

    case LOAD_HISTORICO_TRANSFERENCIA:
      return { ...state, historicoTransferencia: action.payload };

    case POST_TRANSFERENCIA:
      return { ...state, transferencia: action.payload };

    case LOAD_CARNE:
      return { ...state, carneList: action.payload };

    case LOAD_CARNE_ID:
      return { ...state, carne: action.payload };

    case POST_CARNE:
      return { ...state, carne: action.payload };

    case POST_COBRANCA_CARTAO:
      return { ...state, cobrancaCartao: action.payload };

    case DEL_CARNE:
      return {
        ...state,
        carneList: state.carneList.data.filter(
          (item) => item.id !== action.payload
        ),
      };

    case LOAD_COBRANCAS_CARTAO:
      return { ...state, cobrancaCartaoList: action.payload };

    case POST_CAPTURA:
      return { ...state, cobrancaCartao: action.payload };

    case LOAD_PLANOS:
      return { ...state, planosList: action.payload };

    case LOAD_EXPORT_TRANSFERENCIA:
      return { ...state, exportTransferencia: action.payload };

    case LOAD_EXPORT_TRANSACAO:
      return { ...state, exportTransacao: action.payload };

    case LOAD_EXPORT_EXTRATO:
      return { ...state, exportExtrato: action.payload };

    case LOAD_PLANO_ID:
      return { ...state, plano: action.payload };

    case POST_PLANO:
      return { ...state, plano: action.payload };

    case PUT_PLANO:
      return { ...state, plano: action.payload };

    case DEL_PLANO:
      return {
        ...state,
        planosList: {
          ...state.planosList,
          data: state.planosList.data.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case POST_ASSINATURA:
      return { ...state, assinatura: action.payload };

    case LOAD_ASSINATURAS:
      return { ...state, assinaturasList: action.payload };

    case LOAD_RESUMO_TRANSACAO:
      return { ...state, resumoTransacao: action.payload };

    case LOAD_TRANSACOES_FUTUROS:
      return { ...state, transacoesFuturas: action.payload };

    case LOAD_ASSINATURA_ID:
      return { ...state, assinatura: action.payload };

    case PUT_ASSINATURA:
      return { ...state, assinatura: action.payload };

    case POST_CARTAO_PAGADOR:
      return { ...state, cartaoPagador: action.payload };

    case DEL_ASSINATURA:
      return {
        ...state,
        assinaturasList: {
          ...state.assinaturasList,
          data: state.assinaturasList.data.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case LOAD_PERMISSAO:
      return { ...state, userPermissao: action.payload };

    case LOAD_PERMISSAO_GERENCIAR:
      return { ...state, gerenciarPermissao: action.payload };

    case POST_AUTHME:
      return { ...state, authMe: action.payload };

    case TRANSFER_DETAILS:
      return { ...state, transferDetails: action.payload };

    default:
      return { ...state };
  }
};
