import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { useImportAgentMutation } from "services/api";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import GradientButton from "components/reusable/GradientButton/GradientButton";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "hooks/useDebounce";
import { loadContasFilter } from "actions/actions";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
}));

const ImportAgentDialog = ({
  open,
  setOpen = () => {},
  refetchAgents,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [importAgent] = useImportAgentMutation();
  const [loadingImport, setLoadingImport] = useState(false);
  const [accountId, setAccountId] = useState("");
  const accounts = useSelector((state) => state.contas);

	const [filters, setFilters] = useState({
		like: "",
    without_agent_id: 1,
    except_contas_filhas: 1,
    page: 1,
	});

	const debouncedFilter = useDebounce(filters, 800);

	useEffect(() => {
    if(open) {
      dispatch(loadContasFilter({ ...debouncedFilter }));
    }
	}, [dispatch, debouncedFilter, open]);

  const handleImportAgent = async () => {
    setLoadingImport(true);
    try {
      await importAgent({account_id : accountId}).unwrap();
      toast.success("Representante importado com sucesso!");
      refetchAgents();
      handleCloseImportAgent();
    } catch (e) {
      if(e.data?.message && e.status === 403) {
        toast.error(e.data.message);
      }
      toast.error("Erro ao importar Representante!");
    }
    setLoadingImport(false);
  };

  const handleCloseImportAgent = () => {
    setOpen(false);
    setFilters({
      ...filters,
      like: "",
    })
    setAccountId("");
  };

  return (
    <Dialog
        open={open}
        onClose={handleCloseImportAgent}
        maxWidth={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          width={"70vw"}
        >
          <LoadingScreen isLoading={loadingImport} />
          <DialogTitle className={classes.dialogHeader}>
            Importar Representante
          </DialogTitle>

          <DialogContent>
            <Box>
              <Typography align="center">
                Selecione a conta desejada para ser importado para o modelo de Representante.
              </Typography>
              <Typography align="center" style={{color:"red", margin:"10px 0"}}>
                ATENÇÃO: Uma conta importada para Representante não poderá ser revertida!
              </Typography>

              <Autocomplete
                freeSolo
                fullWidth
                options={accounts.data}
                getOptionLabel={(account) => account.razao_social ?? account.nome}
                onInputChange={(_event, value, reason) => {
                  if(reason !== "reset") {
                    setFilters({...filters, like: value});
                    setAccountId(null);
                  }
                }}
                onChange={(_event, option) => {
                  setAccountId(option? option.id : null);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Escolher EC" margin="normal" variant="outlined" />
                )}
              />

              <Typography style={{margin:"10px 0"}}>
                OBS: Para que uma conta seja importada, ela precisa atender os seguintes requisitos
              </Typography>
              <Typography>
                - estar ativa
              </Typography>
              <Typography>
                - não possuir vinculo com outro representante
              </Typography>
              <Typography>
                - não ser uma conta digital (conta filha vinculada a outro EC)
              </Typography>
            </Box>
          </DialogContent>

          <Box alignSelf="end" display="flex" padding="12px 24px">
            <Box margin="6px 0" padding="0 12px">
              <GradientButton buttonText={"Importar"} onClick={handleImportAgent} />
            </Box>
            <Box>
              <Button
                style={{ borderRadius: "37px", margin: "6px 0" }}
                variant="outlined"
                onClick={handleCloseImportAgent}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
  );
};

export default ImportAgentDialog;
