import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography, useTheme } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { getCep } from '../../../../services/services';
import { toast } from 'react-toastify';
import { isNull} from 'lodash';

const NewAccount = ({ conta, setConta, errosConta, disableEditar }) => {
	const [pessoaJuridica, setPessoaJuridica] = useState(false);
	const theme = useTheme();
	const handlerCep = async () => {
		try {
			const response = await getCep(conta.endereco.cep);
			setConta({
				...conta,
				endereco: {
					...conta.endereco,
					cep: response.data.cep,
					rua: response.data.logradouro,
					complemento: response.data.complemento,
					bairro: response.data.bairro,
					cidade: response.data.localidade,
					estado: response.data.uf,
				},
			});
		} catch (error) {
			toast.error('Error ao puxar dados do cep');
		}
	};

	useEffect( () => {
		setPessoaJuridica(!isNull(conta.cnpj))
	}, [conta.cnpj]);

	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Typography align="center" variant="h4" style={{ marginTop: '12px' }}>
				Dados da conta
			</Typography>

			<Box>
				<Button
					disabled={disableEditar}
					variant="contained"
					style={{
						margin: '5px',
						borderRadius: '27px',
						backgroundColor: pessoaJuridica ? '' : theme.palette.primary.main,
						color: 'white',
					}}
					onClick={() => setPessoaJuridica(false)}
				>
					Pessoa Física
				</Button>
				<Button
					disabled={disableEditar}
					variant="contained"
					style={{
						margin: '5px',
						borderRadius: '27px',
						backgroundColor: pessoaJuridica ? theme.palette.primary.main : '',
						color: 'white'
					}}
					onClick={() => setPessoaJuridica(true)}
				>
					Pessoa Jurídica
				</Button>
			</Box>
			<form>
				<Box width="100%" display="flex" flexDirection="column" alignItems="center">
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta.renda}
								helperText={errosConta.renda ? errosConta.renda.join(' ') : null}
								value={conta.renda}
								type='number'
								onChange={(e) =>
									setConta({
										...conta,
										renda: e.target.value,
									})
								}
								fullWidth
								required
								label={'Renda (R$)'}
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<InputMask
								disabled={disableEditar}
								mask={'999.999.999-99'}
								value={conta.documento}
								onChange={(e) =>
									setConta({
										...conta,
										documento: e.target.value,
									})
								}
							>
								{() => (
									<TextField
										InputLabelProps={{ shrink: true }}
										disabled={disableEditar}
										error={errosConta.documento}
										helperText={errosConta.documento ? errosConta.documento.join(' ') : null}
										name="documento"
										fullWidth
										required
										label={'CPF'}
									/>
								)}
							</InputMask>
						</Grid>
						<Grid item xs={12} sm={8}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta.nome}
								helperText={errosConta.nome ? errosConta.nome.join(' ') : null}
								value={conta.nome}
								onChange={(e) =>
									setConta({
										...conta,
										nome: e.target.value,
									})
								}
								fullWidth
								required
								label={'Primeiro e Segundo nome'}
							/>
						</Grid>
						{pessoaJuridica || conta.tipo === 2 ? (
							<>
								<Grid item sm={4} xs={12}>
									<InputMask
										disabled={disableEditar}
										mask={'99.999.999/9999-99'}
										value={conta.cnpj}
										onChange={(e) =>
											setConta({
												...conta,
												cnpj: e.target.value,
											})
										}
									>
										{() => (
											<TextField
												InputLabelProps={{ shrink: true }}
												disabled={disableEditar}
												error={errosConta.cnpj}
												helperText={errosConta.cnpj ? errosConta.cnpj.join(' ') : null}
												name="CNPJ"
												fullWidth
												required
												label={'CNPJ'}
											/>
										)}
									</InputMask>
								</Grid>
								<Grid item xs={12} sm={8}>
									<TextField
										InputLabelProps={{ shrink: true }}
										error={errosConta.razao_social}
										helperText={errosConta.razao_social ? errosConta.razao_social.join(' ') : null}
										value={conta.razao_social}
										onChange={(e) =>
											setConta({
												...conta,
												razao_social: e.target.value,
											})
										}
										fullWidth
										required
										label={'Razao Social'}
									/>
								</Grid>
							</>
						) : null}
						<Grid item sm={4} xs={12}>
							<TextField
								error={errosConta.data_nascimento}
								helperText={errosConta.data_nascimento ? errosConta.data_nascimento.join(' ') : null}
								fullWidth
								required
								InputLabelProps={{
									shrink: true,
									pattern: 'd {4}- d {2}- d {2} ',
								}}
								type="date"
								label="Data de Nascimento"
								value={conta.data_nascimento}
								onChange={(e) =>
									setConta({
										...conta,
										data_nascimento: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<InputMask
								mask="99999-999"
								maskChar=" "
								value={conta?.endereco?.cep}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											cep: e.target.value,
										},
									})
								}
								onBlur={handlerCep}
							>
								{() => (
									<TextField
										InputLabelProps={{ shrink: true }}
										error={errosConta['endereco.cep']}
										helperText={
											errosConta['endereco.cep'] ? errosConta['endereco.cep'].join(' ') : null
										}
										fullWidth
										required
										label="CEP"
									/>
								)}
							</InputMask>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta['endereco.rua']}
								helperText={errosConta['endereco.rua'] ? errosConta['endereco.rua'].join(' ') : null}
								value={conta?.endereco?.rua}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											rua: e.target.value,
										},
									})
								}
								fullWidth
								required
								label="Rua"
							/>
						</Grid>
						<Grid item sm={2} xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta['endereco.numero']}
								helperText={
									errosConta['endereco.numero'] ? errosConta['endereco.numero'].join(' ') : null
								}
								value={conta?.endereco?.numero}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											numero: e.target.value,
										},
									})
								}
								fullWidth
								label="Número"
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta['endereco.bairro']}
								helperText={
									errosConta['endereco.bairro'] ? errosConta['endereco.bairro'].join(' ') : null
								}
								value={conta?.endereco?.bairro}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											bairro: e.target.value,
										},
									})
								}
								fullWidth
								required
								label="Bairro"
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								value={conta?.endereco?.complemento}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											complemento: e.target.value,
										},
									})
								}
								fullWidth
								label="Complemento"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta['endereco.cidade']}
								helperText={
									errosConta['endereco.cidade'] ? errosConta['endereco.cidade'].join(' ') : null
								}
								value={conta?.endereco?.cidade}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											cidade: e.target.value,
										},
									})
								}
								fullWidth
								required
								label="Cidade"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta['endereco.estado']}
								helperText={
									errosConta['endereco.estado'] ? errosConta['endereco.estado'].join(' ') : null
								}
								value={conta?.endereco?.estado}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											estado: e.target.value,
										},
									})
								}
								fullWidth
								required
								label="Estado"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<InputMask
								mask="(99) 99999-9999"
								value={conta.celular}
								onChange={(e) =>
									setConta({
										...conta,
										celular: e.target.value,
									})
								}
							>
								{() => (
									<TextField
										InputLabelProps={{ shrink: true }}
										error={errosConta.celular}
										helperText={errosConta.celular ? errosConta.celular.join(' ') : null}
										fullWidth
										required
										label="Celular"
										type="tel"
									/>
								)}
							</InputMask>
						</Grid>
						<Grid item xs={12} sm={8}>
							<TextField
								InputLabelProps={{ shrink: true }}
								disabled={disableEditar}
								error={errosConta.email}
								helperText={errosConta.email ? errosConta.email.join(' ') : null}
								value={conta.email}
								onChange={(e) =>
									setConta({
										...conta,
										email: e.target.value,
									})
								}
								fullWidth
								required
								label="E-mail"
								type="email"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta.site}
								helperText={errosConta.site ? errosConta.site.join(' ') : null}
								value={conta.site}
								onChange={(e) =>
									setConta({
										...conta,
										site: e.target.value,
									})
								}
								fullWidth
								label="Site"
								type="site"
							/>
						</Grid>
					</Grid>
				</Box>
			</form>
		</Box>
	);
};

export default NewAccount;
