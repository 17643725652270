export const updateAccountLimitBoletoMutation = ({ value, conta_id }) => ({
  url: `/contas/${conta_id}/limite-boleto`,
  method: "put",
  data: {
    valor_limite_boleto: value,
  },
});

export const indexAllAccountsQuery = ({ like }) => ({
  url: `/contas-all`,
  method: "get",
  params: { like },
});

export const showAccountByDocumentQuery = ({ documento }) => ({
  url: `/contas-buscar`,
  method: "get",
  params: { documento },
});

export const accountsPreviewQuery = () => ({
  url: `/contas-resumo`,
  method: "get",
});

export const accountsLineChartQuery = () => ({
  url: `/contas-resumo-line-chart`,
  method: "get",
});

export const accountsBarChartQuery = () => ({
  url: `/contas-resumo-bar-chart`,
  method: "get",
});

export const reactivateAccountsAccountMutation = ({ conta_id }) => ({
  url: `/contas/${conta_id}/reativar`,
  method: "post",
});
