import { Box, Button, Menu, MenuItem } from "@material-ui/core";
import { useState } from "react";
import { generatePath, useHistory } from "react-router";

const EditarAccount = ({ row }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditar = (row) => {
    const path = generatePath('/dashboard/contas/:id/editar', {
      id: row.id,
    });
    history.push(path);
  };

  const handleUsuarios = (row) => {
    const path = generatePath('/dashboard/contas/:id/users', {
      id: row.id,
    });
    history.push(path);
  };

  const handleGerenciarListas = (row) => {
    const path = generatePath('/dashboard/contas/:id/listas', {
      id: row.id,
    });
    history.push(path);
  };

  return (
    <Box>
      <Button
        style={{ height: '15px', width: '10px' }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        ...
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleEditar(row)}>Editar</MenuItem>
        <MenuItem onClick={() => handleUsuarios(row)}>Gerenciar Usuários</MenuItem>
        <MenuItem onClick={() => handleGerenciarListas(row)}>Gerenciar Listas</MenuItem>
      </Menu>
    </Box>
  );
};

export default EditarAccount;
