import React from 'react';
import { Route, Switch } from 'react-router';
import Login from '../../Root/Login/Login';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import PaymentLinkPay from '../../Root/PaymentLink/PaymentLinkPay/PaymentLinkPay';
import FirstAcess from '../../Root/FirstAcess/FirstAcess';
import Dashboard from '../../Root/Dashboard/Dashboard';
import AuthTotp from 'components/Root/Login/AuthTotp';
import RecoveryPassword from 'components/Root/RecoveryPassword/RecoveryPassword';
const Routes = () => {
	return (
		<Switch>
			<PrivateRoute path="/dashboard/:section" exact component={Dashboard} />
			<PrivateRoute path="/dashboard/:section/:id/:subsection" exact component={Dashboard} />
			<Route path="/login" exact component={Login} />
			<Route path="/login/auth-totp" exact component={AuthTotp} />
			<Route path="/login/cadastro" exact component={FirstAcess} />
			<Route path="/login/recuperar" exact component={RecoveryPassword} />
			<Route path="/link-pagamento/:id/pagar" exact component={PaymentLinkPay} />
			<PrivateRoute path="/" />
		</Switch>
	);
};

export default Routes;
