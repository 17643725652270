import { Box, Typography } from "@material-ui/core";
import TransactionStatusColumn from "components/reusable/TableColumns/TransactionStatusColumn";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import React from "react";
import { formatMoney } from "utils/money";
import TransactionValidationColumn from "components/reusable/TableColumns/TransactionValidationColumn";
import { getTotalFee } from "helpers/fee_transaction";

const columns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (created_at) => {
      return <DateTimeColumn dateTime={created_at} />;
    },
  },
  {
    headerText: "Pagador",
    key: "pagador",
    CustomValue: (pagador) => (
      <Box display="flex" flexDirection="column">
        <Typography>{pagador ? pagador.nome : null}</Typography>
        <Typography>{pagador ? pagador.documento : null}</Typography>
      </Box>
    ),
  },
  {
    headerText: "Situação",
    key: "transaction.status",
    CustomValue: (status) => {
      return <TransactionStatusColumn status={status} />;
    },
  },
  {
    headerText: "Validação",
    key: "transaction",
    CustomValue: (transaction) => {
      return <TransactionValidationColumn transaction={transaction} />;
    },
  },
  {
    headerText: "Tipo",
    key: "transaction",
    CustomValue: (transaction) => {
      const type = transaction.payment_type;
      if (type === "credit") {
        const installments = transaction.installment_plan
          ? transaction.installment_plan.number_installments
          : 1;
        const flag = transaction.payment_method.card_brand;
        return (
          <Typography>
            Crédito {installments}x - {flag}
          </Typography>
        );
      }
      if (type === "debit") {
        return <Typography>Débito</Typography>;
      }
      if (type === "boleto") {
        return <Typography>Boleto</Typography>;
      }
      if (type === "commission") {
        return <Typography>Comissão</Typography>;
      }
      if (type === "pix") {
        return <Typography>Pix</Typography>;
      }
    },
  },
  {
    headerText: "Valor Bruto",
    key: "transaction.amount",
    CustomValue: (value) => <Typography>{formatMoney(value)}</Typography>,
  },
  {
    headerText: "Valor da taxa",
    key: "custom_valor_taxa",
    FullCustom: (row) => {
      return <Typography>{formatMoney(getTotalFee(row))}</Typography>;
    },
  },

  {
    headerText: "Valor Líquido",
    key: "custom_valor_liquido",
    FullCustom: (row) => {
      const { amount } = row.transaction;
      const total_fees = getTotalFee(row);
      const valorLiquido = (amount - total_fees).toFixed(2);
      return <Typography>{formatMoney(valorLiquido)}</Typography>;
    },
  },
];

export default columns;
