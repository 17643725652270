import * as React from "react";
import {
  ArgumentAxis,
  Chart,
  LineSeries,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { Box, Typography, useTheme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { getCssVariables } from "constants/theme";

const makeLabel =
  (symbol, color) =>
  ({ text, style, ...restProps }) =>
    (
      <ValueAxis.Label
        text={`${text} ${symbol}`}
        style={{
          fontSize: "10px",
          fill: color,
          ...style,
        }}
        {...restProps}
      />
    );

const CustomLineChart = ({ data }) => {
  const theme = useTheme();
  const labelColor = theme.palette.primary.main;
  const labelColorFunction = makeLabel("", labelColor);

  return (
    <Paper
      style={{
        borderRadius: 20,
        background: getCssVariables().gradient.light,
      }}
    >
      <Chart data={data} pallete={theme.palette.primary.main} height={300}>
        <Box style={{ padding: "20px" }}>
          <Typography
            style={{
              marginTop: "3px",
            }}
          >
            CONTAS CADASTRADAS POR MÊS
          </Typography>
        </Box>

        <ArgumentAxis labelComponent={labelColorFunction} />
        <ValueAxis labelComponent={labelColorFunction} showGrid={false} />
        <LineSeries
          color={theme.palette.primary.main}
          valueField="value"
          argumentField="argument"
        />
      </Chart>
    </Paper>
  );
};

export default CustomLineChart;
