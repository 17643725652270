import React, { useEffect } from "react";
import {
  Box,
  Divider,
  Typography,
} from "@material-ui/core";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import FeeDetails from "./Fees/FeeDetails";
import AplicationSalesPlanDetails from "./PlanDetails/AplicationSalesPlanDetails";
import { useShowMySalesPlanSubscriptionFeesQuery, useShowMySalesPlanSubscriptionQuery } from "services/api";
import AgentSalesPlanDetails from "./PlanDetails/AgentSalesPlanDetails";
import { useMemo } from "react";
import CustomCopyToClipboard from "components/reusable/Text/CustomCopyToClipboard";
import { format } from "date-fns";

const ShowSalesPlanEcFees = () => {
  const history = useHistory();
  const { id } = useParams();
  const userData = useSelector((state) => state.userData);
  const accountId = useMemo(() => id || userData.id, [id, userData]);

  const {
    data: salesPlans,
    isLoading: isLoadingSalesPlans,
    isError: isErrorSalesPlans,
    isUninitialized: isUninitializedSalesPlans,
  } = useShowMySalesPlanSubscriptionQuery(
    {
      conta_id: accountId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !accountId,
    }
  );

  const {
    data: fees,
    isLoading: isLoadingFees,
    isError: isErrorFees,
    isUninitialized: isUninitializedFees,
  } = useShowMySalesPlanSubscriptionFeesQuery(
    {
      conta_id: accountId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !accountId,
    }
  );

  useEffect(() => {
    if (isErrorSalesPlans || isErrorFees) {
      toast.error("Aconteceu um erro tente novamente!");
      history.goBack();
    }
  }, [isErrorSalesPlans, isErrorFees, history]);

  return (
    <Box display="flex" flexDirection="column">
      <LoadingScreen isLoading={isLoadingSalesPlans || isLoadingFees} />
      {id ?
        <CustomBreadcrumbs
          path1="Gerenciar Listas"
          to1="goBack"
          path2="Tarifas"
          to2="#"
        />
      :
        <CustomBreadcrumbs
          path1="x"
          to1="goBack"
          path2="Tarifas"
          to2="#"
        />
      }

      <Box display="flex" justifyContent="">
        <Typography style={{ marginTop: "8px" }} variant="h4">
          Plano de Vendas
        </Typography>
      </Box>

      <Divider style={{ marginTop: 16, marginBottom: 8 }} />

      {!isLoadingSalesPlans && !isErrorSalesPlans && !isUninitializedSalesPlans &&
        <>
          <Typography variant="h6">Detalhes do Plano de Vendas da Aplicação:</Typography>
          <Box width="100%">
            <Box width="100%" display="flex" flexWrap="wrap">
              <Box marginTop="12px" marginRight="12px" flexGrow={1} maxWidth={500}>
                <Typography>ID da Assinatura:</Typography>
                <CustomCopyToClipboard title={"Copiar ID do Plano de Vendas"} value={salesPlans?.app_sign?.id ?? "-"} />
              </Box>
              <Box marginTop="12px" flexGrow={1}>
                <Typography>ID de Conciliação da Assinatura:</Typography>
                <CustomCopyToClipboard title={"Copiar ID do Plano de Vendas"} value={salesPlans?.app_sign?.zoop_subscription_id ?? "-"} />
              </Box>
            </Box>
            <Box marginTop="12px" flexGrow={1}>
              <Typography>Assinado em:</Typography>
              <Typography variant="h6">
                {salesPlans?.app_sign?.created_at
                  ? format(
                      new Date(salesPlans?.app_sign?.created_at),
                      "dd/MM/yyyy HH:mm:ss"
                    )
                  : "-"}
              </Typography>
            </Box>
          </Box>
          <AplicationSalesPlanDetails salesPlan={salesPlans.app_plan} />

          <Divider style={{ marginTop: 16, marginBottom: 16 }} />

          {salesPlans.agent_plan &&
            <>
              <Typography variant="h6">Detalhes do Plano de Vendas do Representante:</Typography>
              <Box width="100%">
                <Box width="100%" display="flex" flexWrap="wrap">
                  <Box marginTop="12px" marginRight="12px" flexGrow={1} maxWidth={500}>
                    <Typography>ID da Assinatura:</Typography>
                    <CustomCopyToClipboard title={"Copiar ID do Plano de Vendas"} value={salesPlans.agent_sign.id ?? "-"} />
                  </Box>
                  <Box marginTop="12px" flexGrow={1}>
                    <Typography>Assinado em:</Typography>
                    <Typography variant="h6">
                      {salesPlans?.agent_sign?.created_at
                        ? format(
                            new Date(salesPlans?.agent_sign?.created_at),
                            "dd/MM/yyyy HH:mm:ss"
                          )
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <AgentSalesPlanDetails salesPlan={salesPlans.agent_plan} />
              <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            </>
          }

          {!isLoadingFees && !isErrorFees && !isUninitializedFees &&
            <FeeDetails feeDetails={fees} disableAll={true} />
          }
        </>
      }
    </Box>
  );
};

export default ShowSalesPlanEcFees;
