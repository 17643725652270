import React, { useMemo } from "react";
import {
  Box,
  Button,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CopyToClipboard from "react-copy-to-clipboard";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { ADM_PERMISSIONS } from "constants/permissions";

const AgentSalesPlanDetails = ({ salesPlan }) => {
  const userPermissions = useSelector((state) => state.userPermissao.permissao);
  const permissionsName = userPermissions.map((permission) => permission.tipo);
  const isManager = useMemo(
    () =>
      permissionsName.includes(ADM_PERMISSIONS.MANAGEMENT),
    [permissionsName]
  );
  const isAgent = useMemo(
    () =>
      permissionsName.includes(ADM_PERMISSIONS.AGENT),
    [permissionsName]
  );

  return (
    <Box display="flex" flexWrap="wrap">
      <Box marginRight="12px" flexGrow={1} maxWidth={500}>
        <Box marginTop="12px">
          <Typography>ID do Plano de Vendas:</Typography>
          <Box width="100%" maxWidth={330} display="flex">
            <TextField 
              fullWidth
              value={salesPlan.id ? salesPlan.id : "-"}
            />
            <Tooltip title="Copiar ID do Plano de Vendas">
              <CopyToClipboard
                text={salesPlan.id ? salesPlan.id : "-"}
              >
                <Button
                  aria="Copiar"
                  style={{
                    marginLeft: "8px",
                    padding: 0,
                    minWidth: 0,
                    width: "20px",
                    height: "20px",
                    alignSelf: "center",
                    color: "green",
                  }}
                  onClick={() =>
                    toast.success(
                      "Copiado para area de transferência",
                      {
                        autoClose: 2000,
                      }
                    )
                  }
                >
                  <FontAwesomeIcon
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    icon={faCopy}
                  />
                </Button>
              </CopyToClipboard>
            </Tooltip>
          </Box>
        </Box>
        <Box marginTop="12px">
          <Typography>Nome:</Typography>
          <Typography variant="h6">
            {salesPlan.name ?? "-"}
          </Typography>
        </Box>
        <Box marginTop="12px">
          <Typography>Situação do Plano de Vendas:</Typography>
          <Typography variant="h6">
            {salesPlan.deleted_at ? "Inativo" : "Ativo"}
          </Typography>
        </Box>
        {(isManager || isAgent) &&
          <Box marginTop="12px">
            <Typography>Total de ECs neste Plano de Vendas:</Typography>
            <Typography variant="h6">
              {salesPlan.ec_count ?? "0"}
            </Typography>
          </Box>
        }
      </Box>
      <Box flexGrow={1}>
        <Box marginTop="12px">
          <Typography>Descrição:</Typography>
          <Typography variant="h6">
            {salesPlan.description ?? "-"}
          </Typography>
        </Box>
        <Box marginTop="12px">
          <Typography>Criado em:</Typography>
          <Typography variant="h6">
            {salesPlan.created_at
              ? format(
                  new Date(salesPlan.created_at),
                  "dd/MM/yyyy HH:mm:ss"
                )
              : "-"}
          </Typography>
        </Box>
        <Box marginTop="12px">
          <Typography>Ultima atualização:</Typography>
          <Typography variant="h6">
            {salesPlan.updated_at
              ? format(
                  new Date(salesPlan.updated_at),
                  "dd/MM/yyyy HH:mm:ss"
                )
              : "-"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AgentSalesPlanDetails;
