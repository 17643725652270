import {
  faArchive,
  faBarcode,
  faCopy,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faHistory,
  faLink,
  faUndo,
  faUsers,
  faMobile,
  faMoneyBill,
  faListUl,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import { Box, Divider, makeStyles, Paper } from "@material-ui/core";
import { loadContaId } from "actions/actions";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import AccountCollectionItem from "../../reusable/Content/AccountCollections/AccountCollectionItem/AccountCollectionItem";
import GradientButton from "../../reusable/GradientButton/GradientButton";
import AccountDetails from "components/reusable/Account/AccountDetails";

const useStyles = makeStyles((theme) => ({
  responsiveWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(850)]: {
      flexDirection: "column",
    },
  },
}));

const AdmLists = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const contaSelecionada = useSelector((state) => state.conta);
  const { id } = useParams();

  useEffect(() => {
    dispatch(loadContaId(id));
  }, [dispatch, id]);

  const hasContaPai = useMemo(
    () => contaSelecionada.conta_pai?.length > 0,
    [contaSelecionada]
  );

  const contaPai = useMemo(() => {
    if (hasContaPai) {
      return contaSelecionada.conta_pai[0];
    }
    return null;
  }, [contaSelecionada, hasContaPai]);

  return (
    <Box display="flex" justifyContent="space-around" flexWrap="wrap">
      <Box minWidth="300px" maxWidth="350px" flexGrow="1" marginBottom="12px">
        <Paper
          style={{
            padding: "24px",
            margin: "12px",
            borderRadius: "27px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AccountDetails text="Conta Selecionada" account={contaSelecionada} />
          {contaPai && (
            <>
              <Divider
                style={{
                  marginTop: "10px",
                }}
              />
              <AccountDetails text="Conta Proprietária" account={contaPai} />
            </>
          )}
          <Box display="flex" justifyContent="center" marginTop="20px">
            <GradientButton
              buttonText="Voltar"
              onClick={() => history.push("/dashboard/contas")}
            />
          </Box>
        </Paper>
      </Box>

      <Box display="flex" flexDirection="column">
        <Box className={classes.responsiveWrapper}>
          <Box display="flex">
            <AccountCollectionItem
              text="Histórico de Transações"
              link="historico-de-transacoes"
              icon={faDesktop}
            />
            <AccountCollectionItem
              text="Histórico de Transferencias"
              link="historico-transferencia"
              icon={faHistory}
            />
            <AccountCollectionItem
              text="Extrato"
              link="extrato"
              icon={faDollarSign}
            />
          </Box>
        </Box>
        <Box className={classes.responsiveWrapper}>
          <Box display="flex">
            <AccountCollectionItem
              text="Pagadores"
              link="pagadores"
              icon={faUsers}
            />
            <AccountCollectionItem
              text="Máquinas Virtuais"
              link="cobrancas-credito"
              icon={faCreditCard}
            />
            <AccountCollectionItem
              text="Boletos"
              link="boleto"
              icon={faBarcode}
            />
          </Box>
        </Box>
        <Box className={classes.responsiveWrapper}>
          <Box display="flex">
            <AccountCollectionItem text="Carnês" link="carne" icon={faCopy} />
            <AccountCollectionItem
              text="Links de Pagamento"
              link="link-pagamento"
              icon={faLink}
            />
            <AccountCollectionItem
              text="Cobrança Recorrente"
              link="assinaturas"
              icon={faUndo}
            />
          </Box>
        </Box>
        <Box className={classes.responsiveWrapper}>
          <Box display="flex">
            <AccountCollectionItem
              link={"exportacoes-solicitadas"}
              text="Exportações Solicitadas"
              icon={faArchive}
            />
            <AccountCollectionItem
              link={"listar-terminais-pos"}
              text="Terminais POS"
              icon={faMobile}
            />
            <AccountCollectionItem
              link={"tarifas-ec"}
              text="Tarifas"
              icon={faMoneyBill}
            />
          </Box>
        </Box>
        <Box className={classes.responsiveWrapper}>
          <Box display="flex">
            <AccountCollectionItem
              link={"arquivo-remessa"}
              text="Arquivos de remessa"
              icon={faFolderOpen}
            />
            <AccountCollectionItem
              link={"contas-digitais"}
              state={{ conta_id: contaSelecionada.id }}
              text="Contas"
              subText={`Total: ${contaSelecionada.conta_filha_count}`}
              icon={faListUl}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AdmLists;
