import { Box, makeStyles, Typography } from '@material-ui/core';
import { ColorPicker } from 'material-ui-color';
import React from 'react';

const useStyles = makeStyles(() => ({
	currency: {
    
	},
}));

const CustomColorPicker = ({
  value,
  defaultValue,
  label,
  colors,
  setColors,
  keyString
}) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Typography>{label}</Typography>
        <ColorPicker
          defaultValue={defaultValue}
          value={value}
          deferred
          onChange={(color) => {
            console.log(keyString, color.css.backgroundColor);
            setColors({
              ...colors,
              [keyString]: color.css.backgroundColor
            });
          }}
        />
      </Box>
    </>
  );
}

export default CustomColorPicker;
