import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  loadBancos,
  loadContaBancaria,
  postContaBancariaAction,
} from "../../../../actions/actions";
import GradientButton from "../../../reusable/GradientButton/GradientButton";
import LoadingScreen from "../../../reusable/LoadingScreen/LoadingScreen";

const CreateBankAccount = ({
  contaId,
  contaBancaria,
  setContaBancaria,
  disableCadastro,
  setDisableCadastro,
}) => {
  const dispatch = useDispatch();
  const [errosContaBancaria, setErrosContaBancaria] = useState({});
  const Listbancos = useSelector((state) => state.bancos);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(loadBancos());
  }, [dispatch]);

  const handleCadastrarConta = async () => {
    setLoading(true);
    const res = await dispatch(postContaBancariaAction(contaBancaria, contaId));
    if (res) {
      setErrosContaBancaria(res);
      toast.error("Erro ao cadastrar conta bancaria");
    } else {
      toast.success("Conta criada com sucesso");
      if (disableCadastro) {
        setDisableCadastro(true);
      }
      setErrosContaBancaria({});
      setContaBancaria({
        ...contaBancaria,
        banco: "",
        agencia: "",
        tipo: "1",
        conta: "",
      });
      await dispatch(loadContaBancaria(contaId));
    }
    setLoading(false);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      flexDirection="column"
    >
      <LoadingScreen isLoading={loading} />
      <Typography align="center">Cadastrar conta bancária</Typography>

      <FormControl>
        <RadioGroup
          style={{ alignSelf: "center" }}
          value={contaBancaria.tipo}
          onChange={(e) =>
            setContaBancaria({ ...contaBancaria, tipo: e.target.value })
          }
        >
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="Conta Corrente"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="Conta Poupança"
          />
        </RadioGroup>
      </FormControl>
      <Grid container>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={Object.entries(Listbancos)}
            getOptionLabel={(option) => option[1]}
            onChange={(_event, option) => {
              setContaBancaria({
                ...contaBancaria,
                banco: option ? option[0] : null,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecionar Banco"
                margin="normal"
                error={errosContaBancaria.banco}
                helperText={
                  errosContaBancaria.banco
                    ? errosContaBancaria.banco.join(" ")
                    : null
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={errosContaBancaria.agencia}
            helperText={
              errosContaBancaria.agencia
                ? errosContaBancaria.agencia.join(" ")
                : null
            }
            required
            label="Agência"
            value={contaBancaria.agencia}
            onChange={(e) =>
              setContaBancaria({
                ...contaBancaria,
                agencia: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={errosContaBancaria.conta}
            helperText={
              errosContaBancaria.conta
                ? errosContaBancaria.conta.join(" ")
                : null
            }
            required
            label="Conta"
            value={contaBancaria.conta}
            onChange={(e) =>
              setContaBancaria({ ...contaBancaria, conta: e.target.value })
            }
          />
        </Grid>
      </Grid>
      <Box marginTop="8px">
        <GradientButton
          buttonText="Cadastrar"
          onClick={handleCadastrarConta}
          disabled={disableCadastro}
        />
      </Box>
    </Box>
  );
};

export default CreateBankAccount;
