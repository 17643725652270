import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Loading from "components/reusable/Loading";
import { useState } from "react";
import { toast } from "react-toastify";
import { useExportArquivoRetornoMutation } from "services/api";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
  dialogTitle: {
    background: theme.gradient.main,
    color: "white",
    textAlign: "center",
    marginBottom: 8,
  },
  dialogSelectFile: {
    margin: "10px auto",
  },
}));
const DialogExport = ({ open, handleClose }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    name: "",
    data_inicial: "",
    data_final: "",
  });
  const [errors, setErrors] = useState({});
  const [exportReturnFile] = useExportArquivoRetornoMutation();

  const handleExport = async () => {
    setErrors({});
    try {
      await exportReturnFile({
        name: form.name,
        data_inicial: form.data_inicial,
        data_final: form.data_final,
      }).unwrap();
      toast.success(
        "Estamos processando e em breve será disponibilizado no seu e-mail"
      );
      handleClose();
      setForm({ name: "", data_inicial: "", data_final: "" });
    } catch (e) {
      if (e.status === 422) setErrors(e.data.errors);
      if (e.status === 404) toast.error(e.data.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle className={classes.dialogTitle}>
        Solicitação Arquivo de Retorno
      </DialogTitle>

      <DialogContent>
        <Typography
          style={{
            marginBottom: "10px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Selecione o intervalo de data que você deseja o arquivo de retorno:
        </Typography>
        <TextField
          style={{ marginBottom: "10px" }}
          InputLabelProps={{ shrink: true }}
          value={form.name}
          error={errors?.name}
          helperText={errors.name ? errors.name[0] : null}
          onChange={(e) =>
            setForm({
              ...form,
              name: e.target.value,
            })
          }
          fullWidth
          required
          label={"Nome do arquivo retorno"}
        />
        <TextField
          fullWidth
          style={{ marginBottom: "10px" }}
          InputLabelProps={{
            shrink: true,
            pattern: "d {4}- d {2}- d {2} ",
          }}
          type="date"
          label="Data Inicial"
          value={form.data_inicial}
          error={errors?.data_inicial}
          helperText={errors.data_inicial ? errors.data_inicial[0] : null}
          onChange={(e) => setForm({ ...form, data_inicial: e.target.value })}
          required
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
            pattern: "d {4}- d {2}- d {2} ",
          }}
          type="date"
          label="Data Final"
          value={form.data_final}
          error={errors?.data_final}
          helperText={errors.data_final ? errors.data_final[0] : null}
          onChange={(e) => setForm({ ...form, data_final: e.target.value })}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            minWidth: 120,
            height: 40,
          }}
          onClick={handleClose}
          variant="outlined"
          color="secondary"
        >
          Cancelar
        </Button>
        <Button
          color="default"
          variant="outlined"
          onClick={handleExport}
          autoFocus
          style={{
            minWidth: 120,
            height: 40,
          }}
        >
          <Loading>Enviar</Loading>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogExport;
