import { Button } from "@material-ui/core";
import React from "react";

const HeadButton = (props) => {
	return (
    <Button
      {...props}
      variant="outlined"
      onClick={props.onClick}
    >
      {props.text}
    </Button>
	);
};

export default HeadButton;
