import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import Loading from "components/reusable/Loading";
import { useState } from "react";
import { toast } from "react-toastify";
import { useUploadArquivoRemessaMutation } from "services/api";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
  dialogTitle: {
    background: theme.gradient.main,
    color: "white",
    textAlign: "center",
    marginBottom: 8,
  },
  dialogSelectFile: {
    margin: "10px auto",
  },
}));
const DialogUpload = ({ open, handleClose }) => {
  const classes = useStyles();
  const history = useHistory();

  const [selectedFile, setSelectedFile] = useState(null);

  const [uploadArquivoRemessa] = useUploadArquivoRemessaMutation();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    try {
      const response = await uploadArquivoRemessa({
        file: selectedFile,
      }).unwrap();
      toast.success("Arquivo enviado com sucesso!");
      history.push("/dashboard/criar-arquivo-remessa", { response });
    } catch (e) {
      if (e.status === 422) toast.error(e.data.message);
      else toast.error("Erro ao carregar o arquivo de remessa.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle className={classes.dialogTitle}>
        Carregar arquivo CNBA-400
      </DialogTitle>
      <input
        type="file"
        onChange={handleFileChange}
        className={classes.dialogSelectFile}
      />

      <DialogActions>
        <Button
          style={{
            minWidth: 120,
            height: 40,
          }}
          onClick={handleClose}
          variant="outlined"
          color="secondary"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleUpload}
          color="default"
          variant="outlined"
          autoFocus
          style={{
            minWidth: 120,
            height: 40,
          }}
        >
          <Loading>Enviar</Loading>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUpload;
