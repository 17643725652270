import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import columns from "./Accounts/AccountsColumns";
import { loadContasFilter } from "../../../actions/actions";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import CustomBarChart from "components/reusable/CustomBarChart/CustomBarChart";
import CustomLineChart from "components/reusable/CustomLineChart/CustomLineChart";
import CustomCard from "components/reusable/CustomCard/CustomCard";
import {
  useAccountsBarChartQuery,
  useAccountsLineChartQuery,
  useAccountsPreviewQuery,
} from "services/api";
import EditarAccount from "./Accounts/EditarAccount";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },

  contadorStyle: {
    display: "flex",
    fontSize: "30px",
  },

  cardContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
  },

  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    marginTop: "30px",
  },
}));

const Adm = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const page = 1;
  const contas = useSelector((state) => state.contas);
  useEffect(() => {
    dispatch(loadContasFilter({ page }));
  }, [dispatch, page]);

  const previewQuery = useAccountsPreviewQuery();

  const lineChartQuery = useAccountsLineChartQuery();

  const barChartQuery = useAccountsBarChartQuery();

  const isLoading = useMemo(
    () =>
      previewQuery.isLoading ||
      lineChartQuery.isLoading ||
      barChartQuery.isLoading,
    [previewQuery, lineChartQuery, barChartQuery]
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.headerContainer}>
        <Box marginBottom={2}>
          <Typography variant="h4" style={{ color: theme.palette.background.default }}>
            DASHBOARD
          </Typography>
        </Box>

        {!isLoading ? (
          <>
            <Grid container spacing={2}>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={3}>
                  <CustomCard text="Pendentes">
                    <Box className={classes.cardContainer}>
                      <Typography className={classes.contadorStyle}>
                        {previewQuery.data.cadastro_pendente}
                      </Typography>
                    </Box>
                  </CustomCard>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomCard text="Ativas" aprovada>
                    <Box className={classes.cardContainer}>
                      <Typography className={classes.contadorStyle}>
                        {previewQuery.data.cadastro_ativo}
                      </Typography>
                    </Box>
                  </CustomCard>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomCard text="Negados" rejeitada>
                    <Box className={classes.cardContainer}>
                      <Typography className={classes.contadorStyle}>
                        {previewQuery.data.cadastro_rejeitado}
                      </Typography>
                    </Box>
                  </CustomCard>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomCard text="Desabilitadas / Excluídas">
                    <Box className={classes.cardContainer}>
                      <Typography className={classes.contadorStyle}>
                        {previewQuery.data.cadastro_desabilitado} /{" "}
                        {previewQuery.data.cadastro_deletado}
                      </Typography>
                    </Box>
                  </CustomCard>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} sm={3}>
                  <CustomCard text="Frequência diária">
                    <Box className={classes.cardContainer}>
                      <Typography className={classes.contadorStyle}>
                        {previewQuery.data.frequencia_quantidade_diaria}
                      </Typography>
                    </Box>
                  </CustomCard>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomCard text="Pessoa Física">
                    <Box className={classes.cardContainer}>
                      <Typography className={classes.contadorStyle}>
                        {previewQuery.data.cadastro_pessoa_fisica}
                      </Typography>
                    </Box>
                  </CustomCard>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomCard text="Pessoa Jurídica">
                    <Box className={classes.cardContainer}>
                      <Typography className={classes.contadorStyle}>
                        {previewQuery.data.cadastro_pessoa_juridica}
                      </Typography>
                    </Box>
                  </CustomCard>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomCard text="Total de Contas">
                    <Box className={classes.cardContainer}>
                      <Typography className={classes.contadorStyle}>
                        {previewQuery.data.cadastro_total}
                      </Typography>
                    </Box>
                  </CustomCard>
                </Grid>
              </Grid>
            </Grid>
            <Box className={classes.bodyContainer}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={7}>
                  <Box>
                    <CustomLineChart data={lineChartQuery.data} />
                  </Box>
                </Grid>
                <Grid item sm={12} md={5}>
                  <Box>
                    <CustomBarChart data={barChartQuery.data} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.bodyContainer}>
              <Box maxWidth={"calc(100vw - 65px)"}>
                <CustomTable
                  columns={columns}
                  data={contas?.data ?? []}
                  Editar={EditarAccount}
                />
              </Box>
            </Box>
          </>
        ) : (
          <LoadingScreen isLoading={isLoading} />
        )}
      </Box>
    </Box>
  );
};

export default Adm;
