export const createSplitMutation = ({ split }) => ({
  url: `/split`,
  method: "post",
  data: split,
});

export const recreateSplitsFromConfigMutation = ({
  transacao_id,
  split_config_type,
}) => ({
  url: `/split/recreate-using-config`,
  method: "post",
  data: { transacao_id, split_config_type },
});

export const updateSplitMutation = ({ split, splitId }) => ({
  url: `/split/${splitId}`,
  method: "put",
  data: split,
});

export const deleteSplitMutation = ({ splitId }) => ({
  url: `/split/${splitId}`,
  method: "delete",
});
