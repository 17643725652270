import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	makeStyles,
	Paper,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomCurrencyInput from 'components/reusable/Text/CustomCurrencyInput';
import CustomFormHelperText from 'components/reusable/Text/CustomFormHelperText';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	layout: {},
	paper: {
		marginBottom: theme.spacing(6),
		marginTop: theme.spacing(3),
		padding: theme.spacing(3),
		borderRadius: '27px',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
		height: '60px',
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
		color: 'white',
	},
	headerPaper: {
		padding: theme.spacing(3),
		borderRadius: '27px 0 0 0 ',
		display: 'flex',
		justifyContent: 'space-between',
	},
	currency: {
		font: 'inherit',
		color: 'currentColor',
		width: '100%',
		border: '0px',
		borderBottom: '1px solid gray',
		height: '1.1876em',
		margin: 0,
		display: 'block',
		padding: '6px 0 7px',
		minWidth: 0,
		background: 'none',
		boxSizing: 'content-box',
		animationName: 'mui-auto-fill-cancel',
		letterSpacing: 'inherit',
		animationDuration: '10ms',
		appearance: 'textfield',
		textAlign: 'start',
		paddingLeft: '5px',
	},
}));

const SlipRegistration = ({ boleto, setBoleto, errosBoleto }) => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Typography variant="h4" align="center">
				Dados do boleto
			</Typography>
			<Paper className={classes.paper}>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Box display="flex" flexDirection="column">
								<CustomCurrencyInput
									prefix="R$ "
									value={boleto.valor}
                  label="Valor do boleto"
									onChangeEvent={(event, maskedvalue, floatvalue) =>
										setBoleto({ ...boleto, valor: floatvalue })
									}
                  errorMessage={errosBoleto.valor ? errosBoleto.valor.join(' ') : null}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								fullWidth
								error={errosBoleto.data_vencimento}
								helperText={
									errosBoleto.data_vencimento ? errosBoleto.data_vencimento.join(' ') : null
								}
								InputLabelProps={{
									shrink: true,
									pattern: 'd {4}- d {2}- d {2} ',
								}}
								type="date"
								label="Data de Vencimento"
								value={boleto.data_vencimento}
								onChange={(e) =>
									setBoleto({
										...boleto,
										data_vencimento: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12} sm={8}>
							<TextField
								fullWidth
								error={errosBoleto.instrucao1}
								helperText={errosBoleto.instrucao1 ? errosBoleto.instrucao1.join(' ') : null}
								label="Instruções linha 1"
								value={boleto.instrucao1}
								onChange={(e) =>
									setBoleto({
										...boleto,
										instrucao1: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								error={errosBoleto.instrucao2}
								helperText={errosBoleto.instrucao2 ? errosBoleto.instrucao2.join(' ') : null}
								label="Instruções linha 2"
								value={boleto.instrucao2}
								onChange={(e) =>
									setBoleto({
										...boleto,
										instrucao2: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								error={errosBoleto.instrucao3}
								helperText={errosBoleto.instrucao3 ? errosBoleto.instrucao3.join(' ') : null}
								label="Instruções linha 3"
								value={boleto.instrucao3}
								onChange={(e) =>
									setBoleto({
										...boleto,
										instrucao3: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								error={errosBoleto.descricao}
								helperText={errosBoleto.descricao ? errosBoleto.descricao.join(' ') : null}
								required
								label="Descrição do boleto"
								value={boleto.descricao}
								onChange={(e) =>
									setBoleto({
										...boleto,
										descricao: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography>Deseja aplicar multa após o vencimento do boleto?</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box display="flex" flexDirection="column" width="100%">
										<FormControl component="fieldset">
											<FormLabel component="legend">Forma de cobrança</FormLabel>
											<RadioGroup
												row
												value={boleto.tipo_multa}
												onChange={(e) =>
													setBoleto({
                            ...boleto,
														tipo_multa: e.target.value,
													})
												}
											>
												<FormControlLabel value="1" control={<Radio />} label="Fixo" />
												<FormControlLabel value="2" control={<Radio />} label="Percentual" />
											</RadioGroup>
                      <CustomFormHelperText
                        errorMessage={errosBoleto.tipo_multa ? errosBoleto.tipo_multa.join(' ') : null}
                      />
                      {boleto.tipo_multa === '1' ? (
                        <CustomCurrencyInput
                          value={boleto.valor_multa}
                          label="Valor da multa"
                          prefix="R$ "
                          onChangeEvent={(event, maskedvalue, floatvalue) =>
                            setBoleto({
                              ...boleto,
                              valor_multa: floatvalue,
                            })
                          }
                          errorMessage={errosBoleto.valor_multa ? errosBoleto.valor_multa.join(' ') : null}
                        />
                      ) : (
                        <CustomCurrencyInput
                          value={boleto.valor_multa}
                          label="Valor da multa"
                          suffix=" %"
                          onChangeEvent={(event, maskedvalue, floatvalue) =>
                            setBoleto({
                              ...boleto,
                              valor_multa: floatvalue,
                            })
                          }
                          errorMessage={errosBoleto.valor_multa ? errosBoleto.valor_multa.join(' ') : null}
                        />
                      )}
										</FormControl>
									</Box>
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12}>
							<Accordion>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography>Deseja aplicar juros após o vencimento do boleto?</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box display="flex" flexDirection="column" width="100%">
										<FormControl component="fieldset">
											<FormLabel component="legend">Forma de cobrança</FormLabel>
											<RadioGroup
												row
												value={boleto.tipo_juros}
												onChange={(e) =>
													setBoleto({
                            ...boleto,
														tipo_juros: e.target.value,
													})
												}
											>
												<FormControlLabel value="1" control={<Radio />} label="Valor diário" />
												<FormControlLabel value="2" control={<Radio />} label="Percentual diário" />
												<FormControlLabel value="3" control={<Radio />} label="Percentual mensal" />
											</RadioGroup>
                      <CustomFormHelperText 
                        errorMessage={errosBoleto.tipo_juros ? errosBoleto.tipo_juros.join(' ') : null}
                      />
											{boleto.tipo_juros === '2' || boleto.tipo_juros === '3' ? (
												<CustomCurrencyInput
													suffix=" %"
													value={boleto.valor_juros}
                          label="Valor do juros"
													onChangeEvent={(event, maskedvalue, floatvalue) =>
														setBoleto({
															...boleto,
															valor_juros: floatvalue,
														})
													}
                          errorMessage={errosBoleto.valor_juros ? errosBoleto.valor_juros.join(' ') : null}
												/>
											) : (
												<CustomCurrencyInput
													prefix="R$ "
													value={boleto.valor_juros}
                          label="Valor do juros"
													onChangeEvent={(event, maskedvalue, floatvalue) =>
														setBoleto({
															...boleto,
															valor_juros: floatvalue,
														})
													}
                          errorMessage={errosBoleto.valor_juros ? errosBoleto.valor_juros.join(' ') : null}
												/>
											)}
										</FormControl>
									</Box>
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12}>
							<Accordion>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography>Deseja aplicar desconto?</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box display="flex" flexDirection="column" width="100%">
										<FormControl component="fieldset">
											<FormLabel component="legend">Forma de desconto</FormLabel>
											<RadioGroup
												row
												value={boleto.tipo_desconto}
												onChange={(e) =>
													setBoleto({
                            ...boleto,
														tipo_desconto: e.target.value,
													})
												}
											>
												<FormControlLabel value="1" control={<Radio />} label="Fixo" />
												<FormControlLabel value="2" control={<Radio />} label="Percentual" />
											</RadioGroup>
                      <CustomFormHelperText 
                        errorMessage={errosBoleto.tipo_desconto ? errosBoleto.tipo_desconto.join(' ') : null}
                      />
                      {boleto.tipo_desconto === '1' ? (
                        <CustomCurrencyInput
                          prefix="R$ "
                          value={boleto.valor_desconto}
                          label="Valor do desconto"
                          onChangeEvent={(event, maskedvalue, floatvalue) =>
                            setBoleto({
                              ...boleto,
                              valor_desconto: floatvalue,
                            })
                          }
                          errorMessage={errosBoleto.valor_desconto ? errosBoleto.valor_desconto.join(' ') : null}
                        />
                      ) : (
                        <CustomCurrencyInput
                          suffix=" %"
                          value={boleto.valor_desconto}
                          label="Valor do desconto"
                          onChangeEvent={(event, maskedvalue, floatvalue) =>
                            setBoleto({
                              ...boleto,
                              valor_desconto: floatvalue,
                            })
                          }
                          errorMessage={errosBoleto.valor_desconto ? errosBoleto.valor_desconto.join(' ') : null}
                        />
                      )}
										</FormControl>

										<Box marginTop="12px">
											<TextField
												type="date"
												fullWidth
												label="Data Limite"
												error={errosBoleto.data_limite_valor_desconto}
												helperText={errosBoleto.data_limite_valor_desconto ? errosBoleto.data_limite_valor_desconto.join(' ') : null}
												value={boleto.data_limite_valor_desconto}
												onChange={(e) =>
													setBoleto({
														...boleto,
														data_limite_valor_desconto: e.target.value,
													})
												}
												InputLabelProps={{
													shrink: true,
													pattern: 'd {4}- d {2}- d {2} ',
												}}
											/>
										</Box>
									</Box>
								</AccordionDetails>
							</Accordion>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Box>
	);
};

export default SlipRegistration;
