import {
	AppBar,
	Box,
	Divider,
	makeStyles,
	Paper,
	Tab,
	Tabs,
	Typography,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import SwipeableViews from 'react-swipeable-views';
import { toast } from 'react-toastify';
import { loadContaId, updateConta } from '../../../actions/actions';
import NewAccount from '../../reusable/Account/NewAccount/NewAccount';
import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import GradientButton from '../../reusable/GradientButton/GradientButton';
import LoadingScreen from '../../reusable/LoadingScreen/LoadingScreen';
import CreateBankAccount from '../BankAccounts/CreateBankAccount/CreateBankAccount';
import ListBankAccounts from '../BankAccounts/ListBankAccounts/ListBankAccounts';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	layout: {
		width: '800px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		width: '70%',
		borderRadius: '27px',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	card: {
		margin: theme.spacing(1),
		padding: 0,
	},
	dropzoneAreaBaseClasses: {
		width: '70%',
		height: '250px',
	},
	dropzoneContainer: {
		margin: '6px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '12px',
		minHeight: '564px',
		fontSize: '12px',
	},
	textoDropzone: {
		fontSize: '1.2rem',
	},
}));

const a11yProps = (index) => {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
};

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const EditDigitalAccount = () => {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const dispatch = useDispatch();
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState(0);
	const [errosConta, setErrosConta] = useState({});
	const [conta, setConta] = useState({
		documento: '',
		nome: '',
		razao_social: '',
		cnpj: '',
		celular: '',
		data_nascimento: '',
		email: '',
		renda: '',
		site: '',
		endereco: {
			cep: '',
			rua: '',
			numero: '',
			complemento: '',
			bairro: '',
			cidade: '',
			estado: '',
		},
	});
	const [contaBancaria, setContaBancaria] = useState({
		banco: '',
		agencia: '',
		tipo: '1',
		conta: '',
	});

	const contaId = useSelector((state) => state.conta);

	useEffect(() => {
		setConta({ ...contaId });
	}, [contaId]);

	useEffect(() => {
		dispatch(loadContaId(id));
	}, []);

	const handleAlterar = async () => {
		setLoading(true);
		const resConta = await dispatch(updateConta(conta, id));
		if (resConta) {
			setErrosConta(resConta);
			toast.error('Erro ao alterar dados');
			setLoading(false);
		} else {
			toast.success('Dados alterados com sucesso!');
			setLoading(false);
			history.push('/dashboard/contas-digitais');
		}
	};
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const handleChangeIndex = (index) => {
		setValue(index);
	};

	return (
		<Box className={classes.root}>
			<CustomBreadcrumbs
				path1="Contas Digitais"
				path2="Editar Conta Digital"
				to1="/dashboard/contas-digitais"
			/>
			<Typography style={{ marginTop: '8px', marginBottom: '8px' }} variant="h4">
				Editar Conta
			</Typography>
			<LoadingScreen isLoading={loading} />
			<Paper className={classes.paper}>
				<AppBar
					position="static"
					style={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: "27px 27px 0 0 ",
            zIndex: "0",
          }}
				>
					<Tabs
						value={value}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="fullWidth"
					>
						<Tab label="Dados Cadastrais" style={{ width: '200%' }} {...a11yProps(0)} />
						<Tab label="Dados Bancários" style={{ width: '200%' }} {...a11yProps(1)} />
					</Tabs>
				</AppBar>
				<SwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={value}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={value} index={0} dir={theme.direction}>
						<NewAccount
							conta={conta}
							setConta={setConta}
							errosConta={errosConta}
							disableEditar="true"
						/>
						<Box display="flex" justifyContent="flex-end" marginTop="16px">
							<GradientButton buttonText="Alterar" onClick={handleAlterar} />
						</Box>
					</TabPanel>
					<TabPanel value={value} index={1} dir={theme.direction}>
						<Box>
							<Typography align="center" variant="h6">
								Lista de contas bancárias
							</Typography>
							<ListBankAccounts contaId={contaId.id} />
							<Box margin="16px"> </Box>
							<Divider />
							<Box margin="16px"> </Box>
							<CreateBankAccount contaId={contaId.id} contaBancaria={contaBancaria} setContaBancaria={setContaBancaria} />
						</Box>
					</TabPanel>
				</SwipeableViews>
			</Paper>
		</Box>
	);
};

export default EditDigitalAccount;
