import * as React from "react";
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { Box, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useMemo } from "react";

const statusNames = {
  pending: "Pendente",
  enabled: "Habilitado",
  active: "Ativo",
  denied: "Negado",
  disabled: "Desabil.",
  deleted: "Excluído",
};

const CustomBarChart = ({ data }) => {
  const items = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        argument: statusNames[item.argument],
      })
    ), [data]
  );

  return (
    <Paper
      style={{
        borderRadius: 20,
        backgroundColor: "#ECECF4",
      }}
    >
      <Chart data={items} pallete="black" height={300}>
        <Box style={{ padding: "20px" }}>
          <Typography
            style={{
              marginTop: "3px",
            }}
          >
            QUANTIDADE DE CONTAS POR STATUS
          </Typography>
        </Box>
        <ArgumentAxis style={{ color: "black" }} />
        <ValueAxis showGrid={false} />

        <BarSeries
          valueField="value"
          argumentField="argument"
          barWidth={0.1}
          color="#4C4B97"
        />
      </Chart>
    </Paper>
  );
};

export default CustomBarChart;
