import Cards from "react-credit-cards";
import React from "react";
import { Box } from "@material-ui/core";

const CardDetails = (props) => {
  const { transaction } = props;
  return (
    <Box margin="10px">
      <Cards
        name={
          transaction.payment_method.holder_name ??
          "Cartão sem nome"
        }
        number={
          transaction.payment_method.first4_digits +
          "********" +
          transaction.payment_method.last4_digits
        }
        placeholders={{ name: "NOME DO TITULAR" }}
        expiry={
          transaction.payment_method.expiration_month > 9
            ? transaction.payment_method.expiration_month
            : "0" +
              transaction.payment_method.expiration_month +
              transaction.payment_method.expiration_year
        }
      />
    </Box>
  );
};

export default CardDetails;
