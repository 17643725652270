import { Box, Typography } from "@material-ui/core";
import TransactionStatusColumn from "components/reusable/TableColumns/TransactionStatusColumn";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import React from "react";
import { formatMoney } from "utils/money";
import DateColumn from "components/reusable/TableColumns/DateColumn";

const columns = [
  {
    headerText: "Transação Criada",
    key: "transacao",
    CustomValue: (transacao) => {
      return <DateTimeColumn dateTime={transacao.created_at} />;
    },
  },
  {
    headerText: "Previsto",
    key: "expected_on",
    CustomValue: (expected_on) => {
      return <DateColumn date={expected_on} />;
    },
  },
  {
    headerText: "Pago",
    key: "paid_at",
    CustomValue: (paid_at) => {
      return <DateColumn date={paid_at} />;
    },
  },
  {
    headerText: "Pagador",
    key: "transacao.pagador",
    CustomValue: (pagador) => (
      <Box display="flex" flexDirection="column">
        <Typography>{pagador ? pagador.nome : null}</Typography>
        <Typography>{pagador ? pagador.documento : null}</Typography>
      </Box>
    ),
  },
  {
    headerText: "Recebedor",
    key: "conta",
    CustomValue: (conta) => (
      <Box display="flex" flexDirection="column">
        <Typography>{conta.razao_social ?? conta.nome}</Typography>
        <Typography>{conta.cnpj ?? conta.documento}</Typography>
      </Box>
    ),
  },
  {
    headerText: "Situação",
    key: "status",
    CustomValue: (status) => {
      return <TransactionStatusColumn status={status} />;
    },
  },
  {
    headerText: "Origem",
    key: "transacao.transaction",
    CustomValue: (transaction) => {
      const type = transaction.payment_type;
      if (type === "credit") {
        const installments = transaction.installment_plan
          ? transaction.installment_plan.number_installments
          : 1;
        const flag = transaction.payment_method.card_brand;
        return (
          <Typography>
            Crédito {installments}x - {flag}
          </Typography>
        );
      }
      if (type === "debit") {
        return <Typography>Débito</Typography>;
      }
      if (type === "boleto") {
        return <Typography>Boleto</Typography>;
      }
      if (type === "commission") {
        return <Typography>Comissão</Typography>;
      }
      if (type === "pix") {
        return <Typography>Pix</Typography>;
      }
    },
  },
  {
    headerText: "Parcela",
    key: "installment",
    CustomValue: (value) => {
      const installment = value ? value : 1;
      return <Typography>{installment}</Typography>;
    },
  },
  {
    headerText: "Valor Bruto",
    key: "gross_amount",
    CustomValue: (value) => <Typography>{formatMoney(value)}</Typography>,
  },
  {
    headerText: "Valor Líquido",
    key: "amount",
    CustomValue: (value) => <Typography>{formatMoney(value)}</Typography>,
  },
];

export default columns;
