const getAuditDetails = ({
  transaction,
  receivablesPaid,
  receivablesPreview,
}) => {
  const paymentPreviewItem = receivablesPreview?.payment[0]?.items[0];
  const splitPreviewItem = receivablesPreview?.split[0]?.items[0];
  const mdrPreviewItem = receivablesPreview?.mdr[0]?.items[0];

  const totalPaymentPreview =
    parseFloat(paymentPreviewItem?.amount) +
    parseFloat(paymentPreviewItem?.total_splits_amount);

  const totalMdrAppFeeApplied = receivablesPaid?.mdr.reduce(function (
    total,
    item
  ) {
    if (item.type === "mdr_aplication") {
      return parseFloat(total) + parseFloat(item.total_paid_amount);
    }
    return total;
  },
  0.0);

  const totalMdrAgentFeeApplied = receivablesPaid?.mdr.reduce(function (
    total,
    item
  ) {
    if (item.type === "mdr_agent") {
      return parseFloat(total) + parseFloat(item.total_paid_amount);
    }
    return total;
  },
  0.0);

  const totalFeeApplied =
    totalMdrAgentFeeApplied +
    totalMdrAppFeeApplied +
    parseFloat(transaction?.fees);

  const totalPaymentPaid = receivablesPaid?.payment.reduce(
    (total, item) => parseFloat(total) + parseFloat(item.total_paid_amount),
    0.0
  );

  const totalSplitPaid = receivablesPaid?.split.reduce(
    (total, item) => parseFloat(total) + parseFloat(item.total_paid_amount),
    0.0
  );

  const totalPaymentPending = receivablesPaid?.payment.reduce(
    (total, item) => parseFloat(total) + parseFloat(item.total_pending_amount),
    0.0
  );
  const totalSplitPending = receivablesPaid?.split.reduce(
    (total, item) => parseFloat(total) + parseFloat(item.total_pending_amount),
    0.0
  );
  const totalPaid = totalPaymentPaid + totalSplitPaid;

  const totalPending = totalPaymentPending + totalSplitPending;

  const conciliationStatus = (() => {
    if (transaction?.status !== "succeeded") {
      return { text: "SEM PAGAMENTO", color: "black" };
    }
    if (totalPaymentPreview.toFixed(2) === totalPaid.toFixed(2)) {
      return { text: "OK", color: "green" };
    }
    if (totalPending.toFixed(2) !== "0.00") {
      return { text: "EM PROCESSO", color: "#dfad06" };
    }
    return { text: "INCONSISTENTE", color: "red" };
  })();

  return {
    paymentPreviewItem,
    splitPreviewItem,
    mdrPreviewItem,
    totalPaymentPreview,
    totalPaid,
    totalPaymentPaid,
    totalSplitPaid,
    totalPending,
    totalPaymentPending,
    totalSplitPending,
    totalMdrAppFeeApplied,
    totalMdrAgentFeeApplied,
    totalFeeApplied,
    conciliationStatus,
  };
};

export default getAuditDetails;
