import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import { addHours, isSameDay } from "date-fns";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import columns from "./ReleaseDetailsColumns/ReleaseColumn";
import { useHistory, generatePath } from "react-router";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    background: theme.gradient.main,
    color: "white",
    textAlign: "center",
  },
}));

const ReleaseDetailsModal = ({ date, modalVisible, handleModalVisible }) => {
  const classes = useStyles();
  const history = useHistory();
  const resumoTransacao = useSelector((state) => state.resumoTransacao);

  const filterDate = useCallback(
    (data) => {
      return data.filter((value) => {
        const expectedDate = addHours(new Date(value.expected_on), 3);
        const sameDay = isSameDay(date, expectedDate);
        return sameDay;
      });
    },
    [date]
  );

  const detailsRelease = useMemo(() => {
    if (!resumoTransacao.month_paid_receivables) return 0;

    const paid = filterDate(resumoTransacao.month_paid_receivables);
    const pending = filterDate(resumoTransacao.month_pending_receivables);

    return { paid, pending };
  }, [resumoTransacao, filterDate]);

  const handleClickRow = (row) => {
    const path = generatePath("/dashboard/detalhes-transacao/:id/ver", {
      id: row.transaction,
    });
    history.push(path);
  };

  return (
    <Dialog
      maxWidth={false}
      open={modalVisible}
      onClose={() => handleModalVisible(false)}
    >
      <DialogTitle className={classes.dialogTitle}>
        Detalhes do Lançamento
      </DialogTitle>
      {modalVisible && (
        <DialogContent>
          <Box marginTop="18px">
            <Box marginBottom={3}>
              <Typography variant="h5">Recebíveis Pagos</Typography>
            </Box>
            <CustomTable
              handleClickRow={handleClickRow}
              data={detailsRelease.paid}
              columns={columns}
            />
          </Box>
          <Box marginTop="18px" marginBottom="10px">
            <Box marginBottom={3}>
              <Typography variant="h5">Recebíveis Pendentes</Typography>
            </Box>
            <CustomTable
              handleClickRow={handleClickRow}
              data={detailsRelease.pending}
              columns={columns}
            />
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ReleaseDetailsModal;
