import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, useMediaQuery } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardRegistration from "./CardRegistration/CardRegistration";
import { useDispatch, useSelector } from "react-redux";
import {
  postLinkPagamentoPay,
  postPagadores,
} from "../../../../../actions/actions";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import NewPayer from "../../../../reusable/Payer/NewPayer/NewPayer";
import LoadingScreen from "../../../../reusable/LoadingScreen/LoadingScreen";
import GradientButton from "../../../../reusable/GradientButton/GradientButton";
import { APP_CONFIG } from "constants/config";
import { LOAD_PAGADOR_ID } from "constants/actionsStrings";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "800px",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius: "0 0 0 27px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0 0 27px 27px",
      margin: 0,
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    height: "60px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    color: "white",
    marginTop: theme.spacing(3),
  },
}));
const STEPS = ["Dados do pagador", "Dados do cartão", "Conclusão"];

const PaymentLinkPaySteps = ({ id, linkPagamentoId, pagadorId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const pagadorExistente = useSelector((state) => state.pagador);

  const [errosPagador, setErrosPagador] = useState({});
  const [errosLink, setErrosLink] = useState({});

  const [activeStep, setActiveStep] = useState(0);
  const [pagador, setPagador] = useState({
    conta_id: null,
    documento: "",
    nome: "",
    celular: "",
    email: "",
    data_nascimento: "",
    endereco: {
      cep: " ",
      rua: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
    },
  });
  const [linkPagamentoPagar, setLinkPagamentoPagar] = useState({
    pagador_id: null,
    parcelas: "",
    cartao: {
      nome: "",
      numero: "",
      cvv: "",
      mes: "",
      ano: "",
      focus: "",
    },
  });

  useEffect(() => {
    if (pagadorId) {
      toast.success("Link com pagador já vinculado!");
      dispatch({
        type: LOAD_PAGADOR_ID,
        payload: linkPagamentoId.pagador,
      });
      setActiveStep(1);
    }
  }, [dispatch, pagadorId, linkPagamentoId]);

  useEffect(() => {
    setPagador({ ...pagadorExistente });
  }, [pagadorExistente]);

  const STEPS_COMPONENTS = {
    0: (
      <NewPayer
        errosPagador={errosPagador}
        pagador={pagador}
        setPagador={setPagador}
        linkPagamentoPagar={linkPagamentoPagar}
      />
    ),
    1: (
      <CardRegistration
        errosLink={errosLink}
        linkPagamentoPagar={linkPagamentoPagar}
        setLinkPagamentoPagar={setLinkPagamentoPagar}
        pagador={pagador}
        limiteParcelas={linkPagamentoId.limite_parcelas}
        minimoParcelas={linkPagamentoId.minimo_parcelas}
      />
    ),
    2: (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" align="center">
          Pagamento Efetuado com Sucesso!
        </Typography>
        <GradientButton
          style={{ marginTop: theme.spacing(2) }}
          buttonText="Voltar para plataforma"
          onClick={() => history.push("/dashboard/home")}
        />
      </Box>
    ),
  };

  const handleClick = async () => {
    setLoading(true);
    // cadastro de pagador
    if (activeStep === 0) {
      let newPayerData = {
        ...pagador,
        conta_id: linkPagamentoId.conta_id,
      };
      setPagador(newPayerData);

      const resPagador = await dispatch(postPagadores(newPayerData));

      if (resPagador) {
        setErrosPagador(resPagador);
        setActiveStep(0);
        setLoading(false);
      } else {
        toast.success("Cadastro efetuado com sucesso!");
        setActiveStep(activeStep + 1);
        setLoading(false);
      }
    }

    // cadastro do cartao e pagamento do link

    if (activeStep === 1) {
      let newPaymentData = {
        ...linkPagamentoPagar,
        pagador_id: pagador.id,
      };
      setLinkPagamentoPagar(newPaymentData);

      const resLink = await dispatch(postLinkPagamentoPay(newPaymentData, id));

      if (resLink) {
        toast.error("Erro ao efetuar a transação!");
        if (resLink.success === false) {
          toast.error("Código do erro: " + resLink.message_display);
        }
        setErrosLink(resLink);
        setActiveStep(1);
        setLoading(false);
      } else {
        toast.success("Link pago com sucesso!");
        setLoading(false);
        setActiveStep(activeStep + 1);
      }
    }
  };

  return (
    <Box>
      <LoadingScreen isLoading={loading} />
      <Box className={classes.layout}>
        <Paper className={classes.paper}>
          <Box display="flex" justifyContent="space-between">
            <img
              style={matches ? { height: "4rem" } : { height: "6rem" }}
              src={APP_CONFIG().assets.secondaryLogo}
              alt="logo"
            />
            <Box alignSelf="center">
              <Typography variant="h6">Dúvidas ?</Typography>
              <Typography variant="body">{APP_CONFIG().mailSupport}</Typography>
            </Box>
          </Box>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            alternativeLabel
          >
            {STEPS.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box display="flex" flexDirection="column" margin="0" padding="0">
            {STEPS_COMPONENTS[activeStep]}
            {activeStep === 0 || activeStep === 1 ? (
              <Box className={classes.buttons}>
                <Button
                  style={{ borderRadius: "27px" }}
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  className={classes.button}
                  type="submit"
                >
                  {activeStep === 0 ? "Próximo" : ""}
                  {activeStep === 1
                    ? "Pagar link - R$ " + linkPagamentoId.valor
                    : ""}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default PaymentLinkPaySteps;
