import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingUsd,
  faBalanceScale,
  faExchangeAlt,
  faFileInvoiceDollar,
  faChartLine,
  faHouseUser,
  faDesktop,
  faUsers,
  faUserShield,
  faBook,
  faList,
  faCogs,
  faMoneyBillAlt,
  faUserFriends,
  faMoneyBill,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { ADM_PERMISSIONS, USER_PERMISSIONS } from "constants/permissions";

const specificUserItems = [
  {
    pathname: "/dashboard/arquivo-remessa",
    name: "Arquivos remessa",
    icon: faFolderOpen,
    permission: USER_PERMISSIONS.BILLING_SHIPPING_FILE,
  },
];

const defaultUserItems = [
  {
    pathname: "/dashboard/home",
    name: "Home",
    icon: faChartLine,
  },
  {
    pathname: "/dashboard/solicitacoes-de-cobranca",
    name: "Cobranças",
    icon: faHandHoldingUsd,
  },
  {
    pathname: "/dashboard/financas",
    name: "Finanças",
    icon: faBalanceScale,
  },
  {
    pathname: "/dashboard/transferencias",
    name: "Tranferências",
    icon: faExchangeAlt,
  },
  {
    pathname: "/dashboard/outros-servicos",
    name: "Outros serviços",
    icon: faFileInvoiceDollar,
  },
  {
    pathname: "/dashboard/menu-opcoes",
    name: "Opções",
    icon: faCogs,
  },
];

const defaultAgentItems = [
  {
    pathname: "/dashboard/home",
    name: "Home",
    icon: faMoneyBillAlt,
    permission: ADM_PERMISSIONS.AGENT,
  },
  {
    pathname: "/dashboard/solicitacoes-de-cobranca",
    name: "Cobranças",
    icon: faHandHoldingUsd,
    permission: ADM_PERMISSIONS.AGENT,
  },
  {
    pathname: "/dashboard/financas",
    name: "Finanças",
    icon: faBalanceScale,
    permission: ADM_PERMISSIONS.AGENT,
  },
  {
    pathname: "/dashboard/transferencias",
    name: "Tranferências",
    icon: faExchangeAlt,
    permission: ADM_PERMISSIONS.AGENT,
  },
  {
    pathname: "/dashboard/outros-servicos",
    name: "Outros serviços",
    icon: faFileInvoiceDollar,
    permission: ADM_PERMISSIONS.AGENT,
  },
  {
    pathname: "/dashboard/adm",
    name: "Dashboard",
    icon: faHouseUser,
    permission: ADM_PERMISSIONS.AGENT,
  },
  {
    pathname: "/dashboard/contas",
    name: "Gerenciar Contas",
    icon: faUsers,
    permission: ADM_PERMISSIONS.ACCOUNTS_MANAGEMENT,
  },
  {
    pathname: "/dashboard/transacoes",
    name: "Transações",
    icon: faDesktop,
    permission: ADM_PERMISSIONS.TRANSACTIONS,
  },
  {
    pathname: "/dashboard/recebiveis",
    name: "Recebíveis",
    icon: faMoneyBill,
    permission: ADM_PERMISSIONS.TRANSACTIONS,
  },
  {
    pathname: "/dashboard/plano-vendas",
    name: "Planos de Venda",
    icon: faBook,
    permission: ADM_PERMISSIONS.SALES_PLAN_MANAGEMENT,
  },
  {
    pathname: "/dashboard/logs",
    name: "Logs",
    icon: faList,
    permission: ADM_PERMISSIONS.LOGS,
  },
  {
    pathname: "/dashboard/menu-opcoes",
    name: "Opções",
    icon: faCogs,
    permission: ADM_PERMISSIONS.AGENT,
  },
];

const defaultAdmItems = [
  {
    pathname: "/dashboard/adm",
    name: "Home",
    icon: faHouseUser,
    permission: ADM_PERMISSIONS.MANAGEMENT,
  },
  {
    pathname: "/dashboard/contas",
    name: "Gerenciar Contas",
    icon: faUsers,
    permission: ADM_PERMISSIONS.ACCOUNTS_MANAGEMENT,
  },
  {
    pathname: "/dashboard/transacoes",
    name: "Transações",
    icon: faDesktop,
    permission: ADM_PERMISSIONS.TRANSACTIONS,
  },
  {
    pathname: "/dashboard/recebiveis",
    name: "Recebíveis",
    icon: faMoneyBill,
    permission: ADM_PERMISSIONS.TRANSACTIONS,
  },
  {
    pathname: "/dashboard/representantes",
    name: "Representantes",
    icon: faUserFriends,
    permission: ADM_PERMISSIONS.AGENT_MANAGEMENT,
  },
  {
    pathname: "/dashboard/lista-de-administradores",
    name: "Administradores",
    icon: faUserShield,
    permission: ADM_PERMISSIONS.ADMIN_MANAGEMENT,
  },
  {
    pathname: "/dashboard/plano-vendas",
    name: "Planos de Venda",
    icon: faBook,
    permission: ADM_PERMISSIONS.SALES_PLAN_MANAGEMENT,
  },
  {
    pathname: "/dashboard/logs",
    name: "Logs",
    icon: faList,
    permission: ADM_PERMISSIONS.LOGS,
  },
  {
    pathname: "/dashboard/menu-opcoes",
    name: "Opções",
    icon: faCogs,
    permission: ADM_PERMISSIONS.MANAGEMENT,
  },
];

const localStorageKey = "lastPathName";

const ListItems = () => {
  const theme = useTheme();
  const history = useHistory();
  const userPermissao = useSelector((state) => state.userPermissao.permissao);
  const permissions = useMemo(
    () => userPermissao.map((permissao) => permissao.tipo),
    [userPermissao]
  );
  const isManager = useMemo(
    () => permissions.includes(ADM_PERMISSIONS.MANAGEMENT),
    [permissions]
  );
  const isAdm = useMemo(
    () =>
      permissions.includes(ADM_PERMISSIONS.ADMIN) ||
      permissions.includes(ADM_PERMISSIONS.INTTEGRAR),
    [permissions]
  );
  const isAgent = useMemo(
    () => permissions.includes(ADM_PERMISSIONS.AGENT),
    [permissions]
  );

  const agentItems = useMemo(() => {
    return defaultAgentItems.filter((defaultAgentItem) =>
      permissions.includes(defaultAgentItem.permission)
    );
  }, [permissions]);

  const userItems = useMemo(() => {
    const POSITION_BEFORE_OPTIONS = -2;
    const specificItems = specificUserItems.filter((specificUserItem) =>
      permissions.includes(specificUserItem.permission)
    );
    const items = [...defaultUserItems];
    items.splice(POSITION_BEFORE_OPTIONS, 0, ...specificItems);

    return items;
  }, [permissions]);

  const admItems = useMemo(() => {
    return defaultAdmItems.filter(
      (defaultAdmItem) =>
        permissions.includes(defaultAdmItem.permission) || isAdm
    );
  }, [permissions, isAdm]);

  const items = useMemo(() => {
    if (isManager || isAdm) return admItems;
    if (isAgent) return agentItems;
    return userItems;
  }, [admItems, agentItems, userItems, isManager, isAdm, isAgent]);

  const getSideBarItemBackgroundColor = (isSelected = false) =>
    isSelected
      ? theme.palette.menu.backgroundItemSelected
      : theme.palette.menu.backgroundItem;

  const getSideBarItemColor = (isSelected = false) =>
    isSelected ? theme.palette.menu.iconSelected : theme.palette.menu.icon;

  const [itemSelected, setItemSelected] = useState(() =>
    localStorage.getItem(localStorageKey)
  );

  const currentPathName = useMemo(
    () => history.location.pathname,
    [history.location]
  );

  useEffect(() => {
    const isValidPathName = items.some((item) =>
      item.pathname.includes(currentPathName)
    );
    if (isValidPathName) {
      localStorage.setItem(localStorageKey, currentPathName);
      setItemSelected(currentPathName);
    }
  }, [items, currentPathName]);

  return (
    <List component="nav">
      <>
        {items.map((item) => {
          const isSelected = itemSelected === item.pathname;

          return (
            <>
              {isAgent && item.name === "Home" && (
                <>
                  <Divider />
                  <Typography
                    align="center"
                    style={{
                      marginTop: "10px",
                      color: theme.palette.menu.text,
                    }}
                  >
                    ADQUIRÊNCIA
                  </Typography>
                </>
              )}
              <ListItem
                component={Link}
                to={item.pathname}
                button
                selected={isSelected}
                key={item.pathname}
              >
                <ListItemIcon>
                  <FontAwesomeIcon
                    style={{
                      padding: "6px",
                      borderRadius: "16px",
                      width: "40px",
                      height: "40px",
                      backgroundColor:
                        getSideBarItemBackgroundColor(isSelected),
                      color: getSideBarItemColor(isSelected),
                    }}
                    icon={item.icon}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ color: theme.palette.menu.text }}
                  primary={item.name}
                />
              </ListItem>
              {isAgent && item.name === "Outros serviços" && (
                <>
                  <Divider />
                  <Typography
                    align="center"
                    style={{
                      marginTop: "10px",
                      color: theme.palette.menu.text,
                    }}
                  >
                    REPRESENTANTE
                  </Typography>
                </>
              )}
            </>
          );
        })}
      </>
    </List>
  );
};

export default ListItems;
