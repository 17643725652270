import {
  Box,
  Button,
  LinearProgress,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import { useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { useIndexPagadoresRemessaQuery } from "services/api";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import useUserPermissions from "hooks/useUserPermissions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "5px",
  },
}));

const columns = [
  { headerText: "Nome", key: "nome" },
  { headerText: "Documento", key: "documento" },
  { headerText: "E-mail", key: "email" },
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (created_at) => {
      return <DateTimeColumn dateTime={created_at} />;
    },
  },
];

const PagadorRemessaIndex = () => {
  const { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { isManagementAdmin } = useUserPermissions();

  const {
    data: payers,
    isLoading,
    isError,
    isUninitialized,
  } = useIndexPagadoresRemessaQuery(
    {
      page,
      arquivo_remessa_id: id,
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const handleClickRow = (row) => {
    if (isManagementAdmin) return;
    const path = generatePath("/dashboard/pagadores/:id/editar", {
      id: row.id,
    });
    history.push(path);
  };

  return (
    <Box className={classes.root}>
      <CustomBreadcrumbs
        path1="Arquivo de remessa"
        to1={`/dashboard/arquivo-remessa/${id}/itens`}
        path2="Pagadores da remessa"
        to2="#"
      />

      <Box className={classes.header}>
        <Typography
          variant="h4"
          style={{ color: theme.palette.background.default }}
        >
          PAGADORES DA REMESSA
        </Typography>
        <Button
          variant="outlined"
          style={{ marginTop: "8px", marginBottom: "12px" }}
          onClick={() => history.push(`/dashboard/arquivo-remessa/${id}/itens`)}
        >
          ITENS DA REMESSA
        </Button>
      </Box>
      <>
        {!isLoading && !isError && !isUninitialized ? (
          <>
            <CustomTable
              columns={columns}
              data={payers.data}
              handleClickRow={handleClickRow}
            />
            <Box alignSelf="flex-end" marginTop="8px">
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={payers.last_page}
                onChange={handleChangePage}
                page={page}
              />
            </Box>
          </>
        ) : (
          <LinearProgress />
        )}
      </>
    </Box>
  );
};

export default PagadorRemessaIndex;
