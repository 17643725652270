import React, { useMemo } from "react";
import {
  Box,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { ADM_PERMISSIONS } from "constants/permissions";
import BoletoFeesBox from "./BoletoFeesBox";
import BoletoFeesSum from "./BoletoFeesSum";

const BoletoFeeDetails = ({ boletoFees, disableAll }) => {
  const userPermissions = useSelector((state) => state.userPermissao.permissao);
  const permissionsName = userPermissions.map((permission) => permission.tipo);
  const canSeeAndEditAllFees = useMemo(
    () =>
      permissionsName.includes(ADM_PERMISSIONS.INTTEGRAR)
      || permissionsName.includes(ADM_PERMISSIONS.APP_SALES_PLAN_FEE_MANAGEMENT),
    [permissionsName]
  );
  const isSalesPlanManager = useMemo(
    () => permissionsName.includes(ADM_PERMISSIONS.SALES_PLAN_MANAGEMENT),
    [permissionsName]
  );
  const isAdm = useMemo(
    () => permissionsName.includes(ADM_PERMISSIONS.ADMIN),
    [permissionsName]
  );

  return (
    <Box>
      {canSeeAndEditAllFees &&
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
          width="100%"
        >
          {boletoFees.partner &&
            <BoletoFeesBox
              boletoFee={boletoFees.partner[0]}
              label="PARCEIRO"
              editable={false}
            /> // sera removido futuramente
          }
          {boletoFees.zoop &&
            <BoletoFeesBox
              boletoFee={boletoFees.zoop[0]}
              label="SISTEMA"
              editable={false}
            />
          }
          {boletoFees.aplication &&
            <BoletoFeesBox
              boletoFee={boletoFees.aplication[0]}
              label="APLICAÇÃO"
              editable={true && !disableAll}
            />
          }
          {boletoFees.agent &&
            <BoletoFeesBox
              boletoFee={boletoFees.agent[0]}
              label="REPRESENTANTE"
              editable={true && !disableAll}
            />
          }
        </Box>
      }
      {!canSeeAndEditAllFees && (isAdm || isSalesPlanManager) &&
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
          width="100%"
        >
          {boletoFees.aplication &&
            <BoletoFeesSum
              zoopFees={boletoFees.zoop}
              partnerFees={boletoFees.partner}
              aplicationFees={boletoFees.aplication}
            />
          }
          {boletoFees.agent &&
            <BoletoFeesBox
              boletoFee={boletoFees.agent[0]}
              label="REPRESENTANTE"
              editable={true && !disableAll}
            />
          }
        </Box>
      }
      {!canSeeAndEditAllFees && !isAdm && !isSalesPlanManager &&
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
          width="100%"
        >
          {boletoFees.aplication &&
            <BoletoFeesSum
              zoopFees={boletoFees.zoop}
              partnerFees={boletoFees.partner}
              aplicationFees={boletoFees.aplication}
              agentFees={boletoFees.agent}
            />
          }
        </Box>
      }
    </Box>
  );
};

export default BoletoFeeDetails;
