import React from "react";
import { Box, Typography } from "@material-ui/core";
import { formatMoney } from "utils/money";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const columnsSplit = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (data) => {
      const p = data.split(/\D/g);
      const dataFormatada = [p[2], p[1], p[0]].join("/");
      return (
        <Box display="flex" justifyContent="center">
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
          <Typography style={{ marginLeft: "6px" }}>{dataFormatada}</Typography>
        </Box>
      );
    },
  },
  {
    headerText: "ID Conta repartida",
    key: "conta",
    CustomValue: (conta) => {
      return (
        <Box>
          <Typography>{conta?.razao_social ?? conta?.nome}</Typography>
          <Typography>{conta?.cnpj ?? conta?.documento}</Typography>
        </Box>
      );
    },
  },
  {
    headerText: "Valor repartido",
    key: "split.receivable_amount",
    CustomValue: (amount) => <Typography>{formatMoney(amount)}</Typography>,
  },
];

export default columnsSplit;
