import {
	Box,
	Button,
	LinearProgress,
	Typography,
} from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import CustomTable from '../CustomTable/CustomTable';
import SearchBar from '../SearchBar/SearchBar';

const SelectPayer = ({
  payerList,
  columns,
  filters,
  setFilters,
}) => {
	const history = useHistory();

	return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <SearchBar
        style={{ width: '80%', margin: '12px 0' }}
        placeholder="Pesquisar por nome, documento..."
        value={filters.like}
        onChange={(e) =>
          setFilters({
            ...filters,
            like: e.target.value,
          })
        }
      />
      {payerList.data && payerList.per_page ? (
        <Box display="flex" flexDirection="column" width="80%">
          <CustomTable columns={columns} data={payerList.data} compacta="true" />
          <Typography align="center" variant="overline">
            Caso seu pagador ainda não tenha cadastro
          </Typography>
          <Box alignSelf="center">
            <Button
              variant="outlined"
              onClick={() => history.push('/dashboard/novo-pagadores')}
              style={{ borderRadius: '27px' }}
            >
              clique aqui
            </Button>
          </Box>
        </Box>
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
};

export default SelectPayer;
