import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, LinearProgress, Paper, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import { useState } from "react";
import { useGetLinkSplitConfigsQuery } from "services/api";

const columns = [
  {
    headerText: "Conta Destino",
    key: "receiver",
    CustomValue: (conta) => {
      return (
        <Box>
          <Typography>{conta?.razao_social ?? conta?.nome}</Typography>
          <Typography>{conta?.cnpj ?? conta?.documento}</Typography>
        </Box>
      );
    },
  },
  {
    headerText: "Porcentagem",
    key: "porcentagem",
    CustomValue: (porcentagem) => {
      return <Typography>{porcentagem.replace(".", ",")} %</Typography>;
    },
  },
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (data) => {
      const p = data.split(/\D/g);
      const dataFormatada = [p[2], p[1], p[0]].join("/");
      return (
        <Box display="flex" justifyContent="center">
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
          <Typography style={{ marginLeft: "6px" }}>{dataFormatada}</Typography>
        </Box>
      );
    },
  },
];

const LinkSplitConfigIndex = ({ data }) => {
  const [page, setPage] = useState(1);

  const {
    data: split,
    isLoading,
    isError,
    isUninitialized,
  } = useGetLinkSplitConfigsQuery(
    {
      page,
      link_pagamento_id: data.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !data.id,
    }
  );

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  return (
    <Paper
      style={{
        padding: "24px",
        margin: "12px 0",
        borderRadius: "27px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box marginBottom="12px">
        <Typography variant="h4">Splits configurados</Typography>
      </Box>
      {!isLoading && !isError && !isUninitialized ? (
        <>
          <CustomTable columns={columns} data={split.data} />
          <Box alignSelf="flex-end" marginTop="8px">
            <Pagination
              variant="outlined"
              color="secondary"
              size="large"
              count={split.last_page}
              onChange={handleChangePage}
              page={page}
            />
          </Box>
        </>
      ) : (
        <LinearProgress />
      )}
    </Paper>
  );
};

export default LinkSplitConfigIndex;
