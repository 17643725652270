import {
  Box,
  Button,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import AccountStatusColumn from "components/reusable/TableColumns/AccountStatusColumn";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { delConta, loadContasFilhas } from "../../../actions/actions";
import useDebounce from "../../../hooks/useDebounce";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "../../reusable/CustomTable/CustomTable";
import GradientButton from "../../reusable/GradientButton/GradientButton";
import SearchBar from "../../reusable/SearchBar/SearchBar";

const columns = [
  {
    headerText: "Conta",
    key: "custom_conta",
    FullCustom: (row) => {
      return (
        <>
          <Typography>{row.razao_social ?? row.nome}</Typography>
        </>
      );
    },
  },
  {
    headerText: "Documento",
    key: "custom_documento",
    FullCustom: (row) => {
      return <Typography>{row.cnpj ?? row.documento}</Typography>;
    },
  },
  { headerText: "E-mail", key: "email" },
  { headerText: "Contato", key: "celular" },
  {
    headerText: "Status",
    key: "status",
    CustomValue: (status) => {
      return <AccountStatusColumn status={status} />;
    },
  },
  { headerText: "Ações", key: "menu" },
];

const DigitalAccounts = () => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [filters, setFilters] = useState({
    like: "",
  });
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const digitalAccounts = useSelector((state) => state.contas);
  const { id: conta_pai_id } = useSelector((state) => state.userData);
  const { state } = useLocation();

  const debouncedLike = useDebounce(filters.like, 800);

  const setContaPaiId = useMemo(
    () => (state ? state.conta_id : conta_pai_id),
    [state, conta_pai_id]
  );

  const setColumns = useMemo(() => {
    const columnsWithoutActions = [...columns];
    columnsWithoutActions.pop();

    return state ? columnsWithoutActions : columns;
  }, [state]);

  useEffect(() => {
    dispatch(loadContasFilhas(page, debouncedLike, setContaPaiId));
  }, [dispatch, page, debouncedLike, setContaPaiId]);

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const Editar = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleExcluir = async () => {
      dispatch(delConta(row.id));
      setAnchorEl(null);
    };

    const handleEditar = (row) => {
      const path = generatePath("/dashboard/contas-digitais/:id/editar", {
        id: row.id,
      });
      history.push(path);
    };

    return (
      <Box>
        <Button
          style={{ height: "15px", width: "10px" }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          ...
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleEditar(row)}>Editar</MenuItem>
          <MenuItem onClick={() => handleExcluir(row)}>Excluir</MenuItem>
        </Menu>
      </Box>
    );
  };
  return (
    <Box display="flex" flexDirection="column">
      <CustomBreadcrumbs path1="Contas Digitais" />
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Typography style={{ marginTop: "8px" }} variant="h4">
          Contas Digitais
        </Typography>

        {state ? null : (
          <Link to="criar-conta-digital">
            <GradientButton buttonText="+ Nova Conta Digital" />
          </Link>
        )}
      </Box>
      <Box marginTop="16px" marginBottom="16px">
        <SearchBar
          fullWidth
          placeholder="Pesquisar por nome, documento..."
          value={filters.like}
          onChange={(e) =>
            setFilters({
              ...filters,
              like: e.target.value,
            })
          }
        />
      </Box>

      {digitalAccounts.data && digitalAccounts.per_page ? (
        <CustomTable
          columns={setColumns}
          data={digitalAccounts.data}
          Editar={Editar}
        />
      ) : (
        <LinearProgress />
      )}
      <Box alignSelf="flex-end" marginTop="8px">
        {
          <Pagination
            variant="outlined"
            color="secondary"
            size="large"
            count={digitalAccounts.last_page}
            onChange={handleChangePage}
            page={page}
          />
        }
      </Box>
    </Box>
  );
};

export default DigitalAccounts;
