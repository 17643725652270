import {
  Box,
  Button,
  LinearProgress,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { generatePath, useHistory, useParams } from "react-router";
import { Pagination } from "@material-ui/lab";
import { useIndexSalesPlanQuery } from "services/api";
import CustomTable from "../../reusable/CustomTable/CustomTable";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useState } from "react";
import useDebounce from "hooks/useDebounce";
import SearchBar from "components/reusable/SearchBar/SearchBar";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ADM_PERMISSIONS } from "constants/permissions";
import StoreAgentSalesPlanModal from "./Dialogs/StoreAgentSalesPlanModal";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import SetDefaultAppAccount from "./Dialogs/SetDefaultAppAccount";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

const columns = [
  { headerText: "Nome", key: "name" },
  { headerText: "ECs ativos", key: "ec_count" },
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (data) => {
      const date = new Date(data);
      const option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      const [dia] = date.toLocaleDateString("pt-br", option).split(" ");
      return <Typography align="center">{dia}</Typography>;
    },
  },
  {
    headerText: "Status",
    key: "fees_updated",
    CustomValue: (data) => {
      return data ? (
        <Typography align="center" style={{ color: "green" }}>
          OK
        </Typography>
      ) : (
        <Typography align="center" style={{ color: "red" }}>
          DESATUALIZADO
        </Typography>
      );
    },
  },
  {
    headerText: "",
    key: "is_default",
    CustomValue: (data) => {
      return data ? (
        <Typography align="center" style={{ color: "green" }}>
          PADRÃO
        </Typography>
      ) : (
        <Typography align="center">-</Typography>
      );
    },
  },
];

const SalesPlanList = () => {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const history = useHistory();
  const [openStoreAgentSalesPlanDialog, setOpenStoreAgentSalesPlanDialog] =
    useState(false);
  const [openDefaultAppAccountDialog, setOpenDefaultAppAccountDialog] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userPermissions = useSelector((state) => state.userPermissao.permissao);
  const permissionsName = userPermissions.map((permission) => permission.tipo);
  const isInttegrarAdm = useMemo(
    () => permissionsName.includes(ADM_PERMISSIONS.INTTEGRAR),
    [permissionsName]
  );
  const isAgent = useMemo(
    () =>
      permissionsName.includes(ADM_PERMISSIONS.AGENT) &&
      permissionsName.includes(ADM_PERMISSIONS.SALES_PLAN_MANAGEMENT),
    [permissionsName]
  );
  const [filters, setFilters] = useState({
    planName: "",
  });

  const debouncedPlanName = useDebounce(filters.planName, 800);

  const {
    data: planList,
    isLoading: isLoadingPlanList,
    isError,
    isUninitialized,
    refetch,
  } = useIndexSalesPlanQuery(
    {
      page,
      agent_id: id,
      plan_name: debouncedPlanName,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleClickRow = (row) => {
    const path = generatePath("/dashboard/plano-vendas/:id/detalhes", {
      id: row.id,
    });
    history.push(path);
  };

  const handleNewSalesPlan = () => {
    const path = generatePath("/dashboard/plano-vendas-zoop");
    history.push(path);
  };

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const handleOpenStoreAgentSalesPlanDialog = () => {
    setOpenStoreAgentSalesPlanDialog(true);
  };

  return (
    <Box className={classes.root}>
      <LoadingScreen isLoading={isLoading} />

      <StoreAgentSalesPlanModal
        openDialog={openStoreAgentSalesPlanDialog}
        setOpenDialog={setOpenStoreAgentSalesPlanDialog}
        refetchSalesPlanList={refetch}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />

      {isInttegrarAdm && !id && (
        <SetDefaultAppAccount
          openDialog={openDefaultAppAccountDialog}
          setOpenDialog={setOpenDefaultAppAccountDialog}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}

      {id ? (
        <CustomBreadcrumbs
          path1="Representantes"
          to1="/dashboard/representantes"
          path2="Planos de Vendas"
          to2="#"
        />
      ) : (
        <CustomBreadcrumbs path1="Planos de Vendas" to1="#" />
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            style={{ color: theme.palette.background.default }}
          >
            PLANOS DE VENDAS
          </Typography>
        </Box>
        {isInttegrarAdm && !id ? (
          <Box style={{ marginBottom: "8px" }}>
            <Button
              variant="outlined"
              style={{ marginBottom: "12px", marginRight: "12px" }}
              onClick={setOpenDefaultAppAccountDialog}
            >
              Definir Conta da Aplicação
            </Button>
            <Button
              variant="outlined"
              style={{ marginBottom: "12px" }}
              onClick={handleNewSalesPlan}
            >
              Buscar Novo Plano de Vendas
            </Button>
          </Box>
        ) : null}
        {isAgent ? (
          <Button
            variant="outlined"
            style={{ marginTop: "8px", marginBottom: "12px" }}
            onClick={handleOpenStoreAgentSalesPlanDialog}
          >
            Novo Plano de Vendas
          </Button>
        ) : null}
      </Box>
      <Box marginBottom="16px">
        <SearchBar
          fullWidth
          placeholder="Pesquisar por nome do plano de vendas"
          value={filters.planName}
          onChange={(e) =>
            setFilters({
              ...filters,
              planName: e.target.value,
            })
          }
        />
      </Box>
      <>
        {!isLoadingPlanList && !isError && !isUninitialized ? (
          <>
            <CustomTable
              columns={columns}
              data={planList.data}
              handleClickRow={handleClickRow}
            />
            <Box alignSelf="flex-end" marginTop="8px">
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={planList.last_page}
                onChange={handleChangePage}
                page={page}
              />
            </Box>
          </>
        ) : (
          <LinearProgress />
        )}
      </>
    </Box>
  );
};

export default SalesPlanList;
