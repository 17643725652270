export const getNewTotpMutation = () => ({
  url: `/totp/get-new`,
  method: "post",
});

export const activeTotpMutation = ({ totpCode }) => ({
  url: `/totp/activate`,
  method: "post",
  data: {
    totp_code: totpCode
  },
});

export const sendTotpMutation = () => ({
  url: `/totp/send-totp`,
  method: "post",
});

export const authTotpMutation = ({ totpCode }) => ({
  url: `/totp/verify`,
  method: "post",
  data: {
    totp_code: totpCode
  },
});

export const removeTotpMutation = ({ totpCode }) => ({
  url: `/totp/remove`,
  method: "delete",
  data: {
    totp_code: totpCode
  },
});
