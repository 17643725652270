import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	Button,
	LinearProgress,
	Menu,
	MenuItem,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { delPlano, loadPlanosFilters } from '../../../../actions/actions';
import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../../reusable/CustomTable/CustomTable';
import GradientButton from '../../../reusable/GradientButton/GradientButton';
import SearchBar from '../../../reusable/SearchBar/SearchBar';

const columns = [
	{
		headerText: 'Criado em',
		key: 'created_at',
		CustomValue: (data) => {
			const p = data.split(/\D/g);
			const dataFormatada = [p[2], p[1], p[0]].join('/');
			return (
				<Box display="flex" justifyContent="center">
					<FontAwesomeIcon icon={faCalendarAlt} size="lg" />
					<Typography style={{ marginLeft: '6px' }}>{dataFormatada}</Typography>
				</Box>
			);
		},
	},
	{ headerText: 'Plano', key: 'nome' },
	{ headerText: 'Frequência', key: 'frequencia' },
	{ headerText: 'Descrição', key: 'descricao' },
	{
		headerText: 'Valor',
		key: 'valor',
		CustomValue: (valor) => {
			return (
				<Typography variant="" style={{ fontSize: 17, fontWeight: 600, color: 'green' }}>
					R$ {valor}
				</Typography>
			);
		},
	},
	{ headerText: 'Ações', key: 'menu' },
];

const SubscriptionPlans = () => {
	const history = useHistory();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const userData = useSelector((state) => state.userData);
  const { id } = useParams();
	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);

	const planosList = useSelector((state) => state.planosList);

	useEffect(() => {
		dispatch(loadPlanosFilters(page, filters.like, filters.order, filters.mostrar, id ?? userData.id));
	}, [dispatch, page, filters, planosList.data.total, id, userData.id]);

	const handleNewPlano = () => {
		history.push('/dashboard/novo-plano');
	};

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	const Editar = ({ row }) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleExcluir = async () => {
			await dispatch(delPlano(row.id));
			setAnchorEl(null);
		};
		const handleEditar = (row) => {
			const path = generatePath('/dashboard/novo-plano/:id/editar', {
				id: row.id,
			});
			history.push(path);
		};

		return (
      <Box>
        {id ? null :
          <>
            <Button
              style={{ height: '15px', width: '10px' }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              ...
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleEditar(row)}>Editar</MenuItem>
              <MenuItem onClick={() => handleExcluir(row)}>Excluir</MenuItem>
            </Menu>
          </>
        }
      </Box>
		);
	};

	return (
		<Box display="flex" flexDirection="column">
      {id ?
			  <CustomBreadcrumbs
          path1="Gerenciar Listas" to1={`/dashboard/contas/${id}/listas`}
          path2="Cobrança Recorrente" to2={`/dashboard/contas/${id}/assinaturas`}
          path3="Planos" to3="#" />
      : <CustomBreadcrumbs path1="Planos" to1="#" />
      }
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? 'column' : null}>
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Planos
				</Typography>
        {id ? null :
          <Box>
            <GradientButton buttonText="+ Novo Plano" onClick={handleNewPlano} />
          </Box>
        }
			</Box>
			<Box marginTop="16px" marginBottom="16px">
				<SearchBar
					fullWidth
					placeholder="Pesquisar por nome, descrição..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
			</Box>

			{planosList.data && planosList.per_page ? (
				<CustomTable columns={columns} data={planosList.data} Editar={Editar} />
			) : (
				<LinearProgress />
			)}
			<Box alignSelf="flex-end" marginTop="8px">
				<Pagination
					variant="outlined"
					color="secondary"
					size="large"
					count={planosList.last_page}
					onChange={handleChangePage}
					page={page}
				/>
			</Box>
		</Box>
	);
};

export default SubscriptionPlans;
