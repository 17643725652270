import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	MenuItem,
	Select,
	Step,
	StepLabel,
	Stepper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Cards from "react-credit-cards";
import {
	postCartaoAssinaturaAction,
  updateAssinatura,
} from "../../../../actions/actions";
import GradientButton from "../../../reusable/GradientButton/GradientButton";
import LoadingScreen from "../../../reusable/LoadingScreen/LoadingScreen";
import SubscriptionCard from "../../../reusable/SubscriptionCard/SubscriptionCard";
import SimpleButton from "components/reusable/Buttons/SimpleButton";

const useStyles = makeStyles((theme) => ({
	dialog: {
		padding: "20px",
	},
	dialogHeader: {
		background: theme.gradient.main,
		color: "white",
	},
}));

const ALL_STEPS = {
	PAGAMENTO: "Pagamento",
	ESCOLHER_PLANO: "Escolha o plano",
};

const EditAccountSubscriptionDialog = ({
  signature = {},
  open,
  onClose = () => {},
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
  const planosList = useSelector((state) => state.planosList);
	const steps = ["PAGAMENTO", "ESCOLHER_PLANO"];
	const [activeStep, setActiveStep] = useState(0);
  const [errosCartao, setErrosCartao] = useState({});
  const [updateCard, setUpdateCard] = useState(false);
	const [loading, setLoading] = useState(false);

	const [updateSignature, setUpdateSignature] = useState({
    plano_id: "",
    card_id: "",
  });

  const [newCard, setNewCard] = useState({
    nome: "",
    numero: "",
    cvv: "",
    mes: "",
    ano: "",
	});

  useEffect(() => {
    setUpdateSignature({
      plano_id: signature.plano_id,
      card_id: signature.card_id,
    });
  }, [signature]);

	const handleNext = async () => {
		setLoading(true);

		if (activeStep === 0 && updateCard) {
      const response = await dispatch(
        postCartaoAssinaturaAction(signature.pagador_id, newCard)
      );
      if(response?.data?.card_id) {
        toast.success("Cartão vinculado com sucesso!");
        setUpdateSignature({ ...updateSignature, card_id: response.data.card_id });
        setActiveStep(activeStep + 1);
      } else {
        setErrosCartao(response);
      }
		} else {
      setActiveStep(activeStep + 1);
    }

		if (activeStep === 1) {
			const response = await dispatch(
        updateAssinatura(signature.id, updateSignature.plano_id, updateSignature.card_id)
      );

			if (response) {
				toast.error("Erro ao atualizar assinatura!");
			} else {
				toast.success("Assinatura atualizada com sucesso!");
				handleClose();
			}
		}

    setLoading(false);
	};

  const handleClose = () => {
    onClose();
    setUpdateCard(false);
    setActiveStep(0);
    setNewCard({
      nome: "",
      numero: "",
      cvv: "",
      mes: "",
      ano: "",
    });
    setErrosCartao({});
  };

	const STEPS_COMPONENTS = {
		PAGAMENTO: (
      updateCard ? (
        <SubscriptionCard
          cartao={newCard}
          setCartao={setNewCard}
          errosCartao={errosCartao}
        />
      ) : (
        <Box width="100%" display="flex" flexDirection="column">
          <Typography align="center">Dados do cartão atual</Typography>
          <Box marginBottom={2} marginTop={2}>
            {signature?.card?.id ? (
              <Cards
                name={ signature.card?.holder_name }
                number={ signature.card?.first4_digits + "********" + signature.card?.last4_digits }
                expiry={signature.card?.expiration_month + "/" + signature.card?.expiration_year}
              />
              ) : (
                <Typography variant="h6" align="center" style={{color: "red"}}>SEM DADOS DE CARTÃO</Typography>
            )}
          </Box>
          <SimpleButton text="Editar Dados do Cartão" onClick={() => setUpdateCard(true)} />
        </Box>
      )
    ),
		ESCOLHER_PLANO: (
			<Box>
				<Select
					fullWidth
					variant="standard"
          defaultValue={signature.plano_id}
					onChange={(e) => setUpdateSignature({ ...updateSignature, plano_id: e.target.value })}
				>
					{planosList.data.map((plano) => (
						<MenuItem value={plano.id} key={plano.id}>
							{plano.nome}
						</MenuItem>
					))}
				</Select>
			</Box>
		),
	};

	return (
    <Dialog className={classes.dialog} onClose={handleClose} open={open}>
			<LoadingScreen isLoading={loading} />

		  <Box width="500px">
				<DialogTitle className={classes.dialogHeader}>
					<Typography align="center" variant="h6">
						Editar Assinatura
					</Typography>
				</DialogTitle>

        <DialogContent>
          <Box display="flex" flexDirection="column">
            <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{ALL_STEPS[label]}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {STEPS_COMPONENTS[steps[activeStep]]}
          </Box>
        </DialogContent>

        <DialogActions>
          <SimpleButton text="Fechar" onClick={handleClose} />
          <GradientButton buttonText={activeStep === 1 ? "Atualizar" : "Próximo"} onClick={handleNext} />
        </DialogActions>
		  </Box>
    </Dialog>
	);
};

export default EditAccountSubscriptionDialog;
