import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import CurrencyInput from "react-currency-input";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import {
  useCreateSplitMutation,
  useIndexAllAccountsQuery,
  useShowAccountByDocumentQuery,
  useUpdateSplitMutation,
} from "services/api";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import useDebounce from "hooks/useDebounce";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { ADM_PERMISSIONS } from "constants/permissions";

const useStyles = makeStyles((theme) => ({
  UserInfosContainer: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    color: theme.palette.primary.main,
  },
  userContentsContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.secondary.light,
    padding: "8px",
    borderRadius: "27px",
  },
  userContentItem: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    padding: "12px",
    margin: "8px",
    borderRadius: "27px",
  },
  SplitModal: {
    padding: "20px",
  },
  saqueHeader: {
    background: theme.gradient.main,
    color: "white",
  },
  currency: {
    font: "inherit",
    color: "currentColor",
    width: "100%",
    border: "0px",
    borderBottom: "1px solid gray",
    height: "1.1876em",
    margin: 0,
    display: "block",
    padding: "6px 0 7px",
    minWidth: 0,
    background: "none",
    boxSizing: "content-box",
    animationName: "mui-auto-fill-cancel",
    letterSpacing: "inherit",
    animationDuration: "10ms",
    appearance: "textfield",
    textAlign: "start",
    paddingLeft: "5px",
  },
}));

const defaultInfo = {
  conta_id: "",
  porcentagem: 0,
  valor: 0,
  usar_valor_liquido: false,
};

const SplitModal = ({ open, onClose, onSplit, id, info, splitId }) => {
  const [errosSplit, setErrosSplit] = useState({});
  const [tipoSplit, setTipoSplit] = useState("porcentagem");
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [updateSplit] = useUpdateSplitMutation();
  const [createSplit] = useCreateSplitMutation();
  const userPermissions = useSelector((state) => state.userPermissao.permissao);
  const permissionsName = userPermissions.map((permission) => permission.tipo);
  const canViewAllAccounts = useMemo(
    () =>
      (permissionsName.includes(ADM_PERMISSIONS.MANAGEMENT) &&
        permissionsName.includes(ADM_PERMISSIONS.ACCOUNTS_MANAGEMENT)) ||
      permissionsName.includes(ADM_PERMISSIONS.ADMIN) ||
      permissionsName.includes(ADM_PERMISSIONS.INTTEGRAR),
    [permissionsName]
  );
  const [filterByPj, setFilterByPj] = useState(false);
  const canFilterByAutoComplete = useMemo(
    () => canViewAllAccounts,
    [canViewAllAccounts]
  );

  const [filters, setFilters] = useState({
    like: "",
  });
  const debouncedLike = useDebounce(filters.like, 800);

  const { data: indexAllAccounts } = useIndexAllAccountsQuery(
    {
      like: debouncedLike,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !canViewAllAccounts,
    }
  );

  const { data: account } = useShowAccountByDocumentQuery(
    {
      documento: debouncedLike,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: canViewAllAccounts,
    }
  );

  const accounts = useMemo(() => {
    if (canViewAllAccounts) return indexAllAccounts?.data;
    return account ? [account] : [];
  }, [canViewAllAccounts, indexAllAccounts, account]);

  const [transacaoInfos, setTransacaoInfos] = useState({
    ...defaultInfo,
  });

  useEffect(() => {
    setTransacaoInfos((values) => ({
      ...values,
      cobranca_boleto_ou_cartao_id: id,
    }));
  }, [id]);

  useEffect(() => {
    if (info) {
      setTransacaoInfos((values) => ({
        ...values,
        ...info,
      }));
      setFilterByPj(info.split.conta.cnpj ? true : false);
      setFilters({
        like: info.split.conta.cnpj ?? info.split.conta.documento,
      });

      setTipoSplit(info.porcentagem > 0 ? "porcentagem" : "valor");
    } else {
      setTransacaoInfos((values) => ({
        ...values,
        ...defaultInfo,
      }));
    }
  }, [info]);

  const handleClose = () => {
    onClose();
    setFilters({
      like: "",
    });
    setErrosSplit({});
  };

  const getOptionLabel = (conta) => {
    return conta.cnpj
      ? `${conta.razao_social}, documento: ${conta.cnpj}`
      : `${conta.nome}, documento: ${conta.documento}`;
  };

  const handleOnInputChange = (value, reason, filter) => {
    if (reason !== "reset" && filter) {
      setFilters({ ...filters, like: value });
    }
    if (reason !== "reset") {
      setTransacaoInfos({
        ...transacaoInfos,
        conta_id: null,
      });
    }
  };

  const handleSplit = async () => {
    setLoading(true);
    try {
      const split = {
        ...transacaoInfos,
        responsavel_pelo_prejuizo: true,
      };

      if (splitId) {
        await updateSplit({
          split,
          splitId: splitId,
        }).unwrap();
      } else {
        await createSplit({ split }).unwrap();
      }

      toast.success("Valor repartido com sucesso!");
      handleClose();
      onSplit();
    } catch (e) {
      if (e.data.errors) setErrosSplit(e.data.errors);
      toast.error(
        splitId
          ? "Não foi possível alterar a repartição de valor!"
          : "Não foi possível efetuar a repartição de valores!"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} className={classes.SplitModal}>
      <Box>
        <LoadingScreen isLoading={loading} />
        <DialogTitle className={classes.saqueHeader}>
          <Typography align="center" variant="h6">
            Repartir valor
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <Box textAlign="center" color="red">
              Todos os splits criados utilizando percentual, serão calculados
              proporcionalmente a partir do valor bruto da transação. Exemplo:
            </Box>
            <Box textAlign="center" color="red">
              Split : 50%
              <Box>R$ 100 * 50% = R$ 50,00</Box>
            </Box>
            <Box margin="6px 0">
              <FormControl fullWidth>
                <InputLabel>Tipo de repartição</InputLabel>
                <Select
                  fullWidth
                  value={tipoSplit}
                  onChange={(e) => {
                    setTipoSplit(e.target.value);
                    setTransacaoInfos({
                      ...transacaoInfos,
                      valor: 0,
                      porcentagem: 0,
                    });
                  }}
                >
                  <MenuItem value="valor">Valor</MenuItem>
                  <MenuItem value="porcentagem">Porcentagem</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {tipoSplit === "valor" ? (
              <Box margin="6px 0">
                <FormControl fullWidth error={errosSplit.valor}>
                  <Typography
                    style={{
                      alignSelf: "center",
                    }}
                  >
                    Valor a ser repartido
                  </Typography>
                  <CurrencyInput
                    className={classes.currency}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix="R$ "
                    value={transacaoInfos.valor}
                    onChangeEvent={(event, maskedvalue, floatvalue) =>
                      setTransacaoInfos({
                        ...transacaoInfos,
                        valor: floatvalue,
                      })
                    }
                    style={{
                      marginBottom: "6px",
                      width: "60%",
                      alignSelf: "center",
                    }}
                  />
                  <FormHelperText
                    style={{
                      marginBottom: "6px",
                      width: "60%",
                      alignSelf: "center",
                    }}
                  >
                    {errosSplit.valor ? errosSplit.valor.join(" ") : null}
                  </FormHelperText>
                </FormControl>
              </Box>
            ) : (
              <Box margin="6px 0">
                <FormControl fullWidth error={errosSplit.porcentagem}>
                  <Typography
                    style={{
                      alignSelf: "center",
                    }}
                  >
                    Porcentagem a ser repartida
                  </Typography>
                  <CurrencyInput
                    className={classes.currency}
                    decimalSeparator=","
                    thousandSeparator="."
                    suffix=" %"
                    value={transacaoInfos.porcentagem}
                    onChangeEvent={(event, maskedvalue, floatvalue) =>
                      setTransacaoInfos({
                        ...transacaoInfos,
                        porcentagem: floatvalue,
                      })
                    }
                    style={{
                      marginBottom: "6px",
                      width: "60%",
                      alignSelf: "center",
                    }}
                  />
                  <FormHelperText
                    style={{
                      marginBottom: "6px",
                      width: "60%",
                      alignSelf: "center",
                    }}
                  >
                    {errosSplit.porcentagem
                      ? errosSplit.porcentagem.join(" ")
                      : null}
                  </FormHelperText>
                </FormControl>
              </Box>
            )}
            {/* {tipoSplit === "porcentagem" && (
              <Box margin="6px 0">
                <InputLabel>Dividir Taxas</InputLabel>
                <Select
                  fullWidth
                  value={transacaoInfos.usar_valor_liquido}
                  onChange={(e) =>
                    setTransacaoInfos({
                      ...transacaoInfos,
                      usar_valor_liquido: e.target.value,
                    })
                  }
                >
                  <MenuItem value={true}>Sim</MenuItem>
                  <MenuItem value={false}>Não</MenuItem>
                </Select>
              </Box>
            )} */}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="20px"
          >
            <Typography variant="h6">Conta</Typography>
            {!canViewAllAccounts && (
              <>
                <Box display="flex" alignItems="center" alignSelf="start">
                  <Typography style={{ marginRight: "10px" }}>
                    Filtrar por:
                  </Typography>
                  <Button
                    disabled={info ? true : false}
                    variant={filterByPj ? "outlined" : "contained"}
                    style={{ marginRight: "10px" }}
                    onClick={() => setFilterByPj(false)}
                  >
                    CPF
                  </Button>
                  <Button
                    disabled={info ? true : false}
                    variant={filterByPj ? "contained" : "outlined"}
                    onClick={() => setFilterByPj(true)}
                  >
                    CNPJ
                  </Button>
                </Box>
                <InputMask
                  disabled={info ? true : false}
                  mask={filterByPj ? "99.999.999/9999-99" : "999.999.999-99"}
                  value={filters.like}
                  onChange={(e) =>
                    handleOnInputChange(e.target.value, "change", true)
                  }
                >
                  {() => (
                    <TextField
                      disabled={info ? true : false}
                      label="Documento"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  )}
                </InputMask>
              </>
            )}
            <FormControl fullWidth>
              <Autocomplete
                disabled={info ? true : false}
                fullWidth
                options={accounts}
                noOptionsText="Nenhuma conta encontrada!"
                getOptionLabel={(conta) => getOptionLabel(conta)}
                onInputChange={(_event, value, reason) =>
                  handleOnInputChange(value, reason, canFilterByAutoComplete)
                }
                onChange={(_event, option) => {
                  setTransacaoInfos({
                    ...transacaoInfos,
                    conta_id: option ? option.id : null,
                  });
                }}
                defaultValue={info?.split?.conta}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecionar Conta"
                    margin="normal"
                    variant="outlined"
                    error={errosSplit?.conta_id}
                    helperText={
                      errosSplit?.conta_id ? "Conta não selecionada!" : null
                    }
                  />
                )}
              />
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            style={{
              minWidth: 120,
              height: 40,
            }}
            onClick={handleClose}
            variant="outlined"
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSplit}
            color="default"
            variant="outlined"
            autoFocus
            style={{
              minWidth: 120,
              height: 40,
            }}
          >
            {info ? "Alterar" : "Repartir"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default SplitModal;
