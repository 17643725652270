import { useEffect } from "react";

const useQueryParams = (query, filters, page, history) => {
  useEffect(() => {
    query.set("page", page);

    const filterKeys = Object.keys(filters);

    filterKeys.forEach((value) => {
      filters[value] === "" || filters[value] === null
        ? query.delete(value)
        : query.set(value, filters[value]);
    });

    history.replace({ search: query.toString() });
  }, [filters, page, query, history]);
};

export default useQueryParams;
