import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { formatMoney } from "utils/money";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { downloadReceiptLetter } from "services/transaction";

const columnsCancelationLetters = [
  {
    headerText: "Tipo",
    key: "type",
    CustomValue: (value) => {
      if (value === "total") {
        return <Typography>Total</Typography>;
      } else if (value === "partial") {
        return <Typography>Parcial</Typography>;
      }
    },
  },
  {
    headerText: "Valor",
    key: "amount",
    CustomValue: (amount) => {
      return amount ? <Typography>{formatMoney(amount)}</Typography> : null;
    },
  },
  {
    headerText: "Download",
    key: "url",
    CustomValue: (value, data) => (
      <Box display="flex" justifyContent="center">
        <Button
          onClick={() =>
            downloadReceiptLetter({
              id: data.id,
            })
          }
        >
          <Box>
            <FontAwesomeIcon icon={faDownload} size="lg" />
            <Typography
              style={{ marginLeft: "6px", textTransform: "capitalize" }}
            >
              Download
            </Typography>
          </Box>
        </Button>
      </Box>
    ),
  },
];

export default columnsCancelationLetters;
