import { MAPPED_FLAGS } from "constants/flags";

export const showDefaltSalesPlanAppAccountQuery = () => ({
  url: `/plano-vendas-conta-app-padrao`,
  method: "get",
});

export const setDefaltSalesPlanAppAccountMutation = ({ conta_id }) => ({
  url: `/plano-vendas-conta-app-padrao`,
  method: "post",
  data: {
    conta_id,
  },
});

export const indexSalesPlanQuery = ({ page, plan_name, agent_id }) => ({
  url: `/plano-vendas`,
  method: "get",
  params: {
    page,
    plan_name,
    agent_id,
  },
});

export const showSalesPlanQuery = ({ plan_id }) => ({
  url: `/plano-vendas/${plan_id}`,
  method: "get",
});

export const indexZoopSalesPlanQuery = ({ page, zoop_plan_name }) => ({
  url: `/plano-vendas-zoop`,
  method: "get",
  params: {
    page,
    zoop_plan_name,
  },
});

export const showZoopSalesPlanQuery = ({ zoop_plan_id }) => ({
  url: `/plano-vendas-zoop/${zoop_plan_id}`,
  method: "get",
});

export const storeAgentSalesPlanMutation = ({ name }) => ({
  url: `/plano-vendas`,
  method: "post",
  data: {
    name,
  },
});

export const storeZoopSalesPlanMutation = ({ zoop_plan_id }) => ({
  url: `/plano-vendas-zoop`,
  method: "post",
  data: {
    zoop_plan_id: zoop_plan_id,
  },
});

export const createDefaultSalesPlanFeesMutation = ({ sales_plan_id }) => ({
  url: `/plano-vendas/${sales_plan_id}/create-default-fees`,
  method: "post",
});

export const setDefaultSalesPlanMutation = ({ sales_plan_id }) => ({
  url: `/plano-vendas/${sales_plan_id}/set-default`,
  method: "post",
});

export const deleteSalesPlanMutation = ({ plan_id }) => ({
  url: `/plano-vendas/${plan_id}`,
  method: "delete",
});

export const indexMyEcsSalesPlanQuery = ({ agent_id }) => ({
  url: `/plano-vendas-meus-ecs`,
  method: "get",
  params: {
    agent_id,
  },
});

export const updateSalesPlanFeesMutation = ({ sales_plan_id }) => ({
  url: `/plano-vendas/${sales_plan_id}/update-fees`,
  method: "post",
});

export const transformResponseShowSalesPlan = ({
  fee_details: initial_fee_details,
  ...rest
}) => {
  const fee_details = Object.fromEntries(
    Object.entries(initial_fee_details).map(([key, value]) => {
      if (key.includes("debit", 0) || key.includes("credit", 0)) {
        const mapCardBrand = ({ card_brand, ...rest }) => ({
          ...rest,
          card_brand: MAPPED_FLAGS[card_brand] || card_brand,
        });

        const mappedValue = Object.fromEntries(
          Object.entries(value).map(([k, v]) => {
            let { partner, zoop, aplication } = v;

            return [
              k,
              {
                partner: partner.map(mapCardBrand),
                zoop: zoop.map(mapCardBrand),
                aplication: aplication.map(mapCardBrand),
              },
            ];
          })
        );

        return [key, mappedValue];
      }

      return [key, value];
    })
  );

  return {
    ...rest,
    fee_details,
  };
};
