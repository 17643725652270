import { Typography } from "@material-ui/core";
import AccountStatusColumn from "components/reusable/TableColumns/AccountStatusColumn";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import React from "react";

const columns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (date) => {
      return <DateTimeColumn dateTime={date} />;
    },
  },
  {
    headerText: "Conta",
    key: "custom_conta",
    FullCustom: (row) => {
      return <Typography>{row.cnpj ? row.razao_social : row.nome}</Typography>;
    },
  },
  { headerText: "Tipo", key: "tipo_string" },
  {
    headerText: "Documento",
    key: "custom_documento",
    FullCustom: (row) => {
      return <Typography>{row.cnpj ? row.cnpj : row.documento}</Typography>;
    },
  },
  { headerText: "E-mail", key: "email" },
  { headerText: "Contato", key: "celular" },
  {
    headerText: "Status",
    key: "status",
    CustomValue: (status) => {
      return <AccountStatusColumn status={status} />;
    },
  },
  {
    headerText: "Editar",
    key: "menu",
    CustomValue: (value) => <h1>{value}</h1>,
  },
];

export default columns;
