import React, { useMemo, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import flags from "constants/flags";
import { InputAdornment, TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import { useUpdateFeeMutation } from "services/api";
import { useEffect } from "react";

const CardFee = ({ cardFee, editable, setIsLoading, showFixedFee }) => {
  const [feeValue, setFeeValue] = useState(0);
  const [changed, setChanged] = useState(false);
  const [updateCardFee, updateCardFeeStatus] = useUpdateFeeMutation();
  const updateFeeError = useMemo(
    () =>
      updateCardFeeStatus.error ? updateCardFeeStatus.error.data.errors : false,
    [updateCardFeeStatus]
  );

  const fixedFeeValue = (cardFee.dollar_amount / 100).toFixed(2);

  useEffect(() => {
    setFeeValue((cardFee.percent_amount / 100).toFixed(2));
  }, [cardFee]);

  const handleChange = (event) => {
    setChanged(true);
    setFeeValue(event.target.value.replace(",", "."));
  };

  const handleUpdateCardFee = async () => {
    if (changed) {
      setIsLoading(true);
      try {
        await updateCardFee({
          fee_id: cardFee.id,
          percent_amount: (feeValue * 100).toFixed(0),
        }).unwrap();
        toast.success("Taxa atualizada!");
      } catch (e) {
        toast.error("Erro ao atualizar taxa!");
      }
      setIsLoading(false);
      setChanged(false);
    }
  };

  const Flag =
    flags[
      cardFee.card_brand
        ? cardFee.card_brand.toLowerCase().replace(" ", "")
        : ""
    ];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      key={cardFee.card_brand}
    >
      <Box display="flex" width="200px" alignItems="center">
        <Box
          width={40}
          height={40}
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginRight={1}
        >
          {Flag ? (
            <Flag />
          ) : (
            <FontAwesomeIcon icon={faCreditCard} size={"1x"} />
          )}
        </Box>
        <Typography style={{ paddingLeft: "5px" }}>
          {cardFee.card_brand}
        </Typography>
      </Box>

      <TextField
        id={cardFee.id}
        disabled={!editable}
        value={feeValue}
        onChange={handleChange}
        style={{ width: "6ch" }}
        InputProps={{
          endAdornment: <InputAdornment>%</InputAdornment>,
        }}
        error={updateFeeError}
        onBlur={handleUpdateCardFee}
      />
      {showFixedFee ? (
        <TextField
          id={cardFee.id}
          disabled
          value={fixedFeeValue}
          style={{ width: "7ch", marginLeft: "5px" }}
          InputProps={{
            startAdornment: <InputAdornment>R$</InputAdornment>,
          }}
        />
      ) : null}
    </Box>
  );
};

const CardFeesBox = ({ cardFees, label, editable, showFixedFee = false }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="0 10px 10px"
    >
      <LoadingScreen isLoading={isLoading} />
      <Typography variant="h6">{label}</Typography>
      {cardFees.map((cardFee) => {
        return (
          <CardFee
            cardFee={cardFee}
            editable={editable}
            setIsLoading={setIsLoading}
            showFixedFee={showFixedFee}
          />
        );
      })}
    </Box>
  );
};

export default CardFeesBox;
