import {
  Avatar,
  Box,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import GradientButton from "../../GradientButton/GradientButton";
import { Skeleton } from "@material-ui/lab";
import UserAccountId from "./UserAccountId";

const useStyles = makeStyles((theme) => ({
  avatarMenuContainer: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  avatarBox: {
    display: "flex",
    alignItems: "center",
    alignContent: "space-between",
  },
  avatarButton: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  avatarImage: {
    width: "72px",
    height: "72px",
    border: "2px solid #fff",
    color: theme.palette.background.main,
    backgroundColor: theme.palette.background.paper,
  },
}));

const UserMenu = () => {
  const theme = useTheme();
  const classes = useStyles();
  const userData = useSelector((state) => state.userData);
  const token = useAuth();

  return (
    <Box className={classes.avatarMenuContainer}>
      <Box className={classes.avatarBox}>
        <Avatar className={classes.avatarImage} />
        <Box marginLeft="24px" width="100%">
          {userData.id ? (
            <>
              <Typography style={{ color: theme.palette.menu.text }}>
                {userData.razao_social ?? userData.nome}
              </Typography>
              <UserAccountId textColor={theme.palette.menu.text} />
            </>
          ) : token && userData === "" ? (
            <Typography style={{ color: theme.palette.menu.text }}>
              Administrador
            </Typography>
          ) : (
            <Skeleton
              variant="text"
              animation="pulse"
              width="200px"
              height="40px"
              style={{ backgroundColor: "#d3d3d3" }}
            />
          )}
        </Box>
      </Box>
      {token && userData.id && (
        <Box height="28px" marginTop="12px" className={classes.avatarButton}>
          <GradientButton
            component={Link}
            to={
              userData.owner_agent_id
                ? "/dashboard/editar-meu-representante"
                : "/dashboard/editar-conta"
            }
            buttonText="editar perfil"
          />
        </Box>
      )}
    </Box>
  );
};

export default UserMenu;
