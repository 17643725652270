import { Typography } from "@material-ui/core";
import { formatMoney } from "utils/money";

const ReceivablesPreviewColumns = [
  {
    headerText: "Recebedor",
    key: "account_name",
    CustomValue: (account_name) => {
      return <Typography>{account_name}</Typography>;
    },
  },
  {
    headerText: "Valor Bruto",
    key: "total_gross_amount",
    CustomValue: (value) => {
      return <Typography>{formatMoney(value)}</Typography>;
    },
  },
  {
    headerText: "Valor Liquido",
    key: "total_amount",
    CustomValue: (value) => {
      return <Typography>{formatMoney(value)}</Typography>;
    },
  },
];

export default ReceivablesPreviewColumns;
