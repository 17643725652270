import {
	Box,
	Button,
	LinearProgress,
	Menu,
	MenuItem,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { delUser, loadReenviarToken, loadUserConta } from "actions/actions";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import { Pagination } from "@material-ui/lab";
import { toast } from "react-toastify";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";

const columns = [
	{ headerText: "E-mail", key: "email" },
	{ headerText: "Id", key: "id" },
	{ headerText: "Ações", key: "menu" },
];

const MyUsers = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	const dispatch = useDispatch();
	const usuarios = useSelector((state) => state.usuarios);
	const history = useHistory();
	const [page, setPage] = useState(1);
  const userData = useSelector((state) => state.userData);
  const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		dispatch(loadUserConta(userData.id));
	}, [dispatch, userData]);

	const handleCreateUser = () => {
		const path = generatePath("/dashboard/create-new-user");
		history.push(path);
	};

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	const Editar = ({ row }) => {
		const [anchorEl, setAnchorEl] = useState(null);

		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleReenvioToken = async () => {
			await dispatch(loadReenviarToken(row.id));
			setAnchorEl(null);
		};

		const handleExcluir = async () => {
      setIsLoading(true);
			const response = await dispatch(delUser(row.id));
      if(response) {
        toast.error("Erro ao excluir usuário!");
      } else {
        toast.success("Usuario excluido com sucesso!");
      }
			setAnchorEl(null);
      setIsLoading(false);
		};

		const handleGerenciar = () => {
			const path = generatePath("/dashboard/my-users/:id/permissoes", {
				id: row.id,
			});
			history.push(path);
			setAnchorEl(null);
		};

		return (
			<Box>
				<Button
					style={{ height: "15px", width: "10px" }}
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					...
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={() => handleExcluir(row)}>Excluir</MenuItem>
					<MenuItem onClick={() => handleReenvioToken(row)}>Reenviar Token de Confirmação</MenuItem>
					<MenuItem onClick={() => handleGerenciar(row)}>Gerenciar Permissões</MenuItem>
				</Menu>
			</Box>
		);
	};

	return (
		<Box display="flex" flexDirection="column">
      <LoadingScreen isLoading={isLoading} />
			<CustomBreadcrumbs path1="Meus Usuários" to1="#" />
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? "column" : null}>
				<Typography style={{ marginTop: "8px", marginBottom: "12px" }} variant="h4">
					Gerenciar usuários
				</Typography>

        <Button
          variant="outlined"
          style={{ marginTop: "8px", marginBottom: "12px" }}
          onClick={handleCreateUser}
        >
          Criar novo usuário
        </Button>
			</Box>

			<>
				{usuarios.user ? (
					<CustomTable columns={columns} data={usuarios.user} Editar={Editar} />
				) : (
					<LinearProgress />
				)}
				<Box alignSelf="flex-end" marginTop="8px">
					<Pagination
						variant="outlined"
						color="secondary"
						size="large"
						count={usuarios.last_page}
						onChange={handleChangePage}
						page={page}
					/>
				</Box>
			</>
		</Box>
	);
};

export default MyUsers;
