import {
  Box,
  Grid,
  TextField,
  LinearProgress,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import useDebounce from "hooks/useDebounce";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useGetDetailedLogsQuery } from "services/api";
import { toast } from "react-toastify";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import CustomCopyToClipboard from "components/reusable/Text/CustomCopyToClipboard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
  button: {
    width: "70px",
  },
}));

const columns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (created_at) => {
      return <DateTimeColumn dateTime={created_at} />;
    },
  },
  {
    headerText: "Tipo",
    key: "auditable_type",
    CustomValue: (value) => {
      const models = value.split("\\");
      const type = models[models.length - 1];

      return <Typography align="center">{type}</Typography>;
    },
  },
  { headerText: "Evento", key: "event" },
  {
    headerText: "Anterior",
    key: "old_values",
    CustomValue: (values) => {
      const { transaction, ...rest } = values;
      const newValues = {
        ...rest,
        transaction: JSON.parse(transaction ?? `{}`),
      };
      return (
        <CustomCopyToClipboard value={JSON.stringify(newValues, null, 2)} />
      );
    },
  },
  {
    headerText: "Novo",
    key: "new_values",
    CustomValue: (values) => {
      const { transaction, ...rest } = values;
      const newValues = {
        ...rest,
        transaction: JSON.parse(transaction ?? `{}`),
      };

      return (
        <CustomCopyToClipboard value={JSON.stringify(newValues, null, 2)} />
      );
    },
  },
];

const DetailedLogsPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    event: "",
    type: "",
    data_inicial: "",
    data_final: "",
  });

  const debouncedEvent = useDebounce(filters.event, 800);
  const debouncedType = useDebounce(filters.type, 800);
  const debouncedInitialDate = useDebounce(filters.data_inicial, 800);
  const debouncedFinalDate = useDebounce(filters.data_final, 800);

  const {
    data: logs,
    isLoading,
    isError,
    isUninitialized,
  } = useGetDetailedLogsQuery(
    {
      page,
      type: debouncedType,
      event: debouncedEvent,
      data_inicial: debouncedInitialDate,
      data_final: debouncedFinalDate,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (isError) {
      toast.error("O usuário não está autenticado a entrar nessa página!");
      history.push("/dashboard/adm");
    }
  }, [isError, history]);

  return (
    <Box className={classes.root}>
      <CustomBreadcrumbs
        path2="Logs detalhados"
        to2="/dashboard/detailed-logs"
        path1="Logs"
        to1="/dashboard/logs"
      />
      <Box marginBottom="10px" flexDirection={matches ? "column" : null}>
        <Typography
          variant="h4"
          style={{ color: theme.palette.background.default }}
        >
          LOGS DETALHADOS
        </Typography>
      </Box>
      <Box marginBottom="16px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <InputLabel>Evento</InputLabel>
            <Select
              fullWidth
              value={filters.event}
              onChange={(e) =>
                setFilters({ ...filters, event: e.target.value })
              }
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="created">Criação</MenuItem>
              <MenuItem value="updated">Atualização</MenuItem>
              <MenuItem value="deleted">Exclusão</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel>Tipo</InputLabel>
            <Select
              fullWidth
              value={filters.type}
              onChange={(e) => setFilters({ ...filters, type: e.target.value })}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="App\Models\Financa\Transacao">
                Transação
              </MenuItem>
              <MenuItem value="App\Models\Conta\Conta">Conta</MenuItem>
              <MenuItem value="App\Models\Conta\Pagador">Pagador</MenuItem>
              <MenuItem value="App\Models\Cobranca\Split">Split</MenuItem>
              <MenuItem value="App\Models\Cobranca\SlipSplitConfig">
                Configuração de split
              </MenuItem>
              <MenuItem value="App\Models\Financa\Mdr">Mdr</MenuItem>
              <MenuItem value="App\Models\Conta\Permissao">Permissão</MenuItem>
              <MenuItem value="App\Models\Cobranca\ArquivoRetorno">
                Arquivo Retorno
              </MenuItem>
              <MenuItem value="App\Models\Cobranca\ItemRemessa">
                Item Remessa
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Inicial"
              value={filters.data_inicial}
              onChange={(e) =>
                setFilters({ ...filters, data_inicial: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Final"
              value={filters.data_final}
              onChange={(e) =>
                setFilters({ ...filters, data_final: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </Box>
      <>
        {!isLoading && !isError && !isUninitialized ? (
          <>
            <CustomTable columns={columns} data={logs.data} />
            <Box alignSelf="flex-end" marginTop="8px">
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={logs.last_page}
                onChange={handleChangePage}
                page={page}
              />
            </Box>
          </>
        ) : (
          <LinearProgress />
        )}
      </>
    </Box>
  );
};

export default DetailedLogsPage;
