import {
  Box,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import GradientButton from "../../reusable/GradientButton/GradientButton";
import {
  updateUserPassword,
} from "../../../actions/actions";
import { toast } from "react-toastify";


const UpdateLogedUserPassword = ({loading, setLoading}) => {
  const dispatch = useDispatch();
  
  const [userPass, setUserPass] = useState({
    password: "",
    password_confirmation: "",
  });

  const [errosPass, setErrosPass] = useState({});

  const handleUpdatePass = async () => {
    setLoading(true);
    const resPass = await dispatch(updateUserPassword(userPass));
    if (resPass) {
      toast.error("Erro ao modificar senha");
      setErrosPass(resPass);
      setLoading(false);
    } else {
      toast.success("Senha modificada com sucesso");
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <TextField
        error={errosPass.password}
        helperText={
          errosPass.password ? errosPass.password.join(" ") : null
        }
        label="Nova Senha"
        type="password"
        value={userPass.password}
        onChange={(e) =>
          setUserPass({
            ...userPass,
            password: e.target.value,
          })
        }
        style={{ width: "50%" }}
      />

      <TextField
        style={{ width: "50%" }}
        label="Confirmar Nova Senha"
        type="password"
        value={userPass.password_confirmation}
        onChange={(e) =>
          setUserPass({
            ...userPass,
            password_confirmation: e.target.value,
          })
        }
      />

      <Box marginTop="12px">
        <GradientButton
          buttonText="Alterar"
          onClick={handleUpdatePass}
        />
      </Box>
    </Box>
  );
};

export default UpdateLogedUserPassword;
