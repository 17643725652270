import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import { getCep } from "../../../../services/services";
import { toast } from "react-toastify";
import { useEffect } from "react";

const NewPayer = ({
  pagador,
  setPagador,
  errosPagador,
  disableEditar,
  disableEditarAll,
}) => {
  const [pessoaJuridica, setPessoaJuridica] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const endereco = useMemo(() => pagador.endereco, [pagador]);
  const handlerCep = async () => {
    try {
      const response = await getCep(pagador.endereco.cep);
      setPagador({
        ...pagador,
        endereco: {
          ...pagador.endereco,
          cep: response.data.cep,
          rua: response.data.logradouro,
          complemento: response.data.complemento,
          bairro: response.data.bairro,
          cidade: response.data.localidade,
          estado: response.data.uf,
        },
      });
    } catch (error) {
      toast.error("Error ao puxar dados do cep");
    }
  };

  useEffect(() => {
    if (pagador?.tipo === 1) {
      setPessoaJuridica(false);
    }
    if (pagador?.tipo === 2) {
      setPessoaJuridica(true);
    }
  }, [pagador]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography
        align="center"
        variant="h4"
        style={{ marginTop: "12px", marginBottom: "12px" }}
      >
        Dados do pagador
      </Typography>
      <Box
        style={matches ? { display: "flex", flexDirection: "column" } : null}
      >
        <Button
          disabled={disableEditar || disableEditarAll}
          variant="contained"
          style={{
            margin: "5px",
            borderRadius: "27px",
            backgroundColor: pessoaJuridica ? "" : theme.palette.primary.main,
            color: "white",
          }}
          onClick={() => {
            setPessoaJuridica(false);
            setPagador({
              ...pagador,
              tipo: 1,
            });
          }}
        >
          Pessoa Física
        </Button>
        <Button
          disabled={disableEditar || disableEditarAll}
          style={{
            margin: "5px",
            borderRadius: "27px",
            backgroundColor: pessoaJuridica ? theme.palette.primary.main : "",
            color: "white",
          }}
          variant="contained"
          onClick={() => {
            setPessoaJuridica(true);
            setPagador({
              ...pagador,
              tipo: 2,
            });
          }}
        >
          Pessoa Jurídica
        </Button>
      </Box>
      <form>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <InputMask
                mask={pessoaJuridica ? "99.999.999/9999-99" : "999.999.999-99"}
                value={pagador ? pagador.documento : null}
                onChange={(e) =>
                  setPagador({
                    ...pagador,
                    documento: e.target.value,
                  })
                }
                disabled={disableEditar || disableEditarAll}
                onBlur={() =>
                  setPagador({
                    ...pagador,
                    tipo: pessoaJuridica ? 2 : 1,
                  })
                }
              >
                {() => (
                  <TextField
                    error={errosPagador.documento}
                    helperText={
                      errosPagador.documento
                        ? errosPagador.documento.join(" ")
                        : null
                    }
                    name="documento"
                    fullWidth
                    required
                    label={pessoaJuridica ? "CNPJ" : "CPF"}
                    disabled={disableEditar || disableEditarAll}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                disabled={disableEditarAll}
                error={errosPagador.nome}
                helperText={
                  errosPagador.nome ? errosPagador.nome.join(" ") : null
                }
                value={pagador ? pagador.nome : null}
                onChange={(e) =>
                  setPagador({
                    ...pagador,
                    nome: e.target.value,
                  })
                }
                fullWidth
                required
                label={"Primeiro e Segundo nome"}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                disabled={disableEditarAll}
                error={errosPagador.data_nascimento}
                helperText={
                  errosPagador.data_nascimento
                    ? errosPagador.data_nascimento.join(" ")
                    : null
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                  pattern: "d {4}- d {2}- d {2} ",
                }}
                type="date"
                label="Data de Nascimento"
                value={pagador ? pagador.data_nascimento : null}
                onChange={(e) =>
                  setPagador({
                    ...pagador,
                    data_nascimento: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                disabled={disableEditarAll}
                mask="99999999"
                maskChar=" "
                value={endereco ? endereco.cep : null}
                onChange={(e) =>
                  setPagador({
                    ...pagador,
                    endereco: {
                      ...pagador.endereco,
                      cep: e.target.value,
                    },
                  })
                }
                onBlur={handlerCep}
              >
                {() => (
                  <TextField
                    disabled={disableEditarAll}
                    error={errosPagador["endereco.cep"]}
                    helperText={
                      errosPagador["endereco.cep"]
                        ? errosPagador["endereco.cep"].join(" ")
                        : null
                    }
                    fullWidth
                    required
                    label="CEP"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                disabled={disableEditarAll}
                error={errosPagador["endereco.rua"]}
                helperText={
                  errosPagador["endereco.rua"]
                    ? errosPagador["endereco.rua"].join(" ")
                    : null
                }
                value={endereco ? endereco.rua : null}
                onChange={(e) =>
                  setPagador({
                    ...pagador,
                    endereco: {
                      ...pagador.endereco,
                      rua: e.target.value,
                    },
                  })
                }
                fullWidth
                required
                label="Rua"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                disabled={disableEditarAll}
                error={errosPagador["endereco.numero"]}
                helperText={
                  errosPagador["endereco.numero"]
                    ? errosPagador["endereco.numero"].join(" ")
                    : null
                }
                value={endereco ? endereco.numero : null}
                onChange={(e) =>
                  setPagador({
                    ...pagador,
                    endereco: {
                      ...pagador.endereco,
                      numero: e.target.value,
                    },
                  })
                }
                fullWidth
                label="Número"
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                disabled={disableEditarAll}
                error={errosPagador["endereco.bairro"]}
                helperText={
                  errosPagador["endereco.bairro"]
                    ? errosPagador["endereco.bairro"].join(" ")
                    : null
                }
                value={endereco ? endereco.bairro : null}
                onChange={(e) =>
                  setPagador({
                    ...pagador,
                    endereco: {
                      ...pagador.endereco,
                      bairro: e.target.value,
                    },
                  })
                }
                fullWidth
                required
                label="Bairro"
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                shrink
                disabled={disableEditarAll}
                value={endereco ? endereco.complemento : null}
                onChange={(e) =>
                  setPagador({
                    ...pagador,
                    endereco: {
                      ...pagador.endereco,
                      complemento: e.target.value,
                    },
                  })
                }
                fullWidth
                label="Complemento"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                disabled={disableEditarAll}
                error={errosPagador["endereco.cidade"]}
                helperText={
                  errosPagador["endereco.cidade"]
                    ? errosPagador["endereco.cidade"].join(" ")
                    : null
                }
                value={endereco ? endereco.cidade : null}
                onChange={(e) =>
                  setPagador({
                    ...pagador,
                    endereco: {
                      ...pagador.endereco,
                      cidade: e.target.value,
                    },
                  })
                }
                fullWidth
                required
                label="Cidade"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                disabled={disableEditarAll}
                error={errosPagador["endereco.estado"]}
                helperText={
                  errosPagador["endereco.estado"]
                    ? errosPagador["endereco.estado"].join(" ")
                    : null
                }
                value={endereco ? endereco.estado : null}
                onChange={(e) =>
                  setPagador({
                    ...pagador,
                    endereco: {
                      ...pagador.endereco,
                      estado: e.target.value,
                    },
                  })
                }
                fullWidth
                required
                label="Estado"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                disabled={disableEditarAll}
                mask="(99) 99999-9999"
                value={pagador ? pagador.celular : null}
                onChange={(e) =>
                  setPagador({
                    ...pagador,
                    celular: e.target.value,
                  })
                }
              >
                {() => (
                  <TextField
                    disabled={disableEditarAll}
                    error={errosPagador.celular}
                    helperText={
                      errosPagador.celular
                        ? errosPagador.celular.join(" ")
                        : null
                    }
                    fullWidth
                    required
                    label="Celular"
                    type="tel"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={disableEditarAll}
                error={errosPagador.email}
                helperText={
                  errosPagador.email ? errosPagador.email.join(" ") : null
                }
                value={pagador ? pagador.email : null}
                onChange={(e) =>
                  setPagador({
                    ...pagador,
                    email: e.target.value,
                  })
                }
                fullWidth
                required
                label="E-mail"
                type="email"
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default NewPayer;
