import { toast } from 'react-toastify';

export default function getResponseError(errorResponse) {
  if (errorResponse?.response?.status === 422) {
    return errorResponse.response.data.errors;
  }
  if(errorResponse?.response?.data?.message) {
    toast.error(errorResponse.response.data.message);
  }

  if(errorResponse?.data?.message) {
    toast.error(errorResponse.data.message);
  }

  return ({});
}
