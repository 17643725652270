import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import GradientButton from "../Buttons/GradientButton";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: "20px",
  },
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
}));

function ConfirmDialog({
  open = false,
  title,
  message,
  abortText = "Cancelar",
  confirmText = "Confirmar",
  onConfirm = () => {},
  onClose = () => {},
}) {
  const classes = useStyles();

  return (
    <Dialog className={classes.dialog} open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.dialogHeader}>{title}</DialogTitle>

      <DialogContent
        style={{
          minWidth: 500,
        }}
      >
        {message}
      </DialogContent>

      <DialogActions>
        <Button
          style={{ borderRadius: "27px" }}
          variant="outlined"
          onClick={onClose}
        >
          {abortText}
        </Button>
        <GradientButton text={confirmText} onClick={onConfirm} />
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
