import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useState, useEffect } from "react";
import { generatePath, useHistory } from "react-router-dom";
import CustomTable from "../../../reusable/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { loadContasFilter } from "../../../../actions/actions";
import useDebounce from "../../../../hooks/useDebounce";
import columns from "./AccountsColumns";
import EditarAccount from "./EditarAccount";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import useQuery from "hooks/useQuery";
import useQueryParams from "hooks/useQueryParams";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { clearSearchParams } from "services/functions/clearSearchParams";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDynamicPagination from "hooks/useDynamicPagination";

const useStyles = makeStyles((theme) => ({
  boxFilters: {
    marginTop: "8px",
    marginBottom: "32px",
    display: "flex",
    flexDirection: "column",
  },
}));

const Accounts = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const query = useQuery();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const [page, setPage] = useState(Number(query.get("page")) || 1);
  const contas = useSelector((state) => state.contas);

  const initialFilters = {
    like: query.get("like") ?? "",
    status: query.get("status") ?? "",
    conta_id_filter: query.get("conta_id_filter") ?? "",
    tipo: query.get("tipo") ?? "",
    with_app_sales_plan: query.get("with_app_sales_plan") ?? "",
  };

  const [filters, setFilters] = useState(initialFilters);

  const debouncedFilters = useDebounce(filters, 800);

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const handleCreateAccount = () => {
    const path = generatePath("/dashboard/contas-criar");
    history.push(path);
  };

  useEffect(() => {
    dispatch(loadContasFilter({ page, ...debouncedFilters }));
  }, [dispatch, page, debouncedFilters]);

  useQueryParams(query, filters, page, history);
  useDynamicPagination(page, contas.last_page, setPage);

  return (
    <Box display="flex" flexDirection="column">
      <CustomBreadcrumbs path1="Contas" to1="#" />
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            style={{ color: theme.palette.background.default }}
          >
            CONTAS
          </Typography>
        </Box>
        <Button
          variant="outlined"
          style={{ marginTop: "8px", marginBottom: "12px" }}
          onClick={handleCreateAccount}
        >
          Criar conta
        </Button>
      </Box>
      <Box className={classes.boxFilters}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Pesquisar por nome, documento, e-mail..."
              fullWidth
              label="Nome, email, documento..."
              value={filters.like}
              onChange={(e) => setFilters({ ...filters, like: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Identificador da conta"
              fullWidth
              label="ID da conta"
              value={filters.conta_id_filter}
              onChange={(e) =>
                setFilters({ ...filters, conta_id_filter: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel>Status</InputLabel>
            <Select
              fullWidth
              value={filters.status}
              onChange={(e) =>
                setFilters({ ...filters, status: e.target.value })
              }
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="pending">Pendente</MenuItem>
              <MenuItem value="enabled">Habilitado</MenuItem>
              <MenuItem value="active">Ativo</MenuItem>
              <MenuItem value="denied">Negado</MenuItem>
              <MenuItem value="disabled">Desabilitado</MenuItem>
              <MenuItem value="deleted">Excluído - Inativo</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: "8px" }} container spacing={3}>
          <Grid item xs={12} sm={4}>
            <InputLabel>Tipo de conta</InputLabel>
            <Select
              fullWidth
              value={filters.tipo}
              onChange={(e) => setFilters({ ...filters, tipo: e.target.value })}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="1">Pessoa Física</MenuItem>
              <MenuItem value="2">Pessoa Jurídica</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel>Plano de Venda da Aplicação </InputLabel>
            <Select
              fullWidth
              value={filters.with_app_sales_plan}
              onChange={(e) =>
                setFilters({ ...filters, with_app_sales_plan: e.target.value })
              }
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="1">Definido</MenuItem>
              <MenuItem value="0">Indefinido</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Tooltip title="Limpar Filtros">
              <IconButton
                onClick={() => clearSearchParams(filters, setFilters)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      {contas.data && contas.per_page ? (
        <>
          <Box maxWidth={"calc(100vw - 65px)"}>
            <CustomTable
              columns={columns}
              data={contas.data}
              Editar={EditarAccount}
            />
          </Box>
          <Box alignSelf="flex-end" marginTop="8px">
            <Pagination
              variant="outlined"
              color="secondary"
              size="large"
              count={contas.last_page}
              onChange={handleChangePage}
              page={page}
            />
          </Box>
        </>
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
};

export default Accounts;
