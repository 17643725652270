import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { useStoreSalesPlanSubscriptionMutation } from "services/api";
import useDebounce from "hooks/useDebounce";
import { Autocomplete } from "@material-ui/lab";
import { loadContasFilter } from "actions/actions";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
}));

const AddAgentSalesPlanSubscriptionModal = ({
  openDialog,
  setOpenDialog,
  refetchSubscriptions,
  refetchSalesPlan,
  planId,
  isLoading,
  setIsLoading,
  agentId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [addEcSubscription] = useStoreSalesPlanSubscriptionMutation();
  const { data: contas } = useSelector((state) => state.contas);
  const [accountId, setAccountId] = useState({});
  const [filters, setFilters] = useState({
    like: "",
  });
  const debouncedLike = useDebounce(filters.like, 800);

  useEffect(() => {
    dispatch(loadContasFilter({ like: debouncedLike, agent_id: agentId }));
  }, [dispatch, debouncedLike, agentId]);

  const handleAddEcSubscription = async () => {
    setIsLoading(true);
    try {
      await addEcSubscription({
        conta_id: accountId,
        plano_venda_id: planId,
      }).unwrap();
      toast.success("EC adicionado ao Plano de Venda!");
    } catch (e) {
      toast.error("Erro ao adicionar EC ao Plano de Venda!");
    } finally {
      refetchSubscriptions();
      refetchSalesPlan();
      setOpenDialog(false);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ zIndex: 1000 }}
    >
      <Box width="600px">
        <DialogTitle className={classes.dialogHeader}>
          <Typography align="center" variant="h6">
            Adicionar assinatura
          </Typography>
        </DialogTitle>

        <Box display="flex" flexDirection="column" padding="6px 16px">
          <Typography>
            Escolha um EC para este Plano de Venda
          </Typography>

          <Autocomplete
            freeSolo
            fullWidth
            options={contas}
            getOptionLabel={(conta) => conta.razao_social ?? conta.nome}
            onInputChange={(_event, value, reason) => {
              if(reason !== "reset") {
                setFilters({...filters, like: value});
                setAccountId(null);
              }
            }}
            onChange={(_event, option) => {
              setAccountId(option? option.id : null);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Escolher EC" margin="normal" variant="outlined" />
            )}
          />

          <DialogActions>
            <Button
              onClick={handleAddEcSubscription}
              variant="outlined"
              color="secondary"
              disabled={accountId === null}
            >
              Adicionar
            </Button>
            <Button
              onClick={() => setOpenDialog(false)}
              color="default"
              variant="outlined"
              autoFocus
            >
              Cancelar
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddAgentSalesPlanSubscriptionModal;
