import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDeleteSlipSplitConfigMutation } from "services/api";

const DialogDeleteSplit = ({ open, onClose, splitConfigId }) => {
  const [deleteSplit, deleteSplitResult] = useDeleteSlipSplitConfigMutation();

  const handleClose = () => {
    onClose(false);
  };

  useEffect(() => {
    if (deleteSplitResult.isSuccess) {
      toast.success(deleteSplitResult.data.message);
      handleClose();
    }

    if (deleteSplitResult.isError) {
      toast.error("Não foi possível remover a configuração de split");
    }
  }, [deleteSplitResult.isError, deleteSplitResult.isSuccess]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Deseja realmente remover essa configuração de split?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          Não
        </Button>
        <Button
          disabled={deleteSplitResult.isLoading}
          onClick={() =>
            deleteSplit({
              slip_split_config_id: splitConfigId,
            })
          }
          color="default"
          variant="outlined"
          autoFocus
        >
          {deleteSplitResult.isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <>Sim</>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteSplit;
