import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { get } from 'lodash';
import { Box, Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.light,
		[theme.breakpoints.down('sm')]: {},
	},
	body: {
		fontSize: 16,
		[theme.breakpoints.down('sm')]: {},
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.primary.light,
		},
		'&:hover': {
			cursor: 'pointer',
			backgroundColor:  theme.palette.secondary.light,
		},
		[theme.breakpoints.down('sm')]: {},
	},
}))(TableRow);

const useStyles = makeStyles((theme) => ({
	tableContainer: {
		borderRadius: '15px 15px 0 0',
		[theme.breakpoints.down('sm')]: {},
	},
	table: {
		[theme.breakpoints.down('sm')]: {},
	},
	tableHead: {
		[theme.breakpoints.down('sm')]: {},
	},
}));

const CustomTable = ({ columns, data, Editar, compacta, handleClickRow }) => {
	const classes = useStyles();

	return (
		<>
			<TableContainer className={classes.tableContainer} component={Paper}>
				<Grid item sm={12}>
					<Table
						className={classes.table}
						aria-label="customized table"
						stickyHeader
						size={compacta ? 'small' : null}
					>
						<TableHead>
							<TableRow className={classes.tableHead}>
								{columns.map((column) => (
									<StyledTableCell key={column.headerText} align="center">
										{column.headerText}
									</StyledTableCell>
								))}
							</TableRow>
						</TableHead>

						<TableBody>
							{data.length > 0 ? (
								data.map((row) => (
									<StyledTableRow
										size={compacta ? 'small' : null}
										key={row.id || row.name}
										onClick={handleClickRow ? () => handleClickRow(row) : null}
									>
										{columns.map((column) => (
											<StyledTableCell key={column.key} align="center">
												{column.key === 'menu' ? <Editar row={row} key={row.id} /> : null}
												{column.Teste ? column.Teste(row) : null}
												{column.CustomValue
													? column.CustomValue(get(row, column.key), row)
													: get(row, column.key)}
                        {column.FullCustom ? column.FullCustom(row) : null}
											</StyledTableCell>
										))}
									</StyledTableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={columns.length}>
										<Box display="flex" flexDirection="column" alignItems="center">
											<FontAwesomeIcon
												icon={faExclamationTriangle}
												size="5x"
												style={{ marginBottom: '12px', color: '#ccc' }}
											/>
											<Typography variant="h6" style={{ color: '#ccc' }}>
												Não há dados para serem exibidos
											</Typography>
										</Box>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</Grid>
			</TableContainer>
		</>
	);
};

export default CustomTable;
