import { Box, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import SideBar from "../../reusable/SideBar/SideBar";
import AccountRegistration from "../AccountRegistration/AccountRegistration";
import AccountStatement from "../AccountStatement/AccountStatement";
import AccountSubscriptions from "../AccountSubscriptions/AccountSubscriptions";
import CreateNewPayer from "../Payers/CreateNewPayer/CreateNewPayer";
import NewPaymentLink from "../PaymentLink/NewPaymentLink/NewPaymentLink";
import PaymentSlip from "../PaymentSlip/PaymentSlip";
import TransactionHistory from "../TransactionHistory/TransactionHistory";
import Payers from "../Payers/Payers";
import PaymentLink from "../PaymentLink/PaymentLink";
import Adm from "../Adm/Adm";
import EditPayer from "../Payers/EditPayer/EditPayer";
import Accounts from "../Adm/Accounts/Accounts";
import Users from "../Adm/Users/Users";
import EditAccount from "../Adm/Accounts/EditAccount/EditAccount";
import NewPaymentSlip from "../PaymentSlip/NewPaymentSlip/NewPaymentSlip";
import Lancamentos from "../Transactions/Lancamentos";
import EditLoginAccount from "../EditLoginAccount/EditLoginAccount";
import CreateNewAccount from "../Adm/Accounts/CreateNewAccount/CreateNewAccount";
import MakeTransfer from "../MakeTransfer/MakeTransfer";
import TransferHistory from "../TransferHistory/TransferHistory";
import DigitalAccounts from "../DigitalAccounts/DigitalAccounts";
import CreditCard from "../CreditCard/CreditCard";
import NewTypedSale from "../CreditCard/NewTypedSale/NewTypedSale";
import PaymentBooklet from "../PaymentBooklet/PaymentBooklet";
import NewPaymentBooklet from "../PaymentBooklet/NewPaymentBooklet/NewPaymentBooklet";
import PaymentBookletRegistration from "../PaymentBooklet/PaymentBookletRegistration/PaymentBookletRegistration";
import PaymentBookletDetails from "../PaymentBooklet/PaymentBookletDetails/PaymentBookletDetails";
import SimpleSale from "../CreditCard/SimpleSale/SimpleSale";
import CreditCardBillingList from "../CreditCardBillingList/CreditCardBillingList";
import SubscriptionPlans from "../AccountSubscriptions/SubscriptionPlans/SubscriptionPlans";
import NewSubscriptionPlans from "../AccountSubscriptions/NewSubscriptionPlans/NewSubscriptionPlans";
import NewAccountSubscriptions from "../AccountSubscriptions/NewAccountSubscriptions/NewAccountSubscriptions";
import PaymentSlipList from "../PaymentSlipList/PaymentSlipList";
import TransactionDetails from "../TransactionDetails/TransactionDetails";
import ContentAreas from "../ContentAreas/ContentAreas";
import NewPublicToken from "../PublicTokens/NewPublicToken/NewPublicToken";
import DrawerSideBar from "../../reusable/DrawerSideBar/DrawerSideBar";
import PaymentLinkDetails from "../PaymentLink/PaymentLinkDetails/PaymentLinkDetails";
import { useDispatch, useSelector } from "react-redux";
import { authMe2, loadPermissao, loadUserData } from "../../../actions/actions";
import UserPermissions from "../UserPermissions/UserPermissions";
import AdmLists from "../AdmLists/AdmLists";
import EditDigitalAccount from "../EditDigitalAccount/EditDigitalAccount";
import SolicitedExportations from "../SolicitedExportations/SolicitedExportations";
import PosList from "../CreditCard/Pos/PosList";
import PosDetail from "../CreditCard/Pos/PosDetail";
import AdmTransactionHistory from "../TransactionHistory/AdmTransactionHistory";
import ListaDeAdministradores from "../Adm/ListaDeAdministradores";
import AdmUserPermissions from "../UserPermissions/AdmUserPermissions";
import AccountReactivationProcess from "../AccountReactivation/AccountReactivationProcess";
import SalesPlanList from "../SalesPlan/SalesPlanList";
import SalesPlanDetail from "../SalesPlan/SalesPlanDetail";
import ZoopSalesPlanList from "../SalesPlan/ZoopSalesPlanList";
import ZoopSalesPlanDetail from "../SalesPlan/ZoopSalesPlanDetail";
import LogsPage from "../Logs/LogsPage";
import OptionsCollection from "components/reusable/OptionsMenu/OptionsCollection";
import TotpOptions from "components/reusable/OptionsMenu/TotpOptions";
import AgentUserPermissions from "../UserPermissions/AgentUserPermissions";
import AgentUsers from "../Adm/Agent/AgentUsers";
import AgentList from "../Adm/Agent/AgentList";
import MyUsers from "../User/MyUsers";
import NewUser from "components/reusable/NewUser/NewUser";
import AgentDetails from "../Adm/Agent/AgentDetails";
import EditAgent from "../Adm/Agent/EditAgent";
import EditLoginAgent from "../EditLoginAgent/EditLoginAgent";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import { ADM_PERMISSIONS, USER_PERMISSIONS } from "constants/permissions";
import { setAgentTheme } from "services/functions/setAgentTheme";
import { resetAgentTheme } from "services/functions/resetAgentTheme";
import ShowSalesPlanEcFees from "../SalesPlan/ShowSalesPlanEcFees";
import TransactionAuditIndex from "../TransactionAudit/TransactionAuditIndex";
import TransactionAuditDetails from "../TransactionAudit/TransactionAuditDetails";
import useUserPermissions from "hooks/useUserPermissions";
import ReceivableHistory from "../Receivables/ReceivableHistory";
import ArquivoRemessaForm from "../ArquivoRemessa/ArquivoRemessaForm/ArquivoRemessaForm";
import ItensRemessa from "../ArquivoRemessa/ItensRemessa/ItensRemessa";
import ArquivoRemessaIndex from "../ArquivoRemessa/ArquivoRemessaIndex/ArquivoRemessaIndex";
import ArquivoRetornoIndex from "../ArquivoRetorno/ArquivoRetornoIndex";
import SlipSplitsConfigIndex from "../SlipSplitConfiguration/SlipSplitsConfigIndex";
import PagadorRemessaIndex from "../ArquivoRemessa/Pagador/PagadorRemessaIndex";
import DetailedLogsPage from "../Logs/DetailedLogsPage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    margin: "0px",
    padding: "0px",
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
  },
  sideBarContainer: {
    display: "flex",
    FlexDirection: "column",
    width: "25%",
    height: "100%",
    margin: "0px 0px 70px 0px",
  },
  contentAreaContainer: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    padding: "25px",
    [theme.breakpoints.up("lg")]: {
      borderRadius: "27px 0px 0px 27px",
      boxShadow: "-1px 0px 50px 1px rgba(0, 0, 0, 0.7)",
    },
  },
}));

const Dashboard = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { section, id, subsection } = useParams();
  const classes = useStyles();
  const authMe = useSelector((state) => state.authMe);

  const {
    isLoadingUserData,
    userData,
    permissions: permissoes,
    isAdmin: isAdm,
    isAgent,
    isManagementAdmin: isAdministrativo,
  } = useUserPermissions();

  useEffect(() => {
    if (userData.agent_id) {
      setAgentTheme({ agent: userData.agent });
    }
    if (!userData.agent_id) {
      resetAgentTheme({
        justColors: false,
      });
    }
  }, [userData]);

  useEffect(() => {
    dispatch(authMe2());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadUserData());
  }, [dispatch]);

  useEffect(() => {
    if (authMe.id !== undefined) {
      dispatch(loadPermissao(authMe.id));
    }
  }, [dispatch, authMe.id]);

  let content = null;

  switch (section) {
    case "home":
      content = isAdministrativo ? null : <ContentAreas />;
      break;

    case "solicitacoes-de-cobranca":
      content = isAdministrativo ? null : <ContentAreas />;
      break;

    case "financas":
      content = isAdministrativo ? null : <ContentAreas />;
      break;

    case "transferencias":
      content = isAdministrativo ? null : <ContentAreas />;
      break;

    case "outros-servicos":
      content = isAdministrativo ? null : <ContentAreas />;
      break;

    case "arquivo-remessa":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_SHIPPING_FILE) ? (
        <ArquivoRemessaIndex />
      ) : null;
      switch (subsection) {
        case "itens":
          content =
            isAdm ||
            permissoes.includes(ADM_PERMISSIONS.SHIPPING_FILE) ||
            permissoes.includes(USER_PERMISSIONS.BILLING_SHIPPING_FILE) ? (
              <ItensRemessa />
            ) : null;
          break;
        case "pagadores":
          content =
            isAdm ||
            permissoes.includes(ADM_PERMISSIONS.SHIPPING_FILE) ||
            permissoes.includes(USER_PERMISSIONS.BILLING_SHIPPING_FILE) ? (
              <PagadorRemessaIndex />
            ) : null;
          break;
        default:
      }
      break;

    case "criar-arquivo-remessa":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_SHIPPING_FILE) ? (
        <ArquivoRemessaForm />
      ) : null;
      break;

    case "detalhes-arquivo-remessa":
      switch (id) {
        default:
          content =
            isAdm ||
            permissoes.includes(ADM_PERMISSIONS.SHIPPING_FILE) ||
            permissoes.includes(USER_PERMISSIONS.BILLING_SHIPPING_FILE) ? (
              <ArquivoRemessaForm />
            ) : null;
          break;
      }
      break;

    case "arquivo-retorno":
      content =
        isAdm ||
        permissoes.includes(ADM_PERMISSIONS.SHIPPING_FILE) ||
        permissoes.includes(USER_PERMISSIONS.BILLING_SHIPPING_FILE) ? (
          <ArquivoRetornoIndex />
        ) : null;
      break;

    case "link-pagamento":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_PAYMENT_LINK) ? (
        <PaymentLink />
      ) : null;
      break;

    case "novo-link-pagamento":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_PAYMENT_LINK) ? (
        <NewPaymentLink />
      ) : null;
      break;

    case "boleto":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_BOLETO) ? (
        <PaymentSlipList />
      ) : null;
      break;

    case "gerar-boleto":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_BOLETO) ? (
        <PaymentSlip />
      ) : null;
      break;

    case "novo-boleto":
      switch (id) {
        default:
          content = permissoes.includes(USER_PERMISSIONS.BILLING_BOLETO) ? (
            <NewPaymentSlip />
          ) : null;
          switch (subsection) {
            case "cobrar":
              content = permissoes.includes(USER_PERMISSIONS.BILLING_BOLETO) ? (
                <NewPaymentSlip />
              ) : null;
              break;
            default:
          }
      }
      break;

    case "editar-conta":
      content = isAdministrativo ? null : <EditLoginAccount />;
      break;

    case "conta-reativar":
      content = isAdministrativo ? null : <AccountReactivationProcess />;
      break;

    case "novo-token-publico":
      content = isAdministrativo ? null : <NewPublicToken />;
      break;

    case "extrato":
      content = permissoes.includes(USER_PERMISSIONS.FINANCE_STATEMENT) ? (
        <AccountStatement />
      ) : null;
      break;

    case "exportacoes-solicitadas":
      content = permissoes.includes(USER_PERMISSIONS.FINANCE_STATEMENT) ? (
        <SolicitedExportations />
      ) : null;
      break;

    case "lancamentos":
      content = permissoes.includes(
        USER_PERMISSIONS.FINANCE_FUTURE_RELEASES
      ) ? (
        <Lancamentos />
      ) : null;
      break;

    case "assinaturas":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_SUBSCRIPTION) ? (
        <AccountSubscriptions />
      ) : null;
      break;

    case "planos-de-assinaturas":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_SUBSCRIPTION) ? (
        <SubscriptionPlans />
      ) : null;
      break;

    case "nova-assinatura":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_SUBSCRIPTION) ? (
        <NewAccountSubscriptions />
      ) : null;
      break;

    case "criar-conta-digital":
      content = permissoes.includes(USER_PERMISSIONS.OTHER_DIGITAL_ACCOUNTS) ? (
        <AccountRegistration />
      ) : null;
      break;

    case "contas-digitais":
      switch (id) {
        default:
          content = permissoes.includes(
            USER_PERMISSIONS.OTHER_DIGITAL_ACCOUNTS
          ) ? (
            <DigitalAccounts />
          ) : null;
          switch (subsection) {
            case "editar":
              content = permissoes.includes(
                USER_PERMISSIONS.OTHER_DIGITAL_ACCOUNTS
              ) ? (
                <EditDigitalAccount />
              ) : null;
              break;
            default:
          }
      }
      break;

    case "credito":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_CARD) ? (
        <CreditCard />
      ) : null;
      break;

    case "cobrancas-credito":
      content =
        isAdm || permissoes.includes(USER_PERMISSIONS.BILLING_CARD) ? (
          <CreditCardBillingList />
        ) : null;
      break;

    case "listar-terminais-pos":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_CARD) ? (
        <PosList />
      ) : null;
      break;

    case "show-terminal-pos":
      switch (id) {
        default:
          content =
            isAdm ||
            permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ||
            permissoes.includes(USER_PERMISSIONS.BILLING_CARD) ? (
              <PosDetail />
            ) : null;
      }
      break;

    case "venda-digitada":
      switch (id) {
        default:
          content = permissoes.includes(USER_PERMISSIONS.BILLING_CARD) ? (
            <NewTypedSale />
          ) : null;
          switch (subsection) {
            default:
              content = permissoes.includes(USER_PERMISSIONS.BILLING_CARD) ? (
                <NewTypedSale />
              ) : null;
          }
      }
      break;

    case "pagadores":
      switch (id) {
        default:
          content = permissoes.includes(USER_PERMISSIONS.OTHER_PAYERS) ? (
            <Payers />
          ) : null;
          switch (subsection) {
            case "editar":
              content = permissoes.includes(USER_PERMISSIONS.OTHER_PAYERS) ? (
                <EditPayer />
              ) : null;
              break;
            default:
          }
      }
      break;

    case "novo-pagadores":
      content = permissoes.includes(USER_PERMISSIONS.OTHER_PAYERS) ? (
        <CreateNewPayer />
      ) : null;
      break;

    case "venda-digitada-simples":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_CARD) ? (
        <SimpleSale />
      ) : null;
      break;

    case "carne":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_CARNE) ? (
        <PaymentBooklet />
      ) : null;
      break;

    case "novo-carne":
      switch (id) {
        default:
          content = permissoes.includes(USER_PERMISSIONS.BILLING_CARNE) ? (
            <NewPaymentBooklet />
          ) : null;
          switch (subsection) {
            case "cobrar":
              content = permissoes.includes(USER_PERMISSIONS.BILLING_CARNE) ? (
                <PaymentBookletRegistration />
              ) : null;
              break;
            default:
          }
      }
      break;

    case "adm":
      content = isAgent || isAdministrativo ? <Adm /> : null;
      break;

    case "menu-opcoes":
      content = <OptionsCollection />;
      break;

    case "config-totp":
      content = <TotpOptions />;
      break;

    case "contas-criar":
      content =
        isAdm || permissoes.includes(ADM_PERMISSIONS.ACCOUNTS_MANAGEMENT) ? (
          <CreateNewAccount />
        ) : null;
      break;

    case "contas":
      switch (id) {
        default:
          content =
            isAdm ||
            permissoes.includes(ADM_PERMISSIONS.ACCOUNTS_MANAGEMENT) ? (
              <Accounts />
            ) : null;
          switch (subsection) {
            case "users":
              content =
                isAdm ||
                permissoes.includes(ADM_PERMISSIONS.USER_MANAGEMENT) ? (
                  <Users />
                ) : null;
              break;
            case "users-novo":
              content =
                isAdm ||
                permissoes.includes(ADM_PERMISSIONS.ACCOUNTS_MANAGEMENT) ? (
                  <NewUser />
                ) : null;
              break;
            case "listas":
              content =
                isAdm ||
                permissoes.includes(ADM_PERMISSIONS.ACCOUNTS_MANAGEMENT) ? (
                  <AdmLists />
                ) : null;
              break;
            case "editar":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.CREDENTIALS) ? (
                  <EditAccount />
                ) : null;
              break;
            case "historico-de-transacoes":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? (
                  <TransactionHistory />
                ) : null;
              break;
            case "extrato":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.STATEMENT) ? (
                  <AccountStatement />
                ) : null;
              break;
            case "historico-transferencia":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.STATEMENT) ? (
                  <TransferHistory />
                ) : null;
              break;
            case "pagadores":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? (
                  <Payers />
                ) : null;
              break;
            case "cobrancas-credito":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? (
                  <CreditCardBillingList />
                ) : null;
              break;
            case "boleto":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? (
                  <PaymentSlipList />
                ) : null;
              break;
            case "configuracao-splits-boletos":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? (
                  <SlipSplitsConfigIndex />
                ) : null;
              break;
            case "carne":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? (
                  <PaymentBooklet />
                ) : null;
              break;
            case "link-pagamento":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? (
                  <PaymentLink />
                ) : null;
              break;
            case "assinaturas":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? (
                  <AccountSubscriptions />
                ) : null;
              break;
            case "planos-de-assinaturas":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? (
                  <SubscriptionPlans />
                ) : null;
              break;
            case "exportacoes-solicitadas":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.STATEMENT) ? (
                  <SolicitedExportations />
                ) : null;
              break;
            case "listar-terminais-pos":
              content =
                isAdm || permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? (
                  <PosList />
                ) : null;
              break;
            case "tarifas-ec":
              content =
                isAdm ||
                permissoes.includes(ADM_PERMISSIONS.SALES_PLAN_MANAGEMENT) ? (
                  <ShowSalesPlanEcFees />
                ) : null;
              break;
            case "contas-digitais":
              content =
                isAdm ||
                permissoes.includes(ADM_PERMISSIONS.SALES_PLAN_MANAGEMENT) ? (
                  <DigitalAccounts />
                ) : null;
              break;

            case "arquivo-remessa":
              switch (id) {
                default:
                  content =
                    isAdm ||
                    permissoes.includes(ADM_PERMISSIONS.SHIPPING_FILE) ? (
                      <ArquivoRemessaIndex />
                    ) : null;
                  break;
              }
              break;

            case "arquivo-retorno":
              switch (id) {
                default:
                  content =
                    isAdm ||
                    permissoes.includes(ADM_PERMISSIONS.SHIPPING_FILE) ? (
                      <ArquivoRetornoIndex />
                    ) : null;
                  break;
              }
              break;

            default:
          }
      }
      break;

    case "users":
      switch (id) {
        default:
          switch (subsection) {
            case "permissoes":
              content =
                isAdm ||
                (permissoes.includes(ADM_PERMISSIONS.ACCOUNTS_MANAGEMENT) &&
                  permissoes.includes(ADM_PERMISSIONS.USER_MANAGEMENT)) ? (
                  <UserPermissions />
                ) : null;
              break;
            default:
          }
      }
      break;

    case "agent-users":
      switch (id) {
        default:
          switch (subsection) {
            case "permissoes":
              content =
                isAdm ||
                (permissoes.includes(ADM_PERMISSIONS.AGENT_MANAGEMENT) &&
                  permissoes.includes(ADM_PERMISSIONS.USER_MANAGEMENT)) ? (
                  <AgentUserPermissions />
                ) : null;
              break;
            default:
          }
      }
      break;

    case "my-users":
      switch (id) {
        default:
          content = permissoes.includes(USER_PERMISSIONS.OTHER_MY_USERS) ? (
            <MyUsers />
          ) : null;
          switch (subsection) {
            case "permissoes":
              content = permissoes.includes(USER_PERMISSIONS.OTHER_MY_USERS) ? (
                isAgent ? (
                  <AgentUserPermissions />
                ) : (
                  <UserPermissions />
                )
              ) : null;
              break;
            default:
          }
      }
      break;

    case "create-new-user":
      content =
        !isAdministrativo &&
        permissoes.includes(USER_PERMISSIONS.OTHER_MY_USERS) ? (
          <NewUser />
        ) : null;
      break;

    case "minhas-tarifas":
      content = isAdministrativo ? null : <ShowSalesPlanEcFees />;
      break;

    case "novo-plano":
      switch (id) {
        default:
          content = permissoes.includes(
            USER_PERMISSIONS.BILLING_SUBSCRIPTION
          ) ? (
            <NewSubscriptionPlans />
          ) : null;
          switch (subsection) {
            default:
              content = permissoes.includes(
                USER_PERMISSIONS.BILLING_SUBSCRIPTION
              ) ? (
                <NewSubscriptionPlans />
              ) : null;
          }
      }
      break;

    case "nova-transferencia":
      content = permissoes.includes(USER_PERMISSIONS.TRANSFER) ? (
        <MakeTransfer />
      ) : null;
      break;

    case "historico-transferencia":
      content = permissoes.includes(USER_PERMISSIONS.TRANSFER_HISTORY) ? (
        <TransferHistory />
      ) : null;
      break;

    case "detalhes-carne":
      switch (id) {
        default:
          content =
            isAdm ||
            permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ||
            permissoes.includes(USER_PERMISSIONS.BILLING_CARNE) ? (
              <PaymentBookletDetails />
            ) : null;
          break;
      }
      break;

    case "historico-de-transacoes":
      content = permissoes.includes(
        USER_PERMISSIONS.FINANCE_TRANSACTION_HISTORY
      ) ? (
        <TransactionHistory />
      ) : null;
      break;

    case "historico-de-recebiveis":
      content = permissoes.includes(
        USER_PERMISSIONS.FINANCE_TRANSACTION_HISTORY
      ) ? (
        <ReceivableHistory />
      ) : null;
      break;

    case "transacoes":
      content =
        isAdm || permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? (
          <AdmTransactionHistory />
        ) : null;
      break;

    case "recebiveis":
      content =
        isAdm || permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? (
          <ReceivableHistory />
        ) : null;
      break;

    case "transacoes-auditoria":
      switch (id) {
        default:
          content =
            permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) || isAdm ? (
              <TransactionAuditIndex />
            ) : null;
          switch (subsection) {
            case "detalhes":
              content =
                permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) || isAdm ? (
                  <TransactionAuditDetails />
                ) : null;
              break;

            default:
          }
      }
      break;

    case "detalhes-transacao":
      switch (id) {
        default:
          content =
            isAdm ||
            permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ||
            permissoes.includes(
              USER_PERMISSIONS.FINANCE_TRANSACTION_HISTORY
            ) ? (
              <TransactionDetails />
            ) : null;
          break;
      }
      break;

    case "editar-meu-representante":
      content = isAdministrativo ? null : <EditLoginAgent />;
      break;

    case "representantes":
      switch (id) {
        default:
          content =
            isAdm || permissoes.includes(ADM_PERMISSIONS.AGENT_MANAGEMENT) ? (
              <AgentList />
            ) : null;
          switch (subsection) {
            case "users":
              content =
                isAdm ||
                permissoes.includes(ADM_PERMISSIONS.AGENT_MANAGEMENT) ? (
                  <AgentUsers />
                ) : null;
              break;
            case "detalhes":
              content =
                isAdm ||
                permissoes.includes(ADM_PERMISSIONS.AGENT_MANAGEMENT) ? (
                  <AgentDetails />
                ) : null;
              break;
            case "editar":
              content =
                isAdm ||
                permissoes.includes(ADM_PERMISSIONS.AGENT_MANAGEMENT) ? (
                  <EditAgent />
                ) : null;
              break;
            case "planos-de-vendas":
              content =
                isAdm ||
                permissoes.includes(ADM_PERMISSIONS.AGENT_MANAGEMENT) ? (
                  <SalesPlanList />
                ) : null;
              break;

            default:
          }
      }
      break;

    case "lista-de-administradores":
      switch (id) {
        default:
          content =
            permissoes.includes(ADM_PERMISSIONS.ADMIN_MANAGEMENT) || isAdm ? (
              <ListaDeAdministradores />
            ) : null;
          switch (subsection) {
            case "permissoes":
              content =
                permissoes.includes(ADM_PERMISSIONS.ADMIN_MANAGEMENT) ||
                isAdm ? (
                  <AdmUserPermissions />
                ) : null;
              break;

            default:
          }
      }
      break;

    case "logs":
      switch (id) {
        default:
          content =
            permissoes.includes(ADM_PERMISSIONS.LOGS) || isAdm ? (
              <LogsPage />
            ) : null;

          break;
      }
      break;

    case "detailed-logs":
      switch (id) {
        default:
          content =
            permissoes.includes(ADM_PERMISSIONS.LOGS) || isAdm ? (
              <DetailedLogsPage />
            ) : null;

          break;
      }
      break;

    case "plano-vendas":
      switch (id) {
        default:
          content =
            isAdm ||
            permissoes.includes(ADM_PERMISSIONS.SALES_PLAN_MANAGEMENT) ? (
              <SalesPlanList />
            ) : null;
          switch (subsection) {
            case "detalhes":
              content =
                isAdm ||
                permissoes.includes(ADM_PERMISSIONS.SALES_PLAN_MANAGEMENT) ? (
                  <SalesPlanDetail />
                ) : null;
              break;

            default:
          }
      }
      break;

    case "plano-vendas-zoop":
      switch (id) {
        default:
          content = permissoes.includes(ADM_PERMISSIONS.INTTEGRAR) ? (
            <ZoopSalesPlanList />
          ) : null;
          switch (subsection) {
            case "detalhes":
              content = permissoes.includes(ADM_PERMISSIONS.INTTEGRAR) ? (
                <ZoopSalesPlanDetail />
              ) : null;
              break;

            default:
          }
      }
      break;

    case "detalhes-link":
      switch (id) {
        default:
          content =
            isAdm ||
            permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ||
            permissoes.includes(USER_PERMISSIONS.BILLING_PAYMENT_LINK) ? (
              <PaymentLinkDetails />
            ) : null;
          break;
      }
      break;

    case "configuracao-splits-boletos":
      content = permissoes.includes(USER_PERMISSIONS.BILLING_BOLETO) ? (
        <SlipSplitsConfigIndex />
      ) : null;
      break;

    default:
      content = null;
      break;
  }

  return (
    <Box className={classes.root}>
      <LoadingScreen
        isLoading={isLoadingUserData}
        style={{
          zIndex: "100",
        }}
      />
      {isLoadingUserData ? null : (
        <>
          {matches && (
            <Box className={classes.sideBarContainer}>
              <SideBar />
            </Box>
          )}
          <Box className={classes.contentAreaContainer}>
            {!matches && <DrawerSideBar />}
            {content}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Dashboard;
