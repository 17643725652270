export const uploadArquivoRemessaMutation = ({ file }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("file", file);
  return {
    url: `/arquivo-remessa/upload`,
    method: "post",
    data: bodyFormData,
  };
};

export const storeArquivoRemessaMutation = ({
  name,
  header,
  details,
  trailer,
}) => {
  return {
    url: `/arquivo-remessa`,
    method: "post",
    data: {
      name,
      header,
      details,
      trailer,
    },
  };
};

export const getArquivoRemessaQuery = ({
  nome,
  page,
  data_inicial,
  data_final,
  boleto_id,
  conta_id,
  numero_documento,
  item_remessa_id,
}) => ({
  url: `/arquivo-remessa`,
  method: "get",
  params: {
    nome,
    page,
    data_inicial,
    data_final,
    boleto_id,
    conta_id,
    numero_documento,
    item_remessa_id,
  },
});

export const showArquivoRemessaQuery = ({ id }) => ({
  url: `/arquivo-remessa/${id}`,
  method: "get",
});

export const getItemRemessaQuery = ({
  page,
  arquivo_remessa_id,
  pagador,
  numero_documento,
  boleto_id,
  id,
}) => ({
  url: `/item-remessa`,
  method: "get",
  params: {
    page,
    arquivo_remessa_id,
    pagador,
    numero_documento,
    boleto_id,
    id,
  },
});

export const exportArquivoRetornoMutation = ({
  name,
  data_inicial,
  data_final,
}) => ({
  url: `/arquivo-retorno/export`,
  method: "post",
  data: { name, data_inicial, data_final },
});

export const getArquivoRetornoQuery = ({
  page,
  data_inicial,
  data_final,
  conta_id,
}) => ({
  url: `/arquivo-retorno`,
  method: "get",
  params: { page, data_inicial, data_final, conta_id },
});

export const getPagadoresRemessaQuery = ({ page, arquivo_remessa_id }) => ({
  url: `/arquivo-remessa/${arquivo_remessa_id}/payers`,
  method: "get",
  params: { page, arquivo_remessa_id },
});

export const retryItemsRemessaMutation = ({ arquivo_remessa_id }) => ({
  url: `/arquivo-remessa/${arquivo_remessa_id}/retry-items`,
  method: "post",
});

export const sendArquivoRemessaByEmailMutation = ({ arquivo_remessa_id }) => ({
  url: `/arquivo-remessa/${arquivo_remessa_id}/send-by-email`,
  method: "post",
});

export const sendItemRemessaByEmailMutation = ({ item_remessa_id }) => ({
  url: `/item-remessa/${item_remessa_id}/send-by-email`,
  method: "post",
});

export const exportArquivoRetornoDaRemessaMutation = ({
  arquivo_remessa_id,
}) => ({
  url: `/arquivo-remessa/${arquivo_remessa_id}/export-return-file`,
  method: "post",
});
