import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	buttonGradient: {
		color: '#fff',
		borderRadius: '37px',
		background: theme.gradient.main,
	},
}));

const GradientButton = (props) => {
	const classes = useStyles();
	return (
		<Button {...props} className={classes.buttonGradient} variant="contained">
			{props.buttonText}
		</Button>
	);
};

export default GradientButton;
