import { api } from "./api";

export const cancelTransactionMutation = ({
  transactionId,
  isFullAmount,
  amount,
  voidRules,
}) => ({
  url: `/transacao/cancelar`,
  method: "post",
  data: {
    transaction_id: transactionId,
    is_full_amount: isFullAmount,
    amount,
    void_rules: voidRules || undefined,
  },
});

export const downloadReceiptLetter = async ({ id }) => {
  const { data } = await api.get(`/cancellation-receipts/${id}`);

  window.open(data.url, "_blank");
};

export const indexTransactionsForAuditQuery = ({ page, filters }) => ({
  url: `/auditoria/transacao`,
  method: "get",
  params: {
    page,
    ...filters,
  },
});

export const showCompleteTransactionQuery = ({ transaction_id }) => ({
  url: `/auditoria/transacao/${transaction_id}/show-all-info`,
  method: "get",
});

export const indexTransactionAuditsQuery = ({ transaction_id, filters }) => ({
  url: `/auditoria/transacao/${transaction_id}/index-audits`,
  method: "get",
  params: {
    ...filters,
  },
});

export const indexAllTransactionSplitsQuery = ({
  transaction_id,
  filters,
}) => ({
  url: `/auditoria/transacao/${transaction_id}/index-all-splits`,
  method: "get",
});

export const createMdrMutation = ({ transaction_id }) => ({
  url: `/auditoria/transacao/${transaction_id}/create-mdr`,
  method: "post",
});

export const retryMdrMutation = ({ transaction_id }) => ({
  url: `/auditoria/transacao/${transaction_id}/retry-mdr`,
  method: "post",
});
