import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { authMeAction } from '../../../actions/actions';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Paper, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { APP_CONFIG } from 'constants/config';
import { useAuthTotpMutation, useSendTotpMutation } from 'services/api';
import LoadingScreen from 'components/reusable/LoadingScreen/LoadingScreen';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		backgroundColor: 'white',
		margin: '0px',
		padding: '0px',
		background: theme.gradient.light,
	},
	logoArea: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: theme.palette.background.default,
		color: 'white',
	},
	contentArea: {
		display: 'flex',
		justifyContent: 'center',
		padding: '80px',
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '40px',
		borderRadius: '27px',
		animation: `$myEffect 1000ms ${theme.transitions.easing.easeInOut}`,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	'@keyframes myEffect': {
		'0%': {
			opacity: 0,
			transform: 'translateX(10%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateX(0)',
		},
	},
}));

export default function AuthTotp() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const [totpCode, setTotpCode] = useState('');
	const [err, setErr] = useState(false);
  const [loadding, setLoading] = useState(false);
  const [authTotp] = useAuthTotpMutation();
  const [sendTotp] = useSendTotpMutation();

	const handleAuthTotp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const authenticatedTotp = await authTotp({ totpCode }).unwrap();
      if(authenticatedTotp.success === true) {
        const authMe = await dispatch(authMeAction());
        if (authMe.conta_id === null) {
          history.push('/dashboard/adm');
        } else {
          history.push('/dashboard/home');
        }
      }
      
    } catch (e) {
      setErr(true);
      if(e.data.success === false) {
        toast.error('Código de verificação invalido!');
      } else {
        toast.error("Erro ao verificar!");
      }
    } finally {
      setLoading(false);
    }
	};

  const handleSendTotp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await sendTotp().unwrap();
      toast.success('Código enviado com sucesso!');
  
    } catch (e) {
      toast.error("Erro ao enviar email!");
    }
    setLoading(false);
	};

	return (
		<>
			<Box className={classes.root}>
      <LoadingScreen isLoading={loadding} />
        <Box width="100vw" height="100vh">
          <Box className={classes.logoArea}>
            <Box margin="20px">
              <img
                style={{ width: '250px', justifySelf: 'flex-start' }}
                src={APP_CONFIG().assets.primaryLogo}
                alt={`${APP_CONFIG().name} logo`}
              />
            </Box>
          </Box>
          <Box className={classes.contentArea}>
            <Paper className={classes.paper}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h4">Autentificação de dois fatores - 2FA</Typography>
              </Box>
              <form onSubmit={(e) => handleAuthTotp(e)} className={classes.form}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="totp_code"
                  label="Digite seu código de verificação"
                  name="totpCode"
                  autoFocus
                  value={totpCode}
                  onChange={(e) => setTotpCode(e.target.value)}
                  required
                  error={err}
                />
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  type="submit"
                  style={{
                    borderRadius: '27px',
                    background: theme.gradient.main,
                  }}
                >
                  Verificar
                </Button>
              </form>
              <Box display="flex" flexDirection="column">
                <Typography variant="body2">Deseja receber o código via e-mail?</Typography>
                <Button
                  size="small"
                  component={Link}
                  onClick={handleSendTotp}
                  style={{ height: '28px', borderRadius: '27px' }}
                >
                  Enviar
                </Button>
              </Box>
            </Paper>
          </Box>
        </Box>
			</Box>
		</>
	);
}
