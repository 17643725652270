import {
  Avatar,
  Box,
  Button,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  loadLinkPagamentoId,
  postSenhaLinkAction,
} from "../../../../actions/actions";
import PaymentLinkPayInfos from "./PaymentLinkPaySteps/PaymentLinkPayInfos/PaymentLinkPayInfos";
import PaymentLinkPaySteps from "./PaymentLinkPaySteps/PaymentLinkPaySteps";
import GradientButton from "../../../reusable/GradientButton/GradientButton";
import { Lock } from "@material-ui/icons";
import { APP_CONFIG } from "constants/config";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import { setAgentTheme } from "services/functions/setAgentTheme";
import { resetAgentTheme } from "services/functions/resetAgentTheme";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: "100vw",
    minHeight: "100vh",
    background: theme.gradient.light,
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
  },
  creditCardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "16px 0 16px 0",
  },
  buttonContainer: {
    margin: "24px 6px 6px 0px",
    display: "flex",
    alignSelf: "center",
  },
  contentArea: {
    padding: theme.spacing(3),
    alignSelf: "center",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
}));

const PaymentLinkPay = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const linkPagamentoId = useSelector((state) => state.linkPagamentoId);
  const isLoadingLink = useMemo(() => !linkPagamentoId.id, [linkPagamentoId]);
  const agentDetails = useMemo(
    () => linkPagamentoId?.conta?.agent,
    [linkPagamentoId]
  );

  useEffect(() => {
    if (!isLoadingLink) {
      if (linkPagamentoId.conta.agent_id && agentDetails.id) {
        resetAgentTheme({
          justColors: false,
        });
        setAgentTheme({
          agent: agentDetails,
        });
        setLoading(false);
      }
      if (!linkPagamentoId.conta.agent_id) {
        setLoading(false);
      }
    }
  }, [isLoadingLink, linkPagamentoId, agentDetails]);

  useEffect(() => {
    dispatch(loadLinkPagamentoId(id));
  }, [dispatch, id]);

  const [senha, setSenha] = useState("");
  const [verificador, setVerificador] = useState(false);

  const handleVerificar = async () => {
    setLoading(true);
    const res = await dispatch(postSenhaLinkAction(senha, id));
    if (res) {
      setVerificador(true);
    }
    setLoading(false);
  };

  const component = () => {
    if (linkPagamentoId.status_string === "Usado") {
      return (
        <Box className={classes.root}>
          <Typography variant="h2">Erro - Link não disponível</Typography>
          <Button
            variant="outlined"
            onClick={() => history.push("/dashboard/home")}
          >
            Voltar
          </Button>
        </Box>
      );
    }
    if (linkPagamentoId.status_string === "Expirado") {
      return (
        <Box className={classes.root}>
          <Typography variant="h2">Erro - Link expirado</Typography>
          <Button
            variant="outlined"
            onClick={() => history.push("/dashboard/home")}
          >
            Voltar
          </Button>
        </Box>
      );
    }
    if (linkPagamentoId.privado === true && verificador === false) {
      return (
        <Box className={classes.root}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="24px"
          >
            <Box>
              <img
                width="150px"
                src={APP_CONFIG().assets.secondaryLogo}
                alt="logo"
              />
            </Box>
            <Paper
              style={{
                padding: "18px",
                borderRadius: "27px",
              }}
            >
              <Box
                display="flex"
                width="100%"
                flexDirection="column"
                alignItems="center"
              >
                <Avatar style={{ backgroundColor: "white" }}>
                  <Lock />
                </Avatar>
                <Typography variant="h6">Link privado</Typography>
                <Typography variant="body1">
                  Digite a senha cadastrada para ter acesso
                </Typography>
                <Box display="flex">
                  <Box marginTop="6px">
                    <TextField
                      fullWidth
                      label="Senha"
                      value={senha}
                      onChange={(e) => setSenha(e.target.value)}
                      type="password"
                    />
                  </Box>
                </Box>
                <GradientButton
                  style={{
                    marginTop: "18px",
                    alignSelf: "flex-end",
                  }}
                  buttonText="Verificar"
                  onClick={handleVerificar}
                />
              </Box>
            </Paper>
          </Box>
        </Box>
      );
    }

    return (
      <Box className={classes.root}>
        <Box className={classes.cardContainer}>
          <Box className={classes.contentArea}>
            <PaymentLinkPaySteps
              id={id}
              linkPagamentoId={linkPagamentoId}
              pagadorId={linkPagamentoId.pagador_id}
            />
            <PaymentLinkPayInfos linkPagamentoId={linkPagamentoId} />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      {loading || isLoadingLink ? (
        <LoadingScreen isLoading={loading || isLoadingLink} />
      ) : (
        component
      )}
    </Box>
  );
};

export default PaymentLinkPay;
