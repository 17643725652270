import {
  Box,
  Button,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import TransactionStatusColumn from "components/reusable/TableColumns/TransactionStatusColumn";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { formatMoney } from "utils/money";
import { loadCobrancasCartaoFilters } from "../../../actions/actions";
import useDebounce from "../../../hooks/useDebounce";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "../../reusable/CustomTable/CustomTable";
import GradientButton from "../../reusable/GradientButton/GradientButton";
import SearchBar from "../../reusable/SearchBar/SearchBar";
import CreditCardCaptureModal from "./CreditCardCaptureModal/CreditCardCaptureModal";
import TransactionValidationColumn from "components/reusable/TableColumns/TransactionValidationColumn";

const columns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (created_at) => {
      return <DateTimeColumn dateTime={created_at} />
    },
  },
  {
    headerText: "Situação",
    key: "transacao",
    CustomValue: (transacao) => {
      return <TransactionStatusColumn status={transacao.transaction.status} />
    },
  },
  {
    headerText: "Validação",
    key: "transacao",
    CustomValue: (transacao) => {
      return <TransactionValidationColumn transaction={transacao.transaction} />
    }
  },
  {
    headerText: "Pagador",
    key: "pagador",
    CustomValue: (pagador) => {
      return (
        <>
          <Typography>
            {pagador ? pagador.nome : "Sem pagador específico"}
          </Typography>
          <Typography>{pagador ? pagador.documento : null}</Typography>
        </>
      );
    },
  },
  {
    headerText: "Valor da taxa",
    key: "custom_valor_taxa",
    FullCustom: (row) => {
      const { fees } = row.transacao.transaction;
      const { mdr_fee_values } = row.transacao;
      const total = (parseFloat(fees) + parseFloat(mdr_fee_values)).toFixed(2);

      if (total > 0) {
        return (
          <Typography
            style={{ fontSize: 16, color: "#dfad06", fontWeight: 600 }}
          >
            {formatMoney(total)}
          </Typography>
        );
      } else {
        return (
          <Typography
            style={{ fontSize: 16, color: "	green", fontWeight: 600 }}
          >
            {formatMoney(total)}
          </Typography>
        );
      }
    },
  },

  {
    headerText: "Valor Líquido",
    key: "custom_valor_liquido",
    FullCustom: (row) => {
      const { fees, amount } = row.transacao.transaction;
      const { mdr_fee_values } = row.transacao;
      const total_fees = (parseFloat(fees) + parseFloat(mdr_fee_values)).toFixed(2);
      const valorLiquido = (amount - total_fees).toFixed(2);
      return (
        <Typography
          style={{ fontSize: 17, fontWeight: 600, color: "green" }}
        >
          {formatMoney(valorLiquido)}
        </Typography>
      )
    },
  },

  {
    headerText: "Ações",
    key: "menu",
  },
];

const CreditCardBillingList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();

  const [page, setPage] = useState(1);

  const handleNovaCobrancaCartao = () => {
    history.push("/dashboard/credito");
  };

  const [filters, setFilters] = useState({
    like: "",
    order: "",
    mostrar: "",
  });
  const debouncedLike = useDebounce(filters.like, 800);

  const cobrancaCartaoList = useSelector((state) => state.cobrancaCartaoList);

  useEffect(() => {
    dispatch(
      loadCobrancasCartaoFilters(
        page,
        debouncedLike,
        filters.order,
        filters.mostrar,
        id ?? userData.id
      )
    );
  }, [dispatch, page, filters.order, filters.mostrar, debouncedLike, id, userData.id]);

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const Editar = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Box>
          <>
            <Button
              style={{ height: "15px", width: "10px" }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              ...
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>
                <Link
                  style={{
                    color: theme.palette.text.primary,
                  }}
                  to={`/dashboard/detalhes-transacao/${row.transaction_id}/ver`}
                >
                  Ver detalhes
                </Link>
              </MenuItem>
              {id ? null : (
                <MenuItem
                  onClick={() => {
                    setOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  Capturar Valor
                </MenuItem>
              )}
            </Menu>
            {open ? (
              <CreditCardCaptureModal
                row={row}
                open={open}
                onClose={() => setOpen(false)}
              />
            ) : null}
          </>
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      {id ? (
        <CustomBreadcrumbs
          path1="Gerenciar Listas"
          to1="goBack"
          path2="Lista de Máquinas Virtuais"
          to2="#"
        />
      ) : (
        <CustomBreadcrumbs path1="Lista de Máquinas Virtuais" to1="#" />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Typography style={{ marginTop: "8px" }} variant="h4">
          Lista de Máquinas Virtuais
        </Typography>
        {id ? null : (
          <Box>
            <GradientButton
              buttonText="+ Nova Cobrança"
              onClick={handleNovaCobrancaCartao}
            />
          </Box>
        )}
      </Box>
      <Box marginTop="16px" marginBottom="16px">
        <SearchBar
          fullWidth
          placeholder="Pesquisar por nome, documento..."
          value={filters.like}
          onChange={(e) =>
            setFilters({
              ...filters,
              like: e.target.value,
            })
          }
        />
      </Box>
      {cobrancaCartaoList.data && cobrancaCartaoList.per_page ? (
        <CustomTable
          columns={columns}
          data={cobrancaCartaoList.data}
          Editar={Editar}
        />
      ) : (
        <LinearProgress />
      )}

      <Box alignSelf="flex-end" marginTop="8px">
        <Pagination
          variant="outlined"
          color="secondary"
          size="large"
          count={cobrancaCartaoList.last_page}
          onChange={handleChangePage}
          page={page}
        />
      </Box>
    </Box>
  );
};

export default CreditCardBillingList;
