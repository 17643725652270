import {
  Box,
  LinearProgress,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Button,
  IconButton,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { DeleteOutlined } from "@material-ui/icons";
import { useHistory, useParams } from "react-router";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useIndexSlipSplitConfigQuery } from "services/api";
import { toast } from "react-toastify";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import { useSelector } from "react-redux";
import DialogCreateSplit from "./DialogCreateSplit";
import DialogDeleteSplit from "./DialogDeleteSplit";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

const columns = [
  {
    headerText: "ID Conta repartida",
    key: "receiver",
    CustomValue: (receiver) => {
      return (
        <Box>
          <Typography>{receiver?.razao_social ?? receiver?.nome}</Typography>
          <Typography>{receiver?.cnpj ?? receiver?.documento}</Typography>
        </Box>
      );
    },
  },
  {
    headerText: "Porcentagem",
    key: "porcentagem",
    CustomValue: (porcentagem) => {
      return <Typography>{porcentagem.replace(".", ",")} %</Typography>;
    },
  },
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (created_at) => {
      return <DateTimeColumn dateTime={created_at} />;
    },
  },
];

const SlipSplitsConfigIndex = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const userData = useSelector((state) => state.userData);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedSplitConfigId, setSelectedSplitConfigId] = useState(null);
  const [openSplitModal, setOpenSplitModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(1);

  const accountId = useMemo(() => id ?? userData.id, [id, userData]);
  const isAdm = useMemo(() => id !== undefined, [id]);

  const {
    data: slipSplitConfigs,
    isLoading,
    isError,
    isUninitialized,
  } = useIndexSlipSplitConfigQuery(
    {
      page,
      conta_id: accountId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleOpenDeleteDialog = (id) => {
    setSelectedSplitConfigId(id);
    setOpenDeleteDialog(true);
  };

  const columnWithActions = [
    ...columns,
    {
      headerText: "",
      key: "id",
      CustomValue: (id) => {
        return (
          <Box>
            <IconButton onClick={() => handleOpenDeleteDialog(id)}>
              <DeleteOutlined />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (isError) {
      toast.error("O usuário não está autenticado a entrar nessa página!");
      history.push("/dashboard/home");
    }
  }, [isError, history]);

  return (
    <Box className={classes.root}>
      <CustomBreadcrumbs path1="Configurações de split para boletos" to1="#" />
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            style={{ color: theme.palette.background.default }}
          >
            CONFIGURAÇÕES DE SPLIT PARA BOLETOS
          </Typography>
        </Box>

        {isAdm ? null : (
          <Button
            variant="outlined"
            style={{
              marginTop: "8px",
              marginBottom: "12px",
              marginLeft: matches ? null : "10px",
            }}
            onClick={() => setOpenSplitModal(true)}
          >
            CRIAR NOVA CONFIGURAÇÃO
          </Button>
        )}
      </Box>

      <DialogCreateSplit
        open={openSplitModal}
        onClose={() => setOpenSplitModal(false)}
        accountId={accountId}
      />

      <DialogDeleteSplit
        open={openDeleteDialog}
        onClose={setOpenDeleteDialog}
        splitConfigId={selectedSplitConfigId}
      />
      <>
        {!isLoading && !isError && !isUninitialized ? (
          <>
            <CustomTable
              columns={isAdm ? columns : columnWithActions}
              data={slipSplitConfigs.data}
            />
            <Box alignSelf="flex-end" marginTop="8px">
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={slipSplitConfigs.last_page}
                onChange={handleChangePage}
                page={page}
              />
            </Box>
          </>
        ) : (
          <LinearProgress />
        )}
      </>
    </Box>
  );
};

export default SlipSplitsConfigIndex;
