import { createApi } from "@reduxjs/toolkit/query/react";
import Axios from "axios";
import { getAuthToken, setAuthToken } from "utils/token";
import { solicitedExportationsQuery } from "./solicitedExportations";
import {
  cancelTransactionMutation,
  createMdrMutation,
  indexAllTransactionSplitsQuery,
  indexTransactionAuditsQuery,
  indexTransactionsForAuditQuery,
  retryMdrMutation,
  showCompleteTransactionQuery,
} from "./transaction";
import {
  createSplitMutation,
  deleteSplitMutation,
  recreateSplitsFromConfigMutation,
  updateSplitMutation,
} from "./split";
import {
  indexPosQuery,
  showPosQuery,
  posTransactionsQuery,
  storePosMutation,
  deletePosMutation,
  updatePosMutation,
} from "./pos";
import {
  accountsBarChartQuery,
  accountsLineChartQuery,
  accountsPreviewQuery,
  indexAllAccountsQuery,
  reactivateAccountsAccountMutation,
  showAccountByDocumentQuery,
  updateAccountLimitBoletoMutation,
} from "./account";
import {
  indexAdmQuery,
  indexAgentUsersQuery,
  storeAdmMutation,
  storeAgentUserMutation,
} from "./user";
import {
  createDefaultSalesPlanFeesMutation,
  deleteSalesPlanMutation,
  indexMyEcsSalesPlanQuery,
  indexSalesPlanQuery,
  indexZoopSalesPlanQuery,
  setDefaltSalesPlanAppAccountMutation,
  setDefaultSalesPlanMutation,
  showDefaltSalesPlanAppAccountQuery,
  showSalesPlanQuery,
  showZoopSalesPlanQuery,
  storeAgentSalesPlanMutation,
  storeZoopSalesPlanMutation,
  updateSalesPlanFeesMutation,
} from "./salesPlan";
import {
  deleteSalesPlanSubscriptionMutation,
  indexSalesPlanSubscriptionQuery,
  showMySalesPlanSubscriptionFeesQuery,
  showMySalesPlanSubscriptionQuery,
  showSalesPlanSubscriptionQuery,
  storeSalesPlanSubscriptionMutation,
} from "./salesPlanSubscription";
import { getDetailedLogsQuery, getLogsQuery } from "./logs";
import {
  activeTotpMutation,
  authTotpMutation,
  getNewTotpMutation,
  removeTotpMutation,
  sendTotpMutation,
} from "./authTotp";
import {
  addEcToAgentMutation,
  deleteAgentLogoMutation,
  deleteAgentMutation,
  importAgentMutation,
  indexAgentEcsQuery,
  indexAgentQuery,
  removeEcFromAgentMutation,
  showAgentQuery,
  storeAgentLogoMutation,
  storeAgentMutation,
  updateAgentMutation,
  updateAgentThemeColorsMutation,
} from "./agent";
import { onUpdateSalesPlanFee, updateFeeMutation } from "./salesPlanFee";
import { deletePaymentLinkMutation } from "./paymentLink";
import { indexReceivablesQuery } from "./receivables";
import { getLinkSplitConfigsQuery } from "./linkSplitConfig";
import {
  storeArquivoRemessaMutation,
  uploadArquivoRemessaMutation,
  getArquivoRemessaQuery,
  showArquivoRemessaQuery,
  getItemRemessaQuery,
  getArquivoRetornoQuery,
  getPagadoresRemessaQuery,
  exportArquivoRetornoDaRemessaMutation,
  exportArquivoRetornoMutation,
  retryItemsRemessaMutation,
  sendItemRemessaByEmailMutation,
  sendArquivoRemessaByEmailMutation,
} from "./cnba400";
import {
  deleteSlipSplitConfigMutation,
  getSlipSplitConfigQuery,
  storeSlipSplitConfigMutation,
} from "./SplitConfiguration/slip";
import { cancelMultipleBillsMutation } from "./bills";

const BASE_URL = process.env.REACT_APP_API_URL;

export const api = Axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use((config) => {
  const token = getAuthToken();

  if (token) {
    return {
      ...config,
      headers: {
        ...(config.headers || {}),
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };
  }

  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Accept: "application/json",
    },
  };
});

api.interceptors.response.use(
  function (response) {
    const authToken = response.headers.authorization;

    if (authToken) {
      setAuthToken(authToken.split("Bearer ")[1]);
    }

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const baseQuery = async ({ url, method, data, params }) => {
  try {
    const result = await api({ url: BASE_URL + url, method, data, params });
    return { data: result.data };
  } catch (axiosError) {
    let err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery,
  tagTypes: ["SlitSplitConfig"],
  endpoints: (builder) => ({
    indexTransactionsForAudit: builder.query({
      query: indexTransactionsForAuditQuery,
    }),
    showCompleteTransaction: builder.query({
      query: showCompleteTransactionQuery,
    }),
    indexTransactionAudits: builder.query({
      query: indexTransactionAuditsQuery,
    }),
    indexAllTransactionSplits: builder.query({
      query: indexAllTransactionSplitsQuery,
    }),
    cancelTransaction: builder.mutation({
      query: cancelTransactionMutation,
    }),
    createMdr: builder.mutation({
      query: createMdrMutation,
    }),
    retryMdr: builder.mutation({
      query: retryMdrMutation,
    }),

    indexReceivables: builder.query({
      query: indexReceivablesQuery,
    }),
    solicitedExportations: builder.query({
      query: solicitedExportationsQuery,
    }),
    createSplit: builder.mutation({
      query: createSplitMutation,
    }),
    recreateSplitsFromConfig: builder.mutation({
      query: recreateSplitsFromConfigMutation,
    }),
    deleteSplit: builder.mutation({
      query: deleteSplitMutation,
    }),
    updateSplit: builder.mutation({
      query: updateSplitMutation,
    }),
    indexPos: builder.query({
      query: indexPosQuery,
    }),
    showPos: builder.query({
      query: showPosQuery,
    }),
    posTransactions: builder.query({
      query: posTransactionsQuery,
    }),
    storePos: builder.mutation({
      query: storePosMutation,
    }),
    updatePos: builder.mutation({
      query: updatePosMutation,
    }),
    deletePos: builder.mutation({
      query: deletePosMutation,
    }),

    updateAccountLimitBoleto: builder.mutation({
      query: updateAccountLimitBoletoMutation,
    }),
    reactivateAccountsAccount: builder.mutation({
      query: reactivateAccountsAccountMutation,
    }),
    indexAllAccounts: builder.query({
      query: indexAllAccountsQuery,
    }),
    showAccountByDocument: builder.query({
      query: showAccountByDocumentQuery,
    }),
    accountsPreview: builder.query({
      query: accountsPreviewQuery,
    }),
    accountsLineChart: builder.query({
      query: accountsLineChartQuery,
    }),
    accountsBarChart: builder.query({
      query: accountsBarChartQuery,
    }),

    storeAdm: builder.mutation({
      query: storeAdmMutation,
    }),
    indexAdm: builder.query({
      query: indexAdmQuery,
    }),
    storeAgentUser: builder.mutation({
      query: storeAgentUserMutation,
    }),
    indexAgentUsers: builder.query({
      query: indexAgentUsersQuery,
    }),

    showDefaltSalesPlanAppAccount: builder.query({
      query: showDefaltSalesPlanAppAccountQuery,
    }),
    setDefaltSalesPlanAppAccount: builder.mutation({
      query: setDefaltSalesPlanAppAccountMutation,
    }),
    indexSalesPlan: builder.query({
      query: indexSalesPlanQuery,
    }),
    showSalesPlan: builder.query({
      query: showSalesPlanQuery,
      // transformResponse: transformResponseShowSalesPlan,
    }),
    indexZoopSalesPlan: builder.query({
      query: indexZoopSalesPlanQuery,
    }),
    showZoopSalesPlan: builder.query({
      query: showZoopSalesPlanQuery,
    }),
    storeAgentSalesPlan: builder.mutation({
      query: storeAgentSalesPlanMutation,
    }),
    storeZoopSalesPlan: builder.mutation({
      query: storeZoopSalesPlanMutation,
    }),
    createDefaultSalesPlanFees: builder.mutation({
      query: createDefaultSalesPlanFeesMutation,
    }),
    setDefaultSalesPlan: builder.mutation({
      query: setDefaultSalesPlanMutation,
    }),
    deleteSalesPlan: builder.mutation({
      query: deleteSalesPlanMutation,
    }),
    indexMyEcsSalesPlan: builder.query({
      query: indexMyEcsSalesPlanQuery,
    }),
    updateSalesPlan: builder.mutation({
      query: updateSalesPlanFeesMutation,
    }),

    updateFee: builder.mutation({
      query: updateFeeMutation,
      onQueryStarted: (...args) => onUpdateSalesPlanFee(baseApi, ...args),
    }),

    indexSalesPlanSubscription: builder.query({
      query: indexSalesPlanSubscriptionQuery,
    }),
    showSalesPlanSubscription: builder.query({
      query: showSalesPlanSubscriptionQuery,
    }),
    showMySalesPlanSubscription: builder.query({
      query: showMySalesPlanSubscriptionQuery,
    }),
    showMySalesPlanSubscriptionFees: builder.query({
      query: showMySalesPlanSubscriptionFeesQuery,
    }),
    storeSalesPlanSubscription: builder.mutation({
      query: storeSalesPlanSubscriptionMutation,
    }),
    deleteSalesPlanSubscription: builder.mutation({
      query: deleteSalesPlanSubscriptionMutation,
    }),

    getLogs: builder.query({
      query: getLogsQuery,
    }),
    getDetailedLogs: builder.query({
      query: getDetailedLogsQuery,
    }),

    deletePaymentLink: builder.mutation({
      query: deletePaymentLinkMutation,
    }),

    getNewTotp: builder.mutation({
      query: getNewTotpMutation,
    }),
    activeTotp: builder.mutation({
      query: activeTotpMutation,
    }),
    sendTotp: builder.mutation({
      query: sendTotpMutation,
    }),
    authTotp: builder.mutation({
      query: authTotpMutation,
    }),
    removeTotp: builder.mutation({
      query: removeTotpMutation,
    }),

    indexAgent: builder.query({
      query: indexAgentQuery,
    }),
    indexAgentEcs: builder.query({
      query: indexAgentEcsQuery,
    }),
    showAgent: builder.query({
      query: showAgentQuery,
    }),
    storeAgent: builder.mutation({
      query: storeAgentMutation,
    }),
    importAgent: builder.mutation({
      query: importAgentMutation,
    }),
    updateAgent: builder.mutation({
      query: updateAgentMutation,
    }),
    deleteAgent: builder.mutation({
      query: deleteAgentMutation,
    }),
    addEcToAgent: builder.mutation({
      query: addEcToAgentMutation,
    }),
    removeEcFromAgent: builder.mutation({
      query: removeEcFromAgentMutation,
    }),
    storeAgentLogo: builder.mutation({
      query: storeAgentLogoMutation,
    }),
    deleteAgentLogo: builder.mutation({
      query: deleteAgentLogoMutation,
    }),
    updateAgentThemeColors: builder.mutation({
      query: updateAgentThemeColorsMutation,
    }),
    getLinkSplitConfigs: builder.query({
      query: getLinkSplitConfigsQuery,
    }),
    uploadArquivoRemessa: builder.mutation({
      query: uploadArquivoRemessaMutation,
    }),
    storeArquivoRemessa: builder.mutation({
      query: storeArquivoRemessaMutation,
    }),
    indexArquivoRemessa: builder.query({
      query: getArquivoRemessaQuery,
    }),
    showArquivoRemessa: builder.query({
      query: showArquivoRemessaQuery,
    }),
    indexPagadoresRemessa: builder.query({
      query: getPagadoresRemessaQuery,
    }),
    indexItemRemessa: builder.query({
      query: getItemRemessaQuery,
    }),
    retryItemsRemessa: builder.mutation({
      query: retryItemsRemessaMutation,
    }),
    sendArquivoRemessaByEmail: builder.mutation({
      query: sendArquivoRemessaByEmailMutation,
    }),
    sendItemRemessaByEmail: builder.mutation({
      query: sendItemRemessaByEmailMutation,
    }),
    indexArquivoRetorno: builder.query({
      query: getArquivoRetornoQuery,
    }),
    exportArquivoRetorno: builder.mutation({
      query: exportArquivoRetornoMutation,
    }),
    exportArquivoRetornoDaRemessa: builder.mutation({
      query: exportArquivoRetornoDaRemessaMutation,
    }),

    indexSlipSplitConfig: builder.query({
      query: getSlipSplitConfigQuery,
      providesTags: ["SlitSplitConfig"],
    }),
    storeSlipSplitConfig: builder.mutation({
      query: storeSlipSplitConfigMutation,
      invalidatesTags: ["SlitSplitConfig"],
    }),
    deleteSlipSplitConfig: builder.mutation({
      query: deleteSlipSplitConfigMutation,
      invalidatesTags: ["SlitSplitConfig"],
    }),
    cancelMultipleBills: builder.mutation({
      query: cancelMultipleBillsMutation,
    }),
  }),
});

export const {
  useIndexTransactionsForAuditQuery,
  useShowCompleteTransactionQuery,
  useIndexTransactionAuditsQuery,
  useIndexAllTransactionSplitsQuery,
  useCancelTransactionMutation,
  useCreateMdrMutation,
  useRetryMdrMutation,
  useSolicitedExportationsQuery,
  useIndexReceivablesQuery,

  useCreateSplitMutation,
  useRecreateSplitsFromConfigMutation,
  useDeleteSplitMutation,
  useUpdateSplitMutation,

  useIndexPosQuery,
  useShowPosQuery,
  usePosTransactionsQuery,
  useStorePosMutation,
  useUpdatePosMutation,
  useDeletePosMutation,

  useUpdateAccountLimitBoletoMutation,
  useReactivateAccountsAccountMutation,
  useAccountsPreviewQuery,
  useAccountsLineChartQuery,
  useAccountsBarChartQuery,
  useIndexAllAccountsQuery,
  useShowAccountByDocumentQuery,

  useStoreAdmMutation,
  useIndexAdmQuery,
  useStoreAgentUserMutation,
  useIndexAgentUsersQuery,

  useShowDefaltSalesPlanAppAccountQuery,
  useSetDefaltSalesPlanAppAccountMutation,
  useIndexSalesPlanQuery,
  useShowSalesPlanQuery,
  useIndexZoopSalesPlanQuery,
  useShowZoopSalesPlanQuery,
  useStoreAgentSalesPlanMutation,
  useImportAgentMutation,
  useStoreZoopSalesPlanMutation,
  useCreateDefaultSalesPlanFeesMutation,
  useSetDefaultSalesPlanMutation,
  useDeleteSalesPlanMutation,
  useIndexMyEcsSalesPlanQuery,
  useUpdateSalesPlanMutation,

  useUpdateFeeMutation,

  useIndexSalesPlanSubscriptionQuery,
  useShowSalesPlanSubscriptionQuery,
  useShowMySalesPlanSubscriptionQuery,
  useShowMySalesPlanSubscriptionFeesQuery,
  useStoreSalesPlanSubscriptionMutation,
  useDeleteSalesPlanSubscriptionMutation,

  useGetLogsQuery,
  useGetDetailedLogsQuery,

  useDeletePaymentLinkMutation,

  useGetNewTotpMutation,
  useActiveTotpMutation,
  useSendTotpMutation,
  useAuthTotpMutation,
  useRemoveTotpMutation,

  useIndexAgentQuery,
  useIndexAgentEcsQuery,
  useShowAgentQuery,
  useStoreAgentMutation,
  useUpdateAgentMutation,
  useDeleteAgentMutation,
  useAddEcToAgentMutation,
  useRemoveEcFromAgentMutation,
  useStoreAgentLogoMutation,
  useDeleteAgentLogoMutation,
  useUpdateAgentThemeColorsMutation,

  useGetLinkSplitConfigsQuery,

  useUploadArquivoRemessaMutation,
  useStoreArquivoRemessaMutation,
  useIndexArquivoRemessaQuery,
  useShowArquivoRemessaQuery,
  useLazyShowArquivoRemessaQuery,
  useIndexItemRemessaQuery,
  useExportArquivoRetornoMutation,
  useExportArquivoRetornoDaRemessaMutation,
  useIndexArquivoRetornoQuery,
  useIndexPagadoresRemessaQuery,
  useRetryItemsRemessaMutation,
  useSendArquivoRemessaByEmailMutation,
  useSendItemRemessaByEmailMutation,

  useIndexSlipSplitConfigQuery,
  useStoreSlipSplitConfigMutation,
  useDeleteSlipSplitConfigMutation,

  useCancelMultipleBillsMutation,
} = baseApi;
