import { Box, Typography } from "@material-ui/core";
import React from "react";

const PayerDetails = ({ payer }) => {
  return payer ? (
    <Box>
      <Typography variant="h6" align="center">
        Pagador
      </Typography>
      <Box>
        <Typography>Nome:</Typography>
        <Typography variant="h6">{payer?.nome ?? "-"}</Typography>
        <Typography>Documento:</Typography>
        <Typography variant="h6">{payer?.documento ?? "-"}</Typography>
        <Typography>E-mail:</Typography>
        <Typography variant="h6">{payer?.email ?? "-"}</Typography>
        <Typography>Contato:</Typography>
        <Typography variant="h6">{payer?.celular ?? "-"}</Typography>
      </Box>
    </Box>
  ) : (
    <Typography variant="h6" align="center">
      Sem pagador específico
    </Typography>
  );
};

export default PayerDetails;
