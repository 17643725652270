import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CurrencyInput from "react-currency-input";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import {
  useShowAccountByDocumentQuery,
  useStoreSlipSplitConfigMutation,
} from "services/api";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import useDebounce from "hooks/useDebounce";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  UserInfosContainer: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    color: theme.palette.primary.main,
  },
  userContentsContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.secondary.light,
    padding: "8px",
    borderRadius: "27px",
  },
  userContentItem: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    padding: "12px",
    margin: "8px",
    borderRadius: "27px",
  },
  SplitModal: {
    padding: "20px",
  },
  saqueHeader: {
    background: theme.gradient.main,
    color: "white",
  },
  currency: {
    font: "inherit",
    color: "currentColor",
    width: "100%",
    border: "0px",
    borderBottom: "1px solid gray",
    height: "1.1876em",
    margin: 0,
    display: "block",
    padding: "6px 0 7px",
    minWidth: 0,
    background: "none",
    boxSizing: "content-box",
    animationName: "mui-auto-fill-cancel",
    letterSpacing: "inherit",
    animationDuration: "10ms",
    appearance: "textfield",
    textAlign: "start",
    paddingLeft: "5px",
  },
  boxButtons: {
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
    },
  },
  buttons: {
    minWidth: "120px",
    height: "40px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      marginBottom: "5px",
    },
  },
  boxMessage: {
    display: "flex",
    flexDirection: "column",
  },
  message: {
    color: "red",
    marginBottom: "2px",
  },
}));

const defaultInfo = {
  receiver_id: "",
  porcentagem: 0,
  responsavel_pelo_prejuizo: true,
};

const DialogCreateSplit = ({ open, onClose, accountId }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const [storeSplitConfig] = useStoreSlipSplitConfigMutation();
  const [errosSplit, setErrosSplit] = useState({});
  const [loading, setLoading] = useState(false);
  const [filterByPj, setFilterByPj] = useState(false);
  const [filters, setFilters] = useState({
    like: "",
  });
  const debouncedLike = useDebounce(filters.like, 800);

  const { data: account } = useShowAccountByDocumentQuery(
    {
      documento: debouncedLike,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const accounts = useMemo(() => (account ? [account] : []), [account]);

  const [transacaoInfos, setTransacaoInfos] = useState({
    ...defaultInfo,
  });

  useEffect(() => {
    setTransacaoInfos((values) => ({
      ...values,
      ...defaultInfo,
    }));
  }, []);

  const handleClose = () => {
    onClose();
    setFilters({
      like: "",
    });
    setErrosSplit({});
  };

  const getOptionLabel = (conta) => {
    return conta.cnpj
      ? `${conta.razao_social}, documento: ${conta.cnpj}`
      : `${conta.nome}, documento: ${conta.documento}`;
  };

  const handleOnInputChange = (value, reason, filter) => {
    if (reason !== "reset" && filter) {
      setFilters({ ...filters, like: value });
    }
    if (reason !== "reset") {
      setTransacaoInfos({
        ...transacaoInfos,
        receiver_id: null,
      });
    }
  };

  const handleSplit = async () => {
    setLoading(true);
    try {
      const splitConfig = {
        ...transacaoInfos,
        conta_id: accountId,
      };

      await storeSplitConfig({ splitConfig }).unwrap();

      toast.success("Configuração de split para boletos criada com sucesso!");
      handleClose();
    } catch (e) {
      if (e.data.errors) setErrosSplit(e.data.errors);
      toast.error("Não foi possível criar a configuração de split");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} className={classes.SplitModal}>
      <Box>
        <LoadingScreen isLoading={loading} />
        <DialogTitle className={classes.saqueHeader}>
          <Typography align="center" variant="h6">
            Configuração de split
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.boxMessage}>
            <Box className={classes.message} textAlign="center">
              *Todos os splits criados a partir dessa configuração serão
              calculados proporcionalmente a partir do valor líquido da
              transação. Exemplo:
            </Box>
            <Box
              className={classes.message}
              style={matches ? null : { textAlign: "center" }}
            >
              Split : 50%
              <Box>R$ 100 - R$ 2,90 (Taxa) = R$ 97,10 (Líquido)</Box>
              <Box>R$ 97,10 * 50% = R$ 48,55</Box>
            </Box>
            <Box margin="6px 0">
              <FormControl fullWidth error={errosSplit.porcentagem}>
                <Typography
                  style={{
                    alignSelf: "center",
                  }}
                >
                  Porcentagem a ser repartida
                </Typography>
                <CurrencyInput
                  className={classes.currency}
                  decimalSeparator=","
                  thousandSeparator="."
                  suffix=" %"
                  value={transacaoInfos.porcentagem}
                  onChangeEvent={(event, maskedvalue, floatvalue) =>
                    setTransacaoInfos({
                      ...transacaoInfos,
                      porcentagem: floatvalue,
                    })
                  }
                  style={{
                    marginBottom: "6px",
                    width: "60%",
                    alignSelf: "center",
                  }}
                />
                <FormHelperText
                  style={{
                    marginBottom: "6px",
                    width: "60%",
                    alignSelf: "center",
                  }}
                >
                  {errosSplit.porcentagem
                    ? errosSplit.porcentagem.join(" ")
                    : null}
                </FormHelperText>
              </FormControl>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="20px"
          >
            <Typography variant="h6">Conta</Typography>

            <Box display="flex" alignItems="center" alignSelf="start">
              <Typography style={{ marginRight: "10px" }}>
                Filtrar por:
              </Typography>
              <Button
                variant={filterByPj ? "outlined" : "contained"}
                style={{ marginRight: "10px" }}
                onClick={() => setFilterByPj(false)}
              >
                CPF
              </Button>
              <Button
                variant={filterByPj ? "contained" : "outlined"}
                onClick={() => setFilterByPj(true)}
              >
                CNPJ
              </Button>
            </Box>
            <InputMask
              mask={filterByPj ? "99.999.999/9999-99" : "999.999.999-99"}
              value={filters.like}
              onChange={(e) =>
                handleOnInputChange(e.target.value, "change", true)
              }
            >
              {() => (
                <TextField
                  label="Documento"
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              )}
            </InputMask>

            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                options={accounts}
                noOptionsText="Nenhuma conta encontrada!"
                getOptionLabel={(conta) => getOptionLabel(conta)}
                onInputChange={(_event, value, reason) =>
                  handleOnInputChange(value, reason)
                }
                onChange={(_event, option) => {
                  setTransacaoInfos({
                    ...transacaoInfos,
                    receiver_id: option ? option.id : null,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecionar Conta"
                    margin="normal"
                    variant="outlined"
                    error={errosSplit?.receiver_id}
                    helperText={
                      errosSplit?.receiver_id ? errosSplit?.receiver_id : null
                    }
                  />
                )}
              />
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions
          className={classes.boxButtons}
          disableSpacing={matches ? true : false}
        >
          <Button
            className={classes.buttons}
            onClick={handleClose}
            variant="outlined"
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            className={classes.buttons}
            onClick={handleSplit}
            color="default"
            variant="outlined"
            autoFocus
          >
            Criar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DialogCreateSplit;
