import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { useRecreateSplitsFromConfigMutation } from "services/api";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.gradient.main,
    color: "white",
  },
}));

const RecreateSplitsModal = ({
  open = false,
  setOpen = () => {},
  onRecreate = () => {},
  transacao_id,
  split_config_type,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [recreateSplitsFromConfig] = useRecreateSplitsFromConfigMutation();

  const handleClose = () => {
    setOpen(false);
    onRecreate();
  };

  const handleRecreateSplitsFromConfig = async () => {
    setLoading(true);

    try {
      await recreateSplitsFromConfig({
        transacao_id,
        split_config_type,
      }).unwrap();

      toast.success("Splits configurados com sucesso!");
      handleClose();
    } catch (e) {
      toast.error("Não foi possível configurar os splits!");
    }

    setLoading(false);
  };

  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <LoadingScreen isLoading={loading} />
      <DialogTitle className={classes.header}>
        <Typography align="center" variant="h6">
          Recriar Repartições de Valor
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Essa ação irá removover todas as repartições de valores existentes
          dessa transação e recriar conforme a configuração feita para esse tipo
          de transação.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            minWidth: 120,
            height: 40,
          }}
          onClick={() => setOpen(false)}
          variant="outlined"
          color="secondary"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleRecreateSplitsFromConfig}
          color="default"
          variant="outlined"
          autoFocus
          style={{
            minWidth: 120,
            height: 40,
          }}
        >
          Recriar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecreateSplitsModal;
