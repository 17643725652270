import {
	Box,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	Step,
	StepLabel,
	Stepper,
} from '@material-ui/core';
import SelectPayer from 'components/reusable/SelectPayer/SelectPayer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import {
	loadPagadoresFilter,
	loadPlanosAll,
	postAssinaturaAction,
	postCartaoAssinaturaAction,
} from '../../../../actions/actions';
import useDebounce from '../../../../hooks/useDebounce';
import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import GradientButton from '../../../reusable/GradientButton/GradientButton';
import LoadingScreen from '../../../reusable/LoadingScreen/LoadingScreen';
import SubscriptionCard from '../../../reusable/SubscriptionCard/SubscriptionCard';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '27px',
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column',
		width: '800px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	}
}));

const ALL_STEPS = {
	PAGADOR_CADASTRADO: 'Pagador cadastrado?',
	ESCOLHER_PAGADOR: 'Escolha o pagador',
	CADASTRO_PAGADOR: 'Cadastrar novo pagador',
	PAGAMENTO: 'Pagamento',
	ESCOLHER_PLANO: 'Escolha o plano',
};

const NewAccountSubscriptions = () => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const pagadoresList = useSelector((state) => state.pagadores);
  const planosList = useSelector((state) => state.planosList);
	const steps = ["ESCOLHER_PAGADOR", "PAGAMENTO", "ESCOLHER_PLANO"];
	const [activeStep, setActiveStep] = useState(0);
  const [errosCartao, setErrosCartao] = useState({});
  const [pagadorSelecionado, setPagadorSelecionado] = useState(false);
	const [loading, setLoading] = useState(false);

	const [assinatura, setAssinatura] = useState({
		plano_id: '',
		pagador_id: '',
    card_id: ''
	});

  const [cartao, setCartao] = useState({
    nome: '',
    numero: '',
    cvv: '',
    mes: '',
    ano: '',
	});

	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: 5,
	});

	const debouncedLike = useDebounce(filters.like, 800);

	useEffect(() => {
		dispatch(loadPlanosAll(userData.id));
	}, [dispatch, userData.id]);

	useEffect(() => {
		dispatch(loadPagadoresFilter(undefined, debouncedLike, filters.order, filters.mostrar, userData.id));
	}, [dispatch, debouncedLike, filters.order, filters.mostrar, userData.id]);

	const handleNext = async () => {
		setLoading(true);

		if (activeStep === 0) {
			if (assinatura.pagador_id === '') {
				toast.error('Selecione um pagador');
			} else {
				setActiveStep(activeStep + 1);
			}
		}

		if (activeStep === 1) {
      const resCartao = await dispatch(
        postCartaoAssinaturaAction(assinatura.pagador_id, cartao)
      );
      if(resCartao?.data?.card_id) {
        toast.success('Cartão vinculado com sucesso!');
        setAssinatura({ ...assinatura, card_id: resCartao.data.card_id });
        setActiveStep(activeStep + 1);
      } else {
        setErrosCartao(resCartao);
      }
		}

		if (activeStep === 2) {
			const resAssinatura = await dispatch(postAssinaturaAction(assinatura));

			if (resAssinatura) {
				toast.error('Erro ao cadastrar');
			} else {
				toast.success('Assinatura efetuada com sucesso!');
				history.push('/dashboard/assinaturas');
			}
		}

    setLoading(false);
	};

  const handleSelecionarPagador = (id) => {
		setPagadorSelecionado(true);
		setAssinatura({ ...assinatura, pagador_id: id });
		setActiveStep(activeStep + 1);
	};

	const selectPayerColumns = [
		{ headerText: 'Nome', key: 'nome' },
		{ headerText: 'Documento', key: 'documento' },
		{
			headerText: 'Ações',
			key: 'id',
			CustomValue: (pagadorId) => {
				return (
					<GradientButton
						style={{ height: '27px' }}
						disabled={pagadorSelecionado ? 'true' : false}
						buttonText="Selecionar"
						onClick={() => handleSelecionarPagador(pagadorId)}
					/>
				);
			},
		},
	];

	const STEPS_COMPONENTS = {
		ESCOLHER_PAGADOR: (
      <SelectPayer
        payerList={pagadoresList}
        columns={selectPayerColumns}
        filters={filters}
        setFilters={setFilters}
      />
		),
		PAGAMENTO: (
			<SubscriptionCard
				cartao={cartao}
				setCartao={setCartao}
				errosCartao={errosCartao}
			/>
		),
		ESCOLHER_PLANO: (
			<Box alignSelf="center" width="50%">
				<Select
					fullWidth
					variant="standard"
					onChange={(e) => setAssinatura({ ...assinatura, plano_id: e.target.value })}
				>
					{planosList.data.map((plano) => (
						<MenuItem value={plano.id} key={plano.id}>
							{plano.nome}
						</MenuItem>
					))}
				</Select>
			</Box>
		),
	};

	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />
			<CustomBreadcrumbs path1="Assinatura" to1="/dashboard/assinaturas" path2="Nova Assinatura" to2="#" />

			<Paper className={classes.paper}>
				<Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{ALL_STEPS[label]}</StepLabel>
						</Step>
					))}
				</Stepper>
				{STEPS_COMPONENTS[steps[activeStep]]}
				<Box alignSelf="flex-end" marginTop="16px">
					<GradientButton buttonText={activeStep === 2 ? 'Finalizar' : 'Próximo'} onClick={handleNext} />
				</Box>
			</Paper>

		</Box>
	);
};

export default NewAccountSubscriptions;
