import { getTheme } from "constants/theme";

export const currencyStyle = {
  font: "inherit",
  color: "currentColor",
  width: "100%",
  border: "0px",
  borderBottom: "1px solid gray",
  height: "1.1876em",
  margin: 0,
  display: "block",
  padding: "6px 0 7px",
  minWidth: 0,
  background: "none",
  boxSizing: "content-box",
  animationName: "mui-auto-fill-cancel",
  letterSpacing: "inherit",
  animationDuration: "10ms",
  appearance: "textfield",
  textAlign: "start",
  paddingLeft: "5px",
  "&:disabled": {
    color: getTheme().palette.grey[400],
    borderColor: getTheme().palette.grey[400],
  },
};
