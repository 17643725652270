import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	LinearProgress,
	Menu,
	MenuItem,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import OptionMenuButton from 'components/reusable/Buttons/OptionMenuButton';
import SimpleButton from 'components/reusable/Buttons/SimpleButton';
import ConfirmDialog from 'components/reusable/ConfirmDialog';
import LoadingScreen from 'components/reusable/LoadingScreen/LoadingScreen';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { loadAssinaturasFilters, loadPlanosAll, delAssinatura, cancelarAssinaturaAction, verificarAssinaturaAction } from '../../../actions/actions';
import useDebounce from '../../../hooks/useDebounce';
import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../reusable/CustomTable/CustomTable';
import GradientButton from '../../reusable/GradientButton/GradientButton';
import SearchBar from '../../reusable/SearchBar/SearchBar';
import EditAccountSubscriptionDialog from './EditAccountSubscriptionDialog';

const columns = [
	{
		headerText: 'Criado em',
		key: 'created_at',
		CustomValue: (data) => {
			const p = data.split(/\D/g);
			const dataFormatada = [p[2], p[1], p[0]].join('/');
			return (
				<Box display="flex" justifyContent="center">
					<FontAwesomeIcon icon={faCalendarAlt} size="lg" />
					<Typography style={{ marginLeft: '6px' }}>{dataFormatada}</Typography>
				</Box>
			);
		},
	},
	{
		headerText: 'Pagador',
		key: 'pagador',
		CustomValue: (pagador) => {
			return (
				<>
					<Typography>{pagador.nome ? pagador.nome : null}</Typography>
					<Typography>{pagador.documento ? pagador.documento : null}</Typography>
				</>
			);
		},
	},
  {
		headerText: 'Plano',
		key: 'plano',
		CustomValue: (plano) => {
			return (
				<Typography>{plano?.nome}</Typography>
			);
		},
	},
  {
		headerText: 'Cartão',
		key: 'card_id',
		CustomValue: (card_id) => {
			return (
        card_id
        ? <Typography style={{color:"green"}}>DEFINIDO</Typography>
				: <Typography style={{color:"red"}}>INDEFINIDO</Typography>
			);
		},
	},
  {
		headerText: 'Status',
		key: 'status',
		CustomValue: (status) => {
      if(status === "active") return <Typography style={{color:"green"}}>ATIVO</Typography>
			if(status === "canceled") return <Typography style={{color:"red"}}>CANCELADO</Typography>
			if(!status) return <Typography style={{color:"red"}}>INDEFINIDO</Typography>
      return <Typography>{status}</Typography>
		},
	},
	{ headerText: 'Ações', key: 'menu' },
];

const AccountSubscriptions = () => {
	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
		plano: '',
	});
	const debouncedLike = useDebounce(filters.like, 800);
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const history = useHistory();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const userData = useSelector((state) => state.userData);
	const { id } = useParams();
  const [openEditSignature, setOpenEditSignature] = useState(false);
  const [assinatura, setAssinatura] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

	const assinaturasList = useSelector((state) => state.assinaturasList);

	const handlePlanos = () => {
    if(id) {
      const path = generatePath("/dashboard/contas/:id/planos-de-assinaturas", {
        id: id,
      });
      history.push(path);
    } else {
      history.push('/dashboard/planos-de-assinaturas');
    }
	};

	const handleNovaAssinatura = () => {
		history.push('/dashboard/nova-assinatura');
	};

	const handleChangePage = (e, value) => {
		setPage(value);
	};

  const loadAssinaturas = () => {
    dispatch(
			loadAssinaturasFilters(page, debouncedLike, filters.order, filters.plano, filters.mostrar, id ?? userData.id)
		);
  }

	useEffect(() => {
		loadAssinaturas();
	}, [dispatch, page, filters.order, filters.plano, filters.mostrar, debouncedLike, id, userData.id]);

	const Editar = ({ row }) => {
		const [anchorEl, setAnchorEl] = useState(null);
    const [openVerificarDialog, setOpenVerificarDialog] = useState(false);
    const [openCancelarDialog, setOpenCancelarDialog] = useState(false);
    const [openExcluirDialog, setOpenExcluirDialog] = useState(false);

		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handleClose = () => {
			setAnchorEl(null);
		};

    const handleVerificar = async () => {
      setIsLoading(true);

      await dispatch(verificarAssinaturaAction(row.id));
      loadAssinaturas();

      setIsLoading(false);
		};

    const handleCancelar = async () => {
      setIsLoading(true);

      await dispatch(cancelarAssinaturaAction(row.id));
      loadAssinaturas();

      setIsLoading(false);
		};

		const handleExcluir = async () => {
      setIsLoading(true);

      await dispatch(delAssinatura(row.id));
      loadAssinaturas();

      setIsLoading(false);
		};

		return (
      <>
        <ConfirmDialog
          open={openVerificarDialog}
          onConfirm={handleVerificar}
          onClose={() => setOpenVerificarDialog(false)}
          title="Verificar Assinatura"
          message="Iremos verificar a situação atual de sua assinatura."
        />
        <ConfirmDialog
          open={openCancelarDialog}
          onConfirm={handleCancelar}
          onClose={() => setOpenCancelarDialog(false)}
          title="Cancelar Assinatura"
          message="Deseja realmente cancelar essa assinatura de recorrência?"
        />
        <ConfirmDialog
          open={openExcluirDialog}
          onConfirm={handleExcluir}
          onClose={() => setOpenExcluirDialog(false)}
          title="Excluir Assinatura"
          message="Deseja realmente excluir essa assinatura de recorrência?"
        />
        <Box>
          {id ? null : (
            <>
              <OptionMenuButton onClick={handleClick} />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  disabled={row.status !== "active"}
                  onClick={() => {
                    setOpenEditSignature(true);
                    setAssinatura(row)
                    dispatch(loadPlanosAll(row.pagador.conta_id));
                    setAnchorEl(null);
                  }}
                >
                  Editar
                </MenuItem>
                <MenuItem onClick={() => setOpenVerificarDialog(true)}>Verificar</MenuItem>
                <MenuItem disabled={row.status !== "active"} onClick={() => setOpenCancelarDialog(true)}>Cancelar</MenuItem>
                <MenuItem disabled={row.status !== "canceled"} onClick={() => setOpenExcluirDialog(true)}>Excluir</MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </>
		);
	};

	return (
		<Box display="flex" flexDirection="column">
      <LoadingScreen isLoading={isLoading} />
      <EditAccountSubscriptionDialog
        signature={assinatura}
        open={openEditSignature}
        onClose={() => {
          setOpenEditSignature(false);
          loadAssinaturas();
        }}
      />

			{id ? (
				<CustomBreadcrumbs
          path1="Gerenciar Listas" to1={`/dashboard/contas/${id}/listas`}
          path2="Cobrança Recorrente" to2="#"
        />
			) : (
				<CustomBreadcrumbs path1="Cobrança Recorrente" to1="#" />
			)}
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? 'column' : null}>
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Cobrança Recorrente
				</Typography>
        <Box display="flex" alignItems="center">
          <Box marginRight="5px">
            <SimpleButton text="Planos de Cobrança Recorrente" onClick={handlePlanos} />
          </Box>
          <Box>
            {id ? null : (
              <GradientButton buttonText="+ Nova Assinatura" onClick={handleNovaAssinatura} />
            )}
          </Box>
        </Box>
			</Box>
			<Box marginTop="16px" marginBottom="16px">
				<SearchBar
					fullWidth
					placeholder="Pesquisar por nome, documento..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
			</Box>

			{assinaturasList.data && assinaturasList.per_page ? (
				<CustomTable columns={columns} data={assinaturasList.data} Editar={Editar} />
			) : (
				<LinearProgress />
			)}
			<Box alignSelf="flex-end" marginTop="8px">
				<Pagination
					variant="outlined"
					color="secondary"
					size="large"
					count={assinaturasList.last_page}
					onChange={handleChangePage}
					page={page}
				/>
			</Box>
		</Box>
	);
};

export default AccountSubscriptions;
