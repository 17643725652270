import { useEffect } from "react";

const useDynamicPagination = (currentPage, totalPages, setPage) => {
  useEffect(() => {
    if (currentPage > totalPages) {
      setPage(1);
    }
  }, [currentPage, totalPages, setPage]);
};

export default useDynamicPagination;
