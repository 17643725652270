import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  LinearProgress,
  Menu,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  FormHelperText,
  useMediaQuery,
  FormLabel,
  useTheme,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { Pagination } from "@material-ui/lab";
import { toast } from "react-toastify";
import { delUser, loadContaId, loadReenviarToken } from "actions/actions";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import GradientButton from "components/reusable/GradientButton/GradientButton";
import { useIndexAgentUsersQuery, useShowAgentQuery, useStoreAgentUserMutation } from "services/api";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import useDebounce from "hooks/useDebounce";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import SearchBar from "components/reusable/SearchBar/SearchBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
    textAlign: "center"
  },
  button: {
    width: "70px",
  },
}));

const columns = [
  { headerText: "Nome", key: "nome" },
  { headerText: "Documento", key: "documento" },
  { headerText: "E-mail", key: "email" },
  { headerText: "Criado por", key: "created_by_email" },
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (data) => {
      const date = new Date(data);
      const option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const [dia] = date.toLocaleDateString("pt-br", option).split(" ");
      return <Typography align="center">{dia}</Typography>;
    },
  },
  { headerText: "", key: "menu" },
];

const AgentUsers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [storeAgentUser, storeAgentUserStatus] = useStoreAgentUserMutation();
  const storeAgentUserError = useMemo(
    () => (storeAgentUserStatus.error ? storeAgentUserStatus.error.data.errors : null),
    [storeAgentUserStatus]
  );
  const [loadingStore, setLoadingStore] = useState(false);

  const [filters, setFilters] = useState({
    like: "",
  });

  const [newAgentUser, setNewAgentUser] = useState({
    nome: "",
    email: "",
    documento: "",
    agent_id: id
  });

  const debouncedLike = useDebounce(filters.like, 800);

  const {
    data: agent,
  } = useShowAgentQuery(
    {
      agent_id: id,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: agentUserList,
    refetch,
    isLoading,
    isError,
    isUninitialized,
  } = useIndexAgentUsersQuery(
    {
      page,
      debouncedLike,
      agent_id: id,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleStoreAgentUser = async () => {
    setLoadingStore(true);
    try {
      await storeAgentUser(newAgentUser).unwrap();
      toast.success("Usuário criado com sucesso!");
      refetch();
      setOpen(false);
      setNewAgentUser({
        ...newAgentUser,
        nome: "",
        email: "",
        documento: "",
      });
    } catch (e) {
      toast.error("Erro ao criar Usuário!");
    }
    setLoadingStore(false);
  };

  useEffect(() => {
    if(agent?.conta_id) {
      dispatch(loadContaId(agent.conta_id));
    }
  }, [dispatch, agent]);

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const Editar = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleReenvioToken = async () => {
      await dispatch(loadReenviarToken(row.id));
      setAnchorEl(null);
    };

    const handleExcluir = async () => {
      await dispatch(delUser(row.id));
      setAnchorEl(null);
      refetch();
      setOpenDeleteDialog(false);
    };

    const handleGerenciarPermissoes = () => {
      const path = generatePath(
        "/dashboard/agent-users/:id/permissoes",
        {
          id: row.id,
        }
      );
      history.push(path);
      setAnchorEl(null);
    };

    return (
      <Box>
        <Button
          style={{ height: "15px", width: "10px" }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          ...
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => setOpenDeleteDialog(true)}>Excluir</MenuItem>
          <MenuItem onClick={() => handleReenvioToken(row)}>
            Reenviar Token de Confirmação
          </MenuItem>
          <MenuItem onClick={() => handleGerenciarPermissoes(row)}>
            Gerenciar Permissões
          </MenuItem>
        </Menu>

        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Deseja realmente excluir este Usuário?
          </DialogTitle>
          <Box
            width="50%"
            alignSelf="end"
            display="flex"
            justifyContent="space-around"
            padding="12px 24px"
          >
            <Box margin="6px 0">
              <GradientButton buttonText={"Deletar"} onClick={handleExcluir} />
            </Box>
            <Box>
              <Button
                style={{ borderRadius: "37px", margin: "6px 0" }}
                variant="outlined"
                onClick={() => {
                  setOpenDeleteDialog(false);
                }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
    );
  };

  return (
    <Box className={classes.root}>
      <CustomBreadcrumbs
        path1="Representantes" to1="/dashboard/representantes"
        path2="Usuários" to2="#"
      />
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Box marginBottom={2}>
          <Typography
            style={{ marginTop: "8px", marginBottom: "12px" }}
            variant="h4"
          >
            Usuários do Representante - {agent?.business_name ?? agent?.owner_first_name}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          style={{ marginTop: "8px", marginBottom: "12px" }}
          onClick={() => {
            setOpen(true);
          }}
        >
          Criar Usuário
        </Button>
      </Box>
      <Box marginBottom="16px">
        <SearchBar
          fullWidth
          placeholder="Pesquisar por nome, documento, e-mail..."
          value={filters.like}
          onChange={(e) =>
            setFilters({
              ...filters,
              like: e.target.value,
            })
          }
        />
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box display="flex" flexDirection="column" width="400px">
          <LoadingScreen isLoading={loadingStore} />
          <DialogTitle className={classes.dialogHeader}>
            Criar Usuário
          </DialogTitle>
          <Box margin="20px">
            <FormControl fullWidth>
              <Box marginTop={2}>
                <FormLabel component="label">Nome</FormLabel>
                <TextField
                  value={newAgentUser.nome}
                  onChange={(event) =>
                    setNewAgentUser({
                      ...newAgentUser,
                      nome: event.target.value,
                    })
                  }
                  required
                  style={{
                    marginBottom: "6px",
                    width: "100%",
                  }}
                />
                {storeAgentUserError ? (
                  <FormHelperText
                    style={{
                      marginBottom: "6px",
                      width: "60%",
                      color: "red",
                    }}
                  >
                    {storeAgentUserError.nome ? storeAgentUserError.nome[0] : null}
                  </FormHelperText>
                ) : null}
              </Box>
              <Box marginTop={2}>
                <FormLabel component="label">Documento</FormLabel>
                <InputMask
                  mask={"999.999.999-99"}
                  value={newAgentUser.documento}
                  onChange={(event) =>
                    setNewAgentUser({
                      ...newAgentUser,
                      documento: event.target.value,
                    })
                  }
                >
                  {() => (
                    <TextField
                      fullWidth
                      required
                      style={{
                        marginBottom: "6px",
                        width: "100%",
                      }}
                    />
                  )}
                </InputMask>
                {storeAgentUserError ? (
                  <FormHelperText
                    style={{
                      marginBottom: "6px",
                      width: "60%",
                      color: "red",
                    }}
                  >
                    {storeAgentUserError.documento
                      ? storeAgentUserError.documento[0]
                      : null}
                  </FormHelperText>
                ) : null}
              </Box>
              <Box marginTop={2}>
                <FormLabel component="label">Email</FormLabel>
                <TextField
                  value={newAgentUser.email}
                  onChange={(event) =>
                    setNewAgentUser({
                      ...newAgentUser,
                      email: event.target.value,
                    })
                  }
                  required
                  style={{
                    marginBottom: "6px",
                    width: "100%",
                  }}
                />
                {storeAgentUserError ? (
                  <FormHelperText
                    style={{
                      marginBottom: "6px",
                      width: "60%",
                      color: "red",
                    }}
                  >
                    {storeAgentUserError.email ? storeAgentUserError.email[0] : null}
                  </FormHelperText>
                ) : null}
              </Box>
            </FormControl>
          </Box>
          <Box alignSelf="end" display="flex" padding="12px 24px">
            <Box margin="6px 0" padding="0 12px">
              <GradientButton buttonText={"Criar"} onClick={handleStoreAgentUser} />
            </Box>
            <Box>
              <Button
                style={{ borderRadius: "37px", margin: "6px 0" }}
                variant="outlined"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <>
        {!isLoading && !isError && !isUninitialized ? (
          <>
            <CustomTable
              columns={columns}
              data={agentUserList.data}
              Editar={Editar}
            />
            <Box alignSelf="flex-end" marginTop="8px">
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={agentUserList.last_page}
                onChange={handleChangePage}
                page={page}
              />
            </Box>
          </>
        ) : (
          <LinearProgress />
        )}
      </>
    </Box>
  );
};

export default AgentUsers;
