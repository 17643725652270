import {
  faAddressBook,
  faBook,
  faChartLine,
  faCreditCard,
  faList,
  faLock,
  faLockOpen,
  faReceipt,
  faUserFriends,
  faUsers,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { Box, Switch, makeStyles, Typography } from "@material-ui/core";
import { ADM_PERMISSIONS } from "constants/permissions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import {
  delPermissao,
  loadPermissaoGerenciar,
  postPermissaoAction,
} from "../../../actions/actions";
import AccountCollectionItem from "../../reusable/Content/AccountCollections/AccountCollectionItem/AccountCollectionItem";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import LoadingScreen from "../../reusable/LoadingScreen/LoadingScreen";
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";

const useStyles = makeStyles((theme) => ({
  responsiveContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(550)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const AdmUserPermissions = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { permissao, nome, email, documento } = useSelector(
    (state) => state.gerenciarPermissao
  );
  const [permissoes, setPermissoes] = useState([]);

  useEffect(() => {
    dispatch(loadPermissaoGerenciar(id));
  }, [dispatch, id]);

  useEffect(() => {
    setPermissoes(permissao.map((item) => item.tipo));
  }, [permissao]);

  useEffect(() => {
    return () => {
      setPermissoes([]);
    };
  }, []);

  const handlePermissoes = async (event) => {
    setLoading(true);
    if (permissoes.includes(event.target.name)) {
      const response = await dispatch(delPermissao(id, event.target.value));

      response
        ? toast.success("Permissão removida!")
        : toast.error("Erro ao remover permissão!");
    } else {
      const response = await dispatch(
        postPermissaoAction(id, event.target.value)
      );

      response
        ? toast.success("Permissão adicionada!")
        : toast.error("Erro ao adicionar permissão!");
    }
    await dispatch(loadPermissaoGerenciar(id));
    setLoading(false);
  };

  return (
    <Box display="flex" flexDirection="column">
      <LoadingScreen isLoading={loading} />
      <CustomBreadcrumbs
        path1="Administradores"
        to1="/dashboard/lista-de-administradores"
        path2="Gerenciar Permissões"
      />
      <Typography style={{ marginTop: "8px" }} variant="h4">
        Gerenciar Permissões
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
        alignItems="center"
        margin="15px 0"
      >
        <Typography variant="h5">Administrador Selecionado: </Typography>
        <Typography style={{ wordWrap: "break-word" }} align="center">
          {nome}
        </Typography>
        <Typography align="center">{documento}</Typography>
        <Typography>{email}</Typography>
      </Box>

      <Box className={classes.responsiveContainer}>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem
            text="Gerenciamento de Contas"
            icon={faUsers}
          />
          <Switch
            name={ADM_PERMISSIONS.ACCOUNTS_MANAGEMENT}
            value={16}
            checked={
              permissoes.includes(ADM_PERMISSIONS.ACCOUNTS_MANAGEMENT)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem
            text="Gerenciamento de Usuários"
            icon={faUsers}
          />
          <Switch
            name={ADM_PERMISSIONS.USER_MANAGEMENT}
            value={27}
            checked={
              permissoes.includes(ADM_PERMISSIONS.USER_MANAGEMENT)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem
            text="Gerenciamento de ADMs"
            icon={faUserShield}
          />
          <Switch
            name={ADM_PERMISSIONS.ADMIN_MANAGEMENT}
            value={17}
            checked={
              permissoes.includes(ADM_PERMISSIONS.ADMIN_MANAGEMENT)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
      </Box>

      <Box className={classes.responsiveContainer}>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Transações" icon={faChartLine} />
          <Switch
            name={ADM_PERMISSIONS.TRANSACTIONS}
            value={18}
            checked={
              permissoes.includes(ADM_PERMISSIONS.TRANSACTIONS) ? true : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Extrato" icon={faReceipt} />
          <Switch
            name={ADM_PERMISSIONS.STATEMENT}
            value={19}
            checked={
              permissoes.includes(ADM_PERMISSIONS.STATEMENT) ? true : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Dados cadastrais" icon={faAddressBook} />
          <Switch
            name={ADM_PERMISSIONS.CREDENTIALS}
            value={20}
            checked={
              permissoes.includes(ADM_PERMISSIONS.CREDENTIALS) ? true : false
            }
            onClick={handlePermissoes}
          />
        </Box>
      </Box>

      <Box className={classes.responsiveContainer}>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem
            text="Gerenciamento de Plano de Vendas"
            icon={faBook}
          />
          <Switch
            name={ADM_PERMISSIONS.SALES_PLAN_MANAGEMENT}
            value={22}
            checked={
              permissoes.includes(ADM_PERMISSIONS.SALES_PLAN_MANAGEMENT)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem
            text="Taxas Plano de Vendas Aplicação"
            icon={faBook}
          />
          <Switch
            name={ADM_PERMISSIONS.APP_SALES_PLAN_FEE_MANAGEMENT}
            value={28}
            checked={
              permissoes.includes(ADM_PERMISSIONS.APP_SALES_PLAN_FEE_MANAGEMENT)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem
            text="Gerenciamento Representantes"
            icon={faUserFriends}
          />
          <Switch
            name={ADM_PERMISSIONS.AGENT_MANAGEMENT}
            value={25}
            checked={
              permissoes.includes(ADM_PERMISSIONS.AGENT_MANAGEMENT)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
      </Box>

      <Box className={classes.responsiveContainer}>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Logs" icon={faList} />
          <Switch
            name={ADM_PERMISSIONS.LOGS}
            value={21}
            checked={permissoes.includes(ADM_PERMISSIONS.LOGS) ? true : false}
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Administrativo" icon={faCreditCard} />
          <Switch
            name={ADM_PERMISSIONS.MANAGEMENT}
            value={15}
            checked={
              permissoes.includes(ADM_PERMISSIONS.MANAGEMENT) ? true : false
            }
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Administrador" icon={faLockOpen} />
          <Switch
            name={ADM_PERMISSIONS.ADMIN}
            value={13}
            checked={permissoes.includes(ADM_PERMISSIONS.ADMIN) ? true : false}
            onClick={handlePermissoes}
          />
        </Box>
      </Box>

      <Box className={classes.responsiveContainer}>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem
            text="Arquivo de remessa"
            icon={faFolderOpen}
          />
          <Switch
            name={ADM_PERMISSIONS.SHIPPING_FILE}
            value={30}
            checked={
              permissoes.includes(ADM_PERMISSIONS.SHIPPING_FILE) ? true : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Inttegrar" icon={faLock} />
          <Switch
            name={ADM_PERMISSIONS.INTTEGRAR}
            value={23}
            checked={
              permissoes.includes(ADM_PERMISSIONS.INTTEGRAR) ? true : false
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdmUserPermissions;
