import { faTable, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { RECEIVABLE_STATUS } from "constants/status";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { loadExportHistoricoRecebivel } from "../../../actions/actions";
import useDebounce from "../../../hooks/useDebounce";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "../../reusable/CustomTable/CustomTable";
import LoadingScreen from "../../reusable/LoadingScreen/LoadingScreen";
import columns from "./ReceivableHistoryColumns";
import { useIndexReceivablesQuery } from "services/api";
import useQuery from "hooks/useQuery";
import useQueryParams from "hooks/useQueryParams";
import { clearSearchParams } from "services/functions/clearSearchParams";
import useDynamicPagination from "hooks/useDynamicPagination";
import useUserPermissions from "hooks/useUserPermissions";

const ReceivableHistory = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const query = useQuery();
  const { section } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(Number(query.get("page")) || 1);
  const [loadingExport, setLoadingExport] = useState(false);
  const { userData, isManagementAdmin, isAgent } = useUserPermissions();
  const filterCurrentCustomerReceivables = useMemo(
    () => section === "historico-de-recebiveis",
    [section]
  );
  const initialFilters = {
    transaction: query.get("transaction") ?? "",
    receiver: query.get("receiver") ?? "",
    payer: query.get("payer") ?? "",
    day: query.get("day") ?? "",
    order: query.get("order") ?? "",
    mostrar: query.get("mostrar") ?? "",
    status: query.get("status") ?? "",
    payment_type: query.get("payment_type") ?? "",
    documento: query.get("documento") ?? "",
    holder_name: query.get("holder_name") ?? "",
    data_inicial: query.get("data_inicial") ?? "",
    data_final: query.get("data_final") ?? "",
    data_prevista_inicial: query.get("data_prevista_inicial") ?? "",
    data_prevista_final: query.get("data_prevista_final") ?? "",
    data_pagamento_inicial: query.get("data_pagamento_inicial") ?? "",
    data_pagamento_final: query.get("data_pagamento_final") ?? "",
    vencimento_final: query.get("vencimento_final") ?? "",
    vencimento_inicial: query.get("vencimento_inicial") ?? "",
    terminal_id: query.get("terminal_id") ?? "",
  };
  const [filters, setFilters] = useState(initialFilters);
  const debouncedFilters = useDebounce(filters, 800);

  const {
    data,
    isFetching: isFetchingReceivables,
    isLoading: isLoadingReceivables,
    isError,
  } = useIndexReceivablesQuery(
    {
      filters: {
        ...debouncedFilters,
        conta_id: filterCurrentCustomerReceivables ? userData.id : "",
        page,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleClickRow = (row) => {
    const path = generatePath("/dashboard/detalhes-transacao/:id/ver", {
      id: row.transaction,
    });
    history.push(path);
  };

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const handleExportarRecebivel = async () => {
    setLoadingExport(true);
    toast.warning("A exportação pode demorar um pouco, por favor aguarde...");
    const res = await dispatch(
      loadExportHistoricoRecebivel({ page, ...filters })
    );
    if (res && res.url !== undefined) {
      window.open(`${res.url}`, "", "");
    }
    setLoadingExport(false);
  };

  useEffect(() => {
    if (isError) {
      toast.error("Aconteceu um erro tente novamente!");
      history.goBack();
    }
  }, [isError, history]);

  const isLoading = useMemo(
    () => isLoadingReceivables || isFetchingReceivables || loadingExport,
    [isLoadingReceivables, isFetchingReceivables, loadingExport]
  );

  useQueryParams(query, filters, page, history);
  useDynamicPagination(page, data?.last_page, setPage);

  return (
    <Box display="flex" flexDirection="column">
      <LoadingScreen isLoading={isLoading} />
      <CustomBreadcrumbs path1="Histórico de recebíveis" to1="#" />
      <Box
        marginBottom={2}
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Typography
          variant="h4"
          style={{ color: theme.palette.background.default }}
        >
          HISTÓRICO DE RECEBÍVEIS
        </Typography>
      </Box>
      <Box style={{ marginTop: "8px" }} display="flex">
        <Grid container spacing={3}>
          {(isManagementAdmin || isAgent) && (
            <Grid item xs={12} sm={3}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Filtrar por recebedor (nome, documento, e-mail...)"
                fullWidth
                label="Recebedor"
                value={filters.receiver}
                onChange={(e) =>
                  setFilters({ ...filters, receiver: e.target.value })
                }
              />
            </Grid>
          )}
          <Grid item xs={12} sm={3}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar por pagador (nome, documento, cnpj, e-mail...)"
              fullWidth
              label="Pagador"
              value={filters.payer}
              onChange={(e) =>
                setFilters({ ...filters, payer: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar por ID da transação ou conciliação"
              fullWidth
              label="ID da transação ou conciliação"
              value={filters.transaction}
              onChange={(e) =>
                setFilters({ ...filters, transaction: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel>Período</InputLabel>
            <Select
              fullWidth
              value={filters.day}
              onChange={(e) => setFilters({ ...filters, day: e.target.value })}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value={1}>Hoje</MenuItem>
              <MenuItem value={7}>Últimos 7 dias</MenuItem>
              <MenuItem value={15}>Últimos 15 dias</MenuItem>
              <MenuItem value={30}>Últimos 30 dias</MenuItem>
              <MenuItem value={60}>Últimos 60 dias</MenuItem>
              <MenuItem value={90}>Últimos 90 dias</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel>Situação</InputLabel>
            <Select
              fullWidth
              value={filters.status}
              onChange={(e) =>
                setFilters({ ...filters, status: e.target.value })
              }
            >
              <MenuItem value="">TODOS</MenuItem>
              {Object.values(RECEIVABLE_STATUS).map(({ status, text }) => (
                <MenuItem value={status}>{text}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel>Método</InputLabel>
            <Select
              fullWidth
              value={filters.payment_type}
              onChange={(e) =>
                setFilters({ ...filters, payment_type: e.target.value })
              }
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="debit">Débito</MenuItem>
              <MenuItem value="credit">Crédito</MenuItem>
              <MenuItem value="boleto">Boleto</MenuItem>
              <MenuItem value="commission">Comissão</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Criação Inicial"
              value={filters.data_inicial}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  data_inicial: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Criação Final"
              value={filters.data_final}
              onChange={(e) =>
                setFilters({ ...filters, data_final: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Prevista Inicial"
              value={filters.data_prevista_inicial}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  data_prevista_inicial: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Prevista Final"
              value={filters.data_prevista_final}
              onChange={(e) =>
                setFilters({ ...filters, data_prevista_final: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Pagamento Inicial"
              value={filters.data_pagamento_inicial}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  data_pagamento_inicial: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Pagamento Final"
              value={filters.data_pagamento_final}
              onChange={(e) =>
                setFilters({ ...filters, data_pagamento_final: e.target.value })
              }
            />
          </Grid>
          {filters.payment_type === "boleto" ? null : (
            <>
              <Grid item xs={12} sm={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Filtrar por ID do POS"
                  fullWidth
                  label="ID do POS"
                  value={filters.terminal_id}
                  onChange={(e) =>
                    setFilters({ ...filters, terminal_id: e.target.value })
                  }
                />
              </Grid>
            </>
          )}
          {filters.payment_type === "credit" ||
          filters.payment_type === "debit" ? (
            <>
              <Grid item xs={12} sm={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Nome do portador do Cartão"
                  fullWidth
                  label="Portador do Cartão"
                  value={filters.holder_name}
                  onChange={(e) =>
                    setFilters({ ...filters, holder_name: e.target.value })
                  }
                />
              </Grid>
            </>
          ) : null}

          {filters.payment_type === "boleto" ? (
            <>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  label="Vencimento Data Inicial"
                  value={filters.vencimento_inicial}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      vencimento_inicial: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  label="Vencimento Data Final"
                  value={filters.vencimento_final}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      vencimento_final: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Filtrar por N° Documento"
                  fullWidth
                  label="N° Documento"
                  value={filters.documento}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      documento: e.target.value,
                    })
                  }
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={12} sm={2}>
            <Box display="flex">
              <Tooltip title="Limpar Filtros">
                <IconButton
                  onClick={() => clearSearchParams(filters, setFilters)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Exportar Excel">
                <IconButton
                  variant="outlined"
                  style={{ marginLeft: "6px" }}
                  onClick={handleExportarRecebivel}
                >
                  <FontAwesomeIcon icon={faTable} color="green" />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop="16px" marginBottom="16px"></Box>
      {data ? (
        <CustomTable
          columns={columns}
          data={data?.data}
          handleClickRow={handleClickRow}
        />
      ) : (
        <LinearProgress />
      )}
      <Box alignSelf="flex-end" marginTop="8px">
        <Pagination
          variant="outlined"
          color="secondary"
          size="large"
          count={data?.last_page}
          onChange={handleChangePage}
          page={page}
        />
      </Box>
    </Box>
  );
};

export default ReceivableHistory;
