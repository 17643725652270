import { Box, LinearProgress, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadPagadoresFilter } from '../../../actions/actions';
import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../reusable/CustomTable/CustomTable';
import GradientButton from '../../reusable/GradientButton/GradientButton';
import SearchBar from '../../reusable/SearchBar/SearchBar';
import Pagination from '@material-ui/lab/Pagination';
import { generatePath, useHistory } from 'react-router';
import useDebounce from '../../../hooks/useDebounce';

const columns = [
	{ headerText: 'Nome', key: 'nome' },
	{ headerText: 'Documento', key: 'documento' },
	{ headerText: 'E-mail', key: 'email' },
	{ headerText: 'Contato', key: 'celular' },
	{
		headerText: 'Cobrar',
		key: 'id',
		CustomValue: (id) => {
			const history = useHistory();
			const handleRedirecionar = () => {
				const path = generatePath('/dashboard/novo-boleto/:id/cobrar', {
					id: id,
				});
				history.push(path);
			};
			return <GradientButton onClick={handleRedirecionar} buttonText="Cobrar" />;
		},
	},
];

const PaymentSlip = () => {
	const pagadoresList = useSelector((state) => state.pagadores);
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [page, setPage] = useState(1);
	const history = useHistory();
  const userData = useSelector((state) => state.userData);

	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});

	const debouncedLike = useDebounce(filters.like, 800);

	useEffect(() => {
		dispatch(
			loadPagadoresFilter(page, debouncedLike, filters.order, filters.mostrar, userData.id)
		);
	}, [dispatch, page, filters.order, filters.mostrar, debouncedLike, userData.id]);

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	const handleNewBoleto = () => {
		history.push('/dashboard/novo-boleto');
	};

	return (
		<Box display="flex" flexDirection="column">
			<CustomBreadcrumbs path1="Boletos" to1="/dashboard/boleto" path2="Novo Boleto" />
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? 'column' : null}>
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Novo Boleto
				</Typography>

				<Box>
					<GradientButton onClick={handleNewBoleto} buttonText="Novo pagador" />
				</Box>
			</Box>
			<Box marginTop="16px" marginBottom="16px">
				<SearchBar
					fullWidth
					placeholder="Procurar por documento, nome..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
			</Box>

			<>
				{pagadoresList.data && pagadoresList.per_page ? (
					<CustomTable columns={columns} data={pagadoresList.data} />
				) : (
					<LinearProgress />
				)}
				<Box alignSelf="flex-end" marginTop="8px">
					<Pagination
						variant="outlined"
						color="secondary"
						size="large"
						count={pagadoresList.last_page}
						onChange={handleChangePage}
						page={page}
					/>
				</Box>
			</>
		</Box>
	);
};

export default PaymentSlip;
