import { Box, Typography } from "@material-ui/core";
import React from "react";

const AccountDetails = ({ account, title = "Vendedor" }) => {
  return account ? (
    <Box>
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <Typography>Nome:</Typography>
      <Typography variant="h6">
        {account ? account.razao_social ?? account.nome : "-"}
      </Typography>
      <Typography>Documento:</Typography>
      <Typography variant="h6">
        {account ? account.cnpj ?? account.nome : "-"}
      </Typography>
      <Typography>E-mail:</Typography>
      <Typography variant="h6">
        {account?.email ? account.email : "-"}
      </Typography>
      <Typography>Contato:</Typography>
      <Typography variant="h6">
        {account?.celular ? account.celular : "-"}
      </Typography>
    </Box>
  ) : (
    <Typography variant="h6" align="center">
      Sem dados para exibir!
    </Typography>
  );
};

export default AccountDetails;
