import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Loading from "components/reusable/Loading";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CurrencyInput from "react-currency-input";
import { toast } from "react-toastify";
import { useCancelTransactionMutation } from "services/api";
import { currencyStyle } from "styles";

const useStyles = makeStyles((theme) => ({
  currency: currencyStyle,
  dialogTitle: {
    background: theme.gradient.main,
    color: "white",
    textAlign: "center",
    marginBottom: 8,
  },
}));

function CancellationModal({ isOpen, onClose, transaction, recipients = [] }) {
  const [returningType, setReturningType] = useState("full");
  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState(null);

  const inputRef = useRef();
  const styles = useStyles();

  const [cancelTransaction, { isLoading, isSuccess, isError, error }] =
    useCancelTransactionMutation();

  const hasAnyError = useMemo(() => {
    return !!amountError || amount === 0 || amount > Number(transaction.amount);
  }, [amountError, amount, transaction]);

  const isFullReturningType = useMemo(
    () => returningType === "full",
    [returningType]
  );

  const handleConfirm = useCallback(() => {
    cancelTransaction({
      transactionId: transaction.id,
      isFullAmount: returningType === "full",
      amount: amount,
    });
  }, [cancelTransaction, amount, transaction, returningType]);

  useEffect(() => {
    if (isFullReturningType && amount !== Number(transaction.amount)) {
      setAmountError(
        "Valor para extorno total deve ser igual valor disponível da transação."
      );
    } else if (!isFullReturningType && amount > Number(transaction.amount)) {
      setAmountError(
        "Valor para extorno parcial deve ser menor que o valor disponível da transação."
      );
    } else setAmountError(null);
  }, [amount, transaction, isFullReturningType]);

  useEffect(() => {
    if (isFullReturningType) {
      setAmount(Number(transaction.amount));
    } else {
      setAmount(0);

      if (inputRef.current) {
        inputRef.current.theInput.focus();
      }
    }
  }, [isFullReturningType]);

  useEffect(() => {
    if (isError) {
      toast.error(
        `Erro ao fazer cancelamento ${
          returningType === "full" ? "total" : "parcial"
        }!`
      );
      if (error.status === 422) {
        toast.error(error.data.message);
      }
      if (error.status === 403) {
        toast.error(error.data.error.message);
      }
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        `Transação ${
          returningType === "full" ? "cancelada" : "extornada"
        } com sucesso!`
      );

      onClose("success");
    }
  }, [isSuccess, returningType]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle className={styles.dialogTitle}>
        Solicitação de Extorno
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <FormLabel component="label">Tipo de Extorno</FormLabel>
          <RadioGroup
            row
            value={returningType}
            onChange={(_, value) => setReturningType(value)}
          >
            <FormControlLabel value="full" control={<Radio />} label="Total" />
            <FormControlLabel
              value="partial"
              control={<Radio />}
              label="Parcial"
            />
          </RadioGroup>
          <Box>
            <FormControl
              error={amountError}
              style={{
                marginTop: 6,
              }}
            >
              <FormLabel component="label">Valor do Extorno</FormLabel>
              <CurrencyInput
                ref={inputRef}
                value={amount}
                disabled={isFullReturningType}
                className={styles.currency}
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
                onChangeEvent={(event, maskedvalue, floatvalue) =>
                  setAmount(floatvalue)
                }
              />
              <FormHelperText>{amountError}</FormHelperText>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            minWidth: 120,
            height: 40,
          }}
          onClick={onClose}
          variant="outlined"
          color="secondary"
        >
          Cancelar
        </Button>
        <Button
          disabled={hasAnyError || isLoading}
          onClick={handleConfirm}
          color="default"
          variant="outlined"
          autoFocus
          style={{
            minWidth: 120,
            height: 40,
          }}
        >
          <Loading isLoading={isLoading}>Confirmar</Loading>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancellationModal;
