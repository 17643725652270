import {
  faCalendarAlt,
  faDownload,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  LinearProgress,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { format } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { downloadExportUrl } from "services/solicitedExportations";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomCollapseTable from "../../reusable/CustomCollapseTable/CustomCollapseTable";
import { useSolicitedExportationsQuery } from "services/api";

const typeMapper = {
  statement: "Extrato",
  transfer: "Transferência",
  transaction: "Transação",
  receivables: "Recebíveis",
};

const columns = [
  {
    headerText: "Tipo",
    key: "type",
    CustomValue: (value) => (
      <Box display="flex" justifyContent="center">
        <Typography style={{ textTransform: "capitalize" }}>
          {typeMapper[value]}
        </Typography>
      </Box>
    ),
  },
  {
    headerText: "Identificador da Exportação",
    key: "id",
    CustomValue: (value) => (
      <Box display="flex" justifyContent="center">
        <Typography>{value}</Typography>
      </Box>
    ),
  },
  {
    headerText: "Solicitado em",
    key: "created_at",
    CustomValue: (data) => {
      const dataFormatada = format(new Date(data), "dd/MM/yyyy HH:mm:ss");
      return (
        <Box display="flex" justifyContent="center">
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
          <Typography style={{ marginLeft: "6px" }}>{dataFormatada}</Typography>
        </Box>
      );
    },
  },
  {
    headerText: "Status",
    key: "status",
    CustomValue: (value) => (
      <Box display="flex" justifyContent="center">
        <Typography
          style={{
            marginLeft: "6px",
            color: value === "sucess" ? "green" : "",
          }}
        >
          {value === "sucess" ? "Processado" : "Processando"}
        </Typography>
      </Box>
    ),
  },
  {
    headerText: "Download",
    key: "url",
    CustomValue: (value, data) => (
      <Box display="flex" justifyContent="center">
        {value ? (
          <Link
            href="#"
            onClick={() =>
              downloadExportUrl({
                accountId: data.conta_id,
                id: data.id,
              })
            }
          >
            <FontAwesomeIcon icon={faDownload} size="lg" />
            <Typography style={{ marginLeft: "6px" }}>Download</Typography>
          </Link>
        ) : (
          <FontAwesomeIcon spin icon={faSpinner} size="lg" />
        )}
      </Box>
    ),
  },
];

const typeFilterToType = {
  1: "statement",
  2: "transfer",
  3: "transaction",
  4: "receivables",
};

const SolicitedExportations = () => {
  const [page, setPage] = useState(1);
  const [typeFilter, setTypeFilter] = useState(0);
  const { id } = useParams();
  const history = useHistory();
  const userData = useSelector((state) => state.userData);
  const accountId = useMemo(() => id || userData.id, [id, userData]);
  const isAdmin = useMemo(() => userData === "", [userData]);

  const {
    data: solicitedServices,
    isLoading,
    isError,
    isUninitialized,
  } = useSolicitedExportationsQuery(
    {
      page,
      account: accountId,
      type: typeFilterToType[typeFilter],
    },
    {
      skip: !accountId,
      refetchOnMountOrArgChange: true,
      pollingInterval: 5000,
    }
  );

  const handleChangePage = useCallback((e, value) => {
    setPage(value);
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error("Aconteceu um erro tente novamente!");
      history.goBack();
    }
  }, [isError, history]);

  return (
    <Box display="flex" flexDirection="column">
      {isAdmin ? (
        <CustomBreadcrumbs
          path1="Gerenciar Listas"
          to1="goBack"
          path2="Exportações Solicitadas"
        />
      ) : (
        <CustomBreadcrumbs
          path2="Exportações Solicitadas"
          path1="Outros Serviços"
          to1="goBack"
        />
      )}
      <Box marginBottom={2}>
        <Typography variant="h4">Exportações Solicitadas</Typography>
      </Box>

      <Box marginBottom={2}>
        <Tabs value={typeFilter} onChange={(_, value) => setTypeFilter(value)}>
          <Tab label="Todos" />
          <Tab label="Extrato" />
          <Tab label="Transferência" />
          <Tab label="Transação" />
          <Tab label="Recebíveis" />
        </Tabs>
      </Box>
      {!isLoading && !isError && !isUninitialized ? (
        <>
          <CustomCollapseTable
            data={solicitedServices.data}
            columns={columns}
          />
          <Box alignSelf="flex-end" marginTop={3}>
            {
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={solicitedServices.last_page}
                onChange={handleChangePage}
                page={page}
              />
            }
          </Box>
        </>
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
};

export default SolicitedExportations;
