import { Typography } from "@material-ui/core";
import TransactionStatusColumn from "components/reusable/TableColumns/TransactionStatusColumn";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import React from "react";
import { formatMoney } from "utils/money";
import OperationTransactionColumn from "components/reusable/TableColumns/OperationTransactionColumn";
import { format, subHours } from "date-fns";

const historyTransactionColumns = [
  {
    headerText: "Data",
    key: "created_at",
    CustomValue: (created_at) => {
      const dateAdjustedTimeZone = subHours(new Date(created_at), 3);
      const formatted = format(dateAdjustedTimeZone, "yyyy-MM-dd HH:mm:ss");

      return <DateTimeColumn dateTime={formatted} />;
    },
  },
  {
    headerText: "Operação",
    key: "operation_type",
    CustomValue: (status) => {
      return <OperationTransactionColumn status={status} />;
    },
  },
  {
    headerText: "Status da operação",
    key: "status",
    CustomValue: (status) => {
      return <TransactionStatusColumn status={status} />;
    },
  },
  {
    headerText: "Valor",
    key: "amount",
    CustomValue: (amount) => {
      if (amount < 0) {
        return (
          <Typography style={{ fontSize: 17, fontWeight: 600, color: "red" }}>
            {formatMoney(amount)}
          </Typography>
        );
      } else {
        return (
          <Typography style={{ fontSize: 17, fontWeight: 600, color: "green" }}>
            {formatMoney(amount)}
          </Typography>
        );
      }
    },
  },
];

export default historyTransactionColumns;
