import React from "react";
import { Box } from "@material-ui/core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loading = ({ children, isLoading = false, size = "2x" }) => {
  return (
    <Box width="100%" height="100%">
      <Box hidden={isLoading}>{children}</Box>
      <Box width="100%" height="100%" hidden={!isLoading}>
        <Box
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <FontAwesomeIcon spin icon={faSpinner} size={size} />
        </Box>
      </Box>
    </Box>
  );
};

export default Loading;
