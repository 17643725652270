import React from "react";
import {
  Box,
  Typography,
} from "@material-ui/core";

const BoletoFeesSum = ({ zoopFees, partnerFees, aplicationFees, agentFees }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h6">APLICAÇÃO</Typography>
      
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography style={{ paddingRight:"10px" }}>
          Valor percentual:
        </Typography>
        <Typography>
          {
            ((
              parseInt(partnerFees ? partnerFees[0].percent_amount : 0) +
              parseInt(zoopFees ? zoopFees[0].percent_amount : 0) +
              parseInt(aplicationFees ? aplicationFees[0].percent_amount : 0) +
              parseInt(agentFees ? agentFees[0].percent_amount : 0)
            )/100).toFixed(2)
          }{" "}%
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography style={{ paddingRight:"10px" }}>
          Valor fixo: R$
        </Typography>
        <Typography>
          {
            ((
              parseInt(partnerFees ? partnerFees[0].dollar_amount : 0) +
              parseInt(zoopFees ? zoopFees[0].dollar_amount : 0) +
              parseInt(aplicationFees ? aplicationFees[0].dollar_amount : 0) +
              parseInt(agentFees ? agentFees[0].dollar_amount : 0)
            )/100).toFixed(2)
          }
        </Typography>
      </Box>
    </Box>
  );
};

export default BoletoFeesSum;
