import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
	AppBar,
	Box,
	Card,
	CardActionArea,
	CardMedia,
	Divider,
	Grid,
	IconButton,
	makeStyles,
	Paper,
	Tab,
	Tabs,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import CustomBreadcrumbs from '../../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import {
	loadContaId,
	postDocumentoActionAdm,
	updateConta,
	loadDocumentos,
	delDocumento,
} from '../../../../../actions/actions';
import { toast } from 'react-toastify';
import NewAccount from '../../../../reusable/Account/NewAccount/NewAccount';
import GradientButton from '../../../../reusable/GradientButton/GradientButton';
import LoadingScreen from '../../../../reusable/LoadingScreen/LoadingScreen';
import SwipeableViews from 'react-swipeable-views';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import ClearIcon from '@material-ui/icons/Clear';
import { PictureAsPdf } from '@material-ui/icons';
import CreateBankAccount from '../../../BankAccounts/CreateBankAccount/CreateBankAccount';
import ListBankAccounts from '../../../BankAccounts/ListBankAccounts/ListBankAccounts';
import { useReactivateAccountsAccountMutation } from 'services/api';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		width: '70%',
		borderRadius: '27px',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		zIndex: '0',
	},
	card: {
		margin: theme.spacing(1),
		padding: 0,
	},
	dropzoneAreaBaseClasses: {
		width: '70%',
		height: '250px',
	},
	dropzoneContainer: {
		margin: '6px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '12px',
		minHeight: '564px',
		fontSize: '12px',
	},
	textoDropzone: {
		fontSize: '1.2rem',
	},
}));

const a11yProps = (index) => {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
};

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const EditAccount = () => {
	const classes = useStyles();
	const history = useHistory();
	const { id } = useParams();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState(0);
  const [reactivateAccount] = useReactivateAccountsAccountMutation();
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const handleChangeIndex = (index) => {
		setValue(index);
	};
	const [errosConta, setErrosConta] = useState({});

	useEffect(() => {
		dispatch(loadContaId(id));
	}, [dispatch, id]);

	const contaId = useSelector((state) => state.conta);
	const [contaBancaria, setContaBancaria] = useState({
		banco: '',
		agencia: '',
		tipo: '1',
		conta: '',
	});
	const [conta, setConta] = useState({
		documento: '',
		nome: '',
		razao_social: '',
		cnpj: '',
		renda: '',
		celular: '',
		data_nascimento: '',
		email: '',
		site: '',
		endereco: {
			cep: '',
			rua: '',
			numero: '',
			complemento: '',
			bairro: '',
			cidade: '',
			estado: '',
		},
	});

	useEffect(() => {
		setConta({ ...contaId });
	}, [contaId]);

  useEffect(() => {
    if(contaId.owner_agent_id && contaId.id === id) {
      history.push("back");
      toast.error("Erro!");
    }
	}, [dispatch, history, contaId.owner_agent_id, contaId.id, id]);

	const handleAlterar = async () => {
		setLoading(true);
		const resConta = await dispatch(updateConta(conta, id));
		if (resConta) {
			setErrosConta(resConta);
			toast.error('Erro ao alterar dados');
			setLoading(false);
		} else {
			toast.success('Dados alterados com sucesso!');
			setLoading(false);
			history.push('/dashboard/contas');
		}
	};

  const handleReactivateAccount = async () => {
    setLoading(true);
    try {
			const response = await reactivateAccount({ conta_id: contaId.id }).unwrap();
      await dispatch(loadContaId(id));
      response.success
      ? toast.success(response.message)
      : toast.error(response.message);

    } catch (e) {
      toast.error("Erro ao reativar conta!");
    }
    setLoading(false);
  };

	const arquivoDocumento = useSelector((state) => state.arquivoDocumento);

	useEffect(() => {
		dispatch(loadDocumentos(id));
	}, [dispatch, id]);

	const handleExcluirArquivo = async (item) => {
		setLoading(true);
		await dispatch(delDocumento(item.id));
		await dispatch(loadDocumentos(id));
		setLoading(false);
	};

	const onDropIdentificacao = async (picture, file) => {
		setLoading(true);
		const categoria = 'identificacao';
		await dispatch(postDocumentoActionAdm(picture, categoria, id));
		await dispatch(loadDocumentos(id));
		setLoading(false);
	};
	const onDropComprovanteResidencia = async (picture, file) => {
		setLoading(true);
		const categoria = 'residencia';
		await dispatch(postDocumentoActionAdm(picture, categoria, id));
		await dispatch(loadDocumentos(id));
		setLoading(false);
	};
	const onDropAtividade = async (picture, file) => {
		setLoading(true);
		const categoria = 'atividade';
		await dispatch(postDocumentoActionAdm(picture, categoria, id));
		await dispatch(loadDocumentos(id));
		setLoading(false);
	};
	const onDropOutros = async (picture, file) => {
		setLoading(true);
		const categoria = 'cnpj';
		await dispatch(postDocumentoActionAdm(picture, categoria, id));
		await dispatch(loadDocumentos(id));
		setLoading(false);
	};

	return (
		<Box className={classes.root}>
			<CustomBreadcrumbs path1="Contas" path2="Editar Conta" to1="/dashboard/contas" />
			<Typography style={{ marginTop: '8px', marginBottom: '8px' }} variant="h4">
				Editar Conta
			</Typography>
			<LoadingScreen isLoading={loading} />
			<Paper className={classes.paper}>
				<AppBar
					position="static"
					style={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: "27px 27px 0 0 ",
            zIndex: "0",
          }}
				>
					<Tabs
						value={value}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="fullWidth"
					>
						<Tab label="Dados Cadastrais" style={{ width: '200%' }} {...a11yProps(0)} />
						<Tab label="Documentos" style={{ width: '200%' }} {...a11yProps(1)} />
						<Tab label="Dados Bancários" style={{ width: '200%' }} {...a11yProps(2)} />
					</Tabs>
				</AppBar>
				<SwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={value}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={value} index={0} dir={theme.direction}>
						<NewAccount
							conta={conta}
							setConta={setConta}
							errosConta={errosConta}
							disableEditar={true}
						/>
            {contaId.status === "deleted" ? (
              <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="16px">
                <Box marginRight="16px">
                  <Typography
                    color="secondary"
                    style={{ color: "red", textAlign: "center" }}
                  >
                    Esta conta está inativa!
                  </Typography>
                </Box>
                <Box>
                  <GradientButton buttonText="Reativar" onClick={handleReactivateAccount} />
                </Box>
              </Box>
            ) : (
              <Box display="flex" justifyContent="flex-end" marginTop="16px">
                <GradientButton buttonText="Atualizar" onClick={handleAlterar} />
              </Box>
            )}
					</TabPanel>

					<TabPanel value={value} index={1} dir={theme.direction}>
						<Box
							display="flex"
							style={matches ? { flexDirection: 'column' } : null}
							justifyContent="center"
						>
							<Box display="flex" flexDirection="column" margin="8px">
								<Box className={classes.dropzoneContainer} boxShadow={3}>
									<Typography variant="h6">IDENTIFICAÇÃO</Typography>
									<Typography>RG, CPF ou CNH.</Typography>
									<DropzoneAreaBase
										dropzoneParagraphClass={classes.textoDropzone}
										maxFileSize={3145728}
										onDropRejected={() => {
											toast.error('Tamanho máximo: 3mb ');
											toast.error('Arquivos suportados: .pdf .png .jpg .jpeg');
										}}
										acceptedFiles={['image/*', 'application/pdf']}
										dropzoneClass={classes.dropzoneAreaBaseClasses}
										onAdd={onDropIdentificacao}
										filesLimit={1}
										dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
										showPreviews={false}
										showPreviewsInDropzone={false}
									/>
									<Box width="300px">
										<Grid container>
											{arquivoDocumento.map((item) =>
												item.categoria === 'IDENTIFICACAO' || item.categoria === 'identificacao' ? (
													<Grid item xs={6}>
														<Card className={classes.card}>
															<CardActionArea>
																<Box position="absolute">
																	<IconButton
																		onClick={() => handleExcluirArquivo(item)}
																		size="small"
																		style={{ color: 'white', backgroundColor: 'red' }}
																	>
																		<ClearIcon />
																	</IconButton>
																</Box>

																{item.descricao === 'application/pdf' ? (
																	<PictureAsPdf
																		style={{ height: '100px', width: '100%' }}
																		onClick={() => window.open(item.arquivo)}
																	/>
																) : (
																	<CardMedia
																		component="img"
																		alt="Arquivo de Identificação"
																		height="100"
																		image={item.arquivo}
																		onClick={() => window.open(item.arquivo)}
																	/>
																)}
															</CardActionArea>
														</Card>
													</Grid>
												) : (
													false
												)
											)}
										</Grid>
									</Box>
								</Box>
								<Box className={classes.dropzoneContainer} boxShadow={3}>
									<Typography variant="h6">COMPROVANTE DE RESIDÊNCIA</Typography>
									<Typography>Conta de luz, telefone e água.</Typography>
									<DropzoneAreaBase
										dropzoneParagraphClass={classes.textoDropzone}
										maxFileSize={3145728}
										onDropRejected={() => {
											toast.error('Tamanho máximo: 3mb ');
											toast.error('Arquivos suportados: .pdf .png .jpg .jpeg');
										}}
										acceptedFiles={['image/*', 'application/pdf']}
										dropzoneClass={classes.dropzoneAreaBaseClasses}
										onAdd={onDropComprovanteResidencia}
										filesLimit={1}
										dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
										showPreviews={false}
										showPreviewsInDropzone={false}
									/>
									<Box width="300px">
										<Grid container>
											{arquivoDocumento.map((item) =>
												item.categoria === 'COMPROVANTE_RESIDENCIA' || item.categoria === 'residencia' ? (
													<Grid item xs={6}>
														<Card className={classes.card}>
															<CardActionArea>
																<Box position="absolute">
																	<IconButton
																		onClick={() => handleExcluirArquivo(item)}
																		size="small"
																		style={{ color: 'white', backgroundColor: 'red' }}
																	>
																		<ClearIcon />
																	</IconButton>
																</Box>

																{item.descricao === 'application/pdf' ? (
																	<PictureAsPdf
																		style={{ height: '100px', width: '100%' }}
																		onClick={() => window.open(item.arquivo)}
																	/>
																) : (
																	<CardMedia
																		component="img"
																		alt="Comrpovante de Residência"
																		height="100"
																		image={item.arquivo}
																		onClick={() => window.open(item.arquivo)}
																	/>
																)}
															</CardActionArea>
														</Card>
													</Grid>
												) : (
													false
												)
											)}
										</Grid>
									</Box>
								</Box>
							</Box>
							<Box display="flex" flexDirection="column" margin="8px">
								<Box className={classes.dropzoneContainer} boxShadow={3}>
									<Typography variant="h6">COMPROVANTE DE ATIVIDADE</Typography>
									<Typography>Nota fiscal ou cartão de visita</Typography>
									<DropzoneAreaBase
										dropzoneParagraphClass={classes.textoDropzone}
										maxFileSize={3145728}
										onDropRejected={() => {
											toast.error('Tamanho máximo: 3mb ');
											toast.error('Arquivos suportados: .pdf .png .jpg .jpeg');
										}}
										acceptedFiles={['image/*', 'application/pdf']}
										dropzoneClass={classes.dropzoneAreaBaseClasses}
										onAdd={onDropAtividade}
										filesLimit={1}
										dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
										showPreviews={false}
										showPreviewsInDropzone={false}
									/>
									<Box width="300px">
										<Grid container>
											{arquivoDocumento.map((item) =>
												item.categoria === 'COMPROVANTE_ATIVIDADE' || item.categoria === 'atividade' ? (
													<Grid item xs={6}>
														<Card className={classes.card}>
															<CardActionArea>
																<Box position="absolute">
																	<IconButton
																		onClick={() => handleExcluirArquivo(item)}
																		size="small"
																		style={{ color: 'white', backgroundColor: 'red' }}
																	>
																		<ClearIcon />
																	</IconButton>
																</Box>

																{item.descricao === 'application/pdf' ? (
																	<PictureAsPdf
																		style={{ height: '100px', width: '100%' }}
																		onClick={() => window.open(item.arquivo)}
																	/>
																) : (
																	<CardMedia
																		component="img"
																		alt="Comrpovante de Residência"
																		height="100"
																		image={item.arquivo}
																		onClick={() => window.open(item.arquivo)}
																	/>
																)}
															</CardActionArea>
														</Card>
													</Grid>
												) : (
													false
												)
											)}
										</Grid>
									</Box>
								</Box>
								<Box className={classes.dropzoneContainer} boxShadow={3}>
									<Typography variant="h6">OUTROS</Typography>
									<Typography>Contrato social.</Typography>
									<DropzoneAreaBase
										dropzoneParagraphClass={classes.textoDropzone}
										maxFileSize={3145728}
										onDropRejected={() => {
											toast.error('Tamanho máximo: 3mb ');
											toast.error('Arquivos suportados: .pdf .png .jpg .jpeg');
										}}
										acceptedFiles={['image/*', 'application/pdf']}
										dropzoneClass={classes.dropzoneAreaBaseClasses}
										onAdd={onDropOutros}
										filesLimit={1}
										dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
										showPreviews={false}
										showPreviewsInDropzone={false}
									/>
									<Box width="300px">
										<Grid container>
											{arquivoDocumento.map((item) =>
												item.categoria === 'OUTROS' || item.categoria === 'cnpj' ? (
													<Grid item xs={6}>
														<Card className={classes.card}>
															<CardActionArea>
																<Box position="absolute">
																	<IconButton
																		onClick={() => handleExcluirArquivo(item)}
																		size="small"
																		style={{ color: 'white', backgroundColor: 'red' }}
																	>
																		<ClearIcon />
																	</IconButton>
																</Box>

																{item.descricao === 'application/pdf' ? (
																	<PictureAsPdf
																		style={{ height: '100px', width: '100%' }}
																		onClick={() => window.open(item.arquivo)}
																	/>
																) : (
																	<CardMedia
																		component="img"
																		alt="Comrpovante de Residência"
																		height="100"
																		image={item.arquivo}
																		onClick={() => window.open(item.arquivo)}
																	/>
																)}
															</CardActionArea>
														</Card>
													</Grid>
												) : (
													false
												)
											)}
										</Grid>
									</Box>
								</Box>
							</Box>
						</Box>
					</TabPanel>
					<TabPanel value={value} index={2} dir={theme.direction}>
						<Box>
							<Typography align="center" variant="h6">
								Lista de contas bancárias
							</Typography>
							<ListBankAccounts contaId={contaId.id} />
							<Box margin="16px"> </Box>
							<Divider />
							<Box margin="16px"> </Box>
							<CreateBankAccount contaId={contaId.id} contaBancaria={contaBancaria} setContaBancaria={setContaBancaria} />
						</Box>
					</TabPanel>
				</SwipeableViews>
			</Paper>
		</Box>
	);
};

export default EditAccount;
