import { Box, Typography } from "@material-ui/core";
import React from "react";
import CustomCopyToClipboard from "../Text/CustomCopyToClipboard";

const PixDetails = ({ transaction }) => {
  const paymentDate = transaction.history.find(
    (item) => item.operation_type === "paid"
  );

  const formatDate = () => {
    const date = paymentDate ? new Date(paymentDate.created_at) : null;
    const option = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formatted = date
      ? date.toLocaleDateString("pt-br", option)
      : "Sem pagamento";
    return <Typography>{formatted}</Typography>;
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" paddingTop="10px">
        <Box>
          <Typography variant="h6">QR-Code:</Typography>
          <CustomCopyToClipboard
            title={"Copiar QR-Code:"}
            value={transaction.payment_method.qr_code.emv}
          />
        </Box>
        <Box>
          <Typography variant="h6" style={{ marginTop: 6 }}>
            PIX realizado em:
          </Typography>
          <> {formatDate()}</>
        </Box>
      </Box>
    </Box>
  );
};

export default PixDetails;
