import {
  Box,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Link, useHistory, useParams } from "react-router-dom";
import { loadCarneFilters } from "../../../actions/actions";
import useDebounce from "../../../hooks/useDebounce";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "../../reusable/CustomTable/CustomTable";
import GradientButton from "../../reusable/GradientButton/GradientButton";
import SearchBar from "../../reusable/SearchBar/SearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const columns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (data) => {
      const date = new Date(data);
      const option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const [dia] = date.toLocaleDateString("pt-br", option).split(" ");
      return <Typography align="center">{dia}</Typography>;
    },
  },

  { headerText: "Descrição", key: "descricao" },

  {
    headerText: "Comprador",
    key: "pagador",
    CustomValue: (pagador) => {
      return (
        <>
          <Typography>{pagador.nome ? pagador.nome : "-"}</Typography>
          <Typography>{pagador.documento ? pagador.documento : "-"}</Typography>
        </>
      );
    },
  },
  { headerText: "Parcelas", key: "parcelas" },

  {
    headerText: "Total",
    key: "valor",
    CustomValue: (valor) => <Typography>R${valor}</Typography>,
  },
];

const PaymentBooklet = () => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const carneList = useSelector((state) => state.carneList);
  const userData = useSelector((state) => state.userData);
  const { id } = useParams();
  const initialFilters = {
    like: "",
    id: "",
    order: "",
    mostrar: "",
  };
  const [filters, setFilters] = useState(initialFilters);

  const debouncedFilters = useDebounce(filters, 800);

  useEffect(() => {
    dispatch(
      loadCarneFilters({
        ...debouncedFilters,
        page,
        conta_id: id ?? userData.id,
      })
    );
  }, [dispatch, debouncedFilters, page, id, userData.id]);

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const handleClickRow = (row) => {
    const path = generatePath("/dashboard/detalhes-carne/:id/ver", {
      id: row.id,
    });
    history.push(path);
  };

  return (
    <Box display="flex" flexDirection="column">
      {id ? (
        <CustomBreadcrumbs
          path1="Gerenciar Listas"
          to1="goBack"
          path2="Carnê"
          to2="#"
        />
      ) : (
        <CustomBreadcrumbs path1="Carnê" to1="#" />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Typography style={{ marginTop: "8px" }} variant="h4">
          Lista de Carnês
        </Typography>

        {id ? null : (
          <Link to="novo-carne">
            <GradientButton buttonText="+ Nova Cobrança" />
          </Link>
        )}
      </Box>
      <Box marginTop="16px" marginBottom="16px" display="flex">
        <SearchBar
          fullWidth
          placeholder="Pesquisar nome, documento, email do pagador..."
          value={filters.like}
          onChange={(e) =>
            setFilters({
              ...filters,
              like: e.target.value,
            })
          }
        />
        <TextField
          style={{ marginLeft: "10px" }}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Id do carne"
          fullWidth
          label="Id Carne"
          value={filters.id}
          onChange={(e) =>
            setFilters({
              ...filters,
              id: e.target.value,
            })
          }
        />
        <Tooltip title="Limpar Filtros">
          <IconButton onClick={() => setFilters(initialFilters)}>
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Tooltip>
      </Box>
      {carneList.data && carneList.per_page ? (
        <CustomTable
          columns={columns}
          data={carneList.data}
          handleClickRow={handleClickRow}
        />
      ) : (
        <LinearProgress />
      )}

      <Box alignSelf="flex-end" marginTop="8px">
        <Pagination
          variant="outlined"
          color="secondary"
          size="large"
          count={carneList.last_page}
          onChange={handleChangePage}
          page={page}
        />
      </Box>
    </Box>
  );
};

export default PaymentBooklet;
