import { Box, Typography } from "@material-ui/core";
import React from "react";
import CustomCopyToClipboard from "../Text/CustomCopyToClipboard";

const IdsDetails = ({
  id,
  conciliationId,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Box>
        <Typography variant="h6">ID da transação:</Typography>
        <CustomCopyToClipboard title={"Copiar id da transação:"} value={id} />
      </Box>
      <Box>
        <Typography variant="h6">ID Conciliação:</Typography>
        <CustomCopyToClipboard title={"Copiar id de conciliação:"} value={conciliationId} />
      </Box>
    </Box>
  );
};

export default IdsDetails;
