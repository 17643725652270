import {
	Box,
	Dialog,
	DialogTitle,
	FormHelperText,
	makeStyles,
	TextField,
	Typography,
} from '@material-ui/core';
import React from 'react';
import CurrencyInput from 'react-currency-input';
import GradientButton from '../../../reusable/GradientButton/GradientButton';

const useStyles = makeStyles((theme) => ({
	UserInfosContainer: {
		width: '40%',
		display: 'flex',
		flexDirection: 'column',
		color: theme.palette.primary.main,
	},
	userContentsContainer: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.secondary.light,
		padding: '8px',
		borderRadius: '27px',
	},
	userContentItem: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.paper,
		padding: '12px',
		margin: '8px',
		borderRadius: '27px',
	},
	saqueModal: {
		padding: '20px',
	},
	saqueHeader: {
		background: theme.gradient.main,
		color: 'white',
	},
	currency: {
		font: 'inherit',
		color: 'currentColor',
		width: '100%',
		border: '0px',
		borderBottom: '1px solid gray',
		height: '1.1876em',
		margin: 0,
		display: 'block',
		padding: '6px 0 7px',
		minWidth: 0,
		background: 'none',
		boxSizing: 'content-box',
		animationName: 'mui-auto-fill-cancel',
		letterSpacing: 'inherit',
		animationDuration: '10ms',
		appearance: 'textfield',
		textAlign: 'start',
		paddingLeft: '5px',
	},
}));

const MakeTransferModal = ({
	transferenciaInfos,
	setTransferenciaInfos,
	onClose,
	selectedValue,
	open,
	nome,
	documento,
	razaoSocial,
	cnpj,
	handleTransferencia,
	errosTransferencia,
}) => {
	const classes = useStyles();
	const handleClose = () => {
		onClose(selectedValue);
	};

	return (
		<Dialog onClose={handleClose} open={open} className={classes.saqueModal}>
			<Box width="500px">
				<DialogTitle className={classes.saqueHeader}>
					<Typography align="center" variant="h6">
						Realizar Transferência
					</Typography>
				</DialogTitle>
				<Box display="flex" flexDirection="column" padding="24px">
					<Box display="flex" flexDirection="column">
						{razaoSocial && cnpj ? (
							<TextField
								fullWidth
								disabled
								InputLabelProps={{ shrink: true }}
								label="Razão Social "
								value={razaoSocial}
								style={{
									marginBottom: '6px',
									width: '60%',
									alignSelf: 'center',
								}}
							/>
						) : null}
						{cnpj && razaoSocial ? (
							<TextField
								fullWidth
								disabled
								InputLabelProps={{ shrink: true }}
								label="CNPJ"
								value={cnpj}
								style={{
									marginBottom: '6px',
									width: '60%',
									alignSelf: 'center',
								}}
							/>
						) : null}
						<TextField
							fullWidth
							disabled
							InputLabelProps={{ shrink: true }}
							label={razaoSocial ? 'Nome do Responsável' : 'Nome do Favorecido'}
							value={nome}
							style={{
								marginBottom: '6px',
								width: '60%',
								alignSelf: 'center',
							}}
						/>

						<TextField
							disabled
							fullWidth
							InputLabelProps={{ shrink: true }}
							label={razaoSocial ? 'Documento do Responsável' : 'Documento do Favorecido'}
							value={documento}
							style={{
								marginBottom: '6px',
								width: '60%',
								alignSelf: 'center',
							}}
						/>

						<Typography
							style={{
								alignSelf: 'center',
							}}
						>
							Valor da Transferência
						</Typography>
						<CurrencyInput
							className={classes.currency}
							decimalSeparator=","
							thousandSeparator="."
							prefix="R$ "
							value={transferenciaInfos.valor}
							onChangeEvent={(event, maskedvalue, floatvalue) =>
								setTransferenciaInfos({
									...transferenciaInfos,
									valor: floatvalue,
								})
							}
							style={{
								marginBottom: '6px',
								width: '60%',
								alignSelf: 'center',
							}}
						/>

						<Box alignItems="center">
							{errosTransferencia.valor ? (
								<FormHelperText style={{ color: 'red', textAlign: 'center' }}>
									{errosTransferencia.valor.join(' ')}
								</FormHelperText>
							) : null}
						</Box>

						<TextField
							fullWidth
							InputLabelProps={{ shrink: true }}
							label="Descrição da Transferência"
							value={transferenciaInfos.descricao}
							onChange={(e) =>
								setTransferenciaInfos({
									...transferenciaInfos,
									descricao: e.target.value,
								})
							}
							style={{
								marginBottom: '6px',
								width: '60%',
								alignSelf: 'center',
							}}
							required
							error={errosTransferencia.descricao ? true : false}
							helperText={errosTransferencia.descricao ? errosTransferencia.descricao.join(' ') : null}
						/>
					</Box>

					<Box alignSelf="flex-end" marginTop="6px">
						<GradientButton buttonText="Transferir" onClick={handleTransferencia} />
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
};

export default MakeTransferModal;
