import { faTrash, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadExportLancamentoFuturo,
  loadExportTodosLancamentos,
  loadLancamentosFuturos,
} from "../../../actions/actions";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomCopyToClipboard from "components/reusable/Text/CustomCopyToClipboard";
import CollapsibleTable from "components/reusable/CollapsibleTable/CollapsibleTable";
import DateColumn from "components/reusable/TableColumns/DateColumn";
import FullTransactionSummary from "components/reusable/Content/TransactionsTypes/FullTransactionSummary";
import { formatMoney } from "utils/money";
import GradientButton from "components/reusable/GradientButton/GradientButton";
import TransactionStatusColumn from "components/reusable/TableColumns/TransactionStatusColumn";
import Calendar from "./Calendar";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import ReleaseDetailsModal from "./ReleaseDetailsModal";
import { toast } from "react-toastify";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const columns = [
  {
    headerText: "Data de Liberação",
    key: "data_liberacao",
    CustomValue: (data_liberacao) => {
      return <DateColumn date={data_liberacao} />;
    },
  },
  {
    headerText: "Valor",
    key: "valor",
    CustomValue: (valor) => {
      return (
        <Typography
          style={{ fontSize: 17, color: "green", fontWeight: "bold" }}
        >
          {formatMoney(valor)}
        </Typography>
      );
    },
  },
];

const itemColumns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (date) => {
      return <DateColumn date={date} />;
    },
  },
  {
    headerText: "ID da transação",
    key: "id",
    CustomValue: (id) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          <CustomCopyToClipboard value={id} title="Copiar" />
        </Box>
      );
    },
  },
  {
    headerText: "Valor",
    key: "",
    FullCustom: (item) => {
      return formatMoney(
        parseFloat(item.amount) / 100 - parseFloat(item.fees) / 100
      );
    },
  },
  {
    headerText: "Parcela",
    key: "",
    FullCustom: (item) => {
      if (item.payment_type === "credit" || item.payment_type === "debit") {
        return (
          <Typography>
            {item.current_installment ?? "1"} de{" "}
            {item.installment_plan.number_installments}
          </Typography>
        );
      }
      return <Typography>Única</Typography>;
    },
  },
  {
    headerText: "Tipo",
    key: "payment_type",
    CustomValue: (type) => {
      if (type === "credit") {
        return <Typography>Crédito</Typography>;
      }
      if (type === "debit") {
        return <Typography>Débito</Typography>;
      }
      if (type === "boleto") {
        return <Typography>Boleto</Typography>;
      }
      if (type === "commission") {
        return <Typography>Comissão</Typography>;
      }
    },
  },
  {
    headerText: "Status",
    key: "transfer_status",
    CustomValue: (status) => {
      return <TransactionStatusColumn status={status} />;
    },
  },
];

const Lancamentos = () => {
  const dispatch = useDispatch();
  const userLancamentos = useSelector((state) => state.lancamentosFuturos);
  const userData = useSelector((state) => state.userData);
  const clickedOnSchedule = useLocation().state ?? false;
  const [page, setPage] = useState(1);
  const [isSchedule, setIsSchedule] = useState(clickedOnSchedule);
  const [loading, setLoading] = useState(false);
  const [openReleaseDetails, setOpenReleaseDetails] = useState(false);
  const [dateOnCalendar, setDateOnCalendar] = useState(new Date());
  const [filters, setFilters] = useState({
    data_liberacao_inicial: "",
    data_liberacao_final: "",
    esperado_em: new Date(),
  });
  const { id } = useParams();

  const formatedDate = useMemo(
    () => format(filters.esperado_em, "yyyy-MM-dd"),
    [filters.esperado_em]
  );

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const handleExportarLancamentoFuturo = async () => {
    setLoading(true);
    toast.warning("A exportação pode demorar um pouco, por favor aguarde...");
    const res = await dispatch(
      loadExportLancamentoFuturo({
        data_liberacao_inicial: filters.data_liberacao_inicial,
        data_liberacao_final: filters.data_liberacao_final,

        conta_id: id ?? userData.id,
      })
    );
    if (res && res.url !== undefined) {
      window.open(`${res.url}`, "", "");
    }
    setLoading(false);
  };

  const handleExportarTodosLancamentos = async () => {
    setLoading(true);
    toast.warning("A exportação pode demorar um pouco, por favor aguarde...");
    const res = await dispatch(
      loadExportTodosLancamentos({
        date: formatedDate,
        conta_id: id ?? userData.id,
      })
    );
    if (res && res.url !== undefined) {
      window.open(`${res.url}`, "", "");
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(
      loadLancamentosFuturos(
        page,
        filters.data_liberacao_inicial,
        filters.data_liberacao_final
      )
    );
  }, [page, filters, dispatch]);

  useEffect(() => {
    return () => {
      setFilters({ ...filters });
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <LoadingScreen isLoading={loading} />
      <CustomBreadcrumbs path1="Lançamentos" />

      <ReleaseDetailsModal
        modalVisible={openReleaseDetails}
        handleModalVisible={setOpenReleaseDetails}
        date={dateOnCalendar}
      />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          style={{ marginTop: "8px", marginBottom: "12px" }}
          variant="h4"
        >
          Lançamentos
        </Typography>
        <Box display="flex">
          <Box marginRight="5px">
            <GradientButton
              disabled={isSchedule}
              buttonText="Agenda de Recebíveis"
              onClick={() => setIsSchedule(true)}
            />
          </Box>
          <GradientButton
            disabled={!isSchedule}
            buttonText="Lançamentos Futuros"
            onClick={() => setIsSchedule(false)}
          />
        </Box>
      </Box>

      <Box display="flex" marginTop="8px">
        <Grid container>
          <Grid item xs={12} sm={4}>
            {isSchedule ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  views={["month", "year"]}
                  format="MM/yyyy"
                  label="Data de Liberação"
                  value={filters.esperado_em}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      esperado_em: !!e ? e : new Date(),
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            ) : (
              <Box style={{ display: "flex" }}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  label="Data Inicial"
                  value={filters.data_liberacao_inicial}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      data_liberacao_inicial: e.target.value,
                    })
                  }
                />
                <TextField
                  style={{ marginLeft: "20px" }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  label="Data Final"
                  value={filters.data_liberacao_final}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      data_liberacao_final: e.target.value,
                    })
                  }
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={6} sm={8}>
            <Box display="flex" width="100%" justifyContent="flex-end">
              <Tooltip title="Limpar Filtros">
                <IconButton
                  onClick={() =>
                    setFilters({
                      ...filters,
                      data_liberacao_inicial: "",
                      data_liberacao_final: "",
                      esperado_em: new Date(),
                    })
                  }
                >
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Exportar Excel">
                <IconButton
                  variant="outlined"
                  style={{ marginLeft: "6px" }}
                  onClick={
                    isSchedule
                      ? handleExportarTodosLancamentos
                      : handleExportarLancamentoFuturo
                  }
                >
                  <FontAwesomeIcon icon={faTable} color="green" />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box marginTop="16px" marginBottom="16px">
        {isSchedule ? (
          <Box marginTop="16px" marginBottom="16px">
            <FullTransactionSummary date={formatedDate} />
            <Calendar
              date={formatedDate}
              modalVisible={openReleaseDetails}
              handleModalVisible={setOpenReleaseDetails}
              handleSelectedCalendarDate={setDateOnCalendar}
            />
          </Box>
        ) : (
          <Box marginTop="16px" marginBottom="16px">
            {userLancamentos && userLancamentos.per_page ? (
              <CollapsibleTable
                itemColumns={itemColumns}
                data={userLancamentos.data}
                columns={columns}
                collapseKey="items"
              />
            ) : (
              <LinearProgress />
            )}
            <Box alignSelf="flex-end" marginTop="8px">
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={userLancamentos.last_page}
                onChange={handleChangePage}
                page={page}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Lancamentos;
