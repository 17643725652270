import {
	faCalendarAlt,
	faQuestionCircle,
	faTable,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	LinearProgress,
	makeStyles,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { TRANSFER_DETAILS } from "constants/actionsStrings";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
	loadExportHistoricoTransferencia,
	loadHistoricoTransferenciaFilters,
} from "../../../actions/actions";
import useDebounce from "../../../hooks/useDebounce";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "../../reusable/CustomTable/CustomTable";

const useStyles = makeStyles((theme) => ({
	currency: {
		font: "inherit",
		color: "currentColor",
		width: "100%",
		border: "0px",
		borderBottom: "1px solid gray",
		height: "1.1876em",
		margin: 0,
		display: "block",
		padding: "6px 0 7px",
		minWidth: 0,
		background: "none",
		boxSizing: "content-box",
		animationName: "mui-auto-fill-cancel",
		letterSpacing: "inherit",
		animationDuration: "10ms",
		appearance: "textfield",
		textAlign: "start",
		paddingLeft: "5px",
	},
  dialogTitle: {
    background: theme.palette.background.default,
    color: "white",
    textAlign: "center",
    marginBottom: 8,
  },
  text: {
    fontSize:"14px",
  },
  textValues: {
    fontSize:"18px",
  }
}));

const statusColor = (status) => {
  if(status === "created") return "green";
  if(status === "confirmed") return "green";
  if(status === "failed") return "red";
  if(status === "canceled") return "red";
  if(status === "succeeded") return "green";
  if(status === "pending") return "#CCCC00";
  if(status === "scheduled") return "#CCCC00";
  return "red";
}

const columns = [
	{
		headerText: "Criado em",
		key: "created_at",
		CustomValue: (data) => {
			const date = new Date(data);
			const option = {
				year: "numeric",
				month: "numeric",
				day: "numeric",
			};
			const formatted = date.toLocaleDateString("pt-br", option);
			return (
				<Box display="flex" justifyContent="center">
					<FontAwesomeIcon icon={faCalendarAlt} size="lg" />
					<Typography style={{ marginLeft: "6px" }}>{formatted}</Typography>
				</Box>
			);
		},
	},
	{
		headerText: "Situação",
		key: "custom_status",
		FullCustom: (row) => {
      return (
        <Typography
          style={{
            color: statusColor(row.status),
            fontWeight: "bold",
            borderRadius: "27px",
          }}
        >
          {row.status_string}
        </Typography>
      );
		},
	},
	{
		headerText: "Origem",
		key: "origem",
		CustomValue: (origem) => {
			const { documento, nome, razao_social, cnpj } = origem;
			return (
				<Box>
					<Typography align="center">
						<b>{razao_social ?? nome}</b>
					</Typography>
					<Typography align="center">
						{cnpj ?? documento}
					</Typography>
				</Box>
			);
		},
	},
	{
		headerText: "Destino",
		key: "destino",
		CustomValue: (destino) => {
			const { documento, nome, razao_social, cnpj } = destino;
			return (
				<Box>
					<Typography align="center">
						<b>{razao_social ?? nome}</b>
					</Typography>
					<Typography align="center">
						{cnpj ?? documento}
					</Typography>
				</Box>
			);
		},
	},
  {
		headerText: "Meio de Recebimento",
		key: "meio_de_recebimento",
		FullCustom: (row) => {
			return (
        <>
          <Typography><b>{row.transfer_details?.bank_account?.bank_name ?? "Sem nome"}</b></Typography>
          <Typography>
            Ag: {row.transfer_details?.bank_account?.routing_number ?? "..."}
            {" "}Conta: {row.transfer_details?.bank_account?.account_number ?? "..."}
          </Typography>
        </>
			);
		},
	},
	{ headerText: "Tipo", key: "tipo" },
	{
		headerText: "Valor",
		key: "valor",
		CustomValue: (valor) => {
			return (
				<Typography>
					R$ <b>{valor}</b>
				</Typography>
			);
		},
	},
	{
		headerText: "Descrição",
		key: "descricao",
		CustomValue: (descricao) => {
			return (
				<Tooltip title={descricao ? descricao : "Sem descrição"}>
					<Box>
						<FontAwesomeIcon icon={faQuestionCircle} />
					</Box>
				</Tooltip>
			);
		},
	},
];

const TransferHistory = () => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	const dispatch = useDispatch();
	const historicoTransferencia = useSelector((state) => state.historicoTransferencia);
	const userData = useSelector((state) => state.userData);
	const { id } = useParams();
	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		like: "",
		valor: "",
		data: "",
	});
  const [openTransferDetails, setOpenTransferDetails] = useState(false);
  const transferDetails = useSelector((state) => state.transferDetails);

	const debouncedInputValue = useDebounce(filters.like, 800);

	const handleChangePage = (e, value) => {
		setPage(value);
	};

  const handleClickRow = async (row) => {
    setOpenTransferDetails(true);
    dispatch({
			type: TRANSFER_DETAILS,
			payload: row,
		});
  };

  const handleCloseTransferDetails = () => {
    setOpenTransferDetails(false);
  };

	useEffect(() => {
		dispatch(
			loadHistoricoTransferenciaFilters(page, debouncedInputValue, filters.valor, filters.data, id ?? userData.id)
		);
	}, [dispatch, page, filters.valor, filters.data, debouncedInputValue, id, userData.id]);

	const handleExportarTransferencia = async () => {
		const res = await dispatch(
			loadExportHistoricoTransferencia(page, debouncedInputValue, filters.valor, filters.data, id ?? userData.id)
		);
		if (res && res.url !== undefined) {
			window.open(`${res.url}`, "", "");
		}
	};

	return (
		<Box display="flex" flexDirection="column">
			{id ? (
				<CustomBreadcrumbs path1="Gerenciar Listas" to1="goBack" path2="Histórico de Transferências" to2="#" />
			) : (
				<CustomBreadcrumbs path1="Histórico de Transferências" to1="#" />
			)}
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? "column" : null}>
				<Typography style={{ marginTop: "8px" }} variant="h4">
					Histórico de Transferências
				</Typography>
			</Box>
      <Dialog
        open={openTransferDetails}
        onClose={handleCloseTransferDetails}>
        <DialogTitle className={classes.dialogTitle}>
          Detalhes da Transferência
        </DialogTitle>
        {openTransferDetails &&
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <Box alignSelf="center">
                <Typography
                  variant="h6"
                  style={{
                    color: statusColor(transferDetails.status),
                    fontWeight: "bold",
                  }}
                >
                  {transferDetails.status_string}
                </Typography>
              </Box>

              <Typography className={classes.text}>Valor da transferência</Typography>
              <Typography className={classes.textValues}><b>R$ {transferDetails.valor}</b></Typography>
              <Typography className={classes.text}>Tipo</Typography>
              <Typography className={classes.textValues}><b>{transferDetails.tipo}</b></Typography>
              <Typography className={classes.text}>Descrição</Typography>
              <Typography className={classes.textValues}><b>{transferDetails.descricao}</b></Typography>
              <Typography className={classes.text}>Id da transferência</Typography>
              <Typography className={classes.textValues}><b>{transferDetails.id}</b></Typography>
              <Typography className={classes.text}>Id de conciliação</Typography>
              <Typography className={classes.textValues}><b>{transferDetails.zoop_transfers_id}</b></Typography>

              <Divider style={{margin: "18px 0px"}}/>
              
              <Typography variant="h6">Conta de origem</Typography>
              <Typography className={classes.text}>Nome do titular</Typography>
              <Typography className={classes.textValues}><b>{transferDetails.origem.razao_social ?? transferDetails.origem.nome}</b></Typography>
              <Typography className={classes.text}>{transferDetails.origem.cnpj ? "CNPJ" : "CPF"}</Typography>
              <Typography className={classes.textValues}><b>{transferDetails.origem.cnpj ?? transferDetails.origem.documento}</b></Typography>
              <Typography className={classes.text}>E-mail</Typography>
              <Typography className={classes.textValues}><b>{transferDetails.origem.email}</b></Typography>

              <Divider style={{margin: "18px 0px"}}/>

              <Typography variant="h6">Conta de destino</Typography>
              <Typography className={classes.text}>Nome do titular</Typography>
              <Typography className={classes.textValues}><b>{transferDetails.destino.razao_social ?? transferDetails.destino.nome}</b></Typography>
              <Typography className={classes.text}>{transferDetails.destino.cnpj ? "CNPJ" : "CPF"}</Typography>
              <Typography className={classes.textValues}><b>{transferDetails.destino.cnpj ?? transferDetails.destino.documento}</b></Typography>
              <Typography className={classes.text}>E-mail</Typography>
              <Typography className={classes.textValues}><b>{transferDetails.destino.email}</b></Typography>
              <Typography className={classes.text}>Banco</Typography>
              <Typography className={classes.textValues}>
                <b>{transferDetails.transfer_details?.bank_account?.bank_name ?? "Sem nome"}</b>
              </Typography>
              <Typography className={classes.text}>Agência</Typography>
              <Typography className={classes.textValues}><b>{transferDetails.transfer_details?.bank_account?.routing_number ?? "..."}</b></Typography>
              <Typography className={classes.text}>Conta</Typography>
              <Typography className={classes.textValues}><b>{transferDetails.transfer_details?.bank_account?.account_number ?? "..."}</b></Typography>
            </Box>
          </DialogContent>
        }
      </Dialog>
			<Box
				display="flex"
				flexDirection={matches ? "column" : null}
				alignContent="space-between"
				marginTop="16px"
				marginBottom="16px"
			>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={3}>
						<TextField
							fullWidth
							label="Conta de Destino"
							InputLabelProps={{ shrink: true }}
							placeholder="Pesquisar por nome, documento..."
							value={filters.like}
							onChange={(e) =>
								setFilters({
									...filters,
									like: e.target.value,
								})
							}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							fullWidth
							InputLabelProps={{
								shrink: true,
								pattern: "d {4}- d {2}- d {2} ",
							}}
							type="date"
							label="Data"
							value={filters.data}
							onChange={(e) => setFilters({ ...filters, data: e.target.value })}
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<Typography
							style={{
								alignSelf: "center",
								fontSize: "11px",
								color: "gray",
							}}
						>
							Valor da Transferência
						</Typography>
						<CurrencyInput
							className={classes.currency}
							decimalSeparator=","
							thousandSeparator="."
							prefix="R$ "
							value={filters.valor}
							onChangeEvent={(event, maskedvalue, floatvalue) =>
								setFilters({ ...filters, valor: floatvalue })
							}
							style={{
								marginBottom: "6px",
								width: "100%",
								alignSelf: "center",
							}}
						/>
					</Grid>
				</Grid>
				<Box display="flex">
					<Tooltip title="Limpar Filtros">
						<IconButton
							onClick={() =>
								setFilters({
									...filters,
									like: "",
									valor: "",
									data: "",
								})
							}
						>
							<FontAwesomeIcon icon={faTrash} color="gray" />
						</IconButton>
					</Tooltip>

					<Tooltip title="Exportar Excel">
						<IconButton
							variant="outlined"
							style={{ marginLeft: "6px" }}
							onClick={handleExportarTransferencia}
						>
							<FontAwesomeIcon icon={faTable} color="green" />
						</IconButton>
					</Tooltip>
				</Box>
			</Box>

			{historicoTransferencia.data && historicoTransferencia.per_page ? (
				<CustomTable columns={columns} data={historicoTransferencia.data} handleClickRow={handleClickRow}/>
			) : (
				<LinearProgress />
			)}
			<Box alignSelf="flex-end" marginTop="8px">
				{
					<Pagination
						variant="outlined"
						color="secondary"
						size="large"
						count={historicoTransferencia.last_page}
						onChange={handleChangePage}
						page={page}
					/>
				}
			</Box>
		</Box>
	);
};

export default TransferHistory;
