import {
  AppBar,
  Box,
  Divider,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import SwipeableViews from "react-swipeable-views";
import { useSelector } from "react-redux";
import GradientButton from "../../reusable/GradientButton/GradientButton";
import { toast } from "react-toastify";
import CreateBankAccount from "../BankAccounts/CreateBankAccount/CreateBankAccount";
import ListBankAccounts from "../BankAccounts/ListBankAccounts/ListBankAccounts";
import LoadingScreen from "../../reusable/LoadingScreen/LoadingScreen";
import PublicTokens from "../PublicTokens/PublicTokens";
import { useHistory } from "react-router";
import { useShowAgentQuery, useUpdateAgentMutation } from "services/api";
import LoggedUserDocuments from "components/reusable/Documents/LoggedUserDocuments";
import CreateEditAgentForm from "components/reusable/Agent/CreateEditAgentForm";
import UpdateLogedUserPassword from "components/reusable/Password/UpdateLogedUserPassword";
import AgentLogo from "components/reusable/Agent/AgentLogo";
import AgentThemeColors from "components/reusable/Agent/AgentThemeColors";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    borderRadius: "27px",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const EditLoginAgent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [updateAgent, updateAgentStatus] = useUpdateAgentMutation();
  const updateAgentError = useMemo(
    () => (updateAgentStatus.error ? updateAgentStatus.error.data.errors : {}),
    [updateAgentStatus]
  );
  const userData = useSelector((state) => state.userData);
  
  const [contaBancaria, setContaBancaria] = useState({
    banco: "",
    agencia: "",
    tipo: "1",
    conta: "",
  });

  const [agentToUpdate, setAgentToUpdate] = useState({});

  const {
    data: agent,
    refetch,
    isLoading,
    isError,
    isUninitialized,
  } = useShowAgentQuery(
    {
      agent_id: userData.owner_agent_id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !userData.owner_agent_id
    }
  );

  useEffect(() => {
    if(isError && !isUninitialized && !isLoading) {
      history.push("/dashboard/home");
      toast.error("Erro!");
    }
	}, [history, isError, isUninitialized, isLoading]);

  useEffect(() => {
    setAgentToUpdate({
      ...agent,
      site: agent?.agent_account?.site,
      renda: agent?.agent_account?.renda
    });
  }, [agent]);

  const handleAlterar = async () => {
    setLoading(true);
    try {
      await updateAgent({
        agent_id: agent.id,
        agent: agentToUpdate
      }).unwrap();
      toast.success("Representante atualizado com sucesso!");
      refetch();
    } catch (e) {
      toast.error("Erro ao atualizar Representante!");
    }
    setLoading(false);
  };
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box className={classes.root}>
      <LoadingScreen isLoading={loading || isLoading} />
      <CustomBreadcrumbs path1="Editar conta" to1="#"/>
      <Typography
        style={{ marginTop: "8px", marginBottom: "8px" }}
        variant="h4"
      >
        Editar Conta
      </Typography>
      <Paper className={classes.paper}>
        <AppBar
          position="static"
          style={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: "27px 27px 0 0 ",
            zIndex: "0",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            style={{
              color: theme.palette.primary.main
            }}
          >
            <Tab
              label="Dados Cadastrais"
              style={{ width: "60%" }}
              {...a11yProps(0)}
            />
            <Tab
              label="Alterar senha"
              style={{ width: "60%" }}
              {...a11yProps(1)}
            />
            <Tab
              label="Documentos"
              style={{ width: "60%" }}
              {...a11yProps(2)}
            />
            <Tab
              label="Dados bancários"
              style={{ width: "60%" }}
              {...a11yProps(3)}
            />
            <Tab
              label="Tokens Públicos"
              style={{ width: "60%" }}
              {...a11yProps(4)}
            />
            <Tab
              label="Logo"
              style={{ width: "60%" }}
              {...a11yProps(5)}
            />
            <Tab
              label="Tema"
              style={{ width: "60%" }}
              {...a11yProps(6)}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box display="flex" flexDirection="column">
              <CreateEditAgentForm
                isError={updateAgentStatus.isError}
                errorsInfo={updateAgentError}
                agentInfo={agentToUpdate}
                setAgentInfo={setAgentToUpdate}
                disableEditar={true}
              />

              <Box display="flex" justifyContent="flex-end" marginTop="16px">
                <GradientButton
                  buttonText="Atualizar"
                  onClick={handleAlterar}
                />
              </Box>
            </Box>
          </TabPanel>

          <TabPanel value={value} index={1} dir={theme.direction}>
            <UpdateLogedUserPassword
              loading={loading}
              setLoading={setLoading}
            />
          </TabPanel>

          <TabPanel value={value} index={2} dir={theme.direction}>
            <LoggedUserDocuments
              loading={loading}
              setLoading={setLoading}
            />
          </TabPanel>

          <TabPanel value={value} index={3} dir={theme.direction}>
            <Box>
              <Typography align="center" variant="h6">
                Lista de contas bancárias
              </Typography>
              <ListBankAccounts contaId={userData.id} />
              <Box margin="16px"> </Box>
              <Divider />
              <Box margin="16px"> </Box>
              <CreateBankAccount
                contaId={userData.id}
                contaBancaria={contaBancaria}
                setContaBancaria={setContaBancaria}
              />
            </Box>
          </TabPanel>

          <TabPanel value={value} index={4} dir={theme.direction}>
            <Box display="flex" flexDirection="column">
              <Typography align="center" variant="h6">
                Tokens Públicos
              </Typography>
              <Box margin="16px"> </Box>
              <PublicTokens />
              <Divider />
              <Box margin="16px"> </Box>
              <GradientButton
                style={{ width: "50%", alignSelf: "center" }}
                buttonText="Criar novo token"
                onClick={() => history.push("/dashboard/novo-token-publico")}
              />
            </Box>
          </TabPanel>

          <TabPanel value={value} index={5} dir={theme.direction}>
            <Box display="flex" flexDirection="column">
              <Typography align="center" variant="h6">
                Logo
              </Typography>
              <AgentLogo
                setLoading={setLoading}
              />
            </Box>
          </TabPanel>

          <TabPanel value={value} index={6} dir={theme.direction}>
            <Box display="flex" flexDirection="column">
              <AgentThemeColors setLoading={setLoading}/>
            </Box>
          </TabPanel>
        </SwipeableViews>
      </Paper>
    </Box>
  );
};

export default EditLoginAgent;
