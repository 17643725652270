import React, { useEffect } from "react";
import {
  Box,
  Button,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import ListItems from "./ListItems/ListItems";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { loadContaId } from "../../../actions/actions";
import CurrencyFormat from "react-currency-format";
import { APP_CONFIG } from "constants/config";
import UserMenu from "./UserMenu/UserMenu";

const useStyles = makeStyles((theme) => ({
  sideBar: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "20px",
    width: "100%",
  },
  logo: {
    height: "100px",
  },
  userMenuContainer: {},
  listContainer: {
    color: "#fff",
    width: "100%",
  },
  footer: {},
}));

const SideBar = () => {
  const theme = useTheme();
  const classes = useStyles();
  const contaSelecionada = useSelector((state) => state.conta);
  const userData = useSelector((state) => state.userData);
  const history = useHistory();
  const { id, section } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id && userData === "" && section === "contas") {
      dispatch(loadContaId(id));
    }
  }, [dispatch, id, userData, section]);

  const handleLogout = () => {
    localStorage.removeItem("@auth");
    history.push("/login");
  };

  const exceptSections = [
    "lista-de-administradores",
    "plano-vendas",
  ];

  const hideInfos = (section) => {
    return exceptSections.some((exceptSection) => exceptSection === section)
  };

  return (
    <Box className={classes.sideBar}>
      <img
        className={classes.logo}
        alt="Logo"
        src={APP_CONFIG().assets.primaryLogo}
      />
      <Box className={classes.listContainer}>
        <UserMenu />
      </Box>
      <Box className={classes.listContainer}>
        <ListItems />
      </Box>

      {id && userData === "" && !hideInfos(section) ? (
        <Box
          style={{ color: "white" }}
          display="flex"
          flexDirection="column"
          alignContent="center"
          alignItems="center"
          marginBottom="30px"
        >
          <Typography variant="h5">Conta Selecionada: </Typography>
          <Typography style={{ wordWrap: "break-word", color: theme.palette.menu.text}} align="center">
            {contaSelecionada.razao_social
              ? contaSelecionada.razao_social
              : contaSelecionada.nome
              ? contaSelecionada.nome
              : null}
          </Typography>
          <Typography style={{ color: theme.palette.menu.text }}>
            {contaSelecionada.cnpj
              ? contaSelecionada.cnpj
              : contaSelecionada.documento
              ? contaSelecionada.documento
              : null}
          </Typography>
          <Typography style={{ color: theme.palette.menu.text }}>
            {contaSelecionada.saldo ? (
              <CurrencyFormat
                value={contaSelecionada.saldo.valor.replace(".", ",")}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"R$ "}
                renderText={(value) => <div> Saldo: {value}</div>}
              />
            ) : null}
          </Typography>
        </Box>
      ) : null}

      <Box marginTop="12px">
        <Button style={{ color: theme.palette.menu.text }} onClick={handleLogout}>
          Sair
        </Button>
      </Box>
    </Box>
  );
};

export default SideBar;
