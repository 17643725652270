import {
  Box,
  Button,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  useMediaQuery,
  Tooltip,
  IconButton,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import { generatePath, useHistory } from "react-router";
import { Pagination } from "@material-ui/lab";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import useDebounce from "hooks/useDebounce";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import SearchBar from "components/reusable/SearchBar/SearchBar";
import { useIndexAgentQuery } from "services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CreateAgentDialog from "./CreateAgentDialog";
import ImportAgentDialog from "./ImportAgentDialog";
import HeadButton from "components/reusable/Buttons/HeadButton";
import AccountStatusColumn from "components/reusable/TableColumns/AccountStatusColumn";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  dialogHeader: {
    textAlign: "center",
    background: theme.gradient.main,
    color: "white",
  },
  button: {
    width: "70px",
  },
}));

const columns = [
  {
    headerText: "Nome",
    key: "nome_key",
    FullCustom: (row) => {
      return <Typography align="center">
        {row.cnpj ? row.business_name : `${row.owner_first_name} ${row.owner_last_name}`}
      </Typography>;
    },
  },
  {
    headerText: "Documento",
    key: "document_key",
    FullCustom: (row) => {
      return <Typography align="center">
        {row.cnpj ? row.cnpj : row.owner_cpf}
      </Typography>;
    },
  },
  { headerText: "E-mail", key: "contact_email" },
  { headerText: "Criado por", key: "created_by_email" },
  {
    headerText: "Status",
    key: "agent_account_status",
    FullCustom: (row) => {
      return <AccountStatusColumn status={row.agent_account.status} />
    }
  },
  { headerText: "Ecs", key: "ec_count" },
  {
    headerText: "Criado",
    key: "created_at",
    CustomValue: (data) => {
      const date = new Date(data);
      const option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const [day, hour] = date.toLocaleDateString("pt-br", option).split(" ");
      return <>
        <Typography align="center">
          {day}
        </Typography>
        <Typography align="center">
          {hour}
        </Typography>
      </>
    },
  },
  {
    headerText: "Deletado",
    key: "deleted_at",
    CustomValue: (data) => {
      if(data == null) return<Typography align="center">-</Typography>;
      const date = new Date(data);
      const option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const [day, hour] = date.toLocaleDateString("pt-br", option).split(" ");
      return <>
        <Typography align="center">
          {day}
        </Typography>
        <Typography align="center">
          {hour}
        </Typography>
      </>
    },
  },
  { headerText: "", key: "menu" },
];

const AgentList = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [openCreateAgentDialog, setOpenCreateAgentDialog] = useState(false);
  const [openImportAgentDialog, setOpenImportAgentDialog] = useState(false);
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState({
    like: "",
    trashedAgents: false,
  });

  const debouncedLike = useDebounce(filters.like, 800);

  const {
    data: agentList,
    refetch: refetchAgents,
    isLoading,
    isError,
    isUninitialized,
  } = useIndexAgentQuery(
    {
      page,
      like: debouncedLike,
      trashed_agents: filters.trashedAgents ? 1 : 0
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const Editar = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleManageUsers = (row) => {
      const path = generatePath("/dashboard/representantes/:id/users", {
        id: row.id,
      });
      history.push(path);
      setAnchorEl(null);
    };

    const handleAgentDetails = (row) => {
      const path = generatePath("/dashboard/representantes/:id/detalhes", {
        id: row.id,
      });
      history.push(path);
      setAnchorEl(null);
    };

    const handleEditAgent = async (row) => {
      const path = generatePath("/dashboard/representantes/:id/editar", {
        id: row.id,
      });
      history.push(path);
      setAnchorEl(null);
    };

    const handleManageList = async (row) => {
      const path = generatePath("/dashboard/contas/:id/listas", {
        id: row.conta_id,
      });
      history.push(path);
      setAnchorEl(null);
    };

    const handleIndexAgentSalesPlans = async (row) => {
      const path = generatePath("/dashboard/representantes/:id/planos-de-vendas", {
        id: row.id,
      });
      history.push(path);
      setAnchorEl(null);
    };

    return (
      <Box>
        <>
          <Button
            style={{ height: "15px", width: "10px" }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            >
            ...
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {!row.deleted_at &&
              <>
                <MenuItem onClick={() => handleEditAgent(row)}>Editar</MenuItem>
                <MenuItem onClick={() => handleAgentDetails(row)}>Detalhes</MenuItem>
                <MenuItem onClick={() => handleManageUsers(row)}>Gerenciar Usuários</MenuItem>
                <MenuItem onClick={() => handleIndexAgentSalesPlans(row)}>Planos de Vendas</MenuItem>
              </>
            }
            <MenuItem onClick={() => handleManageList(row)}>Gerenciar Listas</MenuItem>
          </Menu>
        </>
      </Box>
    );
  };

  return (
    <Box className={classes.root}>
      <CustomBreadcrumbs path1="Representantes" to1="#"/>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Box marginBottom={2}>
          <Typography variant="h4" style={{ color: theme.palette.background.default }}>
            REPRESENTANTES
          </Typography>
        </Box>
        <Box display="flex" marginBottom={2}>
          <Box marginRight="5px">
            <HeadButton
              onClick={() => {
                setOpenImportAgentDialog(true);
              }}
              text="Importar Representante"
            />
          </Box>
          <Box>
            <HeadButton
              onClick={() => {
                setOpenCreateAgentDialog(true);
              }}
              text="Criar Representante"
            />
          </Box>
        </Box>
      </Box>
      <Box marginBottom="16px">
        <SearchBar
          style={{
            width:"90%"
          }}
          placeholder="Pesquisar por nome, documento, e-mail..."
          value={filters.like}
          onChange={(e) =>
            setFilters({
              ...filters,
              like: e.target.value,
            })
          }
        />
        <Tooltip title={filters.trashedAgents ? "Mostrando Representantes excluidos" : "Mostrando Representantes ativos"}>
          <IconButton
            onClick={() =>
              setFilters({
                ...filters,
                trashedAgents: !filters.trashedAgents,
              })
            }
          >
            <FontAwesomeIcon
              icon={filters.trashedAgents ? faTrash : faCheckCircle}
              color={filters.trashedAgents ? "red" : "green"}
            />
          </IconButton>
        </Tooltip>
      </Box>

      <ImportAgentDialog
        open={openImportAgentDialog}
        setOpen={setOpenImportAgentDialog}
        refetchAgents={refetchAgents}
      />
      <CreateAgentDialog
        open={openCreateAgentDialog}
        setOpen={setOpenCreateAgentDialog}
        refetchAgents={refetchAgents}
      />

      {!isLoading && !isError && !isUninitialized ? (
        <>
          <CustomTable
            columns={columns}
            data={agentList.data}
            Editar={Editar}
          />
          <Box alignSelf="flex-end" marginTop="8px">
            <Pagination
              variant="outlined"
              color="secondary"
              size="large"
              count={agentList.last_page}
              onChange={handleChangePage}
              page={page}
            />
          </Box>
        </>
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
};

export default AgentList;
