export const getLogsQuery = ({ like, page, data_inicial, data_final }) => ({
  url: `/logs`,
  method: "get",
  params: { like, page, data_inicial, data_final },
});

export const getDetailedLogsQuery = ({
  page,
  type,
  event,
  data_inicial,
  data_final,
}) => ({
  url: `/detailed-logs`,
  method: "get",
  params: { page, type, event, data_inicial, data_final },
});
