import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Header from "./Header";
import Trailer from "./Trailer";
import DetailsList from "./DetailsList";
import GradientButton from "components/reusable/GradientButton/GradientButton";
import { useStoreArquivoRemessaMutation } from "services/api";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory, generatePath } from "react-router";
import { useState } from "react";
import TextFieldController from "components/reusable/FormFields/TextFieldController";
import { toast } from "react-toastify";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";

const ArquivoRemessaForm = () => {
  const theme = useTheme();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(false);

  const headerData = useLocation().state.response.header;
  const trailerData = useLocation().state.response.trailer;
  const detailsData = useLocation().state.response.details;
  const nameData = useLocation().state.response.name;
  const isOnlyShow = useLocation().state.isOnlyShow;
  const shippingFileId = useLocation().state.shippingFileId;
  const accountId = useLocation().state.accountId;

  const [storeArquivoRemessa] = useStoreArquivoRemessaMutation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: nameData,
      header: { ...headerData },
      details: [...detailsData],
      trailer: { ...trailerData },
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "details",
  });

  const handleShowItems = () => {
    const path = generatePath(
      `/dashboard/arquivo-remessa/${shippingFileId}/itens`
    );
    history.push(path, { accountId });
  };

  const submit = async (data) => {
    if (isOnlyShow) return;

    setLoading(true);
    try {
      await storeArquivoRemessa(data).unwrap();
      setLoading(false);
      history.push("/dashboard/arquivo-remessa");
      toast.success(
        "Dados enviados com sucesso, os boletos serão gerados em instantes"
      );
    } catch (e) {
      if (e.status === 422) {
        toast.error(e.data.message);
        Object.entries(e.data.errors).forEach(([name, messages]) =>
          setError(name, {
            type: "manual",
            message: messages[0],
          })
        );
      }
    }
    setLoading(false);
  };

  return (
    <Box>
      <LoadingScreen isLoading={loading} />

      {accountId ? (
        <CustomBreadcrumbs
          path1="Arquivo de remessa"
          to1={`/dashboard/contas/${accountId}/arquivo-remessa`}
          path2="Detalhe do arquivo de remessa "
        />
      ) : (
        <CustomBreadcrumbs
          path1="Arquivo de remessa"
          to1="/dashboard/arquivo-remessa"
          path2={
            isOnlyShow
              ? "Detalhe do arquivo de remessa "
              : "Criar arquivo de remessa"
          }
        />
      )}

      <Box
        marginBottom={3}
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Typography
          variant="h4"
          style={{ color: theme.palette.background.default }}
        >
          FORMULÁRIO
        </Typography>

        {isOnlyShow ? (
          <Button
            variant="outlined"
            style={{ marginTop: "8px", marginBottom: "12px" }}
            onClick={handleShowItems}
          >
            MOSTRAR ITENS DE REMESSA
          </Button>
        ) : null}
      </Box>

      <form onSubmit={handleSubmit(submit)}>
        <Box marginBottom={3} display="flex" justifyContent="end">
          {isOnlyShow ? null : (
            <GradientButton
              buttonText={"Salvar"}
              disabled={loading}
              type="submit"
            />
          )}
        </Box>

        <Box marginBottom={5}>
          <Box marginTop={2}>
            <Grid item xs={12} sm={12}>
              <TextFieldController
                control={control}
                error={errors}
                nameController="name"
                nameField="name"
                label="Nome para o arquivo de remessa"
                required
                disabled={isOnlyShow}
              />
            </Grid>
          </Box>
        </Box>

        <Box marginBottom={5}>
          <Typography
            variant="h6"
            style={{ color: theme.palette.background.default }}
          >
            CABEÇALHO
          </Typography>

          <Header
            error={errors.header}
            control={control}
            disabled={isOnlyShow}
          />
        </Box>
        <Box marginBottom={2}>
          <DetailsList
            fields={fields}
            control={control}
            errors={errors.details}
            disabled={isOnlyShow}
          />
        </Box>
        <Box marginBottom={5}>
          <Typography
            variant="h6"
            style={{ color: theme.palette.background.default }}
          >
            RODAPÉ
          </Typography>

          <Trailer error={errors.trailer} control={control} />
        </Box>
      </form>
    </Box>
  );
};

export default ArquivoRemessaForm;
