import {
  faBan,
  faBarcode,
  faCheck,
  faCopy,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faForward,
  faHistory,
  faLink,
  faSignOutAlt,
  faUndo,
  faUsers,
  faUsersCog,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import { Box, Switch, makeStyles, Typography } from "@material-ui/core";
import { USER_PERMISSIONS } from "constants/permissions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import {
  delPermissao,
  loadPermissaoGerenciar,
  postPermissaoAction,
} from "../../../actions/actions";
import AccountCollectionItem from "../../reusable/Content/AccountCollections/AccountCollectionItem/AccountCollectionItem";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import LoadingScreen from "../../reusable/LoadingScreen/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  responsiveContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(850)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const UserPermissions = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const gerenciarPermissao = useSelector((state) => state.gerenciarPermissao);
  const [permissoes, setPermissoes] = useState([]);

  useEffect(() => {
    dispatch(loadPermissaoGerenciar(id));
  }, []);

  useEffect(() => {
    const { permissao } = gerenciarPermissao;
    setPermissoes(permissao.map((item) => item.tipo));
  }, [gerenciarPermissao, gerenciarPermissao.permissao.length]);

  useEffect(() => {
    return () => {
      setPermissoes([]);
    };
  }, []);

  const handlePermissoes = async (event) => {
    setLoading(true);
    if (permissoes.includes(event.target.name)) {
      const response = await dispatch(delPermissao(id, event.target.value));

      response
        ? toast.success("Permissão removida!")
        : toast.error("Erro ao remover permissão!");
    } else {
      const response = await dispatch(
        postPermissaoAction(id, event.target.value)
      );

      response
        ? toast.success("Permissão adicionada!")
        : toast.error("Erro ao adicionar permissão!");
    }
    await dispatch(loadPermissaoGerenciar(id));
    setLoading(false);
  };

  return (
    <Box display="flex" flexDirection="column">
      <LoadingScreen isLoading={loading} />
      <CustomBreadcrumbs
        path1="Contas"
        path2="Usuários"
        to1="/dashboard/contas"
        to2={`/dashboard/contas/${gerenciarPermissao.conta_id}/users`}
        path3="Gerenciar Permissões"
      />
      <Typography style={{ marginTop: "8px" }} variant="h4">
        Gerenciar Permissões
      </Typography>

      <Box className={classes.responsiveContainer}>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Máquina Virtual" icon={faCreditCard} />
          <Switch
            name={USER_PERMISSIONS.BILLING_CARD}
            value={1}
            checked={
              permissoes.includes(USER_PERMISSIONS.BILLING_CARD) ? true : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Boleto" icon={faBarcode} />
          <Switch
            name={USER_PERMISSIONS.BILLING_BOLETO}
            value={2}
            checked={
              permissoes.includes(USER_PERMISSIONS.BILLING_BOLETO)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Carnê" icon={faCopy} />
          <Switch
            name={USER_PERMISSIONS.BILLING_CARNE}
            value={3}
            checked={
              permissoes.includes(USER_PERMISSIONS.BILLING_CARNE) ? true : false
            }
            onClick={handlePermissoes}
          />
        </Box>
      </Box>

      <Box className={classes.responsiveContainer}>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Cobrança Recorrente" icon={faUndo} />
          <Switch
            name={USER_PERMISSIONS.BILLING_SUBSCRIPTION}
            value={4}
            checked={
              permissoes.includes(USER_PERMISSIONS.BILLING_SUBSCRIPTION)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Link de Pagamento" icon={faLink} />
          <Switch
            name={USER_PERMISSIONS.BILLING_PAYMENT_LINK}
            value={5}
            checked={
              permissoes.includes(USER_PERMISSIONS.BILLING_PAYMENT_LINK)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Extrato" icon={faDollarSign} />
          <Switch
            name={USER_PERMISSIONS.FINANCE_STATEMENT}
            value={6}
            checked={
              permissoes.includes(USER_PERMISSIONS.FINANCE_STATEMENT)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
      </Box>

      <Box className={classes.responsiveContainer}>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem
            text="Histórico de Transações"
            icon={faHistory}
          />
          <Switch
            name={USER_PERMISSIONS.FINANCE_TRANSACTION_HISTORY}
            value={7}
            checked={
              permissoes.includes(USER_PERMISSIONS.FINANCE_TRANSACTION_HISTORY)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Lançamentos" icon={faForward} />
          <Switch
            name={USER_PERMISSIONS.FINANCE_FUTURE_RELEASES}
            value={8}
            checked={
              permissoes.includes(USER_PERMISSIONS.FINANCE_FUTURE_RELEASES)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem
            text="Realizar Transferência"
            icon={faSignOutAlt}
          />
          <Switch
            name={USER_PERMISSIONS.TRANSFER}
            value={9}
            checked={
              permissoes.includes(USER_PERMISSIONS.TRANSFER) ? true : false
            }
            onClick={handlePermissoes}
          />
        </Box>
      </Box>

      <Box className={classes.responsiveContainer}>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem
            text="Histórico Transferências"
            icon={faCheck}
          />
          <Switch
            name={USER_PERMISSIONS.TRANSFER_HISTORY}
            value={10}
            checked={
              permissoes.includes(USER_PERMISSIONS.TRANSFER_HISTORY)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Pagadores" icon={faUsers} />
          <Switch
            name={USER_PERMISSIONS.OTHER_PAYERS}
            value={12}
            checked={
              permissoes.includes(USER_PERMISSIONS.OTHER_PAYERS) ? true : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Contas Digitais" icon={faDesktop} />
          <Switch
            name={USER_PERMISSIONS.OTHER_DIGITAL_ACCOUNTS}
            value={11}
            checked={
              permissoes.includes(USER_PERMISSIONS.OTHER_DIGITAL_ACCOUNTS)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
      </Box>

      <Box className={classes.responsiveContainer}>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem
            text="Gerenciar meus Usuários"
            icon={faUsersCog}
          />
          <Switch
            name={USER_PERMISSIONS.OTHER_MY_USERS}
            value={26}
            checked={
              permissoes.includes(USER_PERMISSIONS.OTHER_MY_USERS)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Bloquer Saldo" icon={faBan} />
          <Switch
            name={USER_PERMISSIONS.OTHER_BLOCK_BALANCE}
            value={14}
            checked={
              permissoes.includes(USER_PERMISSIONS.OTHER_BLOCK_BALANCE)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCollectionItem text="Arquivo Remessa" icon={faFolderOpen} />
          <Switch
            name={USER_PERMISSIONS.BILLING_SHIPPING_FILE}
            value={29}
            checked={
              permissoes.includes(USER_PERMISSIONS.BILLING_SHIPPING_FILE)
                ? true
                : false
            }
            onClick={handlePermissoes}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserPermissions;
