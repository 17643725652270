import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  loadExportExtrato,
  loadExportExtratoPdf,
  loadExtratoFilter,
} from "../../../actions/actions";
import Pagination from "@material-ui/lab/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import CustomCollapseTable from "../../reusable/CustomCollapseTable/CustomCollapseTable";
import {
  faBan,
  faPrint,
  faTable,
  faTrash,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { generatePath, useHistory, useParams } from "react-router";
import useDebounce from "../../../hooks/useDebounce";
import CurrencyFormat from "react-currency-format";
import { getLastBalance } from "../../../services/services";
import { toast } from "react-toastify";
import CustomCopyToClipboard from "components/reusable/Text/CustomCopyToClipboard";

const useStyles = makeStyles((theme) => ({
  userContentItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.light,
    padding: "12px",
    margin: "8px 0px 8px 0px",
    borderRadius: "27px",
  },
}));

const columns = [
  {
    headerText: "Detalhes da Transação",
    key: "data",
    CustomValue: (data) => {
      const p = data.split(/\D/g);
      const dataFormatada = [p[2], p[1], p[0]].join("/");
      return (
        <Box display="flex" justifyContent="center">
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
          <Typography style={{ marginLeft: "6px" }}>{dataFormatada}</Typography>
        </Box>
      );
    },
  },
  {
    headerText: "Valor Bloqueado",
    key: "valor_bloqueado",
    CustomValue: (value) => (
      <Box display="flex" justifyContent="center">
        <FontAwesomeIcon icon={faBan} size="lg" />
        <Typography style={{ marginLeft: "6px", color: "red" }}>
          <b>R$ {value}</b>
        </Typography>
      </Box>
    ),
  },
  {
    headerText: "Saldo do dia",
    key: "saldo",
    CustomValue: (value) => (
      <Box display="flex" justifyContent="center">
        <FontAwesomeIcon icon={faWallet} size="lg" />
        <Typography style={{ marginLeft: "6px" }}>
          <b>R$ {value}</b>
        </Typography>
      </Box>
    ),
  },
];
const itemColumns = [
  {
    headerText: <Typography variant="h6">Descrição</Typography>,
    key: "description",
    CustomValue: (description) => {
      return (
        <Typography variant="" style={{ fontSize: 16 }}>
          {description}
        </Typography>
      );
    },
  },
  {
    headerText: <Typography variant="h6">Id Conciliação</Typography>,
    key: "transaction.id",
    CustomValue: (id) => {
      return id ? (
        <Box display="flex" justifyContent="center">
          <CustomCopyToClipboard
            value={id}
            title="Copiar"
            message="Link copiado com sucesso"
          />
        </Box>
      ) : null;
    },
  },
  {
    headerText: <Typography variant="h6">nsu</Typography>,
    key: "nsu",
    CustomValue: (nsu) => {
      return (
        <Typography variant="" style={{ fontSize: 16 }}>
          {nsu}
        </Typography>
      );
    },
  },
  {
    headerText: <Typography variant="h6">Taxas</Typography>,
    key: "fee",
    CustomValue: (fee) => {
      if (fee > 0) {
        return (
          <Typography
            variant=""
            style={{ fontSize: 16, color: "#dfad06", fontWeight: 600 }}
          >
            R$ {fee}
          </Typography>
        );
      } else {
        return (
          <Typography
            variant=""
            style={{ fontSize: 16, color: "	green", fontWeight: 600 }}
          >
            R$ {fee}
          </Typography>
        );
      }
    },
  },
  {
    headerText: <Typography variant="h6">Valor</Typography>,
    key: "amount",
    CustomValue: (amount) => {
      if (amount < 0) {
        return (
          <Typography
            variant=""
            style={{ fontSize: 17, fontWeight: 600, color: "red" }}
          >
            R$ {amount}
          </Typography>
        );
      } else {
        return (
          <Typography
            variant=""
            style={{ fontSize: 17, fontWeight: 600, color: "green" }}
          >
            R$ {amount}
          </Typography>
        );
      }
    },
  },
];

const AccountStatement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const userExtrato = useSelector((state) => state.extrato);
  const userData = useSelector((state) => state.userData);
  const { id } = useParams();
  const history = useHistory();
  const [filters, setFilters] = useState({
    id: "",
    day: "",
    order: "",
    mostrar: "",
    tipo: "",
    data_inicial: "",
    data_final: "",
  });
  const debouncedId = useDebounce(filters.id, 800);
  const [lastBalance, setLastBalance] = useState({
    saldo: "0.00",
    valor: "0.00",
    valor_bloqueado: "0.00",
    data: "",
  });

  useEffect(() => {
    dispatch(
      loadExtratoFilter(
        page,
        debouncedId,
        filters.day,
        filters.order,
        filters.mostrar,
        filters.tipo,
        filters.data_inicial,
        filters.data_final,
        id ?? userData.id
      )
    );
  }, [
    dispatch,
    filters.day,
    filters.order,
    filters.mostrar,
    filters.tipo,
    filters.data_inicial,
    filters.data_final,
    page,
    debouncedId,
    id,
    userData.id,
  ]);

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const handleClickRow = (row) => {
    if (row.transaction && row.transaction.id) {
      const path = generatePath("/dashboard/detalhes-transacao/:id/ver", {
        id: row.transaction.id,
      });
      history.push(path);
    } else {
      return null;
    }
  };

  const handleExportarExtrato = async () => {
    const res = await dispatch(
      loadExportExtrato(
        page,
        debouncedId,
        filters.day,
        filters.order,
        filters.mostrar,
        filters.tipo,
        filters.data_inicial,
        filters.data_final,
        id ?? userData.id
      )
    );
    if (res && res.url !== undefined) {
      window.open(`${res.url}`, "", "");
    }
  };

  const handleExportarExtratoPdf = () => {
    dispatch(
      loadExportExtratoPdf(
        page,
        debouncedId,
        filters.day,
        filters.order,
        filters.mostrar,
        filters.tipo,
        filters.data_inicial,
        filters.data_final,
        id ?? userData.id
      )
    );
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getLastBalance(id ?? userData.id);
        console.log(res);
        setLastBalance({
          saldo: res?.data?.saldo,
          valor: res?.data?.valor,
          valor_bloqueado: res?.data?.valor_bloqueado,
          data: res?.data?.data,
        });
      } catch (error) {
        toast.warning("Não foi encontrado movimentações!");
      }
    }
    fetchData();
  }, [id, userData.id]);

  return (
    <Box display="flex" flexDirection="column">
      {id ? (
        <CustomBreadcrumbs
          path1="Gerenciar Listas"
          to1="goBack"
          path2="Extrato"
          to2="#"
        />
      ) : (
        <CustomBreadcrumbs path1="Extrato" to1="#" />
      )}
      <Typography style={{ marginTop: "8px" }} variant="h4">
        Extrato
      </Typography>
      <Box display="flex" style={{ marginTop: "10px", marginBottom: "16px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar por ID de conciliação"
              fullWidth
              label="ID de conciliação"
              value={filters.id}
              onChange={(e) => setFilters({ ...filters, id: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel>Período</InputLabel>
            <Select
              fullWidth
              value={filters.day}
              onChange={(e) => setFilters({ ...filters, day: e.target.value })}
            >
              <MenuItem value=" ">Todos</MenuItem>
              <MenuItem value={1}>Hoje</MenuItem>
              <MenuItem value={7}>Últimos 7 dias</MenuItem>
              <MenuItem value={15}>Últimos 15 dias</MenuItem>
              <MenuItem value={30}>Últimos 30 dias</MenuItem>
              <MenuItem value={60}>Últimos 60 dias</MenuItem>
              <MenuItem value={90}>Últimos 90 dias</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Inicial"
              value={filters.data_inicial}
              onChange={(e) =>
                setFilters({ ...filters, data_inicial: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Final"
              value={filters.data_final}
              onChange={(e) =>
                setFilters({ ...filters, data_final: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.userContentItem}>
        <Box display="flex" flexWrap="wrap">
          <Box paddingRight="30px">
            <Typography color="primary" style={{ margin: "2px" }}>
              SALDO ATUAL:
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                style={{
                  color: "#008000",
                  fontSize: "1.6rem",
                  fontWeight: "bold",
                }}
              >
                <CurrencyFormat
                  value={lastBalance.saldo}
                  displayType={"text"}
                  thousandSeparator={","}
                  decimalSeparator={"."}
                  prefix={"R$ "}
                  renderText={(value) => <div>{value}</div>}
                />
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography color="primary" style={{ margin: "2px" }}>
              BLOQUEADO ATUAL:
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                style={{
                  color: "#FF0000",
                  fontSize: "1.6rem",
                  fontWeight: "bold",
                }}
              >
                <CurrencyFormat
                  value={lastBalance.valor_bloqueado}
                  displayType={"text"}
                  thousandSeparator={","}
                  decimalSeparator={"."}
                  prefix={"R$ "}
                  renderText={(value) => <div>{value}</div>}
                />
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex">
          <Tooltip title="Limpar Filtros">
            <IconButton
              onClick={() =>
                setFilters({
                  ...filters,
                  day: " ",
                  order: "",
                  mostrar: "",
                  tipo: "",
                  data_inicial: "",
                  data_final: "",
                })
              }
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Exportar Excel">
            <IconButton
              variant="outlined"
              style={{ marginLeft: "6px" }}
              onClick={handleExportarExtrato}
            >
              <FontAwesomeIcon icon={faTable} color="green" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Exportar PDF">
            <IconButton
              variant="outlined"
              style={{ marginLeft: "6px" }}
              onClick={handleExportarExtratoPdf}
            >
              <FontAwesomeIcon icon={faPrint} color="green" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {userExtrato && userExtrato.per_page ? (
        <CustomCollapseTable
          itemColumns={itemColumns}
          data={userExtrato.data}
          columns={columns}
          handleClickRow={handleClickRow}
        />
      ) : (
        <LinearProgress />
      )}
      <Box alignSelf="flex-end" marginTop="8px">
        {
          <Pagination
            variant="outlined"
            color="secondary"
            size="large"
            count={userExtrato.last_page}
            onChange={handleChangePage}
            page={page}
          />
        }
      </Box>
    </Box>
  );
};

export default AccountStatement;
