import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import {
  loadRecoveryPassword,
  postPrimeiroAcesso,
} from "../../../actions/actions";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Paper, useTheme } from "@material-ui/core";
import getResponseError from "helpers/getResponseError";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    background: theme.gradient.light,
  },

  paperBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },

  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    width: "40%",
    borderRadius: "27px",
    animation: `$myEffect 1000ms ${theme.transitions.easing.easeInOut}`,
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      padding: "20px",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
      transform: "translateX(-10%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
}));

const RecoveryPassword = () => {
  const classes = useStyles();
  const [user, setUser] = useState({
    email: "",
    token: "",
    password: "",
    password_confirmation: "",
  });
  const theme = useTheme();
  const [errosUser, setErrosUser] = useState({});
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [recoveringPassword, setRecoveringPassword] = useState(true);
  const [resetingPasswor, setResetingPasswor] = useState(false);
  const dispatch = useDispatch();

  const resendToken = async () => {
    setLoading(true);
    setErrosUser({});

    const resUser = await dispatch(loadRecoveryPassword(user.email));

    if (resUser) {
      setErrosUser(getResponseError(resUser));
    } else {
      toast.success("Um email será enviado para o endereço informado!");
      setRecoveringPassword(false);
      setResetingPasswor(true);
    }

    setLoading(false);
  };

  const handleNewPassword = async () => {
    setLoading(true);
    setErrosUser({});

    const resUser = await dispatch(postPrimeiroAcesso(user));
    if (resUser) {
      setErrosUser(resUser);
    } else {
      toast.success("Nova senha cadastrada com sucesso!");
      history.push("/login");
    }

    setLoading(false);
  };

  return (
    <Box className={classes.root}>
      <LoadingScreen isLoading={loading} />
      {recoveringPassword && (
        <Box className={classes.paperBox}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar} />
            <Typography
              component="h1"
              variant="h5"
              style={{ marginBottom: "4px" }}
            >
              Recuperar senha
            </Typography>

            <form className={classes.form}>
              <TextField
                error={errosUser.email}
                helperText={errosUser.email ? errosUser.email.join(" ") : null}
                type="email"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Digite seu email"
                name="email"
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                required
              />
              <Button
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{
                  borderRadius: "27px",
                  background: theme.gradient.main,
                }}
                onClick={resendToken}
              >
                Enviar
              </Button>
            </form>
          </Paper>
        </Box>
      )}
      {resetingPasswor && (
        <Box className={classes.paperBox}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar} />
            <Typography
              component="h1"
              variant="h5"
              style={{ marginBottom: "4px" }}
            >
              Cadastrar nova senha
            </Typography>

            <form className={classes.form}>
              <TextField
                error={errosUser.token}
                helperText={errosUser.token ? errosUser.token.join(" ") : null}
                autoFocus
                label="Código de verificação enviado por e-mail"
                variant="outlined"
                fullWidth
                required
                value={user.token}
                onChange={(e) => setUser({ ...user, token: e.target.value })}
              />
              <TextField
                error={errosUser.email}
                helperText={errosUser.email ? errosUser.email.join(" ") : null}
                type="email"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Digite seu email"
                name="email"
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                required
              />
              <TextField
                error={errosUser.password}
                helperText={
                  errosUser.password ? errosUser.password.join(" ") : null
                }
                type="password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Digite sua nova senha"
                id="password"
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />
              <TextField
                error={errosUser.password_confirmation}
                helperText={
                  errosUser.password_confirmation
                    ? errosUser.password_confirmation.join(" ")
                    : null
                }
                type="password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Confirmar senha"
                id="password"
                value={user.password_confirmation}
                onChange={(e) =>
                  setUser({ ...user, password_confirmation: e.target.value })
                }
              />
              <Button
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{
                  borderRadius: "27px",
                  background: theme.gradient.main,
                }}
                onClick={handleNewPassword}
              >
                Cadastrar
              </Button>
            </form>
            <Typography variant="body2">Não recebeu o código?</Typography>
            <Button
              size="small"
              style={{ height: "28px", borderRadius: "27px" }}
              onClick={() => {
                setRecoveringPassword(true);
                setResetingPasswor(false);
              }}
            >
              Enviar novamente
            </Button>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default RecoveryPassword;
