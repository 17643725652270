import React, { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { useSetDefaltSalesPlanAppAccountMutation, useShowDefaltSalesPlanAppAccountQuery } from "services/api";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
}));

const SetDefaultAppAccount = ({
  openDialog,
  setOpenDialog,
  isLoading,
  setIsLoading
}) => {
  const classes = useStyles();
  const [setDefaultAppAccount] = useSetDefaltSalesPlanAppAccountMutation();

  const {
    data: defaultAccount,
    isLoading: isLoadingDefaultAccount,
    isError,
    refetch
  } = useShowDefaltSalesPlanAppAccountQuery();

  useEffect(() => { 
    if(isError){
      toast.error("Conta padrão da aplicação não encontrada!");
    };
  }, [isError])

  useEffect(() => { 
    if(openDialog) refetch();
  }, [refetch, openDialog])

  const handleSetDefaultAppAccount = async () => {
    setIsLoading(true);
    try {
      await setDefaultAppAccount({
        conta_id: null
      }).unwrap();
      toast.success("Conta padrão da aplicação definida!");
    } catch (e) {
      toast.error("Erro ao definir conta padrão da aplicação!");
    } finally {
      setOpenDialog(false);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ zIndex: 1000 }}
    >
      <Box width="600px">
        <DialogTitle className={classes.dialogHeader}>
          <Typography align="center" variant="h6">
            Conta Padrão da Aplicação
          </Typography>
        </DialogTitle>

        {!isLoadingDefaultAccount && defaultAccount &&
          <Box display="flex" alignItems="center" flexDirection="column" flexWrap="wrap" padding="6px 16px">
            <Box>
              <Typography>
                Conta padrão atual:
              </Typography>
              <Typography variant="h6">
                {defaultAccount.razao_social ?? defaultAccount.nome}
              </Typography>
              <Typography>
                Documento:
              </Typography>
              <Typography variant="h6">
                {defaultAccount.cnpj ?? defaultAccount.documento}
              </Typography>              
            </Box>
          </Box>
        }

        <Box padding="6px 16px">
          <Divider />
        </Box>

        <Box display="flex" flexDirection="column" padding="6px 16px">
          <Typography align="center">
            Escolha um EC para defini-lo como conta padrão da aplicação!
          </Typography>
          
          <Typography align="center" style={{ margin: "20px", color:"red" }} >
            Todos os MDRs da aplicação, são distribuidos para essa conta!
          </Typography>

          <DialogActions>
            <Button
              onClick={handleSetDefaultAppAccount}
              variant="outlined"
              color="secondary"
            >
              Definir
            </Button>
            <Button
              onClick={() => setOpenDialog(false)}
              color="default"
              variant="outlined"
              autoFocus
            >
              Cancelar
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SetDefaultAppAccount;
