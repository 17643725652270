import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { useStoreAgentMutation } from "services/api";
import CreateEditAgentForm from "components/reusable/Agent/CreateEditAgentForm";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import GradientButton from "components/reusable/GradientButton/GradientButton";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
}));

const CreateAgentDialog = ({
  open,
  setOpen,
  refetchAgents
}) => {
  const classes = useStyles();
  const [storeAgent, storeAgentStatus] = useStoreAgentMutation();
  const storeAgentError = useMemo(
    () => (storeAgentStatus.error ? storeAgentStatus.error.data.errors : {}),
    [storeAgentStatus]
  );
  const [loadingStore, setLoadingStore] = useState(false);
  const [newAgent, setNewAgent] = useState({});

  const handleStoreAgent = async () => {
    setLoadingStore(true);
    try {
      await storeAgent(newAgent).unwrap();
      toast.success("Representante criado com sucesso!");
      refetchAgents();
      handleCloseCreateAgent();
    } catch (e) {
      console.log({e});
      if(e.data?.message) {
        toast.error(e.data.message);
      }
      toast.error("Erro ao criar Representante!");
    }
    setLoadingStore(false);
  };

  const handleCloseCreateAgent = () => {
    setOpen(false);
    setNewAgent({});
    storeAgentStatus.reset();
  };

  return (
    <Dialog
        open={open}
        onClose={handleCloseCreateAgent}
        maxWidth={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          width={"70vw"}
        >
          <LoadingScreen isLoading={loadingStore} />
          <DialogTitle className={classes.dialogHeader}>
            Criar Representante
          </DialogTitle>
          <Box margin="20px">
            <CreateEditAgentForm
              isError={storeAgentStatus.isError}
              errorsInfo={storeAgentError}
              agentInfo={newAgent}
              setAgentInfo={setNewAgent}
              disableEditar={false}
            />
          </Box>
          <Box alignSelf="end" display="flex" padding="12px 24px">
            <Box margin="6px 0" padding="0 12px">
              <GradientButton buttonText={"Criar"} onClick={handleStoreAgent} />
            </Box>
            <Box>
              <Button
                style={{ borderRadius: "37px", margin: "6px 0" }}
                variant="outlined"
                onClick={handleCloseCreateAgent}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
  );
};

export default CreateAgentDialog;
