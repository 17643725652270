import { Box, Divider, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(3),
    borderRadius: "0 27px 27px 0",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0 27px 0 0",
      margin: "0px",
    },
  },
  content: {
    margin: "14px",
  },
}));

const PaymentLinkPayInfos = ({ linkPagamentoId }) => {
  const classes = useStyles();

  return (
    <Box>
      <Paper className={classes.root}>
        <Box className={classes.content}>
          <Typography
            variant="overline"
            style={{ fontSize: 14, lineHeight: 1 }}
          >
            Beneficiário - Nome/Razão Social
          </Typography>
          <Divider />

          {linkPagamentoId.conta && !linkPagamentoId.conta.razao_social && (
            <Typography variant="h6" style={{ fontSize: 14 }} color="initial">
              {linkPagamentoId.conta && linkPagamentoId.conta.nome
                ? linkPagamentoId.conta.nome
                : null}
            </Typography>
          )}

          <Typography
            variant="h6"
            style={{ fontSize: 14, marginTop: 6 }}
            color="initial"
          >
            {linkPagamentoId.conta && linkPagamentoId.conta.razao_social
              ? linkPagamentoId.conta.razao_social
              : null}
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography
            variant="overline"
            style={{ fontSize: 14, lineHeight: 1 }}
          >
            DOCUMENTO - CPF/CNPJ
          </Typography>
          <Divider />
          {linkPagamentoId.conta && !linkPagamentoId.conta.razao_social && (
            <Typography variant="h6" style={{ fontSize: 14 }} color="initial">
              {linkPagamentoId.conta && linkPagamentoId.conta.documento
                ? linkPagamentoId.conta.documento
                : null}
            </Typography>
          )}
          <Typography
            variant="h6"
            style={{ fontSize: 14, marginTop: 6 }}
            color="initial"
          >
            {linkPagamentoId.conta && linkPagamentoId.conta.cnpj
              ? linkPagamentoId.conta.cnpj
              : null}
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography
            variant="overline"
            style={{ fontSize: 14, lineHeight: 1 }}
          >
            Valor
          </Typography>
          <Divider />
          <Typography variant="h6" style={{ fontSize: 14 }} color="initial">
            R${" "}
            {linkPagamentoId && linkPagamentoId.valor
              ? linkPagamentoId.valor
              : null}
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography
            variant="overline"
            style={{ fontSize: 14, lineHeight: 1 }}
          >
            Parcelas
          </Typography>
          <Divider />
          <Typography variant="h6" style={{ fontSize: 14 }} color="initial">
            {linkPagamentoId?.minimo_parcelas !=
            linkPagamentoId?.limite_parcelas
              ? `${linkPagamentoId?.minimo_parcelas}x até ${linkPagamentoId?.limite_parcelas}x`
              : `até ${linkPagamentoId?.limite_parcelas}x`}
          </Typography>
        </Box>

        {linkPagamentoId && linkPagamentoId.numero_pedido ? (
          <Box className={classes.content}>
            <Typography
              variant="overline"
              style={{ fontSize: 14, lineHeight: 1 }}
            >
              Número do pedido
            </Typography>
            <Divider />
            <Typography variant="h6" style={{ fontSize: 14 }} color="initial">
              {linkPagamentoId.numero_pedido}
            </Typography>
          </Box>
        ) : null}
        {linkPagamentoId && linkPagamentoId.descricao ? (
          <Box className={classes.content}>
            <Typography
              variant="overline"
              style={{ fontSize: 14, lineHeight: 1 }}
            >
              Descrição
            </Typography>
            <Divider />
            <Typography variant="h6" style={{ fontSize: 14 }} color="initial">
              {linkPagamentoId.descricao}
            </Typography>
          </Box>
        ) : null}
      </Paper>
    </Box>
  );
};

export default PaymentLinkPayInfos;
