import {
  AppBar,
  Box,
  Divider,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import SwipeableViews from "react-swipeable-views";
import { useDispatch, useSelector } from "react-redux";
import NewAccount from "../../reusable/Account/NewAccount/NewAccount";
import GradientButton from "../../reusable/GradientButton/GradientButton";
import {
  loadUserData,
  updateUserConta,
} from "../../../actions/actions";
import { toast } from "react-toastify";
import CreateBankAccount from "../BankAccounts/CreateBankAccount/CreateBankAccount";
import ListBankAccounts from "../BankAccounts/ListBankAccounts/ListBankAccounts";
import LoadingScreen from "../../reusable/LoadingScreen/LoadingScreen";
import PublicTokens from "../PublicTokens/PublicTokens";
import { useHistory } from "react-router";
import { useReactivateAccountsAccountMutation } from "services/api";
import LoggedUserDocuments from "components/reusable/Documents/LoggedUserDocuments";
import UpdateLogedUserPassword from "components/reusable/Password/UpdateLogedUserPassword";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    borderRadius: "27px",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const EditLoginAccount = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [reactivateAccount] = useReactivateAccountsAccountMutation();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [conta, setConta] = useState({
    nome: "",
    endereco: {
      cep: "",
    },
  });
  const [contaBancaria, setContaBancaria] = useState({
    banco: "",
    agencia: "",
    tipo: "1",
    conta: "",
  });
  const [errosConta, setErrosConta] = useState({});

  useEffect(() => {
    setConta({ ...userData });
  }, [userData]);

  useEffect(() => {
    if(userData.owner_agent_id) {
      history.push("home");
      toast.error("Erro!");
    }
	}, [dispatch, history, userData.owner_agent_id]);

  const handleAlterar = async () => {
    setLoading(true);

    const resConta = await dispatch(updateUserConta(conta));

    if (resConta) {
      setErrosConta(resConta);
      toast.error("Erro ao alterar dados");
      setLoading(false);
    } else {
      toast.success("Dados alterados com sucesso!");
      setLoading(false);
    }
  };

  const handleReactivateAccount = async () => {
    setLoading(true);
    try {
      const response = await reactivateAccount({
        conta_id: userData.id,
      }).unwrap();
      await dispatch(loadUserData());
      response.success
        ? toast.success(response.message)
        : toast.error(response.message);

      if (response.success && response.status_code === 201) {
        history.push("conta-reativar");
      }
    } catch (e) {
      toast.error("Erro ao reativar conta!");
    }
    setLoading(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box className={classes.root}>
      <LoadingScreen isLoading={loading} />
      <CustomBreadcrumbs path1="Editar conta" />
      <Typography
        style={{ marginTop: "8px", marginBottom: "8px" }}
        variant="h4"
      >
        Editar Conta
      </Typography>
      <Paper className={classes.paper}>
        <AppBar
          position="static"
          style={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: "27px 27px 0 0 ",
            zIndex: "0",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            centered
            style={{
              color: theme.palette.primary.main
            }}
          >
            <Tab
              label="Dados Cadastrais"
              style={{ width: "60%" }}
              {...a11yProps(0)}
            />
            <Tab
              label="Alterar senha"
              style={{ width: "60%" }}
              {...a11yProps(1)}
            />
            <Tab
              label="Documentos"
              style={{ width: "60%" }}
              {...a11yProps(2)}
            />
            <Tab
              label="Dados bancários"
              style={{ width: "60%" }}
              {...a11yProps(3)}
            />
            <Tab
              label="Tokens Públicos"
              style={{ width: "60%" }}
              {...a11yProps(4)}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box display="flex" flexDirection="column">
              <NewAccount
                conta={conta}
                setConta={setConta}
                errosConta={errosConta}
                disableEditar="true"
              />
              {userData.status === "deleted" ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  marginTop="16px"
                >
                  <Box marginRight="16px">
                    <Typography
                      color="secondary"
                      style={{ color: "red", textAlign: "center" }}
                    >
                      Esta conta está inativa!
                    </Typography>
                  </Box>
                  <Box>
                    <GradientButton
                      buttonText="Reativar"
                      onClick={handleReactivateAccount}
                    />
                  </Box>
                </Box>
              ) : (
                <Box display="flex" justifyContent="flex-end" marginTop="16px">
                  <GradientButton
                    buttonText="Atualizar"
                    onClick={handleAlterar}
                  />
                </Box>
              )}
            </Box>
          </TabPanel>

          <TabPanel value={value} index={1} dir={theme.direction}>
            <UpdateLogedUserPassword
              loading={loading}
              setLoading={setLoading}
            />
          </TabPanel>

          <TabPanel value={value} index={2} dir={theme.direction}>
            <LoggedUserDocuments
              loading={loading}
              setLoading={setLoading}
            />
          </TabPanel>

          <TabPanel value={value} index={3} dir={theme.direction}>
            <Box>
              <Typography align="center" variant="h6">
                Lista de contas bancárias
              </Typography>
              <ListBankAccounts contaId={userData.id} />
              <Box margin="16px"> </Box>
              <Divider />
              <Box margin="16px"> </Box>
              <CreateBankAccount
                contaId={userData.id}
                contaBancaria={contaBancaria}
                setContaBancaria={setContaBancaria}
              />
            </Box>
          </TabPanel>

          <TabPanel value={value} index={4} dir={theme.direction}>
            <Box display="flex" flexDirection="column">
              <Typography align="center" variant="h6">
                Tokens Públicos
              </Typography>
              <Box margin="16px"> </Box>
              <PublicTokens />
              <Divider />
              <Box margin="16px"> </Box>
              <GradientButton
                style={{ width: "50%", alignSelf: "center" }}
                buttonText="Criar novo token"
                onClick={() => history.push("/dashboard/novo-token-publico")}
              />
            </Box>
          </TabPanel>
        </SwipeableViews>
      </Paper>
    </Box>
  );
};

export default EditLoginAccount;
