import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, makeStyles, Modal, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearToken, postTokenAction } from '../../../../actions/actions';
import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import GradientButton from '../../../reusable/GradientButton/GradientButton';
import LoadingScreen from '../../../reusable/LoadingScreen/LoadingScreen';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 'auto',
		marginRight: 'auto',
		padding: theme.spacing(3),
		width: '500px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	paperModal: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const NewPublicToken = () => {
	const classes = useStyles();
	const [tokenName, setTokenName] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const dispatch = useDispatch();
	const publicToken = useSelector((state) => state.publicToken);
	const [loading, setLoading] = useState(false);

	const handleCriarToken = async () => {
		setLoading(true);
		await dispatch(postTokenAction(tokenName));
		setLoading(false);
		setModalOpen(true);
	};

	const body = (
		<Box display="flex" flexDirection="column" className={classes.paperModal}>
			<Box display="flex" flexDirection="column">
				<Typography>
					Código do token: <b>{publicToken.token}</b>
				</Typography>
				<Tooltip title="Copiar">
					<CopyToClipboard text={publicToken.token}>
						<Button
							aria="Copiar"
							style={{
								marginLeft: '6px',
								width: '60px',
								height: '20px',
								alignSelf: 'center',
								color: 'green',
							}}
							onClick={() =>
								toast.success('Link copiado com sucesso', {
									autoClose: 2000,
								})
							}
						>
							<FontAwesomeIcon style={{ width: '60px', height: '20px' }} icon={faCopy} />
						</Button>
					</CopyToClipboard>
				</Tooltip>
			</Box>
			<Typography variant="overline">
				Importante: O código só poderá ser acessado desta vez, então salve em um arquivo de texto
				separado.
			</Typography>
		</Box>
	);
	return (
		<>
			<CustomBreadcrumbs path1="Tokens Públicos" to1="/dashboard/editar-conta" path2="Novo Token" />
			<LoadingScreen isLoading={loading} />

			<Box display="flex" flexDirection="column">
				<Typography variant="h4" style={{ marginTop: '12px', marginBottom: '12px' }}>
					Novo Token
				</Typography>

				<Paper className={classes.paper}>
					<TextField label="Nome" value={tokenName} onChange={(e) => setTokenName(e.target.value)} />
					<Box marginTop="12px" alignSelf="center">
						<GradientButton buttonText="Criar Token" onClick={handleCriarToken} />
					</Box>
				</Paper>
				<Modal
					open={modalOpen}
					onClose={() => {
						dispatch(clearToken());
						setModalOpen(false);
					}}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
				>
					{body}
				</Modal>
			</Box>
		</>
	);
};

export default NewPublicToken;
