import React, { useMemo } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    '&:nth-of-type(odd)': {
			backgroundColor: theme.palette.primary.light,
		},
		'&:hover': {
			cursor: 'pointer',
			backgroundColor:  theme.palette.secondary.light,
		},
  },
}))(TableRow);

const useTableStyles = makeStyles((theme) => ({
	tableContainer: {
		borderRadius: '15px 15px 0 0',
	},
  tableHead: {
		backgroundColor: theme.palette.primary.main,
	},
  tableCellHead: {
		color: theme.palette.primary.light,
	},
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
		'&:hover': {
			cursor: 'pointer',
			backgroundColor:  theme.palette.secondary.light,
		},
  },
  rowOdd: {
    backgroundColor: theme.palette.primary.light,
  },
  rowEven: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function Row(props) {
  const { row, columns, itemColumns, defaultOpen, title, collapseKey, handleClickRow, backgroundRowClass } = props;
  const items = useMemo(() => get(row, collapseKey) ?? [row], [row, collapseKey]);
  const [open, setOpen] = React.useState(defaultOpen);
  const tableClasses = useTableStyles();

  return (
    <React.Fragment>
      <TableRow className={[tableClasses.tableRow, backgroundRowClass]} key={`head-row-values-${row.id}`} onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns?.map((item) =>
          item.CustomValue
            ? <TableCell align="center">{item.CustomValue(get(row, item.key))}</TableCell>
            : <TableCell align="center">{get(row, item.key)}</TableCell>
        )}
      </TableRow>
      <TableRow key={`collapse-row-items-${row.id}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {title &&
                <Typography variant="h6" gutterBottom component="div">
                  {title}
                </Typography>
              }
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow key={`collapse-row-items-head-${row.id}`}>
                    {itemColumns?.map((itemColumn) =>
                      <TableCell align="center">{itemColumn.headerText}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items?.map((item) =>
                    <StyledTableRow key={`collapse-row-item-${item.id}`}>
                      {itemColumns.map((itemColumn) => {
                          if(itemColumn.CustomValue) return <TableCell align="center">{itemColumn.CustomValue(get(item, itemColumn.key))}</TableCell>
                          if(itemColumn.FullObject) return <TableCell align="center">{itemColumn.FullObject(row)}</TableCell>
                          if(itemColumn.FullCustom) return <TableCell align="center">{itemColumn.FullCustom(item)}</TableCell>
                          return <TableCell align="center">{get(item, itemColumn.key)}</TableCell>
                      })}
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  const {
    columns,
    itemColumns,
    data,
    defaultOpen = false,
    title,
    collapseKey,
    handleClickRow = () => {}
  } = props;
  const tableClasses = useTableStyles();
  let isOdd = true;

  return (
    <TableContainer className={tableClasses.tableContainer} component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead className={tableClasses.tableHead}>
          <TableRow key={`table-head`}>
            <TableCell />
            {columns?.map((item) =>
              <TableCell className={tableClasses.tableCellHead} align="center">{item.headerText}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0
            ? data?.map((row) => {
              const backgroundRowClass = isOdd ? tableClasses.rowOdd : tableClasses.rowEven;
              isOdd = !isOdd;

              return (
                <Row
                  key={`row-${row.id}`}
                  row={row}
                  columns={columns}
                  itemColumns={itemColumns}
                  defaultOpen={defaultOpen}
                  collapseKey={collapseKey}
                  title={title}
                  handleClickRow={handleClickRow}
                  backgroundRowClass={backgroundRowClass}
                />
              )
            }) : (
              <TableRow key={`no-data-row`}>
                <TableCell align="center" colSpan={columns?.length + 1}>
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      size="2x"
                      style={{ marginBottom: '12px', color: '#ccc' }}
                    />
                    <Typography style={{ color: '#ccc' }}>
                      Não há dados para serem exibidos
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
