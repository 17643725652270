import axios from "axios";
import { api } from "./api";

export const getCep = (cep) => {
  const url = `https://viacep.com.br/ws/${cep}/json`;

  return axios({
    method: "get",
    url,
  });
};

export const authMe = () => {
  const url = `/auth/me`;
  return api.post(url);
};

export const postAuth = (user) => {
  const url = `/auth/login`;
  return api.post(url, {
    email: user.email,
    password: user.password,
  });
};

export const getUserData = () => {
  const url = `/perfil`;
  return api.get(url);
};

export const putUserConta = (conta) => {
  const url = `/perfil`;
  return api.put(url, {
    nome: conta.nome,
    data_nascimento: conta.data_nascimento,
    razao_social: conta.razao_social,
    celular: conta.celular,
    site: conta.site,
    renda: conta.renda,
    endereco: {
      cep: conta.endereco.cep,
      rua: conta.endereco.rua,
      numero: conta.endereco.numero,
      complemento: conta.endereco.complemento,
      bairro: conta.endereco.bairro,
      cidade: conta.endereco.cidade,
      estado: conta.endereco.estado,
    },
  });
};

export const putUserPassword = (userPass) => {
  const url = `/user`;
  return api.put(url, {
    password: userPass.password,
    password_confirmation: userPass.password_confirmation,
  });
};

export const postFirstAcess = (user) => {
  const url = `/user/criar-senha`;
  return api.post(url, {
    email: user.email,
    token: user.token,
    password: user.password,
    password_confirmation: user.password_confirmation,
  });
};

export const getLinkPagamentos = (page) => {
  const url = `/link-pagamento`;
  return api.get(url, {
    params: {
      page,
    },
  });
};

export const getLancamentosFuturos = (
  page,
  data_liberacao_inicial,
  data_liberacao_final
) => {
  const url = `/lancamento-futuro`;
  return api.get(url, {
    params: {
      page,
      data_liberacao_inicial,
      data_liberacao_final,
    },
  });
};

export const getLinkPagamentoId = (id) => {
  const url = `/link-pagamento/${id}`;
  return api.get(url);
};

export const postLinkPagamento = (linkPagamento) => {
  const url = `/link-pagamento`;
  return api.post(url, {
    valor: linkPagamento.valor,
    limite_parcelas: linkPagamento.limite_parcelas,
    vencimento: linkPagamento.vencimento,
    quantidade_utilizacoes: linkPagamento.quantidade_utilizacoes,
    senha: linkPagamento.senha,
    numero_pedido: linkPagamento.numero_pedido,
    descricao: linkPagamento.descricao,
    status: linkPagamento.status,
    pagador_id: linkPagamento.pagador_id,
    pagamento: linkPagamento.pagamento,
  });
};

export const getLinkPagamentosFilter = (
  page,
  like,
  order,
  mostrar,
  conta_id
) => {
  const url = `/link-pagamento`;
  return api.get(url, {
    params: {
      page,
      like,
      order,
      mostrar,
      conta_id,
    },
  });
};

export const postLinkPagamentoPagar = (linkPagamentoPagar, id) => {
  const url = `/link-pagamento/${id}/pagar`;
  return api.post(url, {
    pagador_id: linkPagamentoPagar.pagador_id,
    parcelas: linkPagamentoPagar.parcelas,
    cartao: {
      nome: linkPagamentoPagar.cartao.nome,
      numero: linkPagamentoPagar.cartao.numero,
      cvv: linkPagamentoPagar.cartao.cvv,
      mes: linkPagamentoPagar.cartao.mes,
      ano: linkPagamentoPagar.cartao.ano,
    },
  });
};

export const postCobrancaCartao = (cobrancaCartao) => {
  const url = `/cartao`;
  return api.post(url, {
    pagador_id: cobrancaCartao.pagador_id,
    parcelas: cobrancaCartao.parcelas,
    valor: cobrancaCartao.valor,
    captura: cobrancaCartao.captura,
    cartao: {
      nome: cobrancaCartao.cartao.nome,
      numero: cobrancaCartao.cartao.numero,
      cvv: cobrancaCartao.cartao.cvv,
      mes: cobrancaCartao.cartao.mes,
      ano: cobrancaCartao.cartao.ano,
    },
  });
};
export const postCobrancaCartaoSimples = (cobrancaCartao) => {
  const url = `/simples/cartao`;
  return api.post(url, {
    documento: cobrancaCartao.documento,
    celular: cobrancaCartao.celular,
    parcelas: cobrancaCartao.parcelas,
    valor: cobrancaCartao.valor,
    captura: cobrancaCartao.captura,
    cartao: {
      nome: cobrancaCartao.cartao.nome,
      numero: cobrancaCartao.cartao.numero,
      cvv: cobrancaCartao.cartao.cvv,
      mes: cobrancaCartao.cartao.mes,
      ano: cobrancaCartao.cartao.ano,
    },
  });
};

export const getBoletos = (page) => {
  const url = `/boleto`;
  return api.get(url, {
    params: {
      page,
    },
  });
};

export const getExtratoPage = (page) => {
  const url = `/extrato`;
  return api.get(url, {
    params: {
      page,
    },
  });
};

export const getExtratoFilters = (
  page,
  id,
  day,
  order,
  mostrar,
  tipo,
  data_inicial,
  data_final,
  conta_id
) => {
  const url = `/extrato`;
  return api.get(url, {
    params: {
      page,
      id,
      day,
      order,
      mostrar,
      tipo,
      data_inicial,
      data_final,
      conta_id,
    },
  });
};

export const getBoletoId = (id) => {
  const url = `/boleto/${id}`;
  return api.get(url);
};

export const getBoletosFilter = (
  page,
  like,
  order,
  mostrar,
  status,
  conta_id
) => {
  const url = `/boleto`;
  return api.get(url, {
    params: {
      page,
      like,
      order,
      mostrar,
      status,
      conta_id,
    },
  });
};

export const postCancelarBoleto = (id) => {
  const url = `/boleto/cancelar`;
  return api.post(url, {
    transaction_id: id,
  });
};

export const postBoletos = (boleto) => {
  const url = `/boleto`;
  return api.post(url, {
    pagador_id: boleto.pagador_id,
    valor: boleto.valor,
    descricao: boleto.descricao,
    instrucao1: boleto.instrucao1,
    instrucao2: boleto.instrucao2,
    instrucao3: boleto.instrucao3,
    data_vencimento: boleto.data_vencimento,
    tipo_multa: boleto.tipo_multa,
    valor_multa: boleto.valor_multa,
    tipo_juros: boleto.tipo_juros,
    valor_juros: boleto.valor_juros,
    tipo_desconto: boleto.tipo_desconto,
    valor_desconto: boleto.valor_desconto,
    data_limite_valor_desconto: boleto.data_limite_valor_desconto,
  });
};

export const postPagador = (pagador) => {
  const url = `/pagador`;
  return api.post(url, {
    conta_id: pagador.conta_id,
    documento: pagador.documento,
    tipo: pagador.tipo,
    nome: pagador.nome,
    celular: pagador.celular,
    data_nascimento: pagador.data_nascimento,
    email: pagador.email,
    endereco: {
      cep: pagador.endereco.cep,
      rua: pagador.endereco.rua,
      numero: pagador.endereco.numero,
      complemento: pagador.endereco.complemento,
      bairro: pagador.endereco.bairro,
      cidade: pagador.endereco.cidade,
      estado: pagador.endereco.estado,
    },
  });
};

export const getPagadorId = (id) => {
  const url = `/pagador/${id}`;
  return api.get(url);
};
export const getPagadores = (page) => {
  const url = `/pagador`;
  return api.get(url, {
    params: {
      page,
    },
  });
};

export const deletePagador = (id) => {
  const url = `/pagador/${id}`;
  return api.delete(url);
};

export const putPagador = (pagador, id) => {
  const url = `/pagador/${id}`;
  return api.put(url, {
    conta_id: pagador.conta_id,
    documento: pagador.documento,
    nome: pagador.nome,
    celular: pagador.celular,
    data_nascimento: pagador.data_nascimento,
    email: pagador.email,
    endereco: {
      cep: pagador.endereco.cep,
      rua: pagador.endereco.rua,
      numero: pagador.endereco.numero,
      complemento: pagador.endereco.complemento,
      bairro: pagador.endereco.bairro,
      cidade: pagador.endereco.cidade,
      estado: pagador.endereco.estado,
    },
  });
};

export const getPagadoresFilter = (page, like, order, mostrar, conta_id) => {
  const url = `/pagador`;
  return api.get(url, {
    params: {
      page,
      like,
      order,
      mostrar,
      conta_id,
    },
  });
};

export const getContas = (page) => {
  const url = `/contas`;
  return api.get(url, {
    params: {
      page,
    },
  });
};

export const getContasFilhas = (page, like, conta_pai_id) => {
  const url = `/contas-filhas`;
  return api.get(url, {
    params: {
      page,
      like,
      conta_pai_id,
    },
  });
};

export const getContaId = (id) => {
  const url = `/contas/${id}`;
  return api.get(url);
};

export const postContas = (conta) => {
  const url = `/contas`;
  return api.post(url, {
    is_digital_account: conta.is_digital_account || false,
    documento: conta.documento,
    nome: conta.nome,
    razao_social: conta.razao_social,
    cnpj: conta.cnpj,
    celular: conta.celular,
    data_nascimento: conta.data_nascimento,
    email: conta.email,
    site: conta.site,
    renda: conta.renda,
    endereco: {
      cep: conta.endereco.cep,
      rua: conta.endereco.rua,
      numero: conta.endereco.numero,
      complemento: conta.endereco.complemento,
      bairro: conta.endereco.bairro,
      cidade: conta.endereco.cidade,
      estado: conta.endereco.estado,
    },
  });
};

export const putConta = (conta, id) => {
  const url = `/contas/${id}`;
  return api.put(url, {
    nome: conta.nome,
    data_nascimento: conta.data_nascimento,
    razao_social: conta.razao_social,
    celular: conta.celular,
    site: conta.site,
    renda: conta.renda,
    endereco: {
      cep: conta.endereco.cep,
      rua: conta.endereco.rua,
      numero: conta.endereco.numero,
      complemento: conta.endereco.complemento,
      bairro: conta.endereco.bairro,
      cidade: conta.endereco.cidade,
      estado: conta.endereco.estado,
    },
  });
};

export const getContasFilter = ({
  page,
  like,
  status,
  conta_id_filter,
  tipo,
  agent_id,
  with_app_sales_plan,
  without_agent_id,
  except_contas_filhas,
}) => {
  const url = `/contas`;
  return api.get(url, {
    params: {
      page,
      like,
      status,
      conta_id_filter,
      tipo,
      agent_id,
      with_app_sales_plan,
      without_agent_id,
      except_contas_filhas,
    },
  });
};

export const deleteConta = (id) => {
  const url = `/contas/${id}`;
  return api.delete(url);
};

export const getUserConta = (id) => {
  const url = `/user/${id}`;
  return api.get(url);
};

export const deleteUser = (id) => {
  const url = `/user/${id}`;
  return api.delete(url);
};

export const getUserContaFilters = (page, like, order, mostrar) => {
  const url = `/user`;
  return api.get(url, {
    params: {
      page,
      like,
      order,
      mostrar,
    },
  });
};

export const postUserConta = (user) => {
  const url = `/user`;
  return api.post(url, user);
};

export const getBancos = () => {
  const url = `/banco`;
  return api.get(url);
};

export const postContaBancaria = (conta, conta_id) => {
  const url = `/conta-bancaria`;
  return api.post(url, {
    conta_id,
    banco: conta.banco,
    agencia: conta.agencia,
    tipo: conta.tipo,
    conta: conta.conta,
  });
};

export const getContaBancaria = (conta_id) => {
  const url = `/conta-bancaria`;
  return api.get(url, {
    params: {
      conta_id,
    },
  });
};

export const deleteContaBancaria = (id) => {
  const url = `/conta-bancaria/${id}`;
  return api.delete(url);
};

export const getHistoricoTransacaoFilters = (filters) => {
  return api.get("/transacao", {
    params: {
      ...filters,
    },
  });
};

export const getHistoricoTransacao = (page) => {
  const url = `/transacao`;
  return api.get(url, {
    params: {
      page,
    },
  });
};

export const getTransacaoId = (id) => {
  const url = `/transacao/${id}`;
  return api.get(url);
};

export const getTransacaoRecebiveis = (id) => {
  const url = `/transacao/${id}/recebiveis`;
  return api.get(url);
};

export const enviarBoleto = (id) => {
  const url = `/enviar/${id}/boleto`;
  return api.get(url);
};

export const postSaque = (saqueInfos) => {
  const url = `/saque`;
  return api.post(url, {
    conta_bancaria_id: saqueInfos.conta_bancaria_id,
    valor: saqueInfos.valor,
    descricao: saqueInfos.descricao,
  });
};

export const getHistoricoTransferencia = (page) => {
  const url = `/transferencia?page=${page}`;
  return api.get(url, {
    params: {
      page,
    },
  });
};

export const getHistoricoTransferenciaFilters = (
  page,
  like,
  valor,
  data,
  conta_id
) => {
  const url = `/transferencia`;
  return api.get(url, {
    params: {
      page,
      like,
      valor,
      data,
      conta_id,
    },
  });
};

export const postTransferencia = (transferenciaInfos) => {
  const url = `/transferencia`;
  return api.post(url, {
    conta_destino_id: transferenciaInfos.conta_destino_id,
    valor: transferenciaInfos.valor,
    descricao: transferenciaInfos.descricao,
  });
};

export const postSenhaLink = (senha, id) => {
  const url = `/senha/link-pagamento`;
  return api.post(url, {
    link_pagamento_id: id,
    senha: senha,
  });
};

export const getCarne = (page) => {
  const url = `/carne`;
  return api.get(url, {
    params: {
      page,
    },
  });
};

export const getCarneFilters = (filters) => {
  const url = `/carne`;
  return api.get(url, {
    params: {
      ...filters,
    },
  });
};

export const getCarneId = (id) => {
  const url = `/carne/${id}`;
  return api.get(url);
};

export const getCarneIdBoleto = (id) => {
  const url = `/carne/${id}/boleto`;
  return api.get(url);
};

export const deleteCarneId = (id) => {
  const url = `/carne/${id}`;
  return api.delete(url);
};

export const postCarne = (carne) => {
  const url = `/carne`;
  return api.post(url, {
    pagador_id: carne.pagador_id,
    valor: carne.valor,
    parcelas: carne.parcelas,
    descricao: carne.descricao,
    instrucao1: carne.instrucao1,
    instrucao2: carne.instrucao2,
    instrucao3: carne.instrucao3,
    data_vencimento: carne.data_vencimento,
    tipo_multa: carne.tipo_multa,
    valor_multa: carne.valor_multa,
    tipo_juros: carne.tipo_juros,
    valor_juros: carne.valor_juros,
    tipo_desconto: carne.tipo_desconto,
    valor_desconto: carne.valor_desconto,
    data_limite_valor_desconto: carne.data_limite_valor_desconto,
  });
};

export const getCobrancasCartao = (page) => {
  const url = `/cartao`;
  return api.get(url, {
    params: {
      page,
    },
  });
};

export const getCobrancasCartaoFilters = (
  page,
  like,
  order,
  mostrar,
  conta_id
) => {
  const url = `/cartao`;
  return api.get(url, {
    params: {
      page,
      like,
      order,
      mostrar,
      conta_id,
    },
  });
};

export const postCapturaCobranca = (id, valor) => {
  const url = `/cartao/${id}/captura`;
  return api.post(url, {
    valor: valor,
  });
};

export const postCobrancaEstornar = (id) => {
  const url = `/cartao/${id}/estornar`;
  return api.post(url);
};

export const getPlanos = (page) => {
  const url = `/plano-assinatura`;
  return api.get(url, {
    params: {
      page,
    },
  });
};
export const getPlanosAll = (conta_id) => {
  const url = `/plano-assinatura`;
  return api.get(url, {
    params: {
      conta_id,
    },
  });
};

export const getPlanosFilters = (page, like, order, mostrar, conta_id) => {
  const url = `/plano-assinatura`;
  return api.get(url, {
    params: {
      page,
      like,
      order,
      mostrar,
      conta_id,
    },
  });
};

export const getPlanoId = (id) => {
  const url = `/plano-assinatura/${id}`;
  return api.get(url);
};

export const postPlano = (plano) => {
  const url = `/plano-assinatura`;
  return api.post(url, {
    nome: plano.nome,
    valor: plano.valor,
    frequencia: plano.frequencia,
    descricao: plano.descricao,
    duracao: plano.duracao,
  });
};

export const putPlano = (id, plano) => {
  const url = `/plano-assinatura/${id}`;
  return api.put(url, {
    nome: plano.nome,
    valor: plano.valor,
    frequencia: plano.frequencia,
    descricao: plano.descricao,
    duracao: plano.duracao,
  });
};

export const deletePlano = (id) => {
  const url = `/plano-assinatura/${id}`;
  return api.delete(url);
};

export const getAssinaturas = (page) => {
  const url = `/assinatura`;
  return api.get(url, {
    params: {
      page,
    },
  });
};

export const getAssinaturasFilters = (
  page,
  like,
  plano,
  order,
  mostrar,
  conta_id
) => {
  const url = `/assinatura`;
  return api.get(url, {
    params: {
      page,
      like,
      plano,
      order,
      mostrar,
      conta_id,
    },
  });
};

export const getAssinaturasId = (id) => {
  const url = `/assinatura/${id}`;
  return api.get(url);
};

export const postAssinaturas = (assinatura) => {
  const url = `/assinatura`;
  return api.post(url, {
    plano_id: assinatura.plano_id,
    pagador_id: assinatura.pagador_id,
    card_id: assinatura.card_id,
  });
};

export const putAssinaturas = (id, plano_id, card_id) => {
  const url = `/assinatura/${id}`;
  return api.put(url, {
    plano_id,
    card_id,
  });
};

export const verificarAssinatura = (id) => {
  const url = `/assinatura/${id}/verificar`;
  return api.get(url);
};

export const cancelarAssinatura = (id) => {
  const url = `/assinatura/${id}/cancelar`;
  return api.put(url);
};

export const deleteAssinatura = (id) => {
  const url = `/assinatura/${id}`;
  return api.delete(url);
};

export const postCartaoAssinatura = (id, cartao) => {
  const url = `/pagador/${id}/cartao`;
  return api.post(url, {
    nome: cartao.nome,
    numero: cartao.numero,
    cvv: cartao.cvv,
    mes: cartao.mes,
    ano: cartao.ano,
  });
};
export const postSaqueAutomatico = (saqueAutomatico) => {
  const url = `/politica-recebimento`;
  return api.post(url, {
    saque_automatico: saqueAutomatico,
  });
};

export const postSplit = (transacao) => {
  const url = `/split`;
  return api.post(url, {
    cobranca_boleto_ou_cartao_id: transacao.cobranca_boleto_ou_cartao_id,
    conta_id: transacao.conta_id,
    porcentagem: transacao.porcentagem,
    valor: transacao.valor,
    responsavel_pelo_prejuizo: transacao.responsavel_pelo_prejuizo,
    usar_valor_liquido: transacao.usar_valor_liquido,
  });
};

export const getResumoRecebiveisFuturos = ({ date }) => {
  const url = `/transacao-recebimentos-futuros`;
  return api.get(url, {
    params: {
      date,
    },
  });
};

export const getResumoCompletoRecebiveis = ({ date }) => {
  const url = `/transacao-todos-recebimentos`;
  return api.get(url, {
    params: {
      date,
    },
  });
};

export const getTokens = () => {
  const url = `/tokens`;
  return api.get(url);
};

export const postTokens = (tokenInfos) => {
  const url = `/tokens/create`;
  var bodyFormData = new FormData();
  bodyFormData.append("token_name", tokenInfos);
  return api.post(url, bodyFormData, {
    headers: {
      ContentType: `multipart/form-data`,
    },
  });
};

export const deleteTokens = (index) => {
  const url = `/tokens/${index}`;
  return api.delete(url);
};

export const postDocumentos = (documento, categoria, descricao) => {
  const url = `/documento`;
  var bodyFormData = new FormData();
  bodyFormData.append("arquivo", documento);
  bodyFormData.append("categoria", categoria);
  bodyFormData.append("descricao", descricao);
  return api.post(url, bodyFormData);
};

export const postDocumentosAdm = (
  documento,
  categoria,
  conta_id,
  descricao
) => {
  const url = `/documento?conta_id=${conta_id}`;
  var bodyFormData = new FormData();
  bodyFormData.append("arquivo", documento);
  bodyFormData.append("categoria", categoria);
  bodyFormData.append("descricao", descricao);
  return api.post(url, bodyFormData);
};

export const getDocumento = (conta_id) => {
  const url = `/documento`;
  return api.get(url, {
    params: {
      conta_id,
      mostrar: 100,
    },
  });
};

export const deleteDocumento = (id) => {
  const url = `/documento/${id}`;
  return api.delete(url);
};

export const getPermissao = (id) => {
  const url = `/permissao`;
  return api.get(url, {
    params: {
      user_id: id,
    },
  });
};

export const postPermissao = (id, tipoPermissao) => {
  const url = `/permissao`;
  return api.post(url, {
    tipo: tipoPermissao,
    user_id: id,
  });
};

export const deletePermissao = (id, tipoPermissao) => {
  const url = `/permissao`;
  return api.delete(url, {
    data: {
      tipo: tipoPermissao,
      user_id: id,
    },
  });
};

export const getExportHistoricoTransacao = (filters) => {
  const url = `/export/transacao`;
  return api.get(url, {
    params: {
      ...filters,
    },
  });
};

export const getExportHistoricoRecebivel = (
  page,
  transaction,
  receiver,
  payer,
  day,
  order,
  mostrar,
  status,
  payment_type,
  documento,
  holder_name,
  data_inicial,
  data_final,
  data_pagamento_inicial,
  data_pagamento_final,
  vencimento_final,
  vencimento_inicial
) => {
  const url = `/export/recebivel`;
  return api.get(url, {
    params: {
      page,
      transaction,
      receiver,
      payer,
      day,
      order,
      mostrar,
      status,
      payment_type,
      documento,
      holder_name,
      data_inicial,
      data_final,
      data_pagamento_inicial,
      data_pagamento_final,
      vencimento_final,
      vencimento_inicial,
    },
  });
};

export const getExportTransacaoAuditoria = (
  page,
  day,
  order,
  mostrar,
  status,
  like,
  payment_type,
  data_inicial,
  data_final,
  id,
  documento,
  vencimento_inicial,
  vencimento_final,
  pagamento_inicial,
  pagamento_final,
  conta_id,
  terminal_id,
  seller_like,
  holder_name,
  is_physical_sale
) => {
  const url = `/auditoria/transacao/exportar`;
  return api.get(url, {
    params: {
      page,
      id,
      terminal_id,
      day,
      order,
      mostrar,
      status,
      like,
      seller_like,
      is_physical_sale,
      payment_type,
      documento,
      data_inicial,
      data_final,
      vencimento_inicial,
      vencimento_final,
      conta_id,
      pagamento_inicial,
      pagamento_final,
      holder_name,
    },
  });
};

export const getExportLancamentoFuturo = (
  data_liberacao_inicial,
  data_liberacao_final,
  conta_id
) => {
  const url = `export/lancamentos-futuros`;
  return api.get(url, {
    params: {
      data_liberacao_inicial,
      data_liberacao_final,
      conta_id,
    },
  });
};

export const getExportTodosLancamentos = (date, conta_id) => {
  const url = `export/transacao-todos-recebimentos`;
  return api.get(url, {
    params: {
      date,
      conta_id,
    },
  });
};

export const getExportExtrato = (
  page,
  id,
  day,
  order,
  mostrar,
  tipo,
  data_inicial,
  data_final,
  conta_id
) => {
  const url = `/export/extrato`;
  return api.get(url, {
    params: {
      page,
      id,
      day,
      order,
      mostrar,
      tipo,
      data_inicial,
      data_final,
      conta_id,
    },
  });
};

export const getExportExtratoPdf = (
  page,
  id,
  day,
  order,
  mostrar,
  tipo,
  data_inicial,
  data_final,
  conta_id
) => {
  const url = `/export/extrato-pdf`;
  return api.get(url, {
    params: {
      page,
      id,
      day,
      order,
      mostrar,
      tipo,
      data_inicial,
      data_final,
      conta_id,
    },
  });
};

export const getLastBalance = (conta_id) => {
  const url = `/extrato/last-balance`;
  return api.get(url, {
    params: {
      conta_id,
    },
  });
};

export const getExportHistoricoTransferencia = (
  page,
  like,
  valor,
  data,
  conta_id
) => {
  const url = `/export/transferencia`;
  return api.get(url, {
    params: {
      page,
      like,
      valor,
      data,
      conta_id,
    },
  });
};

export const postReenviarToken = (user_id) => {
  const url = `/users/${user_id}/resend-token`;
  return api.post(url);
};

export const postRecoveryPassword = (user_email) => {
  const url = `/auth/recovery-user-password`;
  return api.post(url, {
    email: user_email,
  });
};
