import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  faShieldAlt, faUsers
} from '@fortawesome/free-solid-svg-icons';
import AccountCollectionItem from '../Content/AccountCollections/AccountCollectionItem/AccountCollectionItem';
import { useSelector } from 'react-redux';
import { USER_PERMISSIONS } from 'constants/permissions';
import CustomBreadcrumbs from '../CustomBreadcrumbs/CustomBreadcrumbs';

const useStyles = makeStyles((theme) => ({
	collectionContainer: {
		width: '60%',
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		color: theme.palette.primary.main,
		[theme.breakpoints.down(850)]: {
			width: '100%',
		},
	},
}));

const OptionsCollection = () => {
	const classes = useStyles();
  const theme = useTheme();
  const userPermissao = useSelector((state) => state.userPermissao);
  const [permissoes, setPermissoes] = useState([]);
  useEffect(() => {
    const { permissao } = userPermissao;
    setPermissoes(permissao.map((item) => item.tipo));
  }, [userPermissao]);

  return (
    <>
      <CustomBreadcrumbs path1="Opções" to1="#" />
      <Box marginBottom={2}>
        <Typography variant="h4" style={{ color: theme.palette.background.default }}>
          OPÇÕES
        </Typography>
      </Box>

      <Box className={classes.collectionContainer}>
        <Box display="flex">
          <AccountCollectionItem
            link="/dashboard/config-totp"
            text="Autentificação 2FA"
            icon={faShieldAlt}
          />
          {permissoes.includes(USER_PERMISSIONS.OTHER_MY_USERS) &&
            <AccountCollectionItem
              link="/dashboard/my-users"
              text="Meus Usuários"
              icon={faUsers}
            />
          }
        </Box>
      </Box>
    </>
  );
};

export default OptionsCollection;
