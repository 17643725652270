import {
  Box,
  Divider,
  makeStyles,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { updateUserConta } from "../../../actions/actions";
import NewAccount from "../../reusable/Account/NewAccount/NewAccount";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import GradientButton from "../../reusable/GradientButton/GradientButton";
import LoadingScreen from "../../reusable/LoadingScreen/LoadingScreen";
import CreateBankAccount from "../BankAccounts/CreateBankAccount/CreateBankAccount";
import LoggedUserDocuments from "components/reusable/Documents/LoggedUserDocuments";
import ListBankAccounts from "../BankAccounts/ListBankAccounts/ListBankAccounts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "40px",
    width: "70%",
    borderRadius: "27px",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  card: {
    margin: theme.spacing(1),
    padding: 0,
  },
  dropzoneAreaBaseClasses: {
    width: "70%",
    height: "250px",
    fontSize: "10px",
  },
  dropzoneContainer: {
    margin: "6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "12px",
    minHeight: "400px",
  },
  textoDropzone: {
    fontSize: "1.2rem",
  },
}));

const STEPS = {
  DADOS_DA_CONTA: "Dados da Conta",
  CADASTRO_DOCUMENTOS: "Documentos",
  CONTA_BANCARIA: "Conta Bancária",
};

const AccountReactivationProcess = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errosConta, setErrosConta] = useState({});
  const [conta, setConta] = useState({
    id: "",
    documento: "",
    nome: "",
    razao_social: "",
    cnpj: "",
    celular: "",
    data_nascimento: "",
    email: "",
    site: "",
    renda: "",
    endereco: {
      cep: "",
      rua: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
    },
  });
  const [contaBancaria, setContaBancaria] = useState({
		banco: "",
		agencia: "",
		tipo: "1",
		conta: "",
	});

  useEffect(() => {
    setConta({ ...userData });
  }, [userData]);

  const handleClick = async () => {
    setLoading(true);

    if (activeStep === 0) {
      const resConta = await dispatch(updateUserConta(conta));
      if (resConta) {
        setErrosConta(resConta);
        toast.error("Erro ao alterar dados");
      } else {
        toast.success("Dados alterados com sucesso!");
      }
    }
    if (activeStep === 1) {
    }
    if (activeStep === 2) {
      history.push("/home");
    }

    setActiveStep(activeStep + 1);
    setLoading(false);
  };

  const steps = [
    STEPS.DADOS_DA_CONTA,
    STEPS.CADASTRO_DOCUMENTOS,
    STEPS.CONTA_BANCARIA,
  ];

  const STEPS_COMPONENTS = {
    [STEPS.DADOS_DA_CONTA]: (
      <NewAccount
        conta={conta}
        setConta={setConta}
        errosConta={errosConta}
        disableEditar
      />
    ),
    [STEPS.CADASTRO_DOCUMENTOS]: (
      <LoggedUserDocuments loading={loading} setLoading={setLoading}/>
    ),
    [STEPS.CONTA_BANCARIA]: (
      <Box>
        <Typography align="center" variant="h6">
          Contas bancárias
        </Typography>
        <ListBankAccounts contaId={userData.id} />
        <Box margin="16px"> </Box>
        <Divider />
        <Box margin="16px"> </Box>
        <CreateBankAccount contaId={userData.id} contaBancaria={contaBancaria} setContaBancaria={setContaBancaria} />
      </Box>
    ),
  };

  return (
    <Box className={classes.root}>
      <LoadingScreen isLoading={loading} />
      <CustomBreadcrumbs path1="Reativar Conta" />
      <Paper className={classes.paper}>
        <Stepper
          className={classes.stepper}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {STEPS_COMPONENTS[steps[activeStep]]}
        <Box alignSelf="flex-end" marginTop="16px">
          <GradientButton
            buttonText={
              activeStep === steps.length - 1 ? "Finalizar" : "Próximo"
            }
            onClick={handleClick}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default AccountReactivationProcess;
