import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import CustomBreadcrumbs from "../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "../../../reusable/CustomTable/CustomTable";
import { postCancelarBoleto } from "../../../../services/services";
import SplitModal from "../SplitModal";
import { useDeleteSplitMutation } from "services/api";
import { TRANSACTION_STATUS } from "constants/status";
import columns from "../TransactionDetailsColumns";
import columnsRecebiveis from "../TransactionReceivableColumns";
import { generatePath, useHistory } from "react-router";
import useUserPermissions from "hooks/useUserPermissions";
import GradientButton from "components/reusable/Buttons/GradientButton";
import columnsSplit from "../TransactionSplitColumns";
import IdsDetails from "components/reusable/TransactionDetails/IdsDetails";
import SlipDetails from "components/reusable/TransactionDetails/SlipDetails";
import PayerDetails from "components/reusable/TransactionDetails/PayerDetails";
import AccountDetails from "components/reusable/TransactionDetails/AccountDetails";
import historyTransactionColumns from "../HistoryTransactionColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRedo } from "@fortawesome/free-solid-svg-icons";
import RecreateSplitsModal from "../RecreateSplitsModal";

const TransactionDetailsSlip = ({
  transacaoId,
  recebiveis,
  reloadTransaction,
}) => {
  const {
    transaction,
    pagador,
    split,
    boleto,
    conta_id,
    conta,
    data_pagamento,
  } = transacaoId;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSplitModal, setOpenSplitModal] = useState(false);
  const [openRecreateSplitsModal, setOpenRecreateSplitsModal] = useState(false);
  const [splitModalInfo, setSplitModalInfo] = useState(null);
  const [splitId, setSplitId] = useState(null);
  const [background, setBackground] = useState("");
  const [deleteSplit, deleteSplitResult] = useDeleteSplitMutation();

  const { userData, isAdmin, canViewAudit } = useUserPermissions();

  const isAdminOrMainSeller = useMemo(
    () => userData.id === conta_id || isAdmin,
    [isAdmin, userData, conta_id]
  );
  const canSplitTransaction = useMemo(
    () => !!boleto && transaction.status === "pending" && isAdminOrMainSeller,
    [boleto, transaction, isAdminOrMainSeller]
  );

  const handleCancelBoleto = async () => {
    setOpenCancelDialog(false);

    toast.warning("Solicitação de cancelamento enviada.", {
      autoClose: 5000,
    });
    try {
      const res = await postCancelarBoleto(transaction.id);
      if (res.status === (200 || 201) && res.data.status === "REQUESTED") {
        toast.success("Cancelamento solicitado.", {
          autoClose: 5000,
        });
      }
    } catch {
      toast.error("Erro ao solicitar cancelamento.", {
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    const color = TRANSACTION_STATUS[transaction.status]?.color;
    if (color) setBackground(color);
  }, [transaction.status]);

  const openEditDialog = (transaction) => {
    setSplitId(transaction.id);
    setSplitModalInfo({
      porcentagem: Number(transaction.porcentagem),
      valor: Number(transaction.valor),
      usar_valor_liquido: transaction.usar_valor_liquido,
      conta_id: transaction.conta_id,
      split: transaction,
    });

    setOpenSplitModal(true);
  };

  const pushToTransactionAuditDetails = (transacaoId) => {
    const path = generatePath("/dashboard/transacoes-auditoria/:id/detalhes", {
      id: transacaoId,
    });
    history.push(path);
  };

  const columnSplitsWithActions = useMemo(() => {
    if (canSplitTransaction) {
      return [
        ...columnsSplit,
        {
          headerText: "Opções",
          key: "split",
          CustomValue: (_, transactionSplit) => {
            return (
              <Box>
                <IconButton onClick={() => openEditDialog(transactionSplit)}>
                  <EditOutlined />
                </IconButton>
                <IconButton
                  onClick={() => setOpenDeleteDialog(transactionSplit.id)}
                >
                  <DeleteOutlined />
                </IconButton>
              </Box>
            );
          },
        },
      ];
    }

    return columnsSplit;
  }, [canSplitTransaction]);

  useEffect(() => {
    if (deleteSplitResult.isSuccess) {
      toast.success("Repartição removida com sucesso!");
      reloadTransaction();
      setOpenDeleteDialog(false);
    }

    if (deleteSplitResult.isError) {
      toast.error("Não foi possível remover a repartição!");
    }
  }, [
    deleteSplitResult.isError,
    deleteSplitResult.isSuccess,
    reloadTransaction,
  ]);

  return (
    <Box display="flex" flexDirection="column">
      {!!boleto && (
        <SplitModal
          id={boleto.id}
          open={openSplitModal}
          onClose={() => setOpenSplitModal(false)}
          onSplit={reloadTransaction}
          info={splitModalInfo}
          splitId={splitId}
        />
      )}
      {canSplitTransaction && (
        <RecreateSplitsModal
          transacao_id={transacaoId.id}
          split_config_type="boleto"
          open={openRecreateSplitsModal}
          setOpen={setOpenRecreateSplitsModal}
          onRecreate={reloadTransaction}
        />
      )}
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Deseja realmente cancelar esta repartição de valor ?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            variant="outlined"
            color="secondary"
          >
            Não
          </Button>
          <Button
            disabled={deleteSplitResult.isLoading}
            onClick={() =>
              deleteSplit({
                splitId: openDeleteDialog,
              })
            }
            color="default"
            variant="outlined"
            autoFocus
          >
            {deleteSplitResult.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>Sim</>
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Deseja realmente cancelar este boleto ?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setOpenCancelDialog(false)}
            variant="outlined"
            color="secondary"
          >
            Não
          </Button>
          <Button
            onClick={handleCancelBoleto}
            color="default"
            variant="outlined"
            autoFocus
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      {userData === "" || userData.owner_agent_id ? (
        <CustomBreadcrumbs
          path1="Histórico de Transações"
          to1="goBack"
          path2="Detalhes da Transação"
          to2="#"
        />
      ) : (
        <CustomBreadcrumbs
          path1="Histórico de Transações"
          to1="/dashboard/historico-de-transacoes"
          path2="Detalhes da Transação"
          to2="#"
        />
      )}
      <Paper
        style={{
          padding: "24px",
          margin: "12px 0",
          borderRadius: "27px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4"> Detalhes da Transação </Typography>

        <Box
          display="flex"
          marginTop="12px"
          style={matches ? { flexDirection: "column" } : null}
        >
          <Box
            marginRight={matches ? "0px" : "20px"}
            marginBottom={matches ? "20px" : "0px"}
            display="flex"
            flexDirection="column"
            width="100%"
          >
            {transacaoId.created_at === undefined ? (
              <LinearProgress />
            ) : (
              <CustomTable data={[transacaoId]} columns={columns} />
            )}

            <Box marginTop="18px">
              <Box marginBottom={2}>
                <Typography variant="h4">Recebimentos</Typography>
              </Box>
              {recebiveis ? (
                <CustomTable data={recebiveis} columns={columnsRecebiveis} />
              ) : null}
            </Box>
            <Box marginTop="18px">
              <Box
                marginBottom={2}
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="h4">Repartições de valor</Typography>
                {canSplitTransaction && (
                  <Box display="flex">
                    <IconButton
                      onClick={() => setOpenRecreateSplitsModal(true)}
                    >
                      <FontAwesomeIcon icon={faRedo} />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSplitId(null);
                        setSplitModalInfo(null);
                        setOpenSplitModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <CustomTable data={split} columns={columnSplitsWithActions} />
            </Box>
            <Box marginTop="18px">
              <Box marginBottom={2}>
                <Typography variant="h4">Histórico da transação</Typography>
              </Box>

              <CustomTable
                data={transaction.history}
                columns={historyTransactionColumns}
              />
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" width="100%">
            <Box
              style={{
                padding: "12px",
                borderRadius: "15px 15px 0 0 ",
                backgroundColor: background,
                color: "white",
              }}
            >
              <Box>
                <Typography variant="h6" align="center">
                  Boleto
                </Typography>
              </Box>
            </Box>
            <Box display="flex" marginTop="6px" flexDirection="column">
              <IdsDetails id={transacaoId.id} conciliationId={transaction.id} />
              <SlipDetails
                transaction={transaction}
                boleto={boleto}
                payment_date={data_pagamento}
              />

              <Divider style={{ marginTop: 16, marginBottom: 8 }} />

              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                alignItems="center"
              >
                <Typography variant="h6">Opções</Typography>
                <Box marginTop="8px" display="flex" flexDirection="column">
                  <GradientButton
                    style={{ marginBottom: "10px" }}
                    text="2° Via do Boleto"
                    onClick={() =>
                      window.open(
                        `${transaction.payment_method.url}`,
                        "Boleto",
                        "height=1000,width=1000"
                      )
                    }
                  />
                  {transaction.status === "pending" && (
                    <GradientButton
                      style={{ marginBottom: "10px" }}
                      text="Cancelar Boleto"
                      onClick={() => setOpenCancelDialog(true)}
                    />
                  )}
                  {canViewAudit && (
                    <GradientButton
                      text="Auditoria"
                      onClick={() =>
                        pushToTransactionAuditDetails(transaction.id)
                      }
                    />
                  )}
                </Box>
              </Box>

              <Divider style={{ marginTop: 16, marginBottom: 8 }} />

              <PayerDetails payer={pagador} />

              <Divider style={{ marginTop: 16, marginBottom: 8 }} />

              {conta && <AccountDetails account={conta} />}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default TransactionDetailsSlip;
