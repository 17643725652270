export const indexAgentQuery = ({ page, like, trashed_agents }) => ({
  url: `/agents`,
  method: "get",
  params: { page, like, trashed_agents },
});

export const showAgentQuery = ({ agent_id }) => ({
  url: `/agents/${agent_id}`,
  method: "get",
});

export const storeAgentMutation = (agent) => ({
  url: `/agents`,
  method: "post",
  data: {
    agent: {
      owner_cpf: agent.owner_cpf,
      owner_first_name: agent.owner_first_name,
      owner_last_name: agent.owner_last_name,
      owner_birth_date: agent.owner_birth_date,
      cnpj: agent.cnpj,
      business_name: agent.business_name,
      contact_email: agent.contact_email,
      contact_number: agent.contact_number,
      business_description: agent.business_description,
    },
    site: agent.site,
    address: {
      cep: agent.endereco?.cep,
      rua: agent.endereco?.rua,
      bairro: agent.endereco?.bairro,
      numero: agent.endereco?.numero,
      complemento: agent.endereco?.complemento,
      cidade: agent.endereco?.cidade,
      estado: agent.endereco?.estado,
    }
  },
});

export const importAgentMutation = ({ account_id }) => ({
  url: `/import-agent-from-account`,
  method: "post",
  data: {
    account_id
  },
});

export const updateAgentMutation = ({ agent_id, agent }) => ({
  url: `/agents/${agent_id}`,
  method: "put",
  data: {
    agent_id,
    agent: {
      owner_first_name: agent.owner_first_name,
      owner_last_name: agent.owner_last_name,
      owner_birth_date: agent.owner_birth_date,
      business_name: agent.business_name,
      contact_email: agent.contact_email,
      contact_number: agent.contact_number,
      business_description: agent.business_description,
    },
    renda: agent.renda,
    site: agent.site,
    address: {
      cep: agent.endereco?.cep,
      rua: agent.endereco?.rua,
      bairro: agent.endereco?.bairro,
      numero: agent.endereco?.numero,
      complemento: agent.endereco?.complemento,
      cidade: agent.endereco?.cidade,
      estado: agent.endereco?.estado,
    }
  },
});

export const deleteAgentMutation = ({ agent_id }) => ({
  url: `/agents/${agent_id}`,
  method: "delete",
});

export const indexAgentEcsQuery = ({ agent_id, page, filters}) => ({
  url: `/agents/${agent_id}/ecs`,
  method: "get",
  params: {
    page,
    like: filters.like,
  },
});

export const addEcToAgentMutation = ({ agent_id, conta_id }) => ({
  url: `/agents/add-ec`,
  method: "post",
  data: {
    agent_id, conta_id
  },
});

export const removeEcFromAgentMutation = ({ agent_id, conta_id }) => ({
  url: `/agents/remove-ec`,
  method: "post",
  data: {
    agent_id, conta_id
  },
});

export const storeAgentLogoMutation = ({ agent_id, logo }) => {
  const logoObject = { ...logo };
  var bodyFormData = new FormData();
	bodyFormData.append('logo', logoObject[0].file);
  return {
    url: `/agents/${agent_id}/store-logo`,
    method: "post",
    data: bodyFormData,
  }
};

export const deleteAgentLogoMutation = ({ agent_id }) => ({
  url: `/agents/${agent_id}/delete-logo`,
  method: "delete",
  data: {
    agent_id
  },
});

export const updateAgentThemeColorsMutation = ({ agent_id, colors }) => ({
  url: `/agents/${agent_id}/theme-colors`,
  method: "put",
  data: {
    agent_id,
    colors
  },
});
