import {
  Box,
  Grid,
  TextField,
  LinearProgress,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import useDebounce from "hooks/useDebounce";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useGetLogsQuery } from "services/api";
import { toast } from "react-toastify";
import HeadButton from "components/reusable/Buttons/HeadButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
  button: {
    width: "70px",
  },
}));

const columns = [
  { headerText: "Usuário", key: "user_email" },
  { headerText: "Ip", key: "ip" },
  { headerText: "Descrição", key: "description" },
  {
    headerText: "Alterado em",
    key: "created_at",
    CustomValue: (data) => {
      return <Typography align="center">{data}</Typography>;
    },
  },
];

const LogsPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    like: "",
    data_inicial: "",
    data_final: "",
  });

  const debouncedLike = useDebounce(filters.like, 800);
  const debouncedDataInicial = useDebounce(filters.data_inicial, 800);
  const debouncedDataFinal = useDebounce(filters.data_final, 800);

  const {
    data: logs,
    isLoading,
    isError,
    isUninitialized,
  } = useGetLogsQuery(
    {
      page,
      like: debouncedLike,
      data_inicial: debouncedDataInicial,
      data_final: debouncedDataFinal,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (isError) {
      toast.error("O usuário não está autenticado a entrar nessa página!");
      history.push("/dashboard/adm");
    }
  }, [isError, history]);

  return (
    <Box className={classes.root}>
      <CustomBreadcrumbs path1="Logs" to1="#" />
      <Box
        marginBottom="10px"
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : null}
      >
        <Typography
          variant="h4"
          style={{ color: theme.palette.background.default }}
        >
          LOGS
        </Typography>
        <HeadButton
          text="Logs detalhados"
          onClick={() => history.push("/dashboard/detailed-logs")}
        />
      </Box>

      <Box marginBottom="16px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar usuário, ip ou e-mail..."
              fullWidth
              label="Pesquisar"
              value={filters.like}
              onChange={(e) => setFilters({ ...filters, like: e.target.value })}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Inicial"
              value={filters.data_inicial}
              onChange={(e) =>
                setFilters({ ...filters, data_inicial: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Final"
              value={filters.data_final}
              onChange={(e) =>
                setFilters({ ...filters, data_final: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </Box>
      <>
        {!isLoading && !isError && !isUninitialized ? (
          <>
            <CustomTable columns={columns} data={logs.data} />
            <Box alignSelf="flex-end" marginTop="8px">
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={logs.last_page}
                onChange={handleChangePage}
                page={page}
              />
            </Box>
          </>
        ) : (
          <LinearProgress />
        )}
      </>
    </Box>
  );
};

export default LogsPage;
