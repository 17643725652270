import {
	Box,
	Divider,
	LinearProgress,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomTable from '../../../reusable/CustomTable/CustomTable';
import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import { useSelector } from 'react-redux';
import { TRANSACTION_STATUS } from 'constants/status';
import columns from "../TransactionDetailsColumns";
import IdsDetails from 'components/reusable/TransactionDetails/IdsDetails';
import AccountDetails from 'components/reusable/TransactionDetails/AccountDetails';
import CustomCopyToClipboard from 'components/reusable/Text/CustomCopyToClipboard';

const TransactionDetailsCommission = ({ transacaoId }) => {
	const { transaction, conta } = transacaoId;
	const [arrayObjetos] = useState([transacaoId]);
	const [background, setBackground] = useState('');
  const userData = useSelector((state) => state.userData);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		const color = TRANSACTION_STATUS[transaction.status]?.color;
    if(color) setBackground(color);
	}, [transaction.status]);

	return (
		<Box display="flex" flexDirection="column">
			{userData === '' || userData.owner_agent_id ? (
				<CustomBreadcrumbs
          path1="Histórico de Transações"
          to1="goBack"
          path2="Detalhes da Transação"
          to2="#"
        />
			) : (
				<CustomBreadcrumbs
          path1="Histórico de Transações"
          to1="/dashboard/historico-de-transacoes"
          path2="Detalhes da Transação"
          to2="#"
        />
			)}
			<Paper
				style={{
					padding: '24px',
					margin: '12px 0',
					borderRadius: '27px',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography variant="h4"> Detalhes da Transação </Typography>

				<Box display="flex" marginTop="12px" style={matches ? { flexDirection: 'column' } : null}>
					<Box display="flex" flexDirection="column" style={{ width: '100%' }}>
						{transacaoId.created_at === undefined ? (
							<LinearProgress />
						) : (
							<CustomTable data={arrayObjetos} columns={columns} />
						)}
					</Box>

					<Box display="flex" flexDirection="column" style={{ marginLeft: '20px', width: '100%' }}>
						<Box
							style={{
								padding: '12px',
								borderRadius: '15px 15px 0 0 ',

								backgroundColor: background,
								color: 'white',
							}}
						>
							<Box>
								<Typography variant="h6" align="center">
									Comissão
								</Typography>
							</Box>
						</Box>
						<Box display="flex" marginTop="6px" flexDirection="column">
							<Box>
								<Typography variant="h6">Valor: R${transaction.amount}</Typography>
							</Box>
							<Box display="flex" alignItems="center" width="100%">
								<Box width="100%" display="flex" alignItems="center">
									<Typography variant="h6">Código de autorização:</Typography>
                  <CustomCopyToClipboard title="Copiar" value={transaction.transaction_number} />
								</Box>
							</Box>

              <IdsDetails id={transacaoId.id} conciliationId={transaction.id} />

							<Divider style={{ marginTop: 16, marginBottom: 8 }} />

              <AccountDetails account={conta} title="Conta" />
						</Box>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};

export default TransactionDetailsCommission;
