import { Box, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";
import Cards from "react-credit-cards";
import InputMask from "react-input-mask";

const SubscriptionCard = ({ cartao, setCartao, errosCartao }) => {
  const [validade, setValidade] = useState("");

  const handleSetValidade = (e) => {
    const [mes, ano] = e.target.value.split("/");
    return (
      setValidade(e.target.value),
      setCartao({
        ...cartao,
        mes: parseInt(mes),
        ano: ano,
      })
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Cards
          cvc={cartao.cvv}
          expiry={validade}
          focused={cartao.focus}
          name={cartao.nome}
          number={cartao.numero}
          placeholders={{ name: "NOME DO TITULAR" }}
        />
      </Box>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputMask
              name="number"
              mask="9999 9999 9999 9999"
              maskChar=" "
              value={cartao.numero}
              onFocus={(e) =>
                setCartao({
                  ...cartao,
                  focus: e.target.name,
                })
              }
              onChange={(e) =>
                setCartao({
                  ...cartao,
                  numero: e.target.value,
                })
              }
            >
              {() => (
                <TextField
                  error={errosCartao?.numero}
                  helperText={
                    errosCartao?.numero ? errosCartao.numero.join(" ") : null
                  }
                  name="number"
                  required
                  fullWidth
                  label="Número do Cartão"
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errosCartao?.nome}
              helperText={errosCartao?.nome ? errosCartao.nome.join(" ") : null}
              value={cartao.nome}
              onFocus={(e) =>
                setCartao({
                  ...cartao,
                  focus: e.target.name,
                })
              }
              onChange={(e) =>
                setCartao({
                  ...cartao,
                  nome: e.target.value,
                })
              }
              required
              name="name"
              fullWidth
              label="Nome do títular"
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <InputMask
              value={validade}
              onChange={(e) => handleSetValidade(e)}
              onFocus={(e) =>
                setCartao({
                  ...cartao,
                  focus: e.target.name,
                })
              }
              name="expiry"
              mask="99/9999"
            >
              {() => (
                <TextField
                  error={errosCartao?.mes + errosCartao?.ano}
                  helperText={
                    errosCartao?.mes
                      ? errosCartao.mes.join(" ")
                      : null || errosCartao?.ano
                      ? errosCartao.ano.join(" ")
                      : null
                  }
                  name="expiry"
                  required
                  fullWidth
                  label="Validade"
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={12} sm={3}>
            <InputMask
              name="cvv"
              value={cartao.cvv}
              onFocus={(e) =>
                setCartao({
                  ...cartao,
                  focus: e.target.name,
                })
              }
              onChange={(e) =>
                setCartao({
                  ...cartao,
                  cvv: e.target.value,
                })
              }
              mask="9999"
              maskChar=" "
            >
              {() => (
                <TextField
                  name="cvc"
                  error={errosCartao?.cvv}
                  helperText={
                    errosCartao?.cvv ? errosCartao.cvv.join(" ") : null
                  }
                  required
                  fullWidth
                  label="CVV"
                />
              )}
            </InputMask>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SubscriptionCard;
