import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router";
import CustomBreadcrumbs from "../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "../../../reusable/CustomTable/CustomTable";
import { loadLinkPagamentoId } from "../../../../actions/actions";
import GradientButton from "components/reusable/GradientButton/GradientButton";
import getResponseError from "helpers/getResponseError";
import { toast } from "react-toastify";
import { useDeletePaymentLinkMutation } from "services/api";
import TransactionStatusColumn from "components/reusable/TableColumns/TransactionStatusColumn";
import LinkSplitConfigIndex from "./LinkSplitConfigIndex";

const columns = [
  {
    headerText: "Pago em",
    key: "created_at",
    CustomValue: (data) => {
      const p = data.split(/\D/g);
      const dataFormatada = [p[2], p[1], p[0]].join("/");
      return (
        <Box display="flex" justifyContent="center">
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
          <Typography style={{ marginLeft: "6px" }}>{dataFormatada}</Typography>
        </Box>
      );
    },
  },
  {
    headerText: "Pagador",
    key: "pagador",
    CustomValue: (pagador) => {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography>{pagador.nome}</Typography>
          <Typography>{pagador.documento}</Typography>
        </Box>
      );
    },
  },
  {
    headerText: "Contato",
    key: "pagador",
    CustomValue: (pagador) => {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography>{pagador.celular}</Typography>
          <Typography>{pagador.email}</Typography>
        </Box>
      );
    },
  },
  {
    headerText: "Status",
    key: "status",
    CustomValue: (status) => {
      return <TransactionStatusColumn status={status} />;
    },
  },
];

const PaymentLinkDetails = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const linkPagamentoId = useSelector((state) => state.linkPagamentoId);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletePaymentLink] = useDeletePaymentLinkMutation();

  useEffect(() => {
    dispatch(loadLinkPagamentoId(id));
  }, [dispatch, id]);

  const handleClickRow = (row) => {
    const path = generatePath("/dashboard/detalhes-transacao/:id/ver", {
      id: row.transaction_id,
    });
    history.push(path);
  };

  const handleDelete = async () => {
    try {
      await deletePaymentLink({ payment_link_id: id }).unwrap();
      toast.success("Link de Pagamento excluido!");
      history.goBack();
    } catch (e) {
      getResponseError(e);
      toast.error("Erro ao excluir Link de Pagamento!");
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle
          style={{
            background: theme.gradient.main,
            color: "white",
          }}
          align="center"
        >
          Deletar Link de Pagamento
        </DialogTitle>
        <DialogContent>
          <Typography>Deseja realmente deletar o Link de Pagamento?</Typography>
          <Typography align="center" style={{ color: "red" }}>
            Após removido o Link de Pagamento não será mais visível.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box margin="6px 0">
            <GradientButton buttonText={"Deletar"} onClick={handleDelete} />
          </Box>
          <Box>
            <Button
              style={{ borderRadius: "37px", margin: "6px 0" }}
              variant="outlined"
              onClick={() => setOpenDeleteDialog(false)}
            >
              Cancelar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <CustomBreadcrumbs
        path1="Link de Pagamento"
        to1="/dashboard/link-pagamento"
        path2="Detalhes do Link"
        to2="#"
      />
      <Paper
        style={{
          padding: "24px",
          margin: "12px 0",
          borderRadius: "27px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">Pagamentos realizados</Typography>
          <Box display="flex" alignItems="center">
            <GradientButton
              disabled={linkPagamentoId.pagamento_count !== 0}
              onClick={() => setOpenDeleteDialog(true)}
              buttonText="REMOVER LINK"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          marginTop="12px"
          style={matches ? { flexDirection: "column" } : null}
        >
          <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
            {linkPagamentoId.created_at === undefined ? (
              <LinearProgress />
            ) : (
              <CustomTable
                data={linkPagamentoId.pagamento}
                columns={columns}
                handleClickRow={handleClickRow}
              />
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginLeft: "20px", width: "100%" }}
          >
            <Box
              style={{
                padding: "12px",
                borderRadius: "15px 15px 0 0 ",
                color: "black",
              }}
            >
              <Box>
                <Typography
                  variant="overline"
                  style={{ fontSize: 14, lineHeight: 1 }}
                >
                  Status
                </Typography>
                <Typography
                  variant="h6"
                  style={
                    linkPagamentoId.status_string === "Ativo"
                      ? { fontWeight: 500, color: "green" }
                      : { fontWeight: 500, color: "#dfad06" }
                  }
                  color="initial"
                >
                  {linkPagamentoId?.status_string}
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap">
                <Box width="50%" marginRight="20px">
                  <Typography
                    variant="overline"
                    style={{ fontSize: 14, lineHeight: 1 }}
                  >
                    Beneficiário
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 500 }}
                    color="initial"
                  >
                    {linkPagamentoId.conta?.razao_social ??
                      linkPagamentoId.conta?.nome}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="overline"
                    style={{ fontSize: 14, lineHeight: 1 }}
                  >
                    CPF/CNPJ
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 500 }}
                    color="initial"
                  >
                    {linkPagamentoId.conta?.cnpj ??
                      linkPagamentoId.conta?.documento}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexWrap="wrap">
                <Box width="50%" marginRight="20px">
                  <Typography
                    variant="overline"
                    style={{ fontSize: 14, lineHeight: 1 }}
                  >
                    Valor
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 500 }}
                    color="initial"
                  >
                    R$ {linkPagamentoId.valor ? linkPagamentoId.valor : null}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="overline"
                    style={{ fontSize: 14, lineHeight: 1 }}
                  >
                    Número do pedido
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 500 }}
                    color="initial"
                  >
                    {linkPagamentoId.numero_pedido
                      ? linkPagamentoId.numero_pedido
                      : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="overline"
                  style={{ fontSize: 14, lineHeight: 1 }}
                >
                  Descrição
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 500 }}
                  color="initial"
                >
                  {linkPagamentoId.descricao ? linkPagamentoId.descricao : "-"}
                </Typography>
              </Box>
              <Divider
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />
              <Box display="flex" justifyContent="space-between">
                <Box maxWidth="120px">
                  <Typography
                    variant="overline"
                    style={{ fontSize: 14, lineHeight: 1 }}
                  >
                    Limite de parcelas
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 500 }}
                    color="initial"
                  >
                    {linkPagamentoId.limite_parcelas
                      ? linkPagamentoId.limite_parcelas
                      : null}
                  </Typography>
                </Box>
                <Box maxWidth="120px">
                  <Typography
                    variant="overline"
                    style={{ fontSize: 14, lineHeight: 1 }}
                  >
                    Limite de utilizações
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 500 }}
                    color="initial"
                  >
                    {linkPagamentoId.quantidade_utilizacoes
                      ? linkPagamentoId.quantidade_utilizacoes
                      : "-"}
                  </Typography>
                </Box>
                <Box maxWidth="120px">
                  <Typography
                    variant="overline"
                    style={{ fontSize: 14, lineHeight: 1 }}
                  >
                    Pagamentos realizados
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 500 }}
                    color="initial"
                  >
                    {linkPagamentoId.pagamento_count
                      ? linkPagamentoId.pagamento_count
                      : 0}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      <LinkSplitConfigIndex data={linkPagamentoId} />
    </Box>
  );
};

export default PaymentLinkDetails;
