import React from "react";
import { Typography } from "@material-ui/core";
import { formatMoney } from "utils/money";
import DateColumn from "components/reusable/TableColumns/DateColumn";
import ReceivableTypeColumn from "components/reusable/TableColumns/ReceivableTypeColumn";

const columnsRecebiveis = [
  {
    headerText: "Data Prevista",
    key: "expected_on",
    CustomValue: (expected_on) => {
      return <DateColumn date={expected_on} />
    },
  },
  {
    headerText: "Status",
    key: "status",
    CustomValue: (status) => {
      if (status === "paid") {
        return <Typography>Pago</Typography>;
      } else if (status === "pending") {
        return <Typography>Pendente</Typography>;
      } else if (status === "canceled") {
        return <Typography>Cancelado</Typography>;
      } else if (status === "refunded") {
        return <Typography>Estornado</Typography>;
      } else if (status) {
        return <Typography>{status}</Typography>;
      } else {
        return <Typography>-</Typography>;
      }
    },
  },
  {
    headerText: "Parcela",
    key: "installment",
    CustomValue: (installment) => {
      return <Typography>{installment || "Única"}</Typography>;
    },
  },
  {
    headerText: "Destinatário",
    key: "conta",
    CustomValue: (conta) => {
      if(!conta) return <Typography>-</Typography>;
      return <Typography>{conta.razao_social ?? conta.nome}</Typography>;
    },
  },
  {
    headerText: "Valor Bruto",
    key: "gross_amount",
    CustomValue: (amount) => {
      if(!amount) return <Typography>-</Typography>;
      return <Typography>{formatMoney(amount)}</Typography>;
    }
  },
  {
    headerText: "Valor Líquido",
    key: "amount",
    CustomValue: (amount) => <Typography>{formatMoney(amount)}</Typography>,
  },
  {
    headerText: "Tipo",
    key: "type",
    CustomValue: (type) => <ReceivableTypeColumn type={type}/>
  },
];

export default columnsRecebiveis;
