import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "16px",
    padding: "0px",
    alignItems: "center",
    borderRadius: "20px",
    color: theme.collectionItem.icon,
    justifyContent: "center",
    backgroundColor: theme.collectionItem.background,
    transition: `${theme.transitions.create(["background-color", "transform"], {
      duration: theme.transitions.duration.standard,
    })}`,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.light,
      transform: "scale(1.05)",
    },
    width: "170px",
    height: "170px",
    animation: `$myEffect 500ms ${theme.transitions.easing.easeInOut}`,
    [theme.breakpoints.down("md")]: {
      width: "120px",
      height: "130px",
      margin: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "110px",
      margin: "6px",
    },
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 1,
      transform: "translateX(20%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },

  textContainer: {
    marginTop: "3px",
    fontSize: "0.7rem",
    fontWeight: "400",
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
      fontWeight: "500",
    },
  },
}));

const AccountCollectionItem = ({
  icon,
  link,
  text,
  subText = "",
  state = false,
  ...rest
}) => {
  const classes = useStyles();
  const { section } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box display="flex">
      <Box
        onClick={
          link === null
            ? () =>
                toast.warning("Sem permissão para acessar essa funcionalidade")
            : null
        }
        boxShadow={3}
        className={classes.iconContainer}
        component={Link}
        to={{
          pathname: link === null ? section : link,
          state,
        }}
        {...rest}
      >
        <FontAwesomeIcon icon={icon} size={matches ? "3x" : "4x"} />
        <Typography
          variant="subtitle2"
          className={classes.textContainer}
          align="center"
        >
          {text}
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.textContainer}
          align="center"
        >
          {subText}
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountCollectionItem;
