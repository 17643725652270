import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useHistory, useParams } from "react-router";
import {
  useRemoveEcFromAgentMutation,
  useDeleteAgentMutation,
  useShowAgentQuery,
  useIndexAgentEcsQuery,
} from "services/api";
import { toast } from "react-toastify";
import GradientButton from "components/reusable/GradientButton/GradientButton";
import { Pagination } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import CopyToClipboard from "react-copy-to-clipboard";
import { faCopy, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import { APP_CONFIG } from "constants/config";
import SearchBar from "components/reusable/SearchBar/SearchBar";
import useDebounce from "hooks/useDebounce";
import AddEcModal from "./AddECModal";
import { loadContaId } from "actions/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
}));

const AgentDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [deleteAgent] = useDeleteAgentMutation();
  const [removeEcFromAgent] = useRemoveEcFromAgentMutation();

  const [openAddEcDialog, setOpenAddEcDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRemoveEcDialog, setOpenRemoveEcDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [ecToRemove, setEcToRemove] = useState({});
  const [filters, setFilters] = useState({
    like: "",
  });

  const debouncedFilters = useDebounce(filters, 800);

  const columns = [
    { headerText: "ID da Conta", key: "id" },
    {
      headerText: "EC",
      key: "ec_name",
      FullCustom: (account) => {
        return (
          <Typography>{account.razao_social ?? account.nome}</Typography>
        );
      },
    },
    { headerText: "Email", key: "email" },
    {
      headerText: "Documento",
      key: "custom_doc",
      FullCustom: (account) => {
        return (
          <Typography>{account.cnpj ?? account.documento}</Typography>
        );
      },
    },
    { headerText: "Tipo", key: "tipo_string" },
    {
      headerText: "Conta Pai",
      key: "custom_father",
      FullCustom: (account) => {
        return (
          <>
            <Typography>{account.conta_pai[0]?.cnpj ?? account.conta_pai[0]?.documento ?? "-"}</Typography>
            <Typography>{account.conta_pai[0]?.razao_social ?? account.conta_pai[0]?.nome ?? "-"}</Typography>
          </>
        );
      },
    },
    {
      headerText: "Menu",
      key: "custom_menu",
      FullCustom: (account) => {
        return (
          <Tooltip title="Remover assinatura">
            <IconButton
              onClick={() => {
                setEcToRemove(account);
                setOpenRemoveEcDialog(true);
              }}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const {
    data: agent,
    isLoading: isLoadingAgent,
    isError: isErrorAgent,
    isUninitialized: isUninitializedAgent,
    refetch: refetchAgent,
  } = useShowAgentQuery(
    {
      agent_id: id,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: agentEcs,
    isLoading: isLoadingAgentEcs,
    isError: isErrorAgentEcs,
    isUninitialized: isUninitializedAgentEcs,
    refetch: refetchAgentEcs,
  } = useIndexAgentEcsQuery(
    {
      page,
      agent_id: id,
      filters: debouncedFilters,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleDeleteAgent = async () => {
    setIsLoading(true);
    try {
      await deleteAgent({ agent_id: agent.id }).unwrap();
      toast.success("Representante excluido!");
      history.goBack();
    } catch (e) {
      toast.error("Erro ao excluir Representante!");
    } finally {
      setOpenDeleteDialog(false);
      setIsLoading(false);
    }
  };

  const handleRemoveEcFromAgent = async () => {
    setIsLoading(true);
    try {
      await removeEcFromAgent({
        agent_id: id,
        conta_id: ecToRemove.id,
      }).unwrap();
      toast.success("EC removida!");
    } catch (e) {
      toast.error("Erro ao remover EC!");
    } finally {
      refetchAgentEcs();
      refetchAgent();
      setEcToRemove({});
      setOpenRemoveEcDialog(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isErrorAgent) {
      toast.error("Aconteceu um erro tente novamente!");
      history.goBack();
    }
  }, [isErrorAgent, history]);

  useEffect(() => {
    if(agent?.conta_id) {
      dispatch(loadContaId(agent.conta_id));
    }
  }, [dispatch, agent]);

  const handleChangePage = useCallback((e, value) => {
    setPage(value);
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <LoadingScreen style={{ zIndex: "10" }} isLoading={isLoading} />

      <AddEcModal
        openDialog={openAddEcDialog}
        setOpenDialog={setOpenAddEcDialog}
        refetchAgentEcs={refetchAgentEcs}
        refetchAgent={refetchAgent}
        agentId={id}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />

      {openRemoveEcDialog && (
        <Dialog
          open={openRemoveEcDialog}
          onClose={() => {
            setEcToRemove({});
            setOpenRemoveEcDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: "10" }}
        >
          <DialogTitle className={classes.dialogHeader}>
            <Typography align="center" variant="h6">
              Remover EC
            </Typography>
          </DialogTitle>
          <Box display="flex" flexDirection="column" padding="12px 24px">
            <Typography>
              Deseja realmente remover o EC do representante {agent.business_name ?? agent.owner_first_name}?
            </Typography>
          </Box>
          <DialogActions>
            <Button
              onClick={handleRemoveEcFromAgent}
              variant="outlined"
              color="secondary"
            >
              Remover
            </Button>
            <Button
              onClick={() => {
                setEcToRemove({});
                setOpenRemoveEcDialog(false);
              }}
              color="default"
              variant="outlined"
              autoFocus
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openDeleteDialog && (
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: "10" }}
        >
          <DialogTitle className={classes.dialogHeader}>
            <Typography align="center" variant="h6">
              Remover Representante
            </Typography>
          </DialogTitle>
          <Box display="flex" flexDirection="column" padding="12px 24px">
            <Typography>
              {"Deseja realmente remover este Representante da Aplicação " +
                APP_CONFIG().name +
                "?"}
            </Typography>
          </Box>
          <DialogActions>
            <Button
              onClick={handleDeleteAgent}
              variant="outlined"
              color="secondary"
            >
              Sim
            </Button>
            <Button
              onClick={() => setOpenDeleteDialog(false)}
              color="default"
              variant="outlined"
              autoFocus
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <CustomBreadcrumbs
        path1="Representantes"
        to1="/dashboard/representantes"
        path2="Detalhes"
        to2="#"
      />

      <Box display="flex" justifyContent="">
        <Typography style={{ marginTop: "8px" }} variant="h4">
          Representante
        </Typography>
      </Box>

      <Divider style={{ marginTop: 16, marginBottom: 8 }} />

      {!isLoadingAgent && !isErrorAgent && !isUninitializedAgent ? (
        <>
          <Box display="flex" flexWrap="wrap" style={{ zIndex: "1" }}>
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Typography variant="h6">Detalhes do Representante:</Typography>
              <Box display="flex" flexWrap="wrap" marginTop="12px">
                <Box flexGrow={1}>
                  <Typography variant="h6">
                    ID do Representante: <br />
                    <Box width="100%" maxWidth={330} display="flex">
                      <TextField 
                        fullWidth
                        value={agent.id ? agent.id : "-"}
                      />
                      <Tooltip title="Copiar ID da transação">
                        <CopyToClipboard
                          text={agent.id ? agent.id : "-"}
                        >
                          <Button
                            aria="Copiar"
                            style={{
                              marginLeft: "8px",
                              padding: 0,
                              minWidth: 0,
                              width: "20px",
                              height: "20px",
                              alignSelf: "center",
                              color: "green",
                            }}
                            onClick={() =>
                              toast.success(
                                "Copiado para area de transferência",
                                {
                                  autoClose: 2000,
                                }
                              )
                            }
                          >
                            <FontAwesomeIcon
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                              icon={faCopy}
                            />
                          </Button>
                        </CopyToClipboard>
                      </Tooltip>
                    </Box>
                  </Typography>
                  <Box marginTop="12px">
                    <Typography style={{}}>Nome:</Typography>
                    <Typography variant="h6">
                      {agent.business_name ?? `${agent.owner_first_name} ${agent.owner_last_name}` ?? "-"}
                    </Typography>
                  </Box>
                  <Box marginTop="12px">
                    <Typography>Situação do Representante:</Typography>
                    <Typography variant="h6">
                      {agent.agent_account.status}
                    </Typography>
                  </Box>
                  <Box marginTop="12px">
                    <Typography>Total de ECs neste Representante:</Typography>
                    <Typography variant="h6">
                      {agent.ec_count ?? "0"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography align="center" variant="h6">
                Opções
              </Typography>
              <Box marginTop="8px" display="flex" justifyContent="center">
                <GradientButton
                  buttonText="Adicionar EC"
                  onClick={() => setOpenAddEcDialog(true)}
                  disabled={false}
                />
              </Box>

              <Box marginTop="8px" display="flex" justifyContent="center">
                <GradientButton
                  buttonText="Remover Representante"
                  onClick={() => setOpenDeleteDialog(true)}
                  disabled={agent.ec_count !== 0}
                />
              </Box>
            </Box>
          </Box>

          <Divider style={{ marginTop: 16, marginBottom: 8 }} />

          {!isLoadingAgentEcs &&
          !isErrorAgentEcs &&
          !isUninitializedAgentEcs ? (
            <Box style={{ zIndex: "1" }}>
              <Typography variant="h6">ECs deste Representante</Typography>
              <Box marginBottom="16px">
                <SearchBar
                  fullWidth
                  placeholder="Pesquisar por nome, documento..."
                  value={filters.like}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      like: e.target.value,
                    })
                  }
                />
              </Box>

              <CustomTable columns={columns} data={agentEcs.data} />

              <Box alignSelf="flex-end" marginTop="8px">
                <Pagination
                  variant="outlined"
                  color="secondary"
                  size="large"
                  count={agentEcs.last_page}
                  onChange={handleChangePage}
                  page={page}
                />
              </Box>
            </Box>
          ) : (
            <LinearProgress />
          )}
        </>
      ) : (
        <LoadingScreen isLoading={true} />
      )}
    </Box>
  );
};

export default AgentDetails;
