import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import { useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import {
  useIndexItemRemessaQuery,
  useSendItemRemessaByEmailMutation,
} from "services/api";
import columns from "./ItensRemessaColumn";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ResumeRemessa from "../ArquivoRemessaIndex/ResumeRemessa";
import useDebounce from "hooks/useDebounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faPaperPlane,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import useQuery from "hooks/useQuery";
import useQueryParams from "hooks/useQueryParams";
import useDynamicPagination from "hooks/useDynamicPagination";
import { clearSearchParams } from "services/functions/clearSearchParams";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

const ItensRemessa = () => {
  const { id } = useParams();
  const theme = useTheme();
  const history = useHistory();
  const accountId = useLocation().state?.accountId;
  const classes = useStyles();
  const query = useQuery();
  const [sendItemByEmail] = useSendItemRemessaByEmailMutation();

  const [page, setPage] = useState(Number(query.get("page")) || 1);
  const initialFilters = {
    pagador: query.get("pagador"),
    boleto_id: query.get("boleto_id"),
    numero_documento: query.get("numero_documento"),
    id: query.get("id"),
  };
  const [filters, setFilters] = useState(initialFilters);

  const debouncePagador = useDebounce(filters.pagador, 800);
  const debouncedBoletoId = useDebounce(filters.boleto_id, 800);
  const debouncedNumeroDocumento = useDebounce(filters.numero_documento, 800);
  const debouncedId = useDebounce(filters.id, 800);

  const { data, isLoading, isError, isUninitialized } =
    useIndexItemRemessaQuery(
      {
        page,
        arquivo_remessa_id: id,
        pagador: debouncePagador,
        boleto_id: debouncedBoletoId,
        numero_documento: debouncedNumeroDocumento,
        id: debouncedId,
      },
      { refetchOnMountOrArgChange: true }
    );

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const itemRemessaColumns = [
    ...columns,
    {
      headerText: "",
      key: "boleto_actions_column",
      FullCustom: (item_remesa) => {
        return (
          <Box display="flex">
            <Tooltip
              title={
                <Typography variant="body2" component="span">
                  Enviar por e-mail
                </Typography>
              }
            >
              <IconButton
                disabled={!item_remesa?.boleto}
                onClick={() => handleSendItenByEmail(item_remesa.id)}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </IconButton>
            </Tooltip>
            <IconButton onClick={() => handleSeeMoreDetails(item_remesa)}>
              <FontAwesomeIcon icon={faArrowCircleRight} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleSendItenByEmail = async (item_remessa_id) => {
    try {
      await sendItemByEmail({
        item_remessa_id,
      }).unwrap();
      toast.success(
        "Estamos reprocessando o boleto para ser enviado por email"
      );
    } catch (e) {
      console.log(e);
      if (e.status === 400) toast.warning(e.data.message);
      else toast.error("Não foi possível iniciar o envio por email");
    }
  };

  const handleSeeMoreDetails = (item_remesa) => {
    try {
      const path = generatePath("/dashboard/detalhes-transacao/:id/ver", {
        id: item_remesa.boleto.transaction_id,
      });
      history.push(path);
    } catch (e) {
      toast.error("Não foi possível encontrar o boleto");
    }
  };

  useQueryParams(query, filters, page, history);
  useDynamicPagination(page, data?.last_page, setPage);

  return (
    <Box className={classes.root}>
      {accountId ? (
        <CustomBreadcrumbs
          path1="Arquivo de remessa"
          to1={`/dashboard/contas/${accountId}/arquivo-remessa`}
          path2="Itens da remessa"
          to2="#"
        />
      ) : (
        <CustomBreadcrumbs
          path1="Arquivo de remessa"
          to1="/dashboard/arquivo-remessa"
          path2="Itens da remessa"
          to2="#"
        />
      )}

      <Box marginBottom={2}>
        <Typography
          variant="h4"
          style={{ color: theme.palette.background.default }}
        >
          ITENS DA REMESSA
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <ResumeRemessa
          data={data}
          arquivoRemessaId={id}
          accountId={accountId}
        />
      </Box>
      <Box marginBottom="16px" marginTop="10px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar por pagador..."
              fullWidth
              label="Pagador"
              value={filters.pagador}
              onChange={(e) =>
                setFilters({ ...filters, pagador: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar ID do boleto..."
              fullWidth
              label="Pesquisar"
              value={filters.boleto_id}
              onChange={(e) =>
                setFilters({ ...filters, boleto_id: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar número do documento..."
              fullWidth
              label="Pesquisar"
              value={filters.numero_documento}
              onChange={(e) =>
                setFilters({ ...filters, numero_documento: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar id do item remessa..."
              fullWidth
              label="Pesquisar"
              value={filters.id}
              onChange={(e) => setFilters({ ...filters, id: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Tooltip title="Limpar Filtros">
              <IconButton
                onClick={() => clearSearchParams(filters, setFilters)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <>
        {!isLoading && !isError && !isUninitialized ? (
          <>
            <CustomTable
              columns={itemRemessaColumns}
              data={data.itens_remessa.data}
              // handleClickRow={handleClickRow}
            />
            <Box alignSelf="flex-end" marginTop="8px">
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={data.itens_remessa.last_page}
                onChange={handleChangePage}
                page={page}
              />
            </Box>
          </>
        ) : (
          <LinearProgress />
        )}
      </>
    </Box>
  );
};

export default ItensRemessa;
