import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import React from "react";
import CustomCopyToClipboard from "../Text/CustomCopyToClipboard";
import { addHours, format } from "date-fns";
import SlipOriginDetails from "./SlipOriginDetails";

const SlipDetails = ({ transaction, payment_date, boleto = null }) => {
  const formatDate = (date) => {
    const dateAdjustedTimeZone = addHours(new Date(date), 3);

    const formatted = format(dateAdjustedTimeZone, "dd/MM/yyyy");
    return formatted;
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography variant="h6">Informações do Boleto</Typography>
      </Box>
      <Box>
        <Box display="flex" alignItems="center" marginRight="10px">
          <FontAwesomeIcon
            style={{ marginRight: "10px" }}
            icon={faBarcode}
            size="2x"
          />
          <Typography>Código:</Typography>
        </Box>
        <CustomCopyToClipboard
          title={"Copiar código do boleto"}
          value={transaction.payment_method.barcode}
        />
      </Box>
      <Box>
        <Typography>N° Documento:</Typography>
        <CustomCopyToClipboard
          title={"Copiar número do documento"}
          value={transaction.payment_method.document_number}
        />
      </Box>
      <Box>
        <Typography>Vencimento:</Typography>
        <Typography variant="h6">
          {formatDate(transaction.payment_method.expiration_date)}
        </Typography>
      </Box>
      {transaction.status === "succeeded" ? (
        <Box>
          <Typography>Pagamento:</Typography>
          <Typography variant="h6">{formatDate(payment_date)}</Typography>
        </Box>
      ) : null}
      {boleto ? (
        <Box>
          <Typography>Origem:</Typography>
          <SlipOriginDetails boleto={boleto} />
        </Box>
      ) : null}
    </Box>
  );
};

export default SlipDetails;
