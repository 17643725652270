import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useSelector } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router";
import {
  useShowSalesPlanQuery,
  useDeleteSalesPlanMutation,
  useIndexSalesPlanSubscriptionQuery,
  useDeleteSalesPlanSubscriptionMutation,
  useSetDefaultSalesPlanMutation,
  useIndexMyEcsSalesPlanQuery,
  useCreateDefaultSalesPlanFeesMutation,
  useUpdateSalesPlanMutation,
} from "services/api";
import { toast } from "react-toastify";
import GradientButton from "components/reusable/GradientButton/GradientButton";
import { Pagination } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import { faMinusCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import { ADM_PERMISSIONS } from "constants/permissions";
import { APP_CONFIG, APP_CONFIG_PROJECT } from "constants/config";
import SearchBar from "components/reusable/SearchBar/SearchBar";
import useDebounce from "hooks/useDebounce";
import FeeDetails from "./Fees/FeeDetails";
import AplicationSalesPlanDetails from "./PlanDetails/AplicationSalesPlanDetails";
import AgentSalesPlanDetails from "./PlanDetails/AgentSalesPlanDetails";
import AddAgentSalesPlanSubscriptionModal from "./Dialogs/AddAgentSalesPlanSubscriptionModal";
import AddAplicationSalesPlanSubscriptionModal from "./Dialogs/AddAplicationSalesPlanSubscriptionModal";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
}));

const SalesPlanDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const userData = useSelector((state) => state.userData);
  const userPermissions = useSelector((state) => state.userPermissao.permissao);
  const permissionsName = userPermissions.map((permission) => permission.tipo);
  const isInttegrarAdm = useMemo(
    () => permissionsName.includes(ADM_PERMISSIONS.INTTEGRAR),
    [permissionsName]
  );

  const [createDefaultFees] = useCreateDefaultSalesPlanFeesMutation();
  const [setDefaultSalesPlan] = useSetDefaultSalesPlanMutation();
  const [deleteSalesPlan] = useDeleteSalesPlanMutation();
  const [removeEcSubscription] = useDeleteSalesPlanSubscriptionMutation();
  const [updateSalesPlan] = useUpdateSalesPlanMutation();

  const [openCreateDefaultFees, setOpenCreateDefaultFees] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddSubscriptionDialog, setOpenAddSubscriptionDialog] =
    useState(false);
  const [openRemoveSubscriptionDialog, setOpenRemoveSubscriptionDialog] =
    useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState({});
  const [filters, setFilters] = useState({
    like: "",
  });
  const [baseSalesPlanId, setBaseSalesPlanId] = useState("");

  const debouncedFilters = useDebounce(filters, 800);

  const handleClickDetails = (row) => {
    const path = generatePath("/dashboard/contas/:id/tarifas-ec", {
      id: row.conta_id,
    });
    history.push(path);
  };

  const columns = [
    { headerText: "ID Assinatura", key: "id" },
    {
      headerText: "EC",
      key: "ec_name",
      Teste: (item) => {
        return (
          <Typography>
            {item.conta?.razao_social ?? item.conta?.nome}
          </Typography>
        );
      },
    },
    { headerText: "Email", key: "conta.email" },
    {
      headerText: "Documento",
      key: "cumtom_documento",
      FullCustom: (item) => {
        return (
          <Typography>{item.conta?.cnpj ?? item.conta?.documento}</Typography>
        );
      },
    },
    {
      headerText: "Menu",
      key: "custom_menu_delete",
      FullCustom: (item) => {
        return (
          <>
            <Tooltip title="Detalhes">
              <IconButton
                onClick={() => {
                  handleClickDetails(item);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remover assinatura">
              <IconButton
                onClick={() => {
                  setSubscriptionToDelete(item);
                  setOpenRemoveSubscriptionDialog(true);
                }}
              >
                <FontAwesomeIcon icon={faMinusCircle} />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const {
    data: salesPlan,
    isLoading: isLoadingSalesPlan,
    isError: isErrorSalesPlan,
    isUninitialized: isUninitializedSalesPlan,
    refetch: refetchSalesPlan,
  } = useShowSalesPlanQuery(
    {
      plan_id: id,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const disableEditAllFees = useMemo(
    () =>
      !(
        isInttegrarAdm ||
        permissionsName.includes(
          ADM_PERMISSIONS.APP_SALES_PLAN_FEE_MANAGEMENT
        ) ||
        userData.owner_agent_id === salesPlan?.agent_id
      ),
    [isInttegrarAdm, permissionsName, userData, salesPlan]
  );

  const {
    data: myEcsSalesPlans,
    isLoading: isLoadingMyEcsSalesPlans,
    isError: isErrorMyEcsSalesPlans,
    isUninitialized: isUninitializedMyEcsSalesPlans,
  } = useIndexMyEcsSalesPlanQuery(
    {
      agent_id: salesPlan?.agent_id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !salesPlan?.agent_id,
    }
  );

  const { data: baseSalesPlan, isLoading: isLoadingBaseSalesPlan } =
    useShowSalesPlanQuery(
      {
        plan_id: baseSalesPlanId,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !baseSalesPlanId,
      }
    );

  const {
    data: subscriptions,
    isLoading: isLoadingSubscriptions,
    isError: isErrorSubscriptions,
    isUninitialized: isUninitializedSubscriptions,
    refetch: refetchSubscriptions,
  } = useIndexSalesPlanSubscriptionQuery(
    {
      page,
      plano_venda_id: id,
      filters: debouncedFilters,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleCreateDefaultFees = async () => {
    setIsLoading(true);
    try {
      await createDefaultFees({ sales_plan_id: id }).unwrap();
      toast.success("Tarifas da aplicação criadas com sucesso!");
      refetchSalesPlan();
    } catch (e) {
      toast.error("Erro ao criar tarifas da aplicação!");
      if (e.status === 403 && e.data?.message) toast.error(e.data.message);
    } finally {
      setOpenCreateDefaultFees(false);
      setIsLoading(false);
    }
  };

  const handleSetDefaultSalesPlan = async () => {
    setIsLoading(true);
    try {
      await setDefaultSalesPlan({ sales_plan_id: id }).unwrap();
      toast.success("Plano de Venda definido como padrão!");
      refetchSalesPlan();
    } catch (e) {
      toast.error("Erro ao definir Plano de Venda como padrão!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteSalesPlan = async () => {
    setIsLoading(true);
    try {
      await deleteSalesPlan({ plan_id: id }).unwrap();
      toast.success("Plano de Venda excluido!");
      history.goBack();
    } catch (e) {
      toast.error("Erro ao excluir Plano de Venda!");
    } finally {
      setOpenDeleteDialog(false);
      setIsLoading(false);
    }
  };

  const handleUpdateSalesPlan = async () => {
    setIsLoading(true);
    try {
      await updateSalesPlan({ sales_plan_id: id }).unwrap();
      toast.success("Estamos atualizando seu Plano de Vendas!");
      refetchSalesPlan();
    } catch (e) {
      toast.error("Erro ao atualizar o plano de vendas!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveEcSubscription = async () => {
    setIsLoading(true);
    try {
      await removeEcSubscription({
        subscription_id: subscriptionToDelete.id,
      }).unwrap();
      toast.success("Assinatura removida!");
    } catch (e) {
      toast.error("Erro ao remover assinatura!");
    } finally {
      refetchSubscriptions();
      refetchSalesPlan();
      setSubscriptionToDelete({});
      setOpenRemoveSubscriptionDialog(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isErrorSalesPlan) {
      toast.error("Aconteceu um erro tente novamente!");
      history.goBack();
    }
  }, [isErrorSalesPlan, history]);

  const handleChangePage = useCallback((e, value) => {
    setPage(value);
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <LoadingScreen
        style={{ zIndex: "10" }}
        isLoading={isLoading || isLoadingBaseSalesPlan}
      />
      {salesPlan?.source === "aplication" && (
        <AddAplicationSalesPlanSubscriptionModal
          openDialog={openAddSubscriptionDialog}
          setOpenDialog={setOpenAddSubscriptionDialog}
          refetchSubscriptions={refetchSubscriptions}
          refetchSalesPlan={refetchSalesPlan}
          planId={id}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {salesPlan?.source === "agent" && (
        <AddAgentSalesPlanSubscriptionModal
          openDialog={openAddSubscriptionDialog}
          setOpenDialog={setOpenAddSubscriptionDialog}
          refetchSubscriptions={refetchSubscriptions}
          refetchSalesPlan={refetchSalesPlan}
          planId={id}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          agentId={salesPlan.agent_id}
        />
      )}

      {openCreateDefaultFees && (
        <Dialog
          open={openCreateDefaultFees}
          onClose={() => {
            setSubscriptionToDelete({});
            setOpenCreateDefaultFees(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 1000 }}
        >
          <DialogTitle className={classes.dialogHeader}>
            <Typography align="center" variant="h6">
              Criar Tarifas Padrão
            </Typography>
          </DialogTitle>
          <Box display="flex" flexDirection="column" padding="12px 24px">
            <Typography>
              {`Deseja realmente criar tarifas da aplicação ${APP_CONFIG_PROJECT.name} para o Plano de Vendas ${salesPlan.name}?`}
            </Typography>
          </Box>
          <DialogActions>
            <Button
              onClick={handleCreateDefaultFees}
              variant="outlined"
              color="secondary"
            >
              Criar
            </Button>
            <Button
              onClick={() => {
                setOpenCreateDefaultFees(false);
              }}
              color="default"
              variant="outlined"
              autoFocus
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openRemoveSubscriptionDialog && (
        <Dialog
          open={openRemoveSubscriptionDialog}
          onClose={() => {
            setSubscriptionToDelete({});
            setOpenRemoveSubscriptionDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 1000 }}
        >
          <DialogTitle className={classes.dialogHeader}>
            <Typography align="center" variant="h6">
              Remover assinatura
            </Typography>
          </DialogTitle>
          <Box display="flex" flexDirection="column" padding="12px 24px">
            <Typography>
              {`Deseja realmente remover a assinatura deste EC do Plano de Vendas ${salesPlan.name}?`}
            </Typography>
          </Box>
          <DialogActions>
            <Button
              onClick={handleRemoveEcSubscription}
              variant="outlined"
              color="secondary"
            >
              Remover
            </Button>
            <Button
              onClick={() => {
                setSubscriptionToDelete({});
                setOpenRemoveSubscriptionDialog(false);
              }}
              color="default"
              variant="outlined"
              autoFocus
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openDeleteDialog && (
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 1000 }}
        >
          <DialogTitle className={classes.dialogHeader}>
            <Typography align="center" variant="h6">
              Remover Plano de Venda
            </Typography>
          </DialogTitle>
          <Box display="flex" flexDirection="column" padding="12px 24px">
            <Typography>
              {"Deseja realmente remover este Plano de Vendas da Aplicação " +
                APP_CONFIG().name +
                "?"}
            </Typography>
          </Box>
          <DialogActions>
            <Button
              onClick={handleDeleteSalesPlan}
              variant="outlined"
              color="secondary"
            >
              Sim
            </Button>
            <Button
              onClick={() => setOpenDeleteDialog(false)}
              color="default"
              variant="outlined"
              autoFocus
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <CustomBreadcrumbs
        path1="Planos de Vendas"
        to1="goBack"
        path2="Detalhes"
        to2="#"
      />

      <Box display="flex" justifyContent="">
        <Typography style={{ marginTop: "8px" }} variant="h4">
          Plano de Vendas
        </Typography>
      </Box>

      <Divider style={{ marginTop: 16, marginBottom: 8 }} />

      {!isLoadingSalesPlan && !isErrorSalesPlan && !isUninitializedSalesPlan ? (
        <>
          <Box display="flex">
            <Typography variant="h6" style={{ marginRight: "16px" }}>
              Detalhes do Plano de Vendas:
            </Typography>
            <Typography variant="h6" style={{ color: "green" }}>
              {salesPlan.is_default ? "PADRÃO" : null}{" "}
            </Typography>
          </Box>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            <Box flexGrow={3}>
              {salesPlan.source === "aplication" && (
                <AplicationSalesPlanDetails salesPlan={salesPlan} />
              )}
              {salesPlan.source === "agent" && (
                <>
                  <AgentSalesPlanDetails salesPlan={salesPlan} />
                  <Box>
                    <Typography>Plano Base para comparação</Typography>
                    <Select
                      style={{ width: "50%" }}
                      variant="standard"
                      onChange={(e) => setBaseSalesPlanId(e.target.value)}
                    >
                      {!isLoadingMyEcsSalesPlans &&
                        !isErrorMyEcsSalesPlans &&
                        !isUninitializedMyEcsSalesPlans &&
                        myEcsSalesPlans.map((plano) => (
                          <MenuItem value={plano.id} key={plano.id}>
                            {plano.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                  {baseSalesPlanId && baseSalesPlan && (
                    <>
                      <Divider style={{ marginTop: "20px" }} />
                      <Typography variant="h6" style={{ marginRight: "16px" }}>
                        Detalhes do Plano de Vendas base:
                      </Typography>
                      <AplicationSalesPlanDetails salesPlan={baseSalesPlan} />
                    </>
                  )}
                </>
              )}
            </Box>

            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Box marginTop="8px" display="flex" justifyContent="center">
                <GradientButton
                  buttonText="Adicionar EC"
                  onClick={() => setOpenAddSubscriptionDialog(true)}
                  disabled={false}
                />
              </Box>

              {isInttegrarAdm && salesPlan.source === "aplication" ? (
                <Box marginTop="8px" display="flex" justifyContent="center">
                  <GradientButton
                    buttonText="Criar Tarifas Padrão"
                    onClick={() => setOpenCreateDefaultFees(true)}
                  />
                </Box>
              ) : null}

              {isInttegrarAdm ||
              userData.owner_agent_id === salesPlan.agent_id ? (
                <Box marginTop="8px" display="flex" justifyContent="center">
                  <GradientButton
                    buttonText="Definar como Padrão"
                    onClick={handleSetDefaultSalesPlan}
                    disabled={salesPlan.is_default}
                  />
                </Box>
              ) : null}

              {isInttegrarAdm ||
              userData.owner_agent_id === salesPlan.agent_id ? (
                <Box marginTop="8px" display="flex" justifyContent="center">
                  <GradientButton
                    buttonText="Remover Plano de Vendas"
                    onClick={() => setOpenDeleteDialog(true)}
                    disabled={salesPlan.ec_count !== 0}
                  />
                </Box>
              ) : null}
              {isInttegrarAdm ? (
                <Box marginTop="8px" display="flex" justifyContent="center">
                  <GradientButton
                    buttonText="Atualizar Plano de Vendas"
                    onClick={handleUpdateSalesPlan}
                    disabled={isLoading}
                  />
                </Box>
              ) : null}
            </Box>
          </Box>

          <Divider style={{ marginTop: 16, marginBottom: 16 }} />

          <FeeDetails
            feeDetails={salesPlan.fee_details}
            disableAll={disableEditAllFees}
            baseFeeDetails={baseSalesPlan?.fee_details}
          />

          <Divider style={{ marginTop: 16, marginBottom: 8 }} />

          {!isLoadingSubscriptions &&
          !isErrorSubscriptions &&
          !isUninitializedSubscriptions ? (
            <>
              <Typography variant="h6">Assinaturas</Typography>
              <Box marginBottom="16px">
                <SearchBar
                  fullWidth
                  placeholder="Pesquisar por nome, documento, email..."
                  value={filters.like}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      like: e.target.value,
                    })
                  }
                />
              </Box>

              <CustomTable columns={columns} data={subscriptions.data} />

              <Box alignSelf="flex-end" marginTop="8px">
                <Pagination
                  variant="outlined"
                  color="secondary"
                  size="large"
                  count={subscriptions.last_page}
                  onChange={handleChangePage}
                  page={page}
                />
              </Box>
            </>
          ) : (
            <LinearProgress />
          )}
        </>
      ) : (
        <LoadingScreen isLoading={true} />
      )}
    </Box>
  );
};

export default SalesPlanDetail;
