import { Box, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import CurrencyFormat from "react-currency-format";

const AccountDetails = ({ account, balance, text }) => {
  const currentBalance = useMemo(
    () => balance ?? account.saldo ?? null,
    [account, balance]
  );

  return account ? (
    <Box>
      <Typography
        variant="h6"
        style={{ textAlign: "center", margin: "10px 0px" }}
      >
        {text}
      </Typography>
      <Typography>Nome:</Typography>
      <Typography variant="h6">{account.nome ? account.nome : "-"}</Typography>
      {account.razao_social && (
        <>
          <Typography>Razão social:</Typography>
          <Typography variant="h6">{account.razao_social}</Typography>
        </>
      )}
      <Typography>Documento:</Typography>
      <Typography variant="h6">
        {account.documento ? account.documento : "-"}
      </Typography>
      {account.cnpj && (
        <>
          <Typography>Cnpj:</Typography>
          <Typography variant="h6">{account.cnpj}</Typography>
        </>
      )}
      {currentBalance && (
        <>
          <Typography>Saldo:</Typography>
          <Typography variant="h6">
            <CurrencyFormat
              value={currentBalance.valor.replace(".", ",")}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"R$ "}
            />
          </Typography>
        </>
      )}
    </Box>
  ) : null;
};

export default AccountDetails;
