import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  useShowZoopSalesPlanQuery,
  useStoreZoopSalesPlanMutation,
} from "services/api";
import { toast } from "react-toastify";
import { format } from "date-fns";
import GradientButton from "components/reusable/GradientButton/GradientButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CopyToClipboard from "react-copy-to-clipboard";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import { ADM_PERMISSIONS } from "constants/permissions";
import { APP_CONFIG } from "constants/config";
import FeeDetails from "./Fees/FeeDetails";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
}));

const ZoopSalesPlanDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const userPermissions = useSelector((state) => state.userPermissao.permissao);
  const permissionsName = userPermissions.map((permission) => permission.tipo);
  const isInttegrarAdm = useMemo(
    () => permissionsName.includes(ADM_PERMISSIONS.INTTEGRAR),
    [permissionsName]
  );

  const [storeSalesPlan] = useStoreZoopSalesPlanMutation();
  const [openStoreDialog, setOpenStoreDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: salesPlan,
    isLoading: isLoadingSalesPlan,
    isError: isErrorSalesPlan,
    isUninitialized: isUninitializedSalesPlan,
  } = useShowZoopSalesPlanQuery(
    {
      zoop_plan_id: id,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleStoreSalesPlan = async () => {
    setIsLoading(true);
    try {
      await storeSalesPlan({
        zoop_plan_id: id,
      }).unwrap();
      toast.success("Plano de Vendas adicionado!");
    } catch (e) {
      toast.error("Erro ao adicionar Plano de Vendas!");
    } finally {
      setOpenStoreDialog(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isErrorSalesPlan) {
      toast.error("Aconteceu um erro tente novamente!");
      history.goBack();
    }
  }, [isErrorSalesPlan, history]);

  return (
    <Box display="flex" flexDirection="column">
      <LoadingScreen style={{ zIndex: "10" }} isLoading={isLoading} />

      {openStoreDialog && (
        <Dialog
          open={openStoreDialog}
          onClose={() => setOpenStoreDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: "1" }}
        >
          <DialogTitle className={classes.dialogHeader}>
            <Typography align="center" variant="h6">
              Adicionar Plano de Venda
            </Typography>
          </DialogTitle>
          <Box display="flex" flexDirection="column" padding="12px 24px">
            <Typography>
              {"Deseja realmente adicionar este Plano de Vendas na Aplicação " +
                APP_CONFIG().name +
                "?"}
            </Typography>
          </Box>
          <DialogActions>
            <Button
              onClick={handleStoreSalesPlan}
              variant="outlined"
              color="secondary"
            >
              Adicionar
            </Button>
            <Button
              onClick={() => setOpenStoreDialog(false)}
              color="default"
              variant="outlined"
              autoFocus
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <CustomBreadcrumbs
        path1="Planos de Vendas"
        to1="/dashboard/plano-vendas"
        path2="Zoop"
        to2="goBack"
        path3="Detalhes"
        to3="#"
      />

      <Box display="flex" justifyContent="">
        <Typography style={{ marginTop: "8px" }} variant="h4">
          Zoop Plano de Vendas
        </Typography>
      </Box>

      <Divider style={{ marginTop: 16, marginBottom: 8 }} />

      {!isLoadingSalesPlan && !isErrorSalesPlan && !isUninitializedSalesPlan ? (
        <>
          <Box display="flex" flexWrap="wrap" justifyContent="space-around">
            <Box display="flex" flexDirection="column">
              <Typography variant="h6">Detalhes do Plano de Vendas:</Typography>

              <Box
                display="flex"
                flexWrap="wrap"
                marginTop="12px"
              >
                <Box minWidth="350px">
                  <Typography variant="h6">
                    ID do Plano de Vendas: <br />
                    <TextField value={salesPlan.id ? salesPlan.id : "-"} />
                    <Tooltip title="Copiar ID da transação">
                      <CopyToClipboard text={salesPlan.id ? salesPlan.id : "-"}>
                        <Button
                          aria="Copiar"
                          style={{
                            marginLeft: "6px",
                            width: "60px",
                            height: "20px",
                            alignSelf: "center",
                            color: "green",
                          }}
                          onClick={() =>
                            toast.success(
                              "Copiado para area de transferência",
                              {
                                autoClose: 2000,
                              }
                            )
                          }
                        >
                          <FontAwesomeIcon
                            style={{
                              width: "60px",
                              height: "20px",
                            }}
                            icon={faCopy}
                          />
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </Typography>
                </Box>
                <Box width="350px">
                  <Typography variant="h6">
                    ID de Conciliação: <br />
                    <TextField value={salesPlan.id || "-"} />
                    <Tooltip title="Copiar ID Conciliação">
                      <CopyToClipboard text={salesPlan.id || "-"}>
                        <Button
                          aria="Copiar"
                          style={{
                            marginLeft: "6px",
                            width: "60px",
                            height: "20px",
                            alignSelf: "center",
                            color: "green",
                          }}
                          onClick={() =>
                            toast.success(
                              "Copiado para area de transferência",
                              {
                                autoClose: 2000,
                              }
                            )
                          }
                        >
                          <FontAwesomeIcon
                            style={{
                              width: "60px",
                              height: "20px",
                            }}
                            icon={faCopy}
                          />
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                marginTop="12px"
              >
                <Box width="350px">
                  <Typography style={{  }}>Nome:</Typography>
                  <Typography variant="h6">
                    {salesPlan.name ?? "-"}
                  </Typography>
                  <Typography>Situação do Plano de Vendas:</Typography>
                  <Typography variant="h6">
                    {salesPlan.is_active ? "Ativo" : "Inativo"}
                  </Typography>
                  <Typography>Total de ECs neste Plano de Vendas:</Typography>
                  <Typography variant="h6">
                    {salesPlan.ec_count ?? "0"}
                  </Typography>
                  <Typography>Valor máximo:</Typography>
                  <Typography variant="h6">
                    {`R$ ${(salesPlan.maximum_amount/100).toFixed(2)}` ?? "-"}
                  </Typography>
                </Box>
                <Box width="350px">
                  <Typography>Descrição:</Typography>
                  <Typography variant="h6">
                    {salesPlan.description ?? "-"}
                  </Typography>
                  <Typography>Criado em:</Typography>
                  <Typography variant="h6">
                    {salesPlan.created_at
                      ? format(new Date(salesPlan.created_at), "dd/MM/yyyy HH:mm:ss")
                      : "-"}
                  </Typography>
                  <Typography>Ultima atualização:</Typography>
                  <Typography variant="h6">
                    {salesPlan.updated_at
                      ? format(new Date(salesPlan.updated_at), "dd/MM/yyyy HH:mm:ss")
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column">
              {isInttegrarAdm ? (
                <Box marginTop="8px" display="flex" justifyContent="center">
                  <GradientButton
                    buttonText="Adicionar"
                    onClick={() => setOpenStoreDialog(true)}
                    disabled={false}
                  />
                </Box>
              ) : null}
            </Box>
          </Box>

          <Divider style={{ marginTop: 16, marginBottom: 16 }} />

          <FeeDetails feeDetails={salesPlan.fee_details} />
        </>
      ) : (
        <LoadingScreen isLoading={true} />
      )}
    </Box>
  );
};

export default ZoopSalesPlanDetail;
