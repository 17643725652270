import {
  Box,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import TransactionStatusColumn from "components/reusable/TableColumns/TransactionStatusColumn";
import CustomCopyToClipboard from "components/reusable/Text/CustomCopyToClipboard";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import { toast } from "react-toastify";
import { loadCarneId, loadCarneIdBoleto } from "../../../../actions/actions";
import CustomBreadcrumbs from "../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "../../../reusable/CustomTable/CustomTable";
import GradientButton from "../../../reusable/GradientButton/GradientButton";
import LoadingScreen from "../../../reusable/LoadingScreen/LoadingScreen";
import { faArrowCircleRight, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PayerDetails from "components/reusable/TransactionDetails/PayerDetails";

const columns = [
  {
    headerText: "Data de Vencimento",
    key: "data_vencimento",
    CustomValue: (data_vencimento) => {
      const date = new Date(data_vencimento);
      const option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      const formatted = date.toLocaleDateString("pt-br", option);
      return <Typography align="center"> {formatted}</Typography>;
    },
  },
  {
    headerText: "Parcela",
    key: "parcela",
  },
  {
    headerText: "Id",
    key: "id",
    CustomValue: (id) => {
      return <CustomCopyToClipboard value={id} title="Copiar" />;
    },
  },
  {
    headerText: "Situação",
    key: "status",
    CustomValue: (status) => {
      return <TransactionStatusColumn status={status} />;
    },
  },
  {
    headerText: "Visualizar",
    key: "url",
    CustomValue: (url) => (
      <IconButton
        onClick={() =>
          window.open(`${url}`, "Boleto", "height=1000,width=1000")
        }
      >
        <FontAwesomeIcon icon={faPrint} />
      </IconButton>
    ),
  },
  {
    headerText: "Detalhes",
    key: "transaction_id",
    CustomValue: (transaction_id) => {
      const history = useHistory();
      return (
        <IconButton
          onClick={() =>
            history.push(`/dashboard/detalhes-transacao/${transaction_id}/ver`)
          }
        >
          <FontAwesomeIcon icon={faArrowCircleRight} />
        </IconButton>
      );
    },
  },
];

const PaymentBookletDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(loadCarneId(id));
  }, []);

  const carneId = useSelector((state) => state.carne);
  const date = new Date(carneId.created_at);
  const option = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const formatted = date.toLocaleDateString("pt-br", option);

  const handleVisualizar = async () => {
    setLoading(true);
    const res = await dispatch(loadCarneIdBoleto(id));
    if (res.data && res && res.status === 200) {
      const newWindow = window.open("", "Carnê", "height=1000,width=1000");
      await newWindow.document.write(res.data);
      setLoading(false);
    } else {
      toast.error("Erro ao visualizar");
      setLoading(false);
    }
  };

  if (carneId.boleto === undefined) {
    return <LinearProgress />;
  } else
    return (
      <Box display="flex" flexDirection="column">
        <CustomBreadcrumbs
          path1="Carnê"
          to1="/dashboard/carne"
          path2="Detalhes do carnê"
        />
        <LoadingScreen isLoading={loading} />
        <Paper
          style={{
            padding: "24px",
            marginTop: "12px",
            borderRadius: "27px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h4"> Detalhes do Carnê </Typography>

          <Box>
            <Box
              display="flex"
              flexDirection="column"
              style={{ width: "100%" }}
            >
              <Box
                style={{
                  padding: "12px",
                  borderRadius: "27px 27px 0 0 ",
                  backgroundColor: "#08e1ae",
                  color: "white",
                }}
              >
                <Typography variant="h6" align="center">
                  Carne de {carneId.boleto.length} parcelas
                </Typography>
              </Box>

              <Box display="flex" marginTop="6px" flexDirection="column">
                <Typography variant="h6">Criado em: {formatted}</Typography>
                <Box display="flex">
                  <Typography style={{ marginRight: "10px" }} variant="h6">
                    Id:
                  </Typography>
                  <CustomCopyToClipboard
                    title={"Copiar número do carne"}
                    value={carneId.id}
                  />
                </Box>

                <Typography variant="h6">
                  Valor total: R${carneId.valor}
                </Typography>
              </Box>

              <Divider style={{ marginTop: "6px" }} />

              <PayerDetails payer={carneId.pagador} />

              <GradientButton
                onClick={handleVisualizar}
                style={{ marginTop: "6px" }}
                buttonText="Visualizar Carnê"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginTop: "26px", width: "100%" }}
          >
            <CustomTable
              data={carneId.boleto ? carneId.boleto : []}
              columns={columns}
            />
          </Box>
        </Paper>
      </Box>
    );
};

export default PaymentBookletDetails;
