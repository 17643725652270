export const APP_CONFIG_PROJECT = {
  aprobank: {
    linkApp: "https://app.aprobank.com.br",
    name: "Aprobank",
    description: "Aprobank - Soluções em pagamento para o seu negócio",
    mailSupport: "sac@aprobank.com.br",
    theme: {
      palette: {
        background: {
          default: "#8D1812",
          paper: "#FFF",
        },
        primary: {
          main: "#8D1812",
          light: "#F8CED0",
        },
        secondary: {
          main: "#E20613",
          light: "#FBE2E3",
        },
        menu: {
          backgroundItem: "rgba(0, 0, 0, 0.08)",
          backgroundItemSelected: "#fff",
          icon: "#fff",
          iconSelected: "#8D1812",
          text: "#fff"
        }
      },
      typography: {
        color: "#2c2c2c",
      },
      collectionItem: {
        background: "#FFFFFF",
        icon: "#8D1812",
      },
      gradient: {
        main: {
          primary: "#8d1812",
          secondary: "#f08b90"
        },
        light: {
          primary: "#c6d8db",
          secondary: "#ddf0f4"
        }
      }
    },
    cssVariables: {
      gradient: {
        main: "linear-gradient(90deg, hsla(356, 95%, 45%, 1), hsla(3, 77%, 31%, 1))",
        light: "linear-gradient(45deg, hsla(356, 95%, 45%, 0.1) 12%, hsla(0, 0%, 100%, 1) 83%)",
        button: "",
      },
    },
    assets: {
      primaryLogo: "/assets/logos/aprobankLogo.svg",
      secondaryLogo: "/assets/logos/secondary/aprobankLogo.svg",
      favicon: "/assets/favicon/aprobank.ico",
    },
  },
  maispay: {
    linkApp: "https://banco.maispaydigital.com.br",
    name: "MaisPay",
    description: "MaisPay - Soluções em pagamento para o seu negócio",
    mailSupport: "contato@maispaydigital.com.br",
    theme: {
      palette: {
        background: {
          default: "#193267",
          paper: "#FFF",
        },
        primary: {
          main: "#193267",
          light: "#c6d8db",
        },
        secondary: {
          main: "#A4C739",
          light: "#ddf0f4",
        },
        menu: {
          backgroundItem: "rgba(0, 0, 0, 0.08)",
          backgroundItemSelected: "#fff",
          icon: "#fff",
          iconSelected: "#A4C739",
          text: "#fff"
        },
      },
      typography: {
        color: "#2c2c2c",
      },
      collectionItem: {
        background: "#c6d8db",
        icon: "#0f1c36",
      },
      gradient: {
        main: {
          primary: "#A4C739",
          secondary: "#A4C739"
        },
        light: {
          primary: "hsla(221, 61%, 25%, 0.01)",
          secondary: "hsla(75, 56%, 50%, 0.1)"
        }
      }
    },
    cssVariables: {
      gradient: {
        main: "#A4C739",
        light: "linear-gradient(90deg, hsla(75, 56%, 50%, 0.1) 0%, hsla(221, 61%, 25%, 0.01) 52%)",
        button: "",
      },
    },
    assets: {
      primaryLogo: "/assets/logos/maispayLogo.svg",
      secondaryLogo: "/assets/logos/secondary/maispayLogo.svg",
      favicon: "/assets/favicon/maispay.ico",
    },
  },
  integrapay: {
    linkApp: "https://banking.integrapay.com.br",
    name: "IntegraPAY",
    description: "IntegraPAY - Soluções em pagamento para o seu negócio",
    mailSupport: "contato@integrapay.com.br",
    theme: {
      palette: {
        background: {
          default: "#1E396D",
          paper: "#FFF",
        },
        primary: {
          main: "#0f1c36",
          light: "#c6d8db",
        },
        secondary: {
          main: "#40DAE2",
          light: "#ddf0f4",
        },
        menu: {
          backgroundItem: "rgba(0, 0, 0, 0.08)",
          backgroundItemSelected: "#fff",
          icon: "#fff",
          iconSelected: "#1E396D",
          text: "#fff"
        }
      },
      typography: {
        color: "#2c2c2c",
      },
      collectionItem: {
        background: "#c6d8db",
        icon: "#0f1c36",
      },
      gradient: {
        main: {
          primary: "#17A8AF",
          secondary: "#40DAE2"
        },
        light: {
          primary: "rgba(0,212,255,0.3)",
          secondary: "rgba(30,57,109,0.1)"
        }
      }
    },
    cssVariables: {
      gradient: {
        main: "linear-gradient(45deg, #40DAE2, #17A8AF)",
        light: "linear-gradient(90deg,  rgba(30,57,109,0.1) 42%, rgba(0,212,255,0.3) 100%)",
        button: "",
      },
    },
    assets: {
      primaryLogo: "/assets/logos/integrapayLogo.svg",
      secondaryLogo: "/assets/logos/secondary/integrapayLogo.svg",
      favicon: "/assets/favicon/integrapay.ico",
    },
  },
}[process.env.REACT_APP_FRONT_APP || "integrapay"];

export const APP_CONFIG = () => ({
  ...APP_CONFIG_PROJECT,
  name: localStorage.getItem("agentName") ?? APP_CONFIG_PROJECT.name,
  description: localStorage.getItem("agentDescription") ?? APP_CONFIG_PROJECT.description,
  mailSupport: localStorage.getItem("agentMailSupport") ?? APP_CONFIG_PROJECT.mailSupport,
  theme: {
    palette: {
      background: {
        default: localStorage.getItem("backgroundDefault") ?? APP_CONFIG_PROJECT.theme.palette.background.default,
        paper: localStorage.getItem("backgroundPaper") ?? APP_CONFIG_PROJECT.theme.palette.background.paper,
      },
      primary: {
        main: localStorage.getItem("primaryMain") ?? APP_CONFIG_PROJECT.theme.palette.primary.main,
        light: localStorage.getItem("primaryLight") ?? APP_CONFIG_PROJECT.theme.palette.primary.light,
      },
      secondary: {
        main: localStorage.getItem("secondaryMain") ?? APP_CONFIG_PROJECT.theme.palette.secondary.main,
        light: localStorage.getItem("secondaryLight") ?? APP_CONFIG_PROJECT.theme.palette.secondary.light,
      },
      menu: {
        backgroundItem: localStorage.getItem("menuBackgroundItem") ?? APP_CONFIG_PROJECT.theme.palette.menu.backgroundItem,
        backgroundItemSelected: localStorage.getItem("menuBackgroundItemSelected") ?? APP_CONFIG_PROJECT.theme.palette.menu.backgroundItemSelected,
        icon: localStorage.getItem("menuIcon") ?? APP_CONFIG_PROJECT.theme.palette.menu.icon,
        iconSelected: localStorage.getItem("menuIconSelected") ?? APP_CONFIG_PROJECT.theme.palette.menu.iconSelected,
        text: localStorage.getItem("menuText") ?? APP_CONFIG_PROJECT.theme.palette.menu.text,
      }
    },
    typography: {
      color: localStorage.getItem("typographyColor") ?? APP_CONFIG_PROJECT.theme.typography.color,
    },
    collectionItem: {
      background: localStorage.getItem("collectionItemBackground") ?? APP_CONFIG_PROJECT.theme.collectionItem.background,
      icon: localStorage.getItem("collectionItemIcon") ?? APP_CONFIG_PROJECT.theme.collectionItem.icon,
    },
  },
  cssVariables: {
    gradient: {
      main: `linear-gradient(45deg, ${localStorage.getItem("gradientMainSecondary") ?? APP_CONFIG_PROJECT.theme.gradient.main.secondary}, ${localStorage.getItem("gradientMainPrimary") ?? APP_CONFIG_PROJECT.theme.gradient.main.primary})`,
      light: `linear-gradient(90deg, ${localStorage.getItem("gradientLightSecondary") ?? APP_CONFIG_PROJECT.theme.gradient.light.secondary} 20%, ${localStorage.getItem("gradientLightPrimary") ?? APP_CONFIG_PROJECT.theme.gradient.light.primary} 80%)`,
      button: "",
    },
  },
  assets: {
    primaryLogo: localStorage.getItem("agentLogoUrl") ?? APP_CONFIG_PROJECT.assets.primaryLogo,
    secondaryLogo: localStorage.getItem("agentLogoUrl") ?? APP_CONFIG_PROJECT.assets.secondaryLogo,
    favicon: localStorage.getItem("agentFaviconUrl") ?? APP_CONFIG_PROJECT.assets.favicon,
  },
});

export const agentThemeCache = {
  name: "agentName",
  description: "agentDescription",
  mailSupport: "agentMailSupport",
  agentLogo: "agentLogoUrl",
  agentFavicon: "agentFaviconUrl",
  theme: {
    palette: {
      background: {
        default: "backgroundDefault",
        paper: "backgroundPaper",
      },
      primary: {
        main: "primaryMain",
        light: "primaryLight",
      },
      secondary: {
        main: "secondaryMain",
        light: "secondaryLight",
      },
      menu: {
        backgroundItem: "menuBackgroundItem",
        backgroundItemSelected: "menuBackgroundItemSelected",
        icon: "menuIcon",
        iconSelected: "menuIconSelected",
        text: "menuText"
      }
    },
    typography: {
      color: "typographyColor",
    },
    collectionItem: {
      background: "collectionItemBackground",
      icon: "collectionItemIcon",
    },
    gradient: {
      main: {
        primary: "gradientMainPrimary",
        secondary: "gradientMainSecondary"
      },
      light: {
        primary: "gradientLightPrimary",
        secondary: "gradientLightSecondary"
      }
    }
  },
}
