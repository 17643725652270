import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import { toast } from "react-toastify";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { useDeleteAgentLogoMutation, useShowAgentQuery, useStoreAgentLogoMutation } from "services/api";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  card: {
    margin: theme.spacing(1),
    padding: 0,
  },
  dropzoneAreaBaseClasses: {
    width: "70%",
    height: "250px",
    fontSize: "10px",
  },
  dropzoneContainer: {
    margin: "6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "12px",
  },
  textoDropzone: {
    fontSize: "1.2rem",
  },
}));

const AgentLogo = ({ loading, setLoading }) => {
  const classes = useStyles();
  const userData = useSelector((state) => state.userData);
  const [storeAgentLogo, storeAgentLogoStatus] = useStoreAgentLogoMutation();
  const [deleteAgentLogo, deleteAgentLogoStatus] = useDeleteAgentLogoMutation();

  const {
    data: agent,
    refetch,
    isLoading,
    isError,
    isUninitialized,
  } = useShowAgentQuery(
    {
      agent_id: userData.owner_agent_id,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  
  const onDropLogo = async (logo) => {
    setLoading(true);
    try {
      await storeAgentLogo({agent_id: agent.id, logo}).unwrap();
      toast.success("Logo adicionado com sucesso!");
      refetch();
    } catch (e) {
      if(e.data.message) toast.error(e.data.message);
      toast.error("Erro ao adicionar Logo!");
    }
    setLoading(false);
  };

  const handleDeleteLogo = async () => {
    setLoading(true);
    try {
      await deleteAgentLogo({agent_id: agent.id}).unwrap();
      toast.success("Logo removido com sucesso!");
      refetch();
    } catch (e) {
      toast.error("Erro ao remover Logo!");
    }
    setLoading(false);
  };

  return (
    <Box className={classes.root}>
      {!isLoading && !isError && !isUninitialized &&
        <Box
          display="flex"
          justifyContent="center"
        >
          <Box display="flex" flexDirection="column" margin="8px">
            <Box className={classes.dropzoneContainer} boxShadow={3}>
              <DropzoneAreaBase
                dropzoneParagraphClass={classes.textoDropzone}
                maxFileSize={3145728}
                onDropRejected={() => {
                  toast.error("Tamanho máximo: 3mb ");
                  toast.error("Arquivos suportados: .png .jpg .jpeg");
                }}
                acceptedFiles={["image/*"]}
                dropzoneClass={classes.dropzoneAreaBaseClasses}
                onAdd={onDropLogo}
                filesLimit={1}
                dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
                showPreviews={false}
                showPreviewsInDropzone={false}
              />
              {agent.logo_url &&
                <Box display="flex">
                  <Card className={classes.card}>
                    <CardActionArea>
                      <Box>
                        <IconButton
                          onClick={() => handleDeleteLogo()}
                          size="small"
                          style={{ color: "white", backgroundColor: "red" }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                      <CardMedia
                        component="img"
                        alt="Logo"
                        height="100"
                        image={agent.public_logo_url}
                        onClick={() => window.open(agent.public_logo_url)}
                      />
                    </CardActionArea>
                  </Card> 
                </Box>
              }
            </Box>
          </Box>
        </Box>
      }
    </Box>
  );
};

export default AgentLogo;
