import { Typography } from "@material-ui/core";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import TransactionStatusColumn from "components/reusable/TableColumns/TransactionStatusColumn";
import { formatMoney } from "utils/money";
import getAuditDetails from "./getAuditDetails";
import { getTotalFee } from "helpers/fee_transaction";

const columns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (created_at) => {
      return <DateTimeColumn dateTime={created_at} />;
    },
  },
  {
    headerText: "Tipo",
    key: "transaction",
    CustomValue: (transaction) => {
      const type = transaction.payment_type;
      if (type === "credit") {
        const installments = transaction.installment_plan
          ? transaction.installment_plan.number_installments
          : 1;
        const flag = transaction.payment_method.card_brand;
        return (
          <Typography>
            Crédito {installments}x - {flag}
          </Typography>
        );
      }
      if (type === "debit") {
        return <Typography>Débito</Typography>;
      }
      if (type === "boleto") {
        return <Typography>Boleto</Typography>;
      }
      if (type === "commission") {
        return <Typography>Comissão</Typography>;
      }
      if (type === "pix") {
        return <Typography>Pix</Typography>;
      }
    },
  },
  {
    headerText: "Situação",
    key: "transaction.status",
    CustomValue: (status) => {
      return <TransactionStatusColumn status={status} />;
    },
  },
  {
    headerText: "Mdr",
    key: "full_custom_mdr",
    FullCustom: (transacao) => {
      const { mdr } = transacao;

      if (!mdr)
        return (
          <Typography style={{ color: "red" }}>
            <b>Erro</b>
          </Typography>
        );

      const systemFeeError = !mdr.applied_system_fee;
      const appFeeError = mdr.app_receiver_id && !mdr.applied_app_fee;
      const agentFeeError = mdr.agent_receiver_id && !mdr?.applied_agent_fee;

      if (appFeeError || agentFeeError || systemFeeError)
        return (
          <>
            {systemFeeError ? (
              <Typography style={{ color: "red" }}>
                <b>Erro na taxa de sistema</b>
              </Typography>
            ) : null}
            {appFeeError ? (
              <Typography style={{ color: "red" }}>
                <b>Erro na taxa app</b>
              </Typography>
            ) : null}
            {agentFeeError ? (
              <Typography style={{ color: "red" }}>
                <b>Erro na taxa de agente</b>
              </Typography>
            ) : null}
          </>
        );

      return (
        <Typography style={{ color: "green" }}>
          <b>OK</b>
        </Typography>
      );
    },
  },
  {
    headerText: "Valor",
    key: "transaction.amount",
    CustomValue: (amount) => {
      return <Typography>{formatMoney(amount)}</Typography>;
    },
  },
  {
    headerText: "Taxa Prevista",
    key: "receivables_preview",
    CustomValue: (receivables) => {
      const paymentItem = receivables.payment[0].items[0];
      return <Typography>{formatMoney(paymentItem.total_fees)}</Typography>;
    },
  },
  {
    headerText: "Taxa Aplicada",
    key: "full_transaction_key",
    FullCustom: (transacao) => {
      return <Typography>{formatMoney(getTotalFee(transacao))}</Typography>;
    },
  },
  {
    headerText: "Previsto",
    key: "receivables_preview",
    CustomValue: (receivables) => {
      const paymentItem = receivables.payment[0].items[0];
      const previewAmount =
        parseFloat(paymentItem.gross_amount) +
        parseFloat(paymentItem.total_splits_amount) -
        parseFloat(paymentItem.total_fees);
      return <Typography>{formatMoney(previewAmount)}</Typography>;
    },
  },
  {
    headerText: "Pago",
    key: "receivables_paid",
    CustomValue: (receivablesPaid) => {
      const totalPaymentPaid = receivablesPaid.payment.reduce(
        (total, item) => parseFloat(total) + parseFloat(item.total_paid_amount),
        0.0
      );
      const totalSplitPaid = receivablesPaid.split.reduce(
        (total, item) => parseFloat(total) + parseFloat(item.total_paid_amount),
        0.0
      );
      return (
        <Typography>
          {formatMoney(totalPaymentPaid + totalSplitPaid)}
        </Typography>
      );
    },
  },
  {
    headerText: "Status Pagamento",
    key: "full_transaction_key1",
    FullCustom: (transacao) => {
      const { conciliationStatus } = getAuditDetails({
        transaction: transacao.transaction,
        receivablesPaid: transacao.receivables_paid,
        receivablesPreview: transacao.receivables_preview,
      });
      return (
        <Typography style={{ color: conciliationStatus.color }}>
          <b>{conciliationStatus.text}</b>
        </Typography>
      );
    },
  },
];

export default columns;
