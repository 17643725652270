import { IconButton, Tooltip, Typography } from "@material-ui/core";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import { formatMoneyCnba } from "utils/moneyCnba";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const infoText = (item) => {
  if (item.boleto === null) {
    return (
      <ul>
        <li>O boleto não podê ser criado</li>
        <li>{item.error_response_data?.message}</li>
      </ul>
    );
  }

  if (item.boleto) return "O boleto foi criado com sucesso";

  if (!item.boleto && item.error_response_data) {
    const reasons = item.error_response_data.error.reasons;
    const formattedReasons = reasons.map((reason, index) => (
      <li key={index}>{reason}</li>
    ));

    return <ul>{formattedReasons}</ul>;
  }

  return "Unable to create bank slip";
};

const columns = [
  { headerText: "ID", key: "id" },
  { headerText: "Nome do pagador", key: "nome_pagador" },
  { headerText: "Documento do pagador", key: "numero_inscricao_pagador" },
  { headerText: "Número documento", key: "numero_documento" },
  {
    headerText: "Valor do título",
    key: "data.valor_titulo",
    CustomValue: (value) => {
      return <Typography>R$ {formatMoneyCnba(value)}</Typography>;
    },
  },
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (created_at) => {
      return <DateTimeColumn dateTime={created_at} />;
    },
  },

  {
    headerText: "Email enviado em",
    key: "sent_by_email_at",
    CustomValue: (sent_by_email_at) => {
      return <DateTimeColumn dateTime={sent_by_email_at} />;
    },
  },

  {
    headerText: "Status",
    key: "",
    FullCustom: (item) => {
      if (item.boleto === null) {
        return (
          <Typography style={{ color: "red" }}>
            <b> NÃO CRIADO</b>
          </Typography>
        );
      } else if (item.boleto) {
        return (
          <Typography style={{ color: "green" }}>
            <b> OK</b>
          </Typography>
        );
      } else {
        return (
          <Typography style={{ color: "#dfad06" }}>
            <b>PENDENTE</b>
          </Typography>
        );
      }
    },
  },
  {
    headerText: "Info",
    key: "",
    FullCustom: (item) => {
      return (
        <Tooltip
          title={
            <Typography variant="body2" component="span">
              {infoText(item)}
            </Typography>
          }
        >
          <IconButton>
            <FontAwesomeIcon icon={faQuestionCircle} width="18px" />
          </IconButton>
        </Tooltip>
      );
    },
  },
];

export default columns;
