import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  LinearProgress,
  makeStyles,
  TextField,
  Tooltip,
  Typography } from "@material-ui/core";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import { useSelector } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router";
import { useDeletePosMutation, usePosTransactionsQuery, useShowPosQuery, useUpdatePosMutation } from "services/api";
import { toast } from "react-toastify";
import { format } from "date-fns";
import GradientButton from "components/reusable/GradientButton/GradientButton";
import { Pagination } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import CopyToClipboard from "react-copy-to-clipboard";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Columns from "../../TransactionHistory/TransactionHistoryColumns";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  SplitModal: {
    padding: "20px",
  },
  updatePosHeader: {
    background: theme.gradient.main,
    color: "white",
  },
}));

const PosDetail = () => {
  const classes = useStyles();
	const [page, setPage] = useState(1);
	const { id } = useParams();
	const history = useHistory();
	const userData = useSelector((state) => state.userData);
	const accountId = useMemo(() => id || userData.id, [id, userData]);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
	const [deletePos] = useDeletePosMutation();
  const [updatePos, updatePosStatus] = useUpdatePosMutation();
	const updatePosError = useMemo(() => updatePosStatus.error ? updatePosStatus.error.data.errors : null, [updatePosStatus]);
  const [namePos, setNamePos] = useState('');

	const {
		data,
		isLoading,
		isError,
		isUninitialized,
    refetch
	} = useShowPosQuery(
		{
			posId: id,
		},
		{
			skip: !accountId,
			refetchOnMountOrArgChange: true,
		}
	);

	const {
		data: transactionsData,
		isLoading: transactionsIsLoading,
		isError: transactionsIsError,
		isUninitialized: transactionsIsUninitialized,
	} = usePosTransactionsQuery(
		{
			posId: id,
		},
		{
			skip: !accountId,
			refetchOnMountOrArgChange: true,
		}
	);

  const handleDeletePos = async () => {
    try {
			await deletePos({ posId: id }).unwrap();
      toast.success("POS excluido!");
      history.goBack();
    } catch (e) {
      toast.error("Erro ao excluir POS!");
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  const handleUpdatePos = async () => {
    try {
			await updatePos({ posId: id, name: namePos}).unwrap();
      toast.success("POS atualizado!");
      setOpenUpdateDialog(false);
      setNamePos('');
      refetch();
    } catch (e) {
      toast.error("Erro ao atualizar POS!");
    }
  };

	useEffect(() => {
		if (isError) {
			toast.error("Aconteceu um erro tente novamente!");
			history.goBack();
		}
	}, [isError, history]);


	const handleClickRow = (row) => {
		const path = generatePath('/dashboard/detalhes-transacao/:id/ver', {
			id: row.transaction.id,
		});
		history.push(path);
	};

	const handleChangePage = useCallback((e, value) => {
    setPage(value);
  }, []);

	return (
		<Box display="flex" flexDirection="column">

      <Dialog 
        onClose={() => setOpenUpdateDialog(false)}
        open={openUpdateDialog}
        className={classes.SplitModal}>
				<Box display="flex" flexDirection="column" width="500px">
					<LoadingScreen isLoading={updatePosStatus.isLoading} />
					<DialogTitle className={classes.updatePosHeader}>
						<Typography align="center" variant="h6">
							Atualizar nome do POS
						</Typography>
					</DialogTitle>

					<Box margin="20px">
						<FormControl fullWidth>
							<Typography variant="h6">
								Novo nome do terminal POS
							</Typography>
							<TextField
								className={classes.currency}
								value={namePos}
								onChange={(event) =>
									setNamePos(event.target.value)
								}
								style={{
									marginBottom: "6px",
									width: "100%",
								}}
							/>
							{
								updatePosError ? (
									<FormHelperText
										style={{
											marginBottom: "6px",
											width: "60%",
											color: "red",
										}}
									>
										{updatePosError.name ? updatePosError.name[0] : null}
									</FormHelperText>
								) : null
              }
						</FormControl>
					</Box>
					
					<Box width="50%" alignSelf="end" display="flex" justifyContent="space-around" padding="12px 24px">
						<Box margin="6px 0">
							<GradientButton
								buttonText={"Atualizar"}
								onClick={handleUpdatePos}
							/>
						</Box>
						<Box>
							<Button
								style={{ borderRadius: "37px", margin: "6px 0" }}
								variant="outlined"
								onClick={() => setOpenUpdateDialog(false)}
							>
								Cancelar
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>

			<Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Deseja realmente excluir este POS?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDeletePos}
            variant="outlined"
            color="secondary"
          >
            Sim
          </Button>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            color="default"
            variant="outlined"
            autoFocus
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
			
			<CustomBreadcrumbs path1="Terminais POS" to1="goBack" path2="Terminal POS" />

			<Box display="flex" justifyContent="">
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Terminal POS
				</Typography>
			</Box>

			<Divider style={{ marginTop: 16, marginBottom: 8 }} />

			{!isLoading && !isError && !isUninitialized ? (
				<>
					<Box 
						display="flex"
						flexWrap="wrap"
						justifyContent="space-around"
					>
						<Box
							display="flex"
							flexDirection="column"
						>
							<Typography variant="h6">
								Detalhes do terminal POS:
							</Typography>

							<Box
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
                marginTop="12px"
              >
                <Box>
                  <Typography variant="h6">
                    ID do terminal POS: <br />
                    <TextField value={data.id ? data.id : "-"} />
                    <Tooltip title="Copiar ID da transação">
                      <CopyToClipboard text={data.id ? data.id : "-"}>
                        <Button
                          aria="Copiar"
                          style={{
                            marginLeft: "6px",
                            width: "60px",
                            height: "20px",
                            alignSelf: "center",
                            color: "green",
                          }}
                          onClick={() =>
                            toast.success(
                              "Copiado para area de transferência",
                              {
                                autoClose: 2000,
                              }
                            )
                          }
                        >
                          <FontAwesomeIcon
                            style={{
                              width: "60px",
                              height: "20px",
                            }}
                            icon={faCopy}
                          />
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6">
                    ID de Conciliação: <br />
                    <TextField value={data.terminal_id || "-"} />
                    <Tooltip title="Copiar ID Conciliação">
                      <CopyToClipboard text={data.terminal_id || "-"}>
                        <Button
                          aria="Copiar"
                          style={{
                            marginLeft: "6px",
                            width: "60px",
                            height: "20px",
                            alignSelf: "center",
                            color: "green",
                          }}
                          onClick={() =>
                            toast.success(
                              "Copiado para area de transferência",
                              {
                                autoClose: 2000,
                              }
                            )
                          }
                        >
                          <FontAwesomeIcon
                            style={{
                              width: "60px",
                              height: "20px",
                            }}
                            icon={faCopy}
                          />
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </Typography>
                </Box>
              </Box>
							<Typography style={{ marginTop: "10px" }}>Nome:</Typography>
							<Typography variant="h6">
								{data.name ? data.name : "-"}
							</Typography>
							<Typography>Status:</Typography>
							<Typography variant="h6">
								{data.status || "sem status"}
							</Typography>
							<Typography>Criado em:</Typography>
							<Typography variant="h6">
								{data.created_at ? format(new Date(data.created_at), "dd/MM/yyyy HH:mm:ss") : "-"}
							</Typography>
							<Typography>Ultima atualização:</Typography>
							<Typography variant="h6">
								{data.updated_at ? format(new Date(data.updated_at), "dd/MM/yyyy HH:mm:ss") : "-"}
							</Typography>
							<Typography>Token de ativação utilizado:</Typography>
							<Typography variant="h6">
								{data.token || "-"}
							</Typography>
						</Box>

						<Box
							display="flex"
							flexDirection="column"
						>
							<Typography align="center" variant="h6">
								Opções
							</Typography>
              <Box marginTop="8px" display="flex" justifyContent="center">
								<GradientButton
									buttonText="Atualizar POS"
									onClick={() => setOpenUpdateDialog(true)}
                  disabled={userData.id !== data?.conta_id}
								/>
							</Box>
							<Box marginTop="8px" display="flex" justifyContent="center">
								<GradientButton
									buttonText="Excluir POS"
									onClick={() => setOpenDeleteDialog(true)}
                  disabled={userData.id !== data.conta_id}
								/>
							</Box>
						</Box>
					</Box>

					<Divider style={{ marginTop: 16, marginBottom: 8 }} />

					{!transactionsIsLoading && !transactionsIsError && !transactionsIsUninitialized ? (
						<>
							<Typography variant="h6">
								Transações
							</Typography>
              <Typography>Total encontradas: {transactionsData.total || "0"} transações</Typography>

							<CustomTable columns={Columns} data={transactionsData.data} handleClickRow={handleClickRow} />
							
							<Box alignSelf="flex-end" marginTop="8px">
								<Pagination
									variant="outlined"
									color="secondary"
									size="large"
									count={transactionsData.last_page}
									onChange={handleChangePage}
									page={page}
								/>
							</Box>
						</>
					) : (
						<LinearProgress />
					)}
						
				</>
			) : (
				<LinearProgress />
			)}

		</Box>
	);
};

export default PosDetail;
