import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, makeStyles, TextField, Typography, useTheme } from "@material-ui/core";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { getCep } from "services/services";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginRight: "10px",
  },
  infoLine: {
    display: "flex",
    marginTop: "10px"
  }
}));

const CreateEditAgentForm = ({ disableEditar, isError, errorsInfo, agentInfo, setAgentInfo }) => {
	const [pessoaJuridica, setPessoaJuridica] = useState(false);
	const theme = useTheme();
  const classes = useStyles();
  const [errors, setErrors] = useState([]);

	const handlerCep = async () => {
		try {
			const response = await getCep(agentInfo.endereco.cep);
			setAgentInfo({
				...agentInfo,
				endereco: {
					...agentInfo.endereco,
					rua: response.data.logradouro,
					bairro: response.data.bairro,
					complemento: response.data.complemento,
					cidade: response.data.localidade,
					estado: response.data.uf,
				},
			});
		} catch (error) {
			toast.error("Erro ao puxar dados do cep");
		}
	};

  useEffect(() => {
    if(isError && errorsInfo) {
      const arrayErros = [];
      Object.keys(errorsInfo).forEach((key, numeric_key) => {
        arrayErros[key.replace(".", "_")] = errorsInfo[key];
      });
      setErrors(arrayErros);
    } else {
      setErrors({});
    }
  }, [errorsInfo, isError])

	useEffect(() => {
    if(agentInfo.cnpj) {
      setPessoaJuridica(true);
    }
	}, [agentInfo]);

	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Typography align="center" variant="h6" style={{ marginTop: "12px" }}>
				Dados do Representante
			</Typography>

			<Box>
				<Button
					disabled={disableEditar}
					variant="contained"
					style={{
						margin: "5px",
						borderRadius: "27px",
						backgroundColor: pessoaJuridica ? "" : theme.palette.primary.main,
						color: "white",
					}}
					onClick={() => setPessoaJuridica(false)}
				>
					Pessoa Física
				</Button>
				<Button
					disabled={disableEditar}
					variant="contained"
					style={{
						margin: "5px",
						borderRadius: "27px",
						backgroundColor: pessoaJuridica ? theme.palette.primary.main : "",
						color: "white"
					}}
					onClick={() => setPessoaJuridica(true)}
				>
					Pessoa Jurídica
				</Button>
			</Box>
			<FormControl fullWidth>
        <Box className={classes.infoLine}>
          <InputMask
            disabled={disableEditar}
            mask={"999.999.999-99"}
            value={agentInfo.owner_cpf}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                owner_cpf: event.target.value,
              })
            }
          >
            {() => (
              <TextField
                disabled={disableEditar}
                InputLabelProps={{ shrink: true }}
                className={classes.textField}
                label="Cpf"
                fullWidth
                error={errors.agent_owner_cpf ? true : false}
                helperText={errors.agent_owner_cpf ? errors.agent_owner_cpf.join(" ") : null}
              />
            )}
          </InputMask>
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            label="Nome"
            fullWidth
            error={errors.agent_owner_first_name ? true : false}
            helperText={errors.agent_owner_first_name ? errors.agent_owner_first_name.join(" ") : null}
            value={agentInfo.owner_first_name}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                owner_first_name: event.target.value,
              })
            }
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            label="Sobrenome"
            fullWidth
            error={errors.agent_owner_last_name ? true : false}
            helperText={errors.agent_owner_last_name ? errors.agent_owner_last_name.join(" ") : null}
            value={agentInfo.owner_last_name}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                owner_last_name: event.target.value,
              })
            }
          />
          <TextField
            fullWidth
            required
            error={errors.agent_owner_birth_date ? true : false}
            helperText={errors.agent_owner_birth_date ? errors.agent_owner_birth_date.join(" ") : null}
            InputLabelProps={{
              shrink: true,
              pattern: "d {4}- d {2}- d {2} ",
            }}
            type="date"
            label="Data de Nascimento"
            value={agentInfo.owner_birth_date}
            onChange={(e) =>
              setAgentInfo({
                ...agentInfo,
                owner_birth_date: e.target.value,
              })
            }
          />
        </Box>
        {pessoaJuridica &&
          <Box className={classes.infoLine}>
            <InputMask
              disabled={disableEditar}
              mask={"99.999.999/9999-99"}
              value={agentInfo.cnpj}
              onChange={(event) =>
                setAgentInfo({
                  ...agentInfo,
                  cnpj: event.target.value,
                })
              }
            >
              {() => (
                <TextField
                  disabled={disableEditar}
                  InputLabelProps={{ shrink: true }}
                  className={classes.textField}
                  label="Cnpj"
                  fullWidth
                  error={errors.agent_cnpj ? true : false}
                  helperText={errors.agent_cnpj ? errors.agent_cnpj.join(" ") : null}
                />
              )}
            </InputMask>
            <TextField
              InputLabelProps={{ shrink: true }}
              label="Razão Social"
              fullWidth
              error={errors.agent_business_name ? true : false}
              helperText={errors.agent_business_name ? errors.agent_business_name.join(" ") : null}
              value={agentInfo.business_name}
              onChange={(event) =>
                setAgentInfo({
                  ...agentInfo,
                  business_name: event.target.value,
                })
              }
            />
          </Box>
        }
        <Box className={classes.infoLine}>
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            label="Renda (R$)"
            type="number"
            fullWidth
            error={errors.renda ? true : false}
            helperText={errors.renda ? errors.renda.join(" ") : null}
            value={agentInfo.renda}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                renda: event.target.value,
              })
            }
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            label="Email de contato"
            fullWidth
            error={errors.agent_contact_email ? true : false}
            helperText={errors.agent_contact_email ? errors.agent_contact_email.join(" ") : null}
            value={agentInfo.contact_email}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                contact_email: event.target.value,
              })
            }
          />
          <InputMask
              mask={"(99) 99999-9999"}
              value={agentInfo.contact_number}
              onChange={(event) =>
                setAgentInfo({
                  ...agentInfo,
                  contact_number: event.target.value,
                })
              }
            >
              {() => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Telefone de contato"
                  fullWidth
                  error={errors.agent_contact_number ? true : false}
                  helperText={errors.agent_contact_number ? errors.agent_contact_number.join(" ") : null}
                />
              )}
            </InputMask>
        </Box>
        {pessoaJuridica &&
          <Box className={classes.infoLine}>
            <TextField
              InputLabelProps={{ shrink: true }}
              label="Descrição"
              fullWidth
              error={errors.agent_business_description ? true : false}
              helperText={errors.agent_business_description ? errors.agent_business_description.join(" ") : null}
              value={agentInfo.business_description}
              onChange={(event) =>
                setAgentInfo({
                  ...agentInfo,
                  business_description: event.target.value,
                })
              }
            />
          </Box>
        }
        <Box className={classes.infoLine}>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Site"
            fullWidth
            error={errors.site ? true : false}
            helperText={errors.site ? errors.site.join(" ") : null}
            value={agentInfo.site}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                site: event.target.value,
              })
            }
          />
        </Box>

        <Box className={classes.infoLine}>
          <InputMask
            mask={"99999-999"}
            value={agentInfo.endereco?.cep}
            onBlur={handlerCep}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                endereco: {
                  ...agentInfo.endereco,
                  cep: event.target.value,
                }
              })
            }
          >
            {() => (
              <TextField
                InputLabelProps={{ shrink: true }}
                className={classes.textField}
                label="Cep"
                fullWidth
                error={errors.address_cep ? true : false}
                helperText={errors.address_cep ? errors.address_cep.join(" ") : null}
              />
            )}
          </InputMask>
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            label="Rua"
            fullWidth
            error={errors.address_rua ? true : false}
            helperText={errors.address_rua ? errors.address_rua.join(" ") : null}
            value={agentInfo.endereco?.rua}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                endereco:{
                  ...agentInfo.endereco,
                  rua: event.target.value,
                }
              })
            }
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            label="Bairro"
            fullWidth
            error={errors.address_bairro ? true : false}
            helperText={errors.address_bairro ? errors.address_bairro.join(" ") : null}
            value={agentInfo.endereco?.bairro}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                endereco:{
                  ...agentInfo.endereco,
                  bairro: event.target.value,
                }
              })
            }
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Número"
            fullWidth
            error={errors.address_numero ? true : false}
            helperText={errors.address_numero ? errors.address_numero.join(" ") : null}
            value={agentInfo.endereco?.numero}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                endereco:{
                  ...agentInfo.endereco,
                  numero: event.target.value,
                }
              })
            }
          />
        </Box>
        <Box className={classes.infoLine}>
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            label="Complemento"
            fullWidth
            error={errors.address_complemento ? true : false}
            helperText={errors.address_complemento ? errors.address_complemento.join(" ") : null}
            value={agentInfo.endereco?.complemento}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                endereco:{
                  ...agentInfo.endereco,
                  complemento: event.target.value,
                }
              })
            }
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            label="Cidade"
            fullWidth
            error={errors.address_cidade ? true : false}
            helperText={errors.address_cidade ? errors.address_cidade.join(" ") : null}
            value={agentInfo.endereco?.cidade}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                endereco:{
                  ...agentInfo.endereco,
                  cidade: event.target.value,
                }
              })
            }
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Estado"
            fullWidth
            error={errors.address_estado ? true : false}
            helperText={errors.address_estado ? errors.address_estado.join(" ") : null}
            value={agentInfo.endereco?.estado}
            onChange={(event) =>
              setAgentInfo({
                ...agentInfo,
                endereco:{
                  ...agentInfo.endereco,
                  estado: event.target.value,
                }
              })
            }
          />
        </Box>
      </FormControl>
		</Box>
	);
};

export default CreateEditAgentForm;
