export const indexSalesPlanSubscriptionQuery = ({ page, plano_venda_id, filters}) => ({
  url: `/assinatura-plano-vendas`,
  method: "get",
  params: {
    page,
    plano_venda_id,
    like: filters.like
  },
});

export const showSalesPlanSubscriptionQuery = ({ subscription_id }) => ({
  url: `/assinatura-plano-vendas/${subscription_id}`,
  method: "get",
});

export const showMySalesPlanSubscriptionQuery = ({ conta_id }) => ({
  url: `/minhas-assinaturas-plano-vendas`,
  method: "get",
  params: {conta_id}
});

export const showMySalesPlanSubscriptionFeesQuery = ({ conta_id }) => ({
  url: `/minhas-taxas-plano-vendas`,
  method: "get",
  params: {conta_id}
});

export const storeSalesPlanSubscriptionMutation = ({ conta_id, plano_venda_id }) => ({
  url: `/assinatura-plano-vendas`,
  method: "post",
  data: {
    conta_id,
    plano_venda_id,
  },
});

export const deleteSalesPlanSubscriptionMutation = ({ subscription_id }) => ({
  url: `/assinatura-plano-vendas/${subscription_id}`,
  method: "delete",
});
