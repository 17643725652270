import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import Loading from "components/reusable/Loading";
import { useState } from "react";
import { toast } from "react-toastify";
import { useCancelMultipleBillsMutation } from "services/api";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    background: theme.gradient.main,
    color: "white",
  },
  dialogTitle: {
    background: theme.gradient.main,
    color: "white",
    textAlign: "center",
    marginBottom: 8,
  },
  dialogSelectFile: {
    margin: "10px auto",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogMessage: {
    color: "red",
    marginBottom: "10px",
  },
}));
const DialogCancelMultipleBills = ({ open, handleClose }) => {
  const classes = useStyles();

  const [selectedFile, setSelectedFile] = useState(null);

  const [cancelMultipleBills] = useCancelMultipleBillsMutation();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    try {
      await cancelMultipleBills({
        file: selectedFile,
      }).unwrap();
      toast.success("Arquivo enviado com sucesso!");
      handleClose();
    } catch (e) {
      if (e.status === 422) toast.error(e.data.message);
      else
        toast.error(
          "Erro ao carregar o arquivo de cancelamento de múltiplos boletos"
        );
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle className={classes.dialogTitle}>
        Carregar arquivo para cancelar múltiplos boletos
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.dialogMessage}>
          *Por favor insira um arquivo excel com somente os ids de conciliação
          na primeira coluna.
        </Box>
        <input
          type="file"
          onChange={handleFileChange}
          className={classes.dialogSelectFile}
        />
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            minWidth: 120,
            height: 40,
          }}
          onClick={handleClose}
          variant="outlined"
          color="secondary"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleUpload}
          color="default"
          variant="outlined"
          autoFocus
          style={{
            minWidth: 120,
            height: 40,
          }}
        >
          <Loading>Enviar</Loading>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCancelMultipleBills;
