import { Box, Divider, IconButton, Typography } from "@material-ui/core";
import React from "react";
import CustomCopyToClipboard from "../Text/CustomCopyToClipboard";
import { formatMoney } from "utils/money";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

const BookletDetails = ({ booklet, slip }) => {
  const history = useHistory();
  return (
    <Box display="flex" flexDirection="column">
      <Divider style={{ marginTop: 16, marginBottom: 8 }} />
      <Box>
        <Typography variant="h6">Informações do Carne</Typography>
      </Box>
      <Box>
        <Typography>Parcela:</Typography>
        <Typography variant="h6">{`${slip.parcela} / ${booklet.parcelas}`}</Typography>
      </Box>
      <Box>
        <Typography>Descrição:</Typography>
        <Typography variant="h6">{booklet.descricao}</Typography>
      </Box>
      <Box>
        <Typography>Valor total:</Typography>
        <Typography variant="h6">{formatMoney(booklet.valor)}</Typography>
      </Box>
      <Box>
        <Typography>Id do carne:</Typography>
        <Box display="flex" alignItems="center">
          <CustomCopyToClipboard
            title={"Copiar número do carne"}
            value={booklet.id}
          />
          <IconButton
            onClick={() =>
              history.push(`/dashboard/detalhes-carne/${booklet.id}/ver`)
            }
          >
            <FontAwesomeIcon icon={faArrowCircleRight} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BookletDetails;
