import { agentThemeCache } from "constants/config";

export const setAgentTheme = ({ agent }) => {
  if(agent.public_logo_url) localStorage.setItem(agentThemeCache.agentLogo, agent.public_logo_url);
  if(agent.public_favicon_url) localStorage.setItem(agentThemeCache.agentFavicon, agent.public_favicon_url);
  if(agent.business_description) localStorage.setItem(agentThemeCache.description, agent.business_description);
  if(agent.agent_name) localStorage.setItem(agentThemeCache.name, agent.agent_name);
  localStorage.setItem(agentThemeCache.mailSupport, agent.contact_email);

  const colors = agent.colors;

  if(colors?.backgroundDefault) localStorage.setItem(agentThemeCache.theme.palette.background.default, colors.backgroundDefault);
  if(colors?.backgroundPaper) localStorage.setItem(agentThemeCache.theme.palette.background.paper, colors.backgroundPaper);

  if(colors?.primaryMain) localStorage.setItem(agentThemeCache.theme.palette.primary.main, colors.primaryMain);
  if(colors?.primaryLight) localStorage.setItem(agentThemeCache.theme.palette.primary.light, colors.primaryLight);

  if(colors?.secondaryMain) localStorage.setItem(agentThemeCache.theme.palette.secondary.main, colors.secondaryMain);
  if(colors?.secondaryLight) localStorage.setItem(agentThemeCache.theme.palette.secondary.light, colors.secondaryLight);
  
  if(colors?.menuBackgroundItem) localStorage.setItem(agentThemeCache.theme.palette.menu.backgroundItem, colors.menuBackgroundItem);
  if(colors?.menuBackgroundItemSelected) localStorage.setItem(agentThemeCache.theme.palette.menu.backgroundItemSelected, colors.menuBackgroundItemSelected);
  if(colors?.menuIcon) localStorage.setItem(agentThemeCache.theme.palette.menu.icon, colors.menuIcon);
  if(colors?.menuIconSelected) localStorage.setItem(agentThemeCache.theme.palette.menu.iconSelected, colors.menuIconSelected);
  if(colors?.menuText) localStorage.setItem(agentThemeCache.theme.palette.menu.text, colors.menuText);
  
  if(colors?.typographyColor) localStorage.setItem(agentThemeCache.theme.typography.color, colors.typographyColor);
  
  if(colors?.collectionItemBackground) localStorage.setItem(agentThemeCache.theme.collectionItem.background, colors.collectionItemBackground);
  if(colors?.collectionItemIcon) localStorage.setItem(agentThemeCache.theme.collectionItem.icon, colors.collectionItemIcon);

  if(colors?.gradientMainPrimary) localStorage.setItem(agentThemeCache.theme.gradient.main.primary, colors.gradientMainPrimary);
  if(colors?.gradientMainSecondary) localStorage.setItem(agentThemeCache.theme.gradient.main.secondary, colors.gradientMainSecondary);
  if(colors?.gradientLightPrimary) localStorage.setItem(agentThemeCache.theme.gradient.light.primary, colors.gradientLightPrimary);
  if(colors?.gradientLightSecondary) localStorage.setItem(agentThemeCache.theme.gradient.light.secondary, colors.gradientLightSecondary);
  
  window.dispatchEvent(new Event("themeChanges"));
}
