import React from "react";
import { Box, Typography } from "@material-ui/core";

const columns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (data) => {
      const date = new Date(data);
      const option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const [dia] = date.toLocaleDateString("pt-br", option).split(" ");
      return <Typography align="center">{dia}</Typography>;
    },
  },
  {
    headerText: "ID da transação",
    key: "transaction",
    CustomValue: (id) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          <Typography>{id}</Typography>
        </Box>
      );
    },
  },
  {
    headerText: "Valor Líquido",
    key: "amount",
    CustomValue: (valor) => <Typography>R${valor}</Typography>,
  },
  {
    headerText: "Parcela",
    key: "",
    FullCustom: (item) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          <Typography>{item.installment ?? "Única"}</Typography>
        </Box>
      );
    },
  },
];

export default columns;
