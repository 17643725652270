import { toast } from "react-toastify";
import { api } from "./api";

export const solicitedExportationsQuery = ({ page, account, type }) => ({
  url: `/exports-by-account`,
  params: {
    page,
    conta_id: account,
    type,
  },
});

export const downloadExportUrl = async ({ id, accountId }) => {
  try {
    const { data } = await api.get("/exports-download", {
      params: {
        conta_id: accountId,
        export_id: id,
      },
    });
    window.open(data, "_blank");
  } catch (error) {
    toast.error("Erro ao baixar arquivo!");
  }
};
