import { ADM_PERMISSIONS } from "constants/permissions";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const useUserPermissions = () => {
  const authMe = useSelector((state) => state.authMe);
  const userData = useSelector((state) => state.userData);
  const { permissao: userPermissions } = useSelector((state) => state.userPermissao);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    setPermissions(userPermissions.map((item) => item.tipo));
  }, [userPermissions]);

  const userIdByPermission = useMemo(() => userPermissions[0].user_id, [userPermissions]);
  const isLoadingUserData = useMemo(() => {
    if(authMe.id === userIdByPermission) {
      if(authMe.conta_id && (userData.id === authMe.conta_id)) return false;
      if(!authMe.conta_id) return false;
    }
    return true;
  }, [authMe, userData, userIdByPermission]);

  const isInttegrar = useMemo(
    () =>
      permissions.includes(ADM_PERMISSIONS.INTTEGRAR),
    [permissions]
  );

  const isAdmin = useMemo(
    () =>
      permissions.includes(ADM_PERMISSIONS.ADMIN) ||
      permissions.includes(ADM_PERMISSIONS.INTTEGRAR),
    [permissions]
  );

  const isManagementAdmin = useMemo(
    () =>
      permissions.includes(ADM_PERMISSIONS.MANAGEMENT) ||
      permissions.includes(ADM_PERMISSIONS.ADMIN) ||
      permissions.includes(ADM_PERMISSIONS.INTTEGRAR),
    [permissions]
  );

  const isAgent = useMemo(
    () => permissions.includes(ADM_PERMISSIONS.AGENT),
    [permissions]
  );

  const canViewAudit = useMemo(
    () =>
    isAdmin || permissions.includes(ADM_PERMISSIONS.TRANSACTIONS),
    [isAdmin, permissions]
  );

  return {
    isLoadingUserData,
    userData,
    userPermissions,
    permissions,
    isInttegrar,
    isAdmin,
    isAgent,
    isManagementAdmin,
    canViewAudit,
  }
};

export default useUserPermissions;
