import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { loadAuth, authMeAction } from '../../../actions/actions';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Paper, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { APP_CONFIG } from 'constants/config';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		backgroundColor: 'white',
		margin: '0px',
		padding: '0px',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},

	leftSide: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '55%',
		height: '100vh',
		backgroundColor: theme.palette.background.default,
		color: 'white',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
			height: '100vh',
		},
	},
	leftSideText: {},
	rightSide: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '45%',
		background: theme.gradient.light,
		padding: '80px',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
			height: '100vh',
			padding: '0px',
		},
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '40px',
		width: '60%',
		borderRadius: '27px',
		animation: `$myEffect 1000ms ${theme.transitions.easing.easeInOut}`,
		[theme.breakpoints.down('sm')]: {
			width: '90%',
      padding: '20px',
		},
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	'@keyframes myEffect': {
		'0%': {
			opacity: 0,
			transform: 'translateX(10%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateX(0)',
		},
	},
}));

export default function SignIn() {
	const classes = useStyles();
	const [email, setEmail] = useState('');
	const theme = useTheme();
	const [password, setPassword] = useState('');
	const [err, setErr] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	const handleLogin = async (e) => {
		e.preventDefault();
		const auth = await dispatch(
			loadAuth({
				email,
				password,
			})
		);

		if (auth) {
			await localStorage.setItem(
				'@auth',
				JSON.stringify({ ...auth.data, login_time: new Date().getTime() })
			);
			const authMe = await dispatch(authMeAction());

      if (authMe.needs_totp_verification === true) {
				history.push('/login/auth-totp');
			} else {
        if(authMe.conta_id === null) {
          history.push('/dashboard/adm');
        } else {
          history.push('/dashboard/home');
        }
      }
      
		} else {
			toast.error('Usuário ou senha inválidos');
			setErr(true);
		}
	};

	return (
		<>
			<Box className={classes.root}>
				<Box className={classes.leftSide}>
					<Box margin="20px">
						<img
							style={{ width: '250px', justifySelf: 'flex-start' }}
							src={APP_CONFIG().assets.primaryLogo}
							alt={`${APP_CONFIG().name} logo`}
						/>
					</Box>

					<Box display="flex" flexDirection="column" alignItems="center" marginTop="150px">
						<Typography variant="h3">Bem vindo(a)!</Typography>
						<Typography variant="h6" align="center" style={{ fontWeight: '100', fontSize: '1.5rem' }}>
							Digite seu e-mail e senha para ter acesso à plataforma
						</Typography>
					</Box>
				</Box>
				<Box className={classes.rightSide}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar} />
            <Typography component="h1" variant="h5">
              Logar
            </Typography>
            <form onSubmit={(e) => handleLogin(e)} className={classes.form}>
              <TextField
                type="email"
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Digite seu email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                error={err}
              />
              <TextField
                type="password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Digite sua senha"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={err}
              />
              <Button
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                type="submit"
                style={{
                  borderRadius: '27px',
                  background: theme.gradient.main,
                }}
              >
                Logar
              </Button>
            </form>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="body2">É seu primeiro acesso?</Typography>
              <Button
                size="small"
                component={Link}
                to="/login/cadastro"
                style={{ height: '28px', borderRadius: '27px' }}
              >
                Cadastrar senha
              </Button>
              <Typography variant="body2">Esqueceu a senha?</Typography>
              <Button
                size="small"
                component={Link}
                to="/login/recuperar"
                style={{ height: '28px', borderRadius: '27px' }}
              >
                Recuperar senha
              </Button>
            </Box>
          </Paper>
				</Box>
			</Box>
		</>
	);
}
