import { faTable, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { TRANSACTION_STATUS } from "constants/status";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, useHistory } from "react-router";
import { toast } from "react-toastify";
import { loadExportTransacaoAuditoria } from "../../../actions/actions";
import useDebounce from "../../../hooks/useDebounce";
import CustomBreadcrumbs from "../../reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomTable from "../../reusable/CustomTable/CustomTable";
import LoadingScreen from "../../reusable/LoadingScreen/LoadingScreen";
import columns from "./TransactionAuditColumns";
import { useIndexTransactionsForAuditQuery } from "services/api";
import useQuery from "hooks/useQuery";
import useQueryParams from "hooks/useQueryParams";
import { clearSearchParams } from "services/functions/clearSearchParams";
import useDynamicPagination from "hooks/useDynamicPagination";

const TransactionAuditIndex = () => {
  const query = useQuery();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingExport, setLoadingExport] = useState(false);
  const [page, setPage] = useState(Number(query.get("page")) || 1);

  const initialFilters = {
    day: query.get("day") ?? "",
    order: query.get("order") ?? "desc",
    mostrar: query.get("mostrar") ?? "",
    status: query.get("status") ?? "",
    like: query.get("like"),
    payment_type: query.get("payment_type") ?? "",
    data_inicial: query.get("data_inicial"),
    data_final: query.get("data_final"),
    id: query.get("id"),
    terminal_id: query.get("terminal_id") ?? "",
    documento: query.get("documento"),
    vencimento_inicial: query.get("vencimento_inicial"),
    vencimento_final: query.get("vencimento_final"),
    pagamento_inicial: query.get("pagamento_inicial"),
    pagamento_final: query.get("pagamento_final"),
    conta_id: query.get("conta_id"),
    seller_like: query.get("seller_like"),
    holder_name: query.get("holder_name"),
    is_physical_sale: query.get("is_physical_sale") ?? "",
    where_mdr: query.get("where_mdr") ?? "",
    origin: query.get("origin") ?? "",
    remessa_data_ocorrencia: query.get("remessa_data_ocorrencia"),
  };

  const [filters, setFilters] = useState(initialFilters);

  const debouncedFilters = useDebounce(filters, 800);

  const { data, isLoading, isError, isUninitialized } =
    useIndexTransactionsForAuditQuery(
      {
        page,
        filters: {
          ...debouncedFilters,
        },
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const handleClickRow = (row) => {
    const path = generatePath("/dashboard/transacoes-auditoria/:id/detalhes", {
      id: row.transaction.id,
    });
    history.push(path);
  };

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const handleExportarTransacao = async () => {
    setLoadingExport(true);
    toast.warning("A exportação será enviada por e-mail assim que finalizada");
    const res = await dispatch(
      loadExportTransacaoAuditoria({ page, ...filters })
    );
    if (res && res.url !== undefined) {
      window.open(`${res.url}`, "", "");
    }
    setLoadingExport(false);
  };

  useEffect(() => {
    if (isError) {
      toast.error("Aconteceu um erro tente novamente!");
      history.goBack();
    }
  }, [isError, history]);

  useQueryParams(query, filters, page, history);
  useDynamicPagination(page, data?.last_page, setPage);

  return (
    <Box display="flex" flexDirection="column">
      <LoadingScreen isLoading={isLoading || loadingExport} />
      <CustomBreadcrumbs
        path1="Histórico de transações"
        to1="/dashboard/transacoes"
        path2="Auditoria de transações"
        to2="#"
      />
      <Box marginBottom={2}>
        <Typography
          variant="h4"
          style={{ color: theme.palette.background.default }}
        >
          AUDITORIA DE TRANSAÇÕES
        </Typography>
      </Box>
      <Box style={{ marginTop: "8px" }} display="flex">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar por EC (nome, documento, cnpj, e-mail...)"
              fullWidth
              label="EC"
              value={filters.seller_like}
              onChange={(e) =>
                setFilters({ ...filters, seller_like: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar por pagador (nome, documento, e-mail...)"
              fullWidth
              label="Pagador"
              value={filters.like}
              onChange={(e) => setFilters({ ...filters, like: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Filtrar por ID da transação ou conciliação"
              fullWidth
              label="ID da transação ou conciliação"
              value={filters.id}
              onChange={(e) => setFilters({ ...filters, id: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel>Período</InputLabel>
            <Select
              fullWidth
              value={filters.day}
              onChange={(e) => setFilters({ ...filters, day: e.target.value })}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value={1}>Hoje</MenuItem>
              <MenuItem value={7}>Últimos 7 dias</MenuItem>
              <MenuItem value={15}>Últimos 15 dias</MenuItem>
              <MenuItem value={30}>Últimos 30 dias</MenuItem>
              <MenuItem value={60}>Últimos 60 dias</MenuItem>
              <MenuItem value={90}>Últimos 90 dias</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel>Situação</InputLabel>
            <Select
              fullWidth
              value={filters.status}
              onChange={(e) =>
                setFilters({ ...filters, status: e.target.value })
              }
            >
              <MenuItem value="">TODOS</MenuItem>
              {Object.values(TRANSACTION_STATUS).map(({ status, text }) => (
                <MenuItem value={status}>{text}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel>Método</InputLabel>
            <Select
              fullWidth
              value={filters.payment_type}
              onChange={(e) =>
                setFilters({ ...filters, payment_type: e.target.value })
              }
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="debit">Débito</MenuItem>
              <MenuItem value="credit">Crédito</MenuItem>
              <MenuItem value="boleto">Boleto</MenuItem>
              <MenuItem value="commission">Comissão</MenuItem>
              <MenuItem value="pix">Pix</MenuItem>
            </Select>
          </Grid>
          {filters.payment_type === "boleto" ? (
            <Grid item xs={12} sm={2}>
              <InputLabel>Origem</InputLabel>
              <Select
                fullWidth
                value={filters.origin}
                onChange={(e) =>
                  setFilters({ ...filters, origin: e.target.value })
                }
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value="shipment_file">Arquivo Remessa</MenuItem>
              </Select>
            </Grid>
          ) : null}
          {filters.origin === "shipment_file" ? (
            <>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  label="Remessa data ocorrencia "
                  value={filters.remessa_data_ocorrencia}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      remessa_data_ocorrencia: e.target.value,
                    })
                  }
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={12} sm={2}>
            <InputLabel>Tipo de Venda</InputLabel>
            <Select
              fullWidth
              value={filters.is_physical_sale}
              onChange={(e) =>
                setFilters({ ...filters, is_physical_sale: e.target.value })
              }
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="1">CP - Captura Presencial</MenuItem>
              <MenuItem value="0">CNP - Captura Não Presencial</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Inicial"
              value={filters.data_inicial}
              onChange={(e) =>
                setFilters({ ...filters, data_inicial: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
                pattern: "d {4}- d {2}- d {2} ",
              }}
              type="date"
              label="Data Final"
              value={filters.data_final}
              onChange={(e) =>
                setFilters({ ...filters, data_final: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel>Mdr</InputLabel>
            <Select
              fullWidth
              value={filters.where_mdr}
              onChange={(e) =>
                setFilters({ ...filters, where_mdr: e.target.value })
              }
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="mdr_error">Erro</MenuItem>
              <MenuItem value="system_fee_error">Erro system fee</MenuItem>
              <MenuItem value="app_fee_error">Erro app fee</MenuItem>
              <MenuItem value="agent_fee_error">Erro agent fee</MenuItem>
            </Select>
          </Grid>
          {filters.payment_type === "credit" ||
          filters.payment_type === "debit" ? (
            <>
              <Grid item xs={12} sm={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Filtrar por ID do POS"
                  fullWidth
                  label="ID do POS"
                  value={filters.terminal_id}
                  onChange={(e) =>
                    setFilters({ ...filters, terminal_id: e.target.value })
                  }
                />
              </Grid>
            </>
          ) : null}
          {filters.payment_type === "credit" ||
          filters.payment_type === "debit" ? (
            <>
              <Grid item xs={12} sm={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Nome do portador do Cartão"
                  fullWidth
                  label="Portador do Cartão"
                  value={filters.holder_name}
                  onChange={(e) =>
                    setFilters({ ...filters, holder_name: e.target.value })
                  }
                />
              </Grid>
            </>
          ) : null}

          {filters.payment_type === "boleto" ? (
            <>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  label="Vencimento Data Inicial"
                  value={filters.vencimento_inicial}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      vencimento_inicial: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  label="Vencimento Data Final"
                  value={filters.vencimento_final}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      vencimento_final: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  label="Pagamento Data Inicial"
                  value={filters.pagamento_inicial}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      pagamento_inicial: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  label="Pagamento Data Final"
                  value={filters.pagamento_final}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      pagamento_final: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Filtrar por N° Documento"
                  fullWidth
                  label="N° Documento"
                  value={filters.documento}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      documento: e.target.value,
                    })
                  }
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={12} sm={2}>
            <Box display="flex">
              <Tooltip title="Limpar Filtros">
                <IconButton
                  onClick={() => clearSearchParams(filters, setFilters)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Exportar Excel">
                <IconButton
                  variant="outlined"
                  style={{ marginLeft: "6px" }}
                  onClick={handleExportarTransacao}
                >
                  <FontAwesomeIcon icon={faTable} color="green" />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop="16px" marginBottom="16px"></Box>
      {!isLoading && !isError && !isUninitialized ? (
        <>
          <CustomTable
            columns={columns}
            data={data.data}
            handleClickRow={handleClickRow}
          />
          <Box alignSelf="flex-end" marginTop="8px">
            <Pagination
              variant="outlined"
              color="secondary"
              size="large"
              count={data?.last_page}
              onChange={handleChangePage}
              page={page}
            />
          </Box>
        </>
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
};

export default TransactionAuditIndex;
