import React, { useMemo } from "react";
import {
  Box,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { ADM_PERMISSIONS } from "constants/permissions";
import CustomCopyToClipboard from "components/reusable/Text/CustomCopyToClipboard";

const AplicationSalesPlanDetails = ({ salesPlan }) => {
  const userPermissions = useSelector((state) => state.userPermissao.permissao);
  const permissionsName = userPermissions.map((permission) => permission.tipo);
  const zoopSalesPlan = useMemo(() => salesPlan.sales_plan, [salesPlan]);
  const isManager = useMemo(
    () =>
      permissionsName.includes(ADM_PERMISSIONS.MANAGEMENT),
    [permissionsName]
  );
  const isAgent = useMemo(
    () =>
      permissionsName.includes(ADM_PERMISSIONS.AGENT),
    [permissionsName]
  );

  return (
    <Box width="100%">
      <Box width="100%" display="flex" flexWrap="wrap">
        <Box marginTop="12px" marginRight="12px" flexGrow={1} maxWidth={500}>
          <Typography>ID do Plano de Vendas:</Typography>
          <CustomCopyToClipboard title={"Copiar ID do Plano de Vendas"} value={salesPlan.id ? salesPlan.id : "-"} />
        </Box>
        <Box marginTop="12px" flexGrow={1}>
          <Typography>ID de Conciliação:</Typography>
          <CustomCopyToClipboard title={"Copiar ID Conciliação"} value={zoopSalesPlan ? zoopSalesPlan.id : "-"} />
        </Box>
      </Box>
      <Box width="100%" display="flex" flexWrap="wrap">
        <Box flexGrow={1} marginRight="12px" maxWidth={500}>
          <Box marginTop="12px">
            <Typography>Nome:</Typography>
            <Typography variant="h6">
              {salesPlan.name ?? "-"}
            </Typography>
          </Box>
          <Box marginTop="12px">
            <Typography>Situação do Plano de Vendas:</Typography>
            <Typography variant="h6">
              {salesPlan.deleted_at ? "Inativo" : "Ativo"}
            </Typography>
          </Box>
          {isManager && !isAgent &&
            <Box marginTop="12px">
              <Typography>Total de ECs neste Plano de Vendas:</Typography>
              <Typography variant="h6">
                {salesPlan.ec_count ?? "0"}
              </Typography>
            </Box>
          }
          <Box marginTop="12px">
            <Typography>Valor máximo:</Typography>
            <Typography variant="h6">
              R${" "}{(zoopSalesPlan?.maximum_amount / 100).toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Box flexGrow={1}>
          <Box marginTop="12px">
            <Typography>Descrição:</Typography>
            <Typography variant="h6">
              {zoopSalesPlan?.description ?? "-"}
            </Typography>
          </Box>
          <Box marginTop="12px">
            <Typography>Criado em:</Typography>
            <Typography variant="h6">
              {salesPlan.created_at
                ? format(
                    new Date(salesPlan.created_at),
                    "dd/MM/yyyy HH:mm:ss"
                  )
                : "-"}
            </Typography>
          </Box>
          <Box marginTop="12px">
            <Typography>Ultima atualização:</Typography>
            <Typography variant="h6">
              {salesPlan.updated_at
                ? format(
                    new Date(salesPlan.updated_at),
                    "dd/MM/yyyy HH:mm:ss"
                  )
                : "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AplicationSalesPlanDetails;
