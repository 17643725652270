import "react-credit-cards/es/styles-compiled.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Divider,
  LinearProgress,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  useCreateMdrMutation,
  useIndexAllTransactionSplitsQuery,
  useIndexTransactionAuditsQuery,
  useRetryMdrMutation,
  useShowCompleteTransactionQuery,
} from "services/api";
import { useParams } from "react-router";
import { TRANSACTION_STATUS } from "constants/status";
import { Pagination } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { loadRecebiveisId } from "actions/actions";
import useUserPermissions from "hooks/useUserPermissions";
import LoadingScreen from "components/reusable/LoadingScreen/LoadingScreen";
import CustomTable from "components/reusable/CustomTable/CustomTable";
import CustomBreadcrumbs from "components/reusable/CustomBreadcrumbs/CustomBreadcrumbs";
import CollapsibleTable from "components/reusable/CollapsibleTable/CollapsibleTable";
import CardDetails from "components/reusable/TransactionDetails/CardDetails";
import SlipDetails from "components/reusable/TransactionDetails/SlipDetails";
import ErrorDetails from "components/reusable/TransactionDetails/ErrorDetails";
import PayerDetails from "components/reusable/TransactionDetails/PayerDetails";
import AccountDetails from "components/reusable/TransactionDetails/AccountDetails";
import IdsDetails from "components/reusable/TransactionDetails/IdsDetails";
import columns from "./TransactionAuditColumns";
import columnsRecebiveis from "../TransactionDetails/TransactionReceivableColumns";
import columnsSplit from "../TransactionDetails/TransactionSplitColumns";
import columnsCancelationLetters from "../TransactionDetails/TransactionCancelationLetterColumns";
import auditColumns from "./AuditColumns";
import receivablesPaidColumns from "./ReceivablesPaidColumns";
import ReceivablesPreviewColumns from "./ReceivablesPreviewColumns";
import PixDetails from "components/reusable/TransactionDetails/PixDetails";
import { formatMoney } from "utils/money";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import getAuditDetails from "./getAuditDetails";
import splitsZoopColumns from "./SplitsZoopColumns";
import historyTransactionColumns from "../TransactionDetails/HistoryTransactionColumns";
import GradientButton from "components/reusable/Buttons/GradientButton";
import ConfirmDialog from "components/reusable/ConfirmDialog";

const TransactionAuditDetails = () => {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [background, setBackground] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [openCreateMdrDialog, setOpenCreateMdrDialog] = useState(false);
  const [openRetryMdrDialog, setOpenRetryMdrDialog] = useState(false);
  const recebiveis = useSelector((state) => state.recebiveis);
  const { canViewAudit, isAdmin } = useUserPermissions();

  const [createMdr] = useCreateMdrMutation();
  const [retryMdr] = useRetryMdrMutation();

  const {
    data: details,
    isLoading: isLoadingTransaction,
    isError,
    isUninitialized,
  } = useShowCompleteTransactionQuery(
    {
      transaction_id: id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    }
  );

  const { data: allTransactionSplits } = useIndexAllTransactionSplitsQuery(
    {
      transaction_id: id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    }
  );

  const { data: audits } = useIndexTransactionAuditsQuery(
    {
      transaction_id: id,
      filters: {
        page,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    }
  );

  const {
    conta,
    transaction,
    pagador,
    split,
    boleto,
    mdr,
    data_pagamento,
    receivables_paid: receivablesPaid,
    receivables_preview: receivablesPreview,
    cancellation_receipts: cancellationReceipts = [],
  } = useMemo(() => details ?? {}, [details]);

  const {
    paymentPreviewItem,
    totalPaymentPreview,
    totalMdrAppFeeApplied,
    totalMdrAgentFeeApplied,
    totalFeeApplied,
    totalPaid,
    conciliationStatus,
  } = getAuditDetails({
    transaction,
    receivablesPaid,
    receivablesPreview,
  });

  const typeOfSale = useMemo(
    function () {
      if (transaction?.point_of_sale?.identification_number === null) {
        return "Online";
      }
      return "Presencial";
    },
    [transaction]
  );

  const disableCreateMdrOption = useMemo(() => {
    if (
      transaction?.status !== "pending" &&
      transaction?.status !== "succeeded" &&
      transaction?.status !== "pre_authorized"
    )
      return true;
    if (recebiveis.find((item) => item?.status === "paid")) return true;
    if (!mdr) return false;

    return true;
  }, [mdr, transaction, recebiveis]);

  const disableRetryMdrOption = useMemo(() => {
    if (
      transaction?.status !== "pending" &&
      transaction?.status !== "succeeded" &&
      transaction?.status !== "pre_authorized"
    )
      return true;
    if (recebiveis.find((item) => item?.status === "paid")) return true;
    if (!mdr) return true;

    const systemFeeError = !mdr.applied_system_fee;
    const appFeeError = mdr.app_receiver_id && !mdr.applied_app_fee;
    const agentFeeError = mdr.agent_receiver_id && !mdr?.applied_agent_fee;
    if (systemFeeError || appFeeError || agentFeeError) return false;

    return true;
  }, [mdr, transaction, recebiveis]);

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const loadTransaction = useCallback(() => {
    dispatch(loadRecebiveisId(id));
  }, [id, dispatch]);

  useEffect(() => {
    loadTransaction();
  }, [loadTransaction]);

  useEffect(() => {
    const color = TRANSACTION_STATUS[transaction?.status]?.color;
    if (color) setBackground(color);
  }, [transaction?.status]);

  // useEffect(() => {
  //   if (canViewAudit === false) {
  //     toast.error("Sem permissão!");
  //     history.goBack();
  //   }
  // }, [canViewAudit, history]);

  useEffect(() => {
    if (isError === true) {
      toast.error("Aconteceu um erro tente novamente!");
      history.goBack();
    }
  }, [isError, history]);

  const handleCreateMdr = async () => {
    setIsLoading(true);
    try {
      await createMdr({ transaction_id: transaction.id }).unwrap();
      toast.success("Criação do MDR solicitada!");
    } catch (e) {
      toast.error("Erro ao solicitar criação do MDR!");
      if (e.status === 403 && e.data?.message) toast.error(e.data.message);
    } finally {
      setOpenCreateMdrDialog(false);
      setIsLoading(false);
    }
  };

  const handleRetryMdr = async () => {
    setIsLoading(true);
    try {
      await retryMdr({ transaction_id: transaction.id }).unwrap();
      toast.success("O MDR será executado novamente em instantes!");
    } catch (e) {
      toast.error("Erro ao solicitar retentativa de execução do MDR!");
      if (e.status === 403 && e.data?.message) toast.error(e.data.message);
    } finally {
      setOpenRetryMdrDialog(false);
      setIsLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <LoadingScreen isLoading={isLoading || isLoadingTransaction} />

      <ConfirmDialog
        open={openCreateMdrDialog}
        message="Essa ação irá executar a criação de MDR para essa transação. Deseja continuar?"
        title="Criar MDR"
        onConfirm={handleCreateMdr}
        onClose={() => setOpenCreateMdrDialog(false)}
      />

      <ConfirmDialog
        open={openRetryMdrDialog}
        message="Essa ação irá executar novamente o MDR já criado para essa transação. Pode ser necessário caso tenha ocorrido algum erro durante a primeira execução. Deseja continuar?"
        title="Executar MDR Novamente"
        onConfirm={handleRetryMdr}
        onClose={() => setOpenRetryMdrDialog(false)}
      />

      <CustomBreadcrumbs
        path1="..."
        to1="goBack"
        path2="Auditoria de transação"
        to2="#"
      />

      {!isLoadingTransaction && !isError && !isUninitialized && (
        <Paper
          style={{
            padding: "24px",
            margin: "24px",
            borderRadius: "27px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h4" style={{ marginBottom: "16px" }}>
            {" "}
            Auditoria de Transação{" "}
          </Typography>

          <Box
            style={{
              padding: "12px",
              borderRadius: "15px 15px 0 0 ",
              backgroundColor: background,
              color: "white",
            }}
          >
            <Typography variant="h6" align="center">
              Transação
            </Typography>
          </Box>
          <Box display="flex" marginTop="15px" flexDirection="column">
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-around"
              alignItems="start"
            >
              <IdsDetails id={details.id} conciliationId={transaction.id} />

              {transaction?.payment_method?.resource === "card" && (
                <CardDetails transaction={transaction} />
              )}

              {transaction?.payment_type === "boleto" && (
                <SlipDetails
                  transaction={transaction}
                  boleto={boleto}
                  payment_date={data_pagamento}
                />
              )}

              {transaction?.payment_type === "pix" && (
                <PixDetails transaction={transaction} />
              )}

              {isAdmin && (
                <Box display="flex" flexDirection="column" paddingTop="10px">
                  <Box marginTop="8px" display="flex" justifyContent="center">
                    <GradientButton
                      text="MDR - criar"
                      onClick={() => setOpenCreateMdrDialog(true)}
                      disabled={disableCreateMdrOption}
                    />
                  </Box>

                  <Box marginTop="8px" display="flex" justifyContent="center">
                    <GradientButton
                      text="MDR - tentar novamente"
                      onClick={() => setOpenRetryMdrDialog(true)}
                      disabled={disableRetryMdrOption}
                    />
                  </Box>
                </Box>
              )}
            </Box>

            {transaction.error && (
              <>
                <Divider style={{ marginTop: 32, marginBottom: 16 }} />
                <ErrorDetails transaction={transaction} />
              </>
            )}

            <Divider style={{ marginTop: 32, marginBottom: 16 }} />

            <Box display="flex" flexWrap="wrap" justifyContent="space-around">
              <Box style={{ margin: "16px" }}>
                <PayerDetails payer={pagador} />
              </Box>
              <Box style={{ margin: "16px" }}>
                <AccountDetails account={conta} />
              </Box>
            </Box>
          </Box>

          <Divider style={{ marginTop: 32, marginBottom: 16 }} />

          <Box>
            <CustomTable data={[details]} columns={columns} />
          </Box>

          <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
            <Box width="230px" style={{ margin: "16px" }}>
              <Typography variant="h6" align="center">
                Previsto
              </Typography>
              <Typography>Total previsto:</Typography>
              <Typography variant="h6">
                {formatMoney(totalPaymentPreview)}
              </Typography>
              <Typography>Taxa prevista da aplicação:</Typography>
              <Typography variant="h6">
                {formatMoney(paymentPreviewItem.app_fee)}
              </Typography>
              <Typography>Taxa prevista do representante:</Typography>
              <Typography variant="h6">
                {formatMoney(paymentPreviewItem.agent_fee)}
              </Typography>
              <Typography>Taxa prevista do sistema:</Typography>
              <Typography variant="h6">
                {formatMoney(paymentPreviewItem.system_fee)}
              </Typography>
              <Typography>Total de taxas:</Typography>
              <Typography variant="h6">
                {formatMoney(paymentPreviewItem.total_fees)}
              </Typography>
            </Box>

            <Box width="230px" style={{ margin: "16px" }}>
              <Typography variant="h6" align="center">
                Aplicado
              </Typography>
              <Typography>Total pago:</Typography>
              <Typography variant="h6">{formatMoney(totalPaid)}</Typography>
              <Typography>Taxa cobrada da aplicação:</Typography>
              <Typography variant="h6">
                {formatMoney(totalMdrAppFeeApplied)}
              </Typography>
              <Typography>Taxa cobrada do representante:</Typography>
              <Typography variant="h6">
                {formatMoney(totalMdrAgentFeeApplied)}
              </Typography>
              <Typography>Taxa cobrada do sistema:</Typography>
              <Typography variant="h6">
                {formatMoney(transaction.fees)}
              </Typography>
              <Typography>Total de taxas:</Typography>
              <Typography variant="h6">
                {formatMoney(totalFeeApplied)}
              </Typography>
            </Box>

            <Box width="230px" style={{ margin: "16px" }}>
              <Typography variant="h6" align="center">
                Detalhes
              </Typography>
              <Typography>Tipo de venda</Typography>
              <Typography variant="h6">{typeOfSale}</Typography>
              <Typography>Status de pagamento</Typography>
              <Typography style={{ color: conciliationStatus.color }}>
                <b>{conciliationStatus.text}</b>
              </Typography>
              <Typography>Total pago:</Typography>
              <Typography variant="h6">{formatMoney(totalPaid)}</Typography>
              <Typography>Taxa prevista do sistema:</Typography>
              <Typography variant="h6">
                {formatMoney(paymentPreviewItem.system_fee)}
              </Typography>
              <Typography>Taxa cobrada do sistema:</Typography>
              <Typography variant="h6">
                {formatMoney(transaction.fees)}
              </Typography>
            </Box>
          </Box>

          <Divider style={{ marginTop: 32, marginBottom: 16 }} />

          <Box
            display="flex"
            marginTop="12px"
            width="100%"
            flexDirection="column"
          >
            <Box marginTop="18px">
              <Box marginBottom={2}>
                <Typography variant="h4">Recebimentos</Typography>
              </Box>
              <CustomTable
                data={recebiveis ?? []}
                columns={columnsRecebiveis}
              />
            </Box>
            <Box marginTop="18px">
              <Box marginBottom={2}>
                <Typography variant="h4">Repartições de valor</Typography>
              </Box>
              <CustomTable data={split ?? null} columns={columnsSplit} />
            </Box>
            <Box marginTop="18px">
              <Box marginBottom={2}>
                <Typography variant="h4">Cartas de Cancelamento</Typography>
              </Box>
              <CustomTable
                data={cancellationReceipts ?? []}
                columns={columnsCancelationLetters}
              />
            </Box>
            <Box marginTop="18px">
              <Box marginBottom={2}>
                <Typography variant="h4">Histórico da transação</Typography>
              </Box>
              <CustomTable
                data={transaction.history ?? []}
                columns={historyTransactionColumns}
              />
            </Box>
          </Box>

          <Divider style={{ marginTop: 32, marginBottom: 16 }} />

          <Paper
            style={{
              padding: "24px",
              margin: "24px 0px",
              borderRadius: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: theme.palette.primary.light,
            }}
          >
            <Typography
              variant="h6"
              style={{
                textAlign: "center",
              }}
            >
              Todos os valores previstos exibidos a seguir, foram calculados a
              partir dos valores de MDR
            </Typography>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-around"
              width="100%"
              borderTop="solid"
              borderBottom="solid"
              margin="10px 0px"
              padding="10px 0px"
            >
              <Box>
                <Typography variant="h6">Taxa sistema</Typography>
                <Typography>
                  Percentual: {mdr?.percent_system_fee / 100}%
                </Typography>
                <Typography>
                  Fixo: {formatMoney(mdr?.fixed_system_fee / 100)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">Taxa parceiro</Typography>
                <Typography>
                  Percentual: {mdr?.percent_app_fee / 100}%
                </Typography>
                <Typography>
                  Fixo: {formatMoney(mdr?.fixed_app_fee / 100)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">Taxa representante</Typography>
                <Typography>
                  Percentual: {mdr?.percent_agent_fee / 100}%
                </Typography>
                <Typography>
                  Fixo: {formatMoney(mdr?.fixed_agent_fee / 100)}
                </Typography>
              </Box>
            </Box>
          </Paper>

          <Box display="flex" alignItems="center">
            <Typography variant="h4">Vendedor - EC</Typography>
          </Box>
          <Box marginTop="10px">
            <Typography variant="h6" style={{ margin: "10px 0px" }}>
              Previsto
            </Typography>
            {receivablesPreview ? (
              <CollapsibleTable
                columns={ReceivablesPreviewColumns}
                data={receivablesPreview.payment}
                itemColumns={columnsRecebiveis}
                collapseKey="items"
              />
            ) : (
              <LinearProgress />
            )}
          </Box>
          <Box marginTop="10px">
            <Typography variant="h6" style={{ margin: "10px 0px" }}>
              Pagamentos
            </Typography>
            {receivablesPaid ? (
              <CollapsibleTable
                columns={receivablesPaidColumns}
                data={receivablesPaid.payment}
                itemColumns={columnsRecebiveis}
                collapseKey="items"
              />
            ) : (
              <LinearProgress />
            )}
          </Box>

          <Divider style={{ marginTop: 32, marginBottom: 16 }} />

          <Typography variant="h4">Splits - Repartições</Typography>

          <Box marginTop="10px">
            <Typography variant="h6" style={{ margin: "10px 0px" }}>
              Previsto
            </Typography>
            {receivablesPreview ? (
              <CollapsibleTable
                columns={ReceivablesPreviewColumns}
                data={receivablesPreview.split}
                itemColumns={columnsRecebiveis}
                collapseKey="items"
              />
            ) : (
              <LinearProgress />
            )}
          </Box>

          <Box marginTop="10px">
            <Typography variant="h6" style={{ margin: "10px 0px" }}>
              Pagamentos
            </Typography>
            {receivablesPaid ? (
              <CollapsibleTable
                columns={receivablesPaidColumns}
                data={receivablesPaid.split}
                itemColumns={columnsRecebiveis}
                collapseKey="items"
              />
            ) : (
              <LinearProgress />
            )}
          </Box>

          <Divider style={{ marginTop: 32, marginBottom: 16 }} />

          <Typography variant="h4">MDR - Taxas</Typography>

          <Box marginTop="10px">
            <Typography variant="h6" style={{ margin: "10px 0px" }}>
              Previsto
            </Typography>
            {receivablesPreview ? (
              <CollapsibleTable
                columns={ReceivablesPreviewColumns}
                data={receivablesPreview.mdr}
                itemColumns={columnsRecebiveis}
                collapseKey="items"
              />
            ) : (
              <LinearProgress />
            )}
          </Box>
          <Box marginTop="10px">
            <Typography variant="h6" style={{ margin: "10px 0px" }}>
              Recebimento
            </Typography>
            {receivablesPaid ? (
              <CollapsibleTable
                columns={receivablesPaidColumns}
                data={receivablesPaid.mdr}
                itemColumns={columnsRecebiveis}
                collapseKey="items"
              />
            ) : (
              <LinearProgress />
            )}
          </Box>

          <Divider style={{ marginTop: 32, marginBottom: 16 }} />

          <Typography variant="h4">Todos os splits relacionados</Typography>
          <Box marginTop="10px">
            {allTransactionSplits ? (
              <CustomTable
                columns={splitsZoopColumns}
                data={allTransactionSplits}
              />
            ) : (
              <LinearProgress />
            )}
          </Box>

          <Divider style={{ marginTop: 32, marginBottom: 16 }} />

          <Typography variant="h4">Histórico de eventos</Typography>

          <Box marginTop="10px">
            {audits?.data ? (
              <CustomTable columns={auditColumns} data={audits?.data} />
            ) : (
              <LinearProgress />
            )}
            <Box alignSelf="flex-end" marginTop="8px">
              <Pagination
                variant="outlined"
                color="secondary"
                size="large"
                count={audits?.last_page}
                onChange={handleChangePage}
                page={page}
              />
            </Box>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default TransactionAuditDetails;
