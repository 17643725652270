import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Typography } from "@material-ui/core";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import CustomCopyToClipboard from "components/reusable/Text/CustomCopyToClipboard";
import React from "react";

const auditColumns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (created_at) => {
      return <DateTimeColumn dateTime={created_at} />
    },
  },
  {
    headerText: "Evento",
    key: "event",
    CustomValue: (event) => {
      return <Typography>{event}</Typography>
    },
  },
  {
    headerText: "Anterior",
    key: "old_values",
    CustomValue: (values) => {
      const { transaction, ...rest } = values;
      const newValues = {
        ...rest,
        transaction: JSON.parse(transaction ?? `{}`),
      };
      return <CustomCopyToClipboard value={JSON.stringify(newValues, null, 2)} />
    },
  },
  {
    headerText: "Novo",
    key: "new_values",
    CustomValue: (values) => {
      const { transaction, ...rest } = values;
      const newValues = {
        ...rest,
        transaction: JSON.parse(transaction ?? `{}`),
      };

      return <CustomCopyToClipboard value={JSON.stringify(newValues, null, 2)} />
      // return <div>
      //   <p
      //     style={{
      //       display: "block",
      //       textAlign:"justify",
      //       fontFamily: "monospace",
      //       whiteSpace: "pre",
      //     }}
      //   >
      //     {JSON.stringify(newValues, null, 2) }
      //   </p>
      // </div>
    },
  },
  {
    headerText: "",
    key: "open_json_editor_online_buttom",
    CustomValue: () => {
      return (
        <IconButton aria-label="expand row" size="small" onClick={() => window.open("https://jsoneditoronline.org/")}>
          <FontAwesomeIcon  icon={faBoxOpen} />
        </IconButton>
      )
    },
  },
];

export default auditColumns;
