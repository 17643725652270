import { Box, Typography } from "@material-ui/core";
import React from "react";

const ErrorDetails = ({
  transaction
}) => {
  return (
    <Box width="100¨%">
      <Typography variant="h6" align="center">
        Aprovação
      </Typography>
      <Box display="flex" flexWrap="wrap">
        <Box marginRight="20px">
          <Typography>Categoria:</Typography>
          <Typography variant="h6">
            {transaction.error.category ?? '-'}
          </Typography>
          <Typography>Mensagem 1:</Typography>
          <Typography variant="h6">
            {transaction.error.message ?? '-'}
          </Typography>
          <Typography>Mensagem 2:</Typography>
          <Typography variant="h6">
            {transaction.error.message_display ?? '-'}
          </Typography>
        </Box>
        <Box>
          <Typography>Código:</Typography>
          <Typography variant="h6">
            {transaction.error.response_code ?? '-'}
          </Typography>
          <Typography>Status:</Typography>
          <Typography variant="h6">
            {transaction.error.status ?? '-'}
          </Typography>
          <Typography>Tipo:</Typography>
          <Typography variant="h6">
            {transaction.error.type ?? '-'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorDetails;
