import { agentThemeCache } from "constants/config";

export const resetAgentTheme = ({ justColors }) => {
  if(!justColors) {
    localStorage.removeItem(agentThemeCache.agentLogo);
    localStorage.removeItem(agentThemeCache.description);
    localStorage.removeItem(agentThemeCache.name);
    localStorage.removeItem(agentThemeCache.mailSupport);
  }

  localStorage.removeItem(agentThemeCache.theme.palette.background.default);
  localStorage.removeItem(agentThemeCache.theme.palette.background.paper);
  localStorage.removeItem(agentThemeCache.theme.palette.primary.main);
  localStorage.removeItem(agentThemeCache.theme.palette.primary.light);
  localStorage.removeItem(agentThemeCache.theme.palette.secondary.main);
  localStorage.removeItem(agentThemeCache.theme.palette.secondary.light);
  localStorage.removeItem(agentThemeCache.theme.palette.menu.backgroundItem);
  localStorage.removeItem(agentThemeCache.theme.palette.menu.backgroundItemSelected);
  localStorage.removeItem(agentThemeCache.theme.palette.menu.icon);
  localStorage.removeItem(agentThemeCache.theme.palette.menu.iconSelected);
  localStorage.removeItem(agentThemeCache.theme.palette.menu.text);
  localStorage.removeItem(agentThemeCache.theme.typography.color);
  localStorage.removeItem(agentThemeCache.theme.collectionItem.background);
  localStorage.removeItem(agentThemeCache.theme.collectionItem.icon);
  localStorage.removeItem(agentThemeCache.theme.gradient.main.primary);
  localStorage.removeItem(agentThemeCache.theme.gradient.main.secondary);
  localStorage.removeItem(agentThemeCache.theme.gradient.light.primary);
  localStorage.removeItem(agentThemeCache.theme.gradient.light.secondary);
  
  window.dispatchEvent(new Event("themeChanges"));
}
