export const updateFeeMutation = ({ fee_id, percent_amount, dollar_amount }) => ({
  url: `/sales-plan-fees/${fee_id}`,
  method: "put",
  data: {
    percent_amount,
    dollar_amount
  },
});

export const onUpdateSalesPlanFee = async (baseApi, args, { dispatch, queryFulfilled }) => {
    const { data } = await queryFulfilled;

    var paymentType = "";
    if (data.payment_type === "boleto") {
      paymentType = "boleto";
    }

    if (data.payment_type === "credit") {
      paymentType =  data.capture_mode === null ? "credit_presential" : "credit_online";
    }

    if (data.payment_type === "debit") {
      paymentType = "debit_presential";
    }

    dispatch(
      baseApi.util.updateQueryData(
        "showSalesPlan",
        { plan_id: data.plano_venda_id },
        (draft) => {
          if(paymentType === "boleto") {
            const feeIndex = draft.fee_details[paymentType][data.source].findIndex((fee) => fee.id === data.id);
            draft.fee_details[paymentType][data.source][feeIndex] = data;
          } else {
            const feeIndex = draft.fee_details[paymentType][data.number_installments][data.source].findIndex((fee) => fee.id === data.id);
            draft.fee_details[paymentType][data.number_installments][data.source][feeIndex] = data;
          }
        }
      )
    );
  };
