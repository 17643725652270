import {
  getLinkPagamentos,
  getBoletos,
  postAuth,
  getUserData,
  postLinkPagamento,
  getLinkPagamentoId,
  postPagador,
  postLinkPagamentoPagar,
  getPagadorId,
  getLinkPagamentosFilter,
  getBoletosFilter,
  getPagadores,
  getPagadoresFilter,
  deletePagador,
  putPagador,
  getContas,
  getContasFilter,
  getUserConta,
  postUserConta,
  postContas,
  getContaId,
  putConta,
  postBoletos,
  getBoletoId,
  getExtratoPage,
  getLancamentosFuturos,
  putUserConta,
  putUserPassword,
  getBancos,
  postContaBancaria,
  getContaBancaria,
  deleteContaBancaria,
  postFirstAcess,
  getHistoricoTransacao,
  enviarBoleto,
  getUserContaFilters,
  postSaque,
  getHistoricoTransferencia,
  postTransferencia,
  deleteConta,
  postSenhaLink,
  getExtratoFilters,
  getHistoricoTransacaoFilters,
  getHistoricoTransferenciaFilters,
  getCarne,
  getCarneFilters,
  getCarneId,
  deleteCarneId,
  postCarne,
  postCobrancaCartao,
  getCobrancasCartao,
  getCobrancasCartaoFilters,
  postCapturaCobranca,
  postCobrancaEstornar,
  getPlanos,
  getPlanosFilters,
  getPlanoId,
  postPlano,
  putPlano,
  deletePlano,
  getAssinaturas,
  getAssinaturasFilters,
  getAssinaturasId,
  postAssinaturas,
  putAssinaturas,
  verificarAssinatura,
  cancelarAssinatura,
  deleteAssinatura,
  postCartaoAssinatura,
  getPlanosAll,
  postSaqueAutomatico,
  postCobrancaCartaoSimples,
  getTransacaoId,
  getCarneIdBoleto,
  postSplit,
  getContasFilhas,
  getResumoRecebiveisFuturos,
  getResumoCompletoRecebiveis,
  getTokens,
  postTokens,
  deleteTokens,
  postDocumentos,
  getPermissao,
  postPermissao,
  deletePermissao,
  authMe,
  deleteUser,
  getExportHistoricoTransacao,
  getExportLancamentoFuturo,
  getExportTodosLancamentos,
  getExportHistoricoTransferencia,
  getExportExtrato,
  getExportExtratoPdf,
  getDocumento,
  deleteDocumento,
  postDocumentosAdm,
  postReenviarToken,
  postRecoveryPassword,
  getTransacaoRecebiveis,
  getExportTransacaoAuditoria,
  getExportHistoricoRecebivel,
} from "../services/services";
import {
  LOAD_AUTH,
  LOAD_BOLETOS,
  LOAD_LINK_PAGAMENTOS,
  LOAD_USER_DATA,
  POST_LINK_PAGAMENTOS,
  LOAD_LINK_PAGAMENTOS_ID,
  POST_PAGADOR,
  PAGAR_LINK_PAGAMENTO,
  LOAD_PAGADOR_ID,
  LOAD_PAGADORES,
  DEL_PAGADOR,
  PUT_PAGADOR,
  SET_STATE,
  LOAD_EXPORT_TRANSFERENCIA,
  LOAD_CONTAS,
  LOAD_USER_CONTA,
  POST_USER_CONTA,
  POST_CONTA,
  LOAD_CONTA_ID,
  POST_BOLETOS,
  LOAD_EXTRATO,
  LOAD_BOLETOS_ID,
  LOAD_LANCAMENTOS_FUTUROS,
  UPDATE_USER_CONTA,
  UPDATE_USER_PASS,
  LOAD_EXPORT_TRANSACAO,
  LOAD_EXPORT_LANCAMENTO_FUTURO,
  LOAD_EXPORT_TODOS_LANCAMENTOS,
  LOAD_BANCOS,
  POST_CONTA_BANCARIA,
  LOAD_CONTA_BANCARIA,
  DEL_CONTA_BANCARIA,
  POST_PRIMEIRO_ACESSO,
  LOAD_HISTORICO_TRANSACAO,
  POST_SAQUE,
  LOAD_HISTORICO_TRANSFERENCIA,
  POST_TRANSFERENCIA,
  DEL_CONTA,
  LOAD_CARNE,
  LOAD_CARNE_ID,
  DEL_CARNE,
  POST_CARNE,
  POST_COBRANCA_CARTAO,
  LOAD_COBRANCAS_CARTAO,
  POST_CAPTURA,
  DEL_PLANO,
  POST_PLANO,
  PUT_PLANO,
  LOAD_PLANOS,
  LOAD_PLANO_ID,
  LOAD_ASSINATURAS,
  LOAD_ASSINATURA_ID,
  POST_ASSINATURA,
  PUT_ASSINATURA,
  DEL_ASSINATURA,
  POST_CARTAO_PAGADOR,
  POST_SAQUE_AUTOMATICO,
  LOAD_TRANSACAO,
  LOAD_RECEBIVEIS_TRANSACAO,
  CLEAR_TRANSACAO,
  POST_SLIPT,
  LOAD_RESUMO_TRANSACAO,
  LOAD_TRANSACOES_FUTUROS,
  DEL_TOKENS,
  POST_TOKENS,
  LOAD_TOKENS,
  CLEAR_TOKEN,
  POST_DOCUMENTO,
  LOAD_PERMISSAO,
  DEL_PERMISSAO,
  POST_PERMISSAO,
  POST_AUTHME,
  LOAD_PERMISSAO_GERENCIAR,
  DEL_USER,
  LOAD_EXPORT_EXTRATO,
  LOAD_DOCUMENTO,
  DELETE_DOCUMENTO,
  REENVIAR_TOKEN,
  LOAD_EXPORT_TRANSACAO_AUDITORIA,
  LOAD_EXPORT_RECEBIVEL,
} from "../constants/actionsStrings";
import { toast } from "react-toastify";

export const loadAuth = (user) => async (dispatch) => {
  try {
    const res = await postAuth(user);
    dispatch({
      type: LOAD_AUTH,
      payload: res.data,
    });
    return res;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const loadPagadorId = (id) => async (dispatch) => {
  try {
    const res = await getPagadorId(id);
    dispatch({
      type: LOAD_PAGADOR_ID,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadPagadores = (page) => async (dispatch) => {
  try {
    const res = await getPagadores(page);
    dispatch({
      type: LOAD_PAGADORES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const loadExtratoPage = (page) => async (dispatch) => {
  try {
    const res = await getExtratoPage(page);
    dispatch({
      type: LOAD_EXTRATO,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadPagadoresFilter =
  (page, like, order, mostrar, conta_id) => async (dispatch) => {
    try {
      const res = await getPagadoresFilter(
        page,
        like,
        order,
        mostrar,
        conta_id
      );
      dispatch({
        type: LOAD_PAGADORES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const loadLinkPagamentos = (page) => async (dispatch) => {
  try {
    const res = await getLinkPagamentos(page);
    dispatch({
      type: LOAD_LINK_PAGAMENTOS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadLancamentosFuturos =
  (page, data_liberacao_inicial, data_liberacao_final) => async (dispatch) => {
    try {
      const res = await getLancamentosFuturos(
        page,
        data_liberacao_inicial,
        data_liberacao_final
      );
      dispatch({
        type: LOAD_LANCAMENTOS_FUTUROS,
        payload: res.data,
      });
    } catch (err) {
      if (err.response.status === 422) {
        toast.warn(err.response.data.message);
      }
    }
  };

export const loadLinkPagamentoId = (id) => async (dispatch) => {
  try {
    const res = await getLinkPagamentoId(id);
    dispatch({
      type: LOAD_LINK_PAGAMENTOS_ID,
      payload: res.data,
    });
  } catch (err) {
    console.log({ err });
    if (err.response.status === 403) {
      toast.error(err.response.data.message);
    } else {
      toast.error("Erro ao buscar link de pagamento!");
    }
  }
};

export const delPagador = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch({
      type: DEL_PAGADOR,
      payload: id,
    });
    await deletePagador(id);
  } catch (err) {
    dispatch({
      type: SET_STATE,
      payload: state,
    });
    toast.error("Erro ao excluir o pagador");
  }
};

export const delConta = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch({
      type: DEL_CONTA,
      payload: id,
    });
    await deleteConta(id);
  } catch (err) {
    dispatch({
      type: SET_STATE,
      payload: state,
    });
    toast.error("Erro ao excluir a conta");
  }
};

export const postLinkPagamentos = (linkPagamentos) => async (dispatch) => {
  try {
    const res = await postLinkPagamento(linkPagamentos);
    dispatch({
      type: POST_LINK_PAGAMENTOS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro no cadastro");
      return err;
    }
  }
};

export const postPagadores = (pagador) => async (dispatch) => {
  try {
    const res = await postPagador(pagador);
    dispatch({
      type: POST_PAGADOR,
      payload: res.data,
    });
    return null;
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro no cadastro");
      return err;
    }
  }
};

export const loadBoletos = (page) => async (dispatch) => {
  try {
    const res = await getBoletos(page);
    dispatch({
      type: LOAD_BOLETOS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadBoletoId = (id) => async (dispatch) => {
  try {
    const res = await getBoletoId(id);
    dispatch({
      type: LOAD_BOLETOS_ID,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postBoletosAction = (boleto) => async (dispatch) => {
  try {
    const res = await postBoletos(boleto);
    dispatch({
      type: POST_BOLETOS,
      payload: res.data,
    });
    return null;
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro no cadastro");
      return err;
    }
  }
};

export const loadUserData = () => async (dispatch) => {
  try {
    const res = await getUserData();
    dispatch({
      type: LOAD_USER_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postLinkPagamentoPay =
  (linkPagamentoPagar, id) => async (dispatch) => {
    try {
      const res = await postLinkPagamentoPagar(linkPagamentoPagar, id);
      dispatch({
        type: PAGAR_LINK_PAGAMENTO,
        payload: res.data,
      });
    } catch (err) {
      if (err.response.status === 422) {
        return err.response.data.errors;
      } else {
        return err.response.data;
      }
    }
  };

export const postCobrancaCartaoAction =
  (cobrancaCartao) => async (dispatch) => {
    try {
      const res = await postCobrancaCartao(cobrancaCartao);
      dispatch({
        type: POST_COBRANCA_CARTAO,
        payload: res.data,
      });
    } catch (err) {
      if (err.response.status === 422) {
        return err.response.data.errors;
      } else {
        return err;
      }
    }
  };
export const postCobrancaCartaoSimplesAction =
  (cobrancaCartao) => async (dispatch) => {
    try {
      const res = await postCobrancaCartaoSimples(cobrancaCartao);
      dispatch({
        type: POST_COBRANCA_CARTAO,
        payload: res.data,
      });
    } catch (err) {
      if (err.response.status === 422) {
        return err.response.data.errors;
      } else {
        return err;
      }
    }
  };

export const loadLinkPagamentoFilter =
  (page, like, order, mostrar, conta_id) => async (dispatch) => {
    try {
      const res = await getLinkPagamentosFilter(
        page,
        like,
        order,
        mostrar,
        conta_id
      );
      dispatch({
        type: LOAD_LINK_PAGAMENTOS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const loadBoletosFilter =
  (page, like, order, mostrar, status, conta_id) => async (dispatch) => {
    try {
      const res = await getBoletosFilter(
        page,
        like,
        order,
        mostrar,
        status,
        conta_id
      );
      dispatch({
        type: LOAD_BOLETOS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const EditPagador = (pagador, id) => async (dispatch) => {
  try {
    const res = await putPagador(pagador, id);
    dispatch({
      type: PUT_PAGADOR,
      payload: res.data,
    });
    return null;
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro no cadastro");
      return err;
    }
  }
};

export const loadContas = (page) => async (dispatch) => {
  try {
    const res = await getContas(page);
    dispatch({
      type: LOAD_CONTAS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const loadContasFilhas =
  (page, like, conta_pai_id) => async (dispatch) => {
    try {
      const res = await getContasFilhas(page, like, conta_pai_id);
      dispatch({
        type: LOAD_CONTAS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const loadContasFilter =
  ({
    page,
    like,
    status,
    conta_id_filter,
    tipo,
    agent_id,
    with_app_sales_plan,
    without_agent_id,
    except_contas_filhas,
  }) =>
  async (dispatch) => {
    try {
      const res = await getContasFilter({
        page,
        like,
        status,
        conta_id_filter,
        tipo,
        agent_id,
        with_app_sales_plan,
        without_agent_id,
        except_contas_filhas,
      });
      dispatch({
        type: LOAD_CONTAS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const loadUserContaFilter =
  (page, like, order, mostrar) => async (dispatch) => {
    try {
      const res = await getUserContaFilters(page, like, order, mostrar);
      dispatch({
        type: LOAD_USER_CONTA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const loadContaId = (id) => async (dispatch) => {
  try {
    const res = await getContaId(id);
    dispatch({
      type: LOAD_CONTA_ID,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postNewConta = (conta) => async (dispatch) => {
  try {
    const res = await postContas(conta);
    dispatch({
      type: POST_CONTA,
      payload: res.data,
    });
  } catch (err) {
    toast.error("Erro no cadastro");
    return err;
  }
};

export const updateConta = (conta, id) => async (dispatch) => {
  try {
    const res = await putConta(conta, id);
    dispatch({
      type: POST_CONTA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro no cadastro");
      return err;
    }
  }
};

export const updateUserConta = (conta) => async (dispatch) => {
  try {
    const res = await putUserConta(conta);
    dispatch({
      type: UPDATE_USER_CONTA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro no cadastro");
      return null;
    }
  }
};

export const updateUserPassword = (userPass) => async (dispatch) => {
  try {
    const res = await putUserPassword(userPass);
    dispatch({
      type: UPDATE_USER_PASS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro no cadastro");
      return null;
    }
  }
};

export const loadUserConta = (id) => async (dispatch) => {
  try {
    const res = await getUserConta(id);
    dispatch({
      type: LOAD_USER_CONTA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const delUser = (id) => async (dispatch) => {
  try {
    await deleteUser(id);
    dispatch({
      type: DEL_USER,
      payload: id,
    });
  } catch (err) {
    return err;
  }
};

export const postUsuarioConta = (user) => async (dispatch) => {
  try {
    const res = await postUserConta(user);
    dispatch({
      type: POST_USER_CONTA,
      payload: res.data,
    });
  } catch (err) {
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro no cadastro");
      return err;
    }
  }
};

export const loadBancos = () => async (dispatch) => {
  try {
    const res = await getBancos();
    dispatch({
      type: LOAD_BANCOS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postContaBancariaAction =
  (conta, conta_id) => async (dispatch) => {
    try {
      const res = await postContaBancaria(conta, conta_id);
      dispatch({
        type: POST_CONTA_BANCARIA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
      if (err.response.status === 422) {
        return err.response.data.errors;
      } else {
        toast.error("Erro no cadastro");
        return null;
      }
    }
  };

export const loadContaBancaria = (conta_id) => async (dispatch) => {
  try {
    const res = await getContaBancaria(conta_id);
    dispatch({
      type: LOAD_CONTA_BANCARIA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const delContaBancaria =
  (id, conta_id) => async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch({
        type: DEL_CONTA_BANCARIA,
        payload: id,
      });
      await deleteContaBancaria(id, conta_id);
      toast.success("Conta Bancaria excluída com sucesso");
    } catch (err) {
      dispatch({
        type: SET_STATE,
        payload: state,
      });
      toast.error("Erro ao excluir conta Bancaria");
      console.error(err);
    }
  };

export const postPrimeiroAcesso = (user) => async (dispatch) => {
  try {
    const res = await postFirstAcess(user);
    dispatch({
      type: POST_PRIMEIRO_ACESSO,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro no cadastro");
      return null;
    }
  }
};

export const loadHistoricoTransacaoFilter = (filters) => async (dispatch) => {
  try {
    const res = await getHistoricoTransacaoFilters(filters);
    dispatch({
      type: LOAD_HISTORICO_TRANSACAO,
      payload: res.data,
    });
  } catch (err) {
    if (err.response.status === 422) {
      toast.error(err.response.data.message);
    } else {
      return null;
    }
  }
};

export const loadHistoricoTransacao = (page) => async (dispatch) => {
  try {
    const res = await getHistoricoTransacao(page);
    dispatch({
      type: LOAD_HISTORICO_TRANSACAO,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadTransacaoId = (id) => async (dispatch) => {
  try {
    const res = await getTransacaoId(id);
    dispatch({
      type: LOAD_TRANSACAO,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadRecebiveisId = (id) => async (dispatch) => {
  try {
    const res = await getTransacaoRecebiveis(id);
    dispatch({
      type: LOAD_RECEBIVEIS_TRANSACAO,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const clearTransacao = () => async (dispatch) => {
  dispatch({
    type: CLEAR_TRANSACAO,
  });
};
export const clearToken = () => async (dispatch) => {
  dispatch({
    type: CLEAR_TOKEN,
  });
};

export const enviarBoletoAction = (id) => async () => {
  try {
    const res = await enviarBoleto(id);
    console.log(res);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const authMeAction = () => async (dispatch) => {
  try {
    const res = await authMe();
    dispatch({
      type: POST_AUTHME,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const authMe2 = () => async (dispatch) => {
  try {
    const res = await authMe();
    dispatch({
      type: POST_AUTHME,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postSaqueAction = (saqueInfos) => async (dispatch) => {
  try {
    const res = await postSaque(saqueInfos);
    dispatch({
      type: POST_SAQUE,
      payload: res.data,
    });
  } catch (err) {
    console.log({ err });
    toast.error("Erro!");
    if (err.response.status === 422) {
      return err.response.data;
    }
    if (err.response.status === 403) {
      toast.error(err.response.data.message);
    }
    if (err.response.status === 400) {
      toast.error(err.response.data.message);
    }
    return err;
  }
};

export const loadHistoricoTransferencia = (page) => async (dispatch) => {
  try {
    const res = await getHistoricoTransferencia(page);
    dispatch({
      type: LOAD_HISTORICO_TRANSFERENCIA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadHistoricoTransferenciaFilters =
  (page, like, valor, data, conta_id) => async (dispatch) => {
    try {
      const res = await getHistoricoTransferenciaFilters(
        page,
        like,
        valor,
        data,
        conta_id
      );
      dispatch({
        type: LOAD_HISTORICO_TRANSFERENCIA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const postSenhaLinkAction = (senha, id) => async () => {
  try {
    const res = await postSenhaLink(senha, id);
    return res;
  } catch (err) {
    toast.error("Senha incorreta");
    console.log(err);
  }
};
export const loadExtratoFilter =
  (page, id, day, order, mostrar, tipo, data_inicial, data_final, conta_id) =>
  async (dispatch) => {
    try {
      const res = await getExtratoFilters(
        page,
        id,
        day,
        order,
        mostrar,
        tipo,
        data_inicial,
        data_final,
        conta_id
      );
      dispatch({
        type: LOAD_EXTRATO,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const loadCarne = (page) => async (dispatch) => {
  try {
    const res = await getCarne(page);
    dispatch({
      type: LOAD_CARNE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadCarneFilters = (filters) => async (dispatch) => {
  try {
    const res = await getCarneFilters(filters);
    dispatch({
      type: LOAD_CARNE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadCarneId = (id) => async (dispatch) => {
  try {
    const res = await getCarneId(id);
    dispatch({
      type: LOAD_CARNE_ID,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const loadCarneIdBoleto = (id) => async () => {
  try {
    const res = await getCarneIdBoleto(id);
    return res;
    // dispatch({
    // 	type: LOAD_CARNE_ID,
    // 	payload: res.data,
    // });
  } catch (err) {
    console.log(err);
  }
};

export const delCarne = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch({
      type: DEL_CARNE,
      payload: id,
    });
    await deleteCarneId(id);
  } catch (err) {
    dispatch({
      type: SET_STATE,
      payload: state,
    });
    toast.error("Erro ao excluir o carnê");
  }
};

export const postCarneAction = (carne) => async (dispatch) => {
  try {
    const res = await postCarne(carne);
    dispatch({
      type: POST_CARNE,
      payload: res.data,
    });
  } catch (err) {
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro durante o cadastro do Carne!");
      return err;
    }
  }
};

export const postTransferenciaAction =
  (transferenciaInfos) => async (dispatch) => {
    try {
      const res = await postTransferencia(transferenciaInfos);
      dispatch({
        type: POST_TRANSFERENCIA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
      if (err.response.status === 422) {
        return err.response.data.errors;
      } else {
        console.log(err);
        toast.error("erro");
        return null;
      }
    }
  };

export const loadCobrancasCartao = (id) => async (dispatch) => {
  try {
    const res = await getCobrancasCartao(id);
    dispatch({
      type: LOAD_COBRANCAS_CARTAO,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadCobrancasCartaoFilters =
  (page, like, order, mostrar, conta_id) => async (dispatch) => {
    try {
      const res = await getCobrancasCartaoFilters(
        page,
        like,
        order,
        mostrar,
        conta_id
      );
      dispatch({
        type: LOAD_COBRANCAS_CARTAO,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const postCapturaCobrancaAction = (id, valor) => async (dispatch) => {
  try {
    const res = await postCapturaCobranca(id, valor);
    dispatch({
      type: POST_CAPTURA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      console.log(err);
      toast.error("erro");
      return null;
    }
  }
};

export const postCobrancaEstornarAction = (id) => async () => {
  try {
    const res = await postCobrancaEstornar(id);
    console.log(res);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const loadPlanos = (page) => async (dispatch) => {
  try {
    const res = await getPlanos(page);
    dispatch({
      type: LOAD_PLANOS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const loadPlanosAll = (conta_id) => async (dispatch) => {
  try {
    const res = await getPlanosAll(conta_id);
    dispatch({
      type: LOAD_PLANOS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadPlanosFilters =
  (page, like, order, mostrar, conta_id) => async (dispatch) => {
    try {
      const res = await getPlanosFilters(page, like, order, mostrar, conta_id);
      dispatch({
        type: LOAD_PLANOS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const loadPlanoId = (id) => async (dispatch) => {
  try {
    const res = await getPlanoId(id);
    dispatch({
      type: LOAD_PLANO_ID,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postPlanoAction = (plano) => async (dispatch) => {
  try {
    const res = await postPlano(plano);
    dispatch({
      type: POST_PLANO,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      console.log(err);
      toast.error("erro");
      return null;
    }
  }
};

export const updatePlano = (id, plano) => async (dispatch) => {
  try {
    const res = await putPlano(id, plano);
    dispatch({
      type: PUT_PLANO,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro no cadastro");
      return null;
    }
  }
};

export const delPlano = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch({
      type: DEL_PLANO,
      payload: id,
    });
    await deletePlano(id);
  } catch (err) {
    console.log(err);
    dispatch({
      type: SET_STATE,
      payload: state,
    });
    toast.error("Erro ao excluir o plano");
  }
};

export const loadAssinaturas = (page) => async (dispatch) => {
  try {
    const res = await getAssinaturas(page);
    dispatch({
      type: LOAD_ASSINATURAS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadAssinaturasFilters =
  (page, like, plano, order, mostrar, conta_id) => async (dispatch) => {
    try {
      const res = await getAssinaturasFilters(
        page,
        like,
        plano,
        order,
        mostrar,
        conta_id
      );
      dispatch({
        type: LOAD_ASSINATURAS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const loadAssinaturaId = (id) => async (dispatch) => {
  try {
    const res = await getAssinaturasId(id);
    dispatch({
      type: LOAD_ASSINATURA_ID,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postAssinaturaAction = (plano) => async (dispatch) => {
  try {
    const res = await postAssinaturas(plano);
    dispatch({
      type: POST_ASSINATURA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      console.log(err);
      toast.error("erro");
      return err;
    }
  }
};

export const updateAssinatura = (id, plano_id, card_id) => async (dispatch) => {
  try {
    const res = await putAssinaturas(id, plano_id, card_id);
    dispatch({
      type: PUT_ASSINATURA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      return err;
    }
  }
};

export const verificarAssinaturaAction = (id) => async () => {
  try {
    const res = await verificarAssinatura(id);
    toast.success("Dados da assinatura atualizados!");
    return res;
  } catch (err) {
    toast.error("Erro ao verificar a situação atual da assinatura!");
    return err;
  }
};

export const cancelarAssinaturaAction = (id) => async () => {
  try {
    const res = await cancelarAssinatura(id);
    toast.success("Assinatura cancelada com sucesso!");
    return res;
  } catch (err) {
    toast.error("Erro ao cancelar a assinatura de cobrança recorrente!");
    if (err.response.status === 403) {
      toast.error(err.response.data.message);
    }
    return err;
  }
};

export const delAssinatura = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    const res = await deleteAssinatura(id);
    dispatch({
      type: DEL_ASSINATURA,
      payload: id,
    });
    toast.success("Assinatura excluída com sucesso!");
    return res;
  } catch (err) {
    dispatch({
      type: SET_STATE,
      payload: state,
    });
    toast.error("Erro ao excluír a assinatura de cobrança recorrente!");
    return err;
  }
};

export const postCartaoAssinaturaAction = (id, cartao) => async (dispatch) => {
  try {
    const res = await postCartaoAssinatura(id, cartao);
    dispatch({
      type: POST_CARTAO_PAGADOR,
      payload: res.data,
    });
    return res;
  } catch (err) {
    if (err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro ao cadastrar cartão!");
      return err;
    }
  }
};

export const postSaqueAutomaticoAction =
  (saqueAutomatico) => async (dispatch) => {
    try {
      const res = await postSaqueAutomatico(saqueAutomatico);
      dispatch({
        type: POST_SAQUE_AUTOMATICO,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        return err.response.data.errors;
      } else {
        toast.error("Erro");
        return err;
      }
    }
  };
export const postSlipAction = (transacao) => async (dispatch) => {
  try {
    const res = await postSplit(transacao);
    dispatch({
      type: POST_SLIPT,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    if (err.response && err.response.status === 422) {
      return err.response.data.errors;
    } else {
      toast.error("Erro");
      return err;
    }
  }
};

export const loadTransacoesFuturas =
  ({ date }) =>
  async (dispatch) => {
    try {
      const res = await getResumoRecebiveisFuturos({ date });
      dispatch({
        type: LOAD_TRANSACOES_FUTUROS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const loadResumoTransacao =
  ({ date }) =>
  async (dispatch) => {
    try {
      const res = await getResumoCompletoRecebiveis({ date });
      dispatch({
        type: LOAD_RESUMO_TRANSACAO,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const loadTokens = () => async (dispatch) => {
  try {
    const res = await getTokens();
    dispatch({
      type: LOAD_TOKENS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postTokenAction = (tokenInfos) => async (dispatch) => {
  try {
    const res = await postTokens(tokenInfos);
    dispatch({
      type: POST_TOKENS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const delTokens = (index) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch({
      type: DEL_TOKENS,
      payload: index,
    });
    await deleteTokens(index);
  } catch (err) {
    console.log(err);
    dispatch({
      type: SET_STATE,
      payload: state,
    });
    toast.error("Erro ao excluir token");
  }
};

export const postDocumentoAction =
  (documento, categoria) => async (dispatch) => {
    const documentoObjeto = { ...documento };
    try {
      const res = await postDocumentos(
        documentoObjeto[0].file,
        categoria,
        documentoObjeto[0].file.type
      );
      dispatch({
        type: POST_DOCUMENTO,
        payload: res.data,
      });
      toast.success("Documento adicionado com sucesso");
    } catch (err) {
      console.log(err);
      toast.error("Erro ao adicionar o documento");
    }
  };

export const postDocumentoActionAdm =
  (documento, categoria, conta_id) => async (dispatch) => {
    const documentoObjeto = { ...documento };
    try {
      const res = await postDocumentosAdm(
        documentoObjeto[0].file,
        categoria,
        conta_id,
        documentoObjeto[0].file.type
      );
      dispatch({
        type: POST_DOCUMENTO,
        payload: res.data,
      });
      toast.success("Documento adicionado com sucesso");
    } catch (err) {
      console.log(err);
      toast.error("Erro ao adicionar o documento");
    }
  };

export const delDocumento = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch({
      type: DELETE_DOCUMENTO,
      payload: id,
    });
    await deleteDocumento(id);
  } catch (err) {
    dispatch({
      type: SET_STATE,
      payload: state,
    });
    toast.error("Erro ao Documento");
  }
};

export const loadDocumentos = (conta_id) => async (dispatch) => {
  try {
    const res = await getDocumento(conta_id);
    dispatch({
      type: LOAD_DOCUMENTO,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const loadPermissao = (id) => async (dispatch) => {
  try {
    const res = await getPermissao(id);
    dispatch({
      type: LOAD_PERMISSAO,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadPermissaoGerenciar = (id) => async (dispatch) => {
  try {
    const res = await getPermissao(id);
    dispatch({
      type: LOAD_PERMISSAO_GERENCIAR,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postPermissaoAction = (id, tipoPermissao) => async (dispatch) => {
  try {
    const res = await postPermissao(id, tipoPermissao);
    dispatch({
      type: POST_PERMISSAO,
      payload: res.data,
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const delPermissao = (id, tipoPermissao) => async (dispatch) => {
  try {
    const res = await deletePermissao(id, tipoPermissao);
    dispatch({
      type: DEL_PERMISSAO,
      payload: res.data,
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const loadExportHistoricoTransacao = (filters) => async (dispatch) => {
  try {
    const res = await getExportHistoricoTransacao(filters);
    dispatch({
      type: LOAD_EXPORT_TRANSACAO,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    toast.error("Erro ao exportar");
    return null;
  }
};

export const loadExportHistoricoRecebivel =
  ({
    page,
    transaction,
    receiver,
    payer,
    day,
    order,
    mostrar,
    status,
    payment_type,
    documento,
    holder_name,
    data_inicial,
    data_final,
    data_pagamento_inicial,
    data_pagamento_final,
    vencimento_final,
    vencimento_inicial,
  }) =>
  async (dispatch) => {
    try {
      const res = await getExportHistoricoRecebivel(
        page,
        transaction,
        receiver,
        payer,
        day,
        order,
        mostrar,
        status,
        payment_type,
        documento,
        holder_name,
        data_inicial,
        data_final,
        data_pagamento_inicial,
        data_pagamento_final,
        vencimento_final,
        vencimento_inicial
      );
      dispatch({
        type: LOAD_EXPORT_RECEBIVEL,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      console.log(err);
      toast.error("Erro ao exportar");
      return null;
    }
  };

export const loadExportTransacaoAuditoria =
  ({
    page,
    day,
    order,
    mostrar,
    status,
    like,
    payment_type,
    data_inicial,
    data_final,
    id,
    documento,
    vencimento_inicial,
    vencimento_final,
    pagamento_inicial,
    pagamento_final,
    conta_id,
    terminal_id,
    seller_like,
    holder_name,
    is_physical_sale,
  }) =>
  async (dispatch) => {
    try {
      const res = await getExportTransacaoAuditoria(
        page,
        day,
        order,
        mostrar,
        status,
        like,
        payment_type,
        data_inicial,
        data_final,
        id,
        documento,
        vencimento_inicial,
        vencimento_final,
        pagamento_inicial,
        pagamento_final,
        conta_id,
        terminal_id,
        seller_like,
        holder_name,
        is_physical_sale
      );
      dispatch({
        type: LOAD_EXPORT_TRANSACAO_AUDITORIA,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      console.log(err);
      toast.error("Erro ao exportar");
      return null;
    }
  };

export const loadExportLancamentoFuturo =
  ({ data_liberacao_inicial, data_liberacao_final, conta_id }) =>
  async (dispatch) => {
    try {
      const res = await getExportLancamentoFuturo(
        data_liberacao_inicial,
        data_liberacao_final,
        conta_id
      );
      dispatch({
        type: LOAD_EXPORT_LANCAMENTO_FUTURO,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      if (err.response.status === 422) {
        toast.error(err.response.data.message);
      } else return toast.error("Erro ao exportar");
    }
  };

export const loadExportTodosLancamentos =
  ({ date, conta_id }) =>
  async (dispatch) => {
    try {
      const res = await getExportTodosLancamentos(date, conta_id);
      dispatch({
        type: LOAD_EXPORT_TODOS_LANCAMENTOS,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      console.log(err);
      toast.error("Erro ao exportar");
      return null;
    }
  };

export const loadExportHistoricoTransferencia =
  (page, like, valor, data, conta_id) => async (dispatch) => {
    try {
      const res = await getExportHistoricoTransferencia(
        page,
        like,
        valor,
        data,
        conta_id
      );
      dispatch({
        type: LOAD_EXPORT_TRANSFERENCIA,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      console.log(err);
      toast.error("Erro ao exportar");
      return null;
    }
  };

export const loadExportExtrato =
  (page, id, day, order, mostrar, tipo, data_inicial, data_final, conta_id) =>
  async (dispatch) => {
    try {
      const res = await getExportExtrato(
        page,
        id,
        day,
        order,
        mostrar,
        tipo,
        data_inicial,
        data_final,
        conta_id
      );
      dispatch({
        type: LOAD_EXPORT_EXTRATO,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      console.log(err);
      toast.error("Erro ao exportar");
      return null;
    }
  };

export const loadExportExtratoPdf =
  (page, id, day, order, mostrar, tipo, data_inicial, data_final, conta_id) =>
  async (dispatch) => {
    try {
      const res = await getExportExtratoPdf(
        page,
        id,
        day,
        order,
        mostrar,
        tipo,
        data_inicial,
        data_final,
        conta_id
      );

      if (res.status === 200) {
        toast.success(
          "Extrato solicitado. Assim que concluirmos ele será enviado para seu email."
        );
      }

      return res.data;
    } catch (err) {
      console.log(err);
      toast.error("Erro ao exportar pdf");
      return null;
    }
  };

export const loadReenviarToken = (user_id) => async (dispatch) => {
  try {
    const res = await postReenviarToken(user_id);
    dispatch({
      type: REENVIAR_TOKEN,
      payload: res.data,
    });
    toast.success("Token reenviado com sucesso!");
  } catch (err) {
    toast.error("Erro ao enviar");
  }
};

export const loadRecoveryPassword = (user_email) => async (dispatch) => {
  try {
    await postRecoveryPassword(user_email);
  } catch (err) {
    toast.error("Erro ao enviar");
    return err;
  }
};
