import { Box, Button, LinearProgress, Menu, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  delContaBancaria,
  loadContaBancaria,
} from "../../../../actions/actions";
import CustomTable from "../../../reusable/CustomTable/CustomTable";
import ConfirmDialog from "components/reusable/ConfirmDialog";

const columns = [
  { headerText: "Banco", key: "banco_string" },
  { headerText: "Agência", key: "agencia" },
  { headerText: "Tipo", key: "tipo_string" },
  { headerText: "Número da conta", key: "conta" },
  { headerText: "Ações", key: "menu" },
];

const ListBankAccounts = ({ contaId }) => {
  const contasBancariasUser = useSelector((state) => state.contasBancarias);
  const dispatch = useDispatch();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [bankAccountToDeleteId, setBankAccountToDeleteId] = useState("");

  useEffect(() => {
    dispatch(loadContaBancaria(contaId));
  }, []);

  const handleDeleteBankAccount = async () => {
    dispatch(delContaBancaria(bankAccountToDeleteId, contaId));
    setBankAccountToDeleteId("");
    setDeleteDialogOpen(false);
  };

  const cancelDeleteAction = async () => {
    setBankAccountToDeleteId("");
    setDeleteDialogOpen(false);
  };

  const Editar = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleExcluir = async (rowToDelete) => {
      setBankAccountToDeleteId(rowToDelete.id);
      setDeleteDialogOpen(true);
      setAnchorEl(null);
    };

    return (
      <Box>
        <Button
          style={{ height: "15px", width: "10px" }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          ...
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleExcluir(row)}>Excluir</MenuItem>
        </Menu>
      </Box>
    );
  };

  return (
    <>
      <ConfirmDialog
        title="Excluir Conta Bancária"
        message="Deseja realmente excluir essa conta bancária?"
        open={deleteDialogOpen}
        onConfirm={handleDeleteBankAccount}
        onClose={cancelDeleteAction}
      />
      {contasBancariasUser ? (
        <CustomTable
          columns={columns}
          data={contasBancariasUser}
          Editar={Editar}
          compacta="true"
        />
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default ListBankAccounts;
