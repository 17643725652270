import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  delDocumento,
  loadDocumentos,
  postDocumentoAction,
} from "../../../actions/actions";
import { PictureAsPdf } from "@material-ui/icons";
import { DropzoneAreaBase } from "material-ui-dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  card: {
    margin: theme.spacing(1),
    padding: 0,
  },
  dropzoneAreaBaseClasses: {
    width: "70%",
    height: "250px",
    fontSize: "10px",
  },
  dropzoneContainer: {
    margin: "6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "12px",
    minHeight: "450px",
  },
  textoDropzone: {
    fontSize: "1.2rem",
  },
}));

const LoggedUserDocuments = ({ loading, setLoading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const arquivoDocumento = useSelector((state) => state.arquivoDocumento);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const userData = useSelector((state) => state.userData);

  useEffect(() => {
    dispatch(loadDocumentos(userData.id));
  }, [dispatch, userData, arquivoDocumento.lenght]);

  const handleExcluirArquivo = async (item) => {
    setLoading(true);
    await dispatch(delDocumento(item.id));
    await dispatch(loadDocumentos(userData.id));
    setLoading(false);
  };

  const onDropIdentificacao = async (picture) => {
    setLoading(true);
    const categoria = "identificacao";
    await dispatch(postDocumentoAction(picture, categoria));
    await dispatch(loadDocumentos(userData.id));
    setLoading(false);
  };

  const onDropComprovanteResidencia = async (picture, file) => {
    setLoading(true);
    const categoria = "residencia";
    await dispatch(postDocumentoAction(picture, categoria));
    await dispatch(loadDocumentos(userData.id));
    setLoading(false);
  };

  const onDropAtividade = async (picture, file) => {
    setLoading(true);
    const categoria = "atividade";
    await dispatch(postDocumentoAction(picture, categoria));
    await dispatch(loadDocumentos(userData.id));
    setLoading(false);
  };

  const onDropOutros = async (picture, file) => {
    setLoading(true);
    const categoria = "cnpj";
    await dispatch(postDocumentoAction(picture, categoria));
    await dispatch(loadDocumentos(userData.id));
    setLoading(false);
  };

  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        style={matches ? { flexDirection: "column" } : null}
        justifyContent="center"
      >
        <Box display="flex" flexDirection="column" margin="8px">
          <Box className={classes.dropzoneContainer} boxShadow={3}>
            <Typography variant="h6">IDENTIFICAÇÃO</Typography>
            <Typography>RG, CPF ou CNH.</Typography>
            <DropzoneAreaBase
              dropzoneParagraphClass={classes.textoDropzone}
              maxFileSize={3145728}
              onDropRejected={() => {
                toast.error("Tamanho máximo: 3mb ");
                toast.error("Arquivos suportados: .pdf .png .jpg .jpeg");
              }}
              acceptedFiles={["image/*", "application/pdf"]}
              dropzoneClass={classes.dropzoneAreaBaseClasses}
              onAdd={onDropIdentificacao}
              filesLimit={1}
              dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
              showPreviews={false}
              showPreviewsInDropzone={false}
            />
            <Box width="300px">
              <Grid container>
                {arquivoDocumento.map((item) =>
                  item.categoria === "IDENTIFICACAO" ||
                  item.categoria === "identificacao" ? (
                    <Grid item xs={6}>
                      <Card className={classes.card}>
                        <CardActionArea>
                          <Box position="absolute">
                            <IconButton
                              onClick={() => handleExcluirArquivo(item)}
                              size="small"
                              style={{ color: "white", backgroundColor: "red" }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Box>

                          {item.descricao === "application/pdf" ? (
                            <PictureAsPdf
                              style={{ height: "100px", width: "100%" }}
                              onClick={() => window.open(item.arquivo)}
                            />
                          ) : (
                            <CardMedia
                              component="img"
                              alt="Arquivo de Identificação"
                              height="100"
                              image={item.arquivo}
                              onClick={() => window.open(item.arquivo)}
                            />
                          )}
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ) : (
                    false
                  )
                )}
              </Grid>
            </Box>
          </Box>
          <Box className={classes.dropzoneContainer} boxShadow={3}>
            <Typography variant="h6">COMPROVANTE DE RESIDÊNCIA</Typography>
            <Typography>Conta de luz, telefone e água.</Typography>
            <DropzoneAreaBase
              dropzoneParagraphClass={classes.textoDropzone}
              maxFileSize={3145728}
              onDropRejected={() => {
                toast.error("Tamanho máximo: 3mb ");
                toast.error("Arquivos suportados: .pdf .png .jpg .jpeg");
              }}
              acceptedFiles={["image/*", "application/pdf"]}
              dropzoneClass={classes.dropzoneAreaBaseClasses}
              onAdd={onDropComprovanteResidencia}
              filesLimit={1}
              dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
              showPreviews={false}
              showPreviewsInDropzone={false}
            />
            <Box width="300px">
              <Grid container>
                {arquivoDocumento.map((item) =>
                  item.categoria === "COMPROVANTE_RESIDENCIA" ||
                  item.categoria === "residencia" ? (
                    <Grid item xs={6}>
                      <Card className={classes.card}>
                        <CardActionArea>
                          <Box position="absolute">
                            <IconButton
                              onClick={() => handleExcluirArquivo(item)}
                              size="small"
                              style={{ color: "white", backgroundColor: "red" }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Box>

                          {item.descricao === "application/pdf" ? (
                            <PictureAsPdf
                              style={{ height: "100px", width: "100%" }}
                              onClick={() => window.open(item.arquivo)}
                            />
                          ) : (
                            <CardMedia
                              component="img"
                              alt="Comrpovante de Residência"
                              height="100"
                              image={item.arquivo}
                              onClick={() => window.open(item.arquivo)}
                            />
                          )}
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ) : (
                    false
                  )
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" margin="8px">
          <Box className={classes.dropzoneContainer} boxShadow={3}>
            <Typography variant="h6">COMPROVANTE DE ATIVIDADE</Typography>
            <Typography>Nota fiscal ou cartão de visita</Typography>
            <DropzoneAreaBase
              dropzoneParagraphClass={classes.textoDropzone}
              maxFileSize={3145728}
              onDropRejected={() => {
                toast.error("Tamanho máximo: 3mb ");
                toast.error("Arquivos suportados: .pdf .png .jpg .jpeg");
              }}
              acceptedFiles={["image/*", "application/pdf"]}
              dropzoneClass={classes.dropzoneAreaBaseClasses}
              onAdd={onDropAtividade}
              filesLimit={1}
              dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
              showPreviews={false}
              showPreviewsInDropzone={false}
            />
            <Box width="300px">
              <Grid container>
                {arquivoDocumento.map((item) =>
                  item.categoria === "COMPROVANTE_ATIVIDADE" ||
                  item.categoria === "atividade" ? (
                    <Grid item xs={6}>
                      <Card className={classes.card}>
                        <CardActionArea>
                          <Box position="absolute">
                            <IconButton
                              onClick={() => handleExcluirArquivo(item)}
                              size="small"
                              style={{ color: "white", backgroundColor: "red" }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Box>

                          {item.descricao === "application/pdf" ? (
                            <PictureAsPdf
                              style={{ height: "100px", width: "100%" }}
                              onClick={() => window.open(item.arquivo)}
                            />
                          ) : (
                            <CardMedia
                              component="img"
                              alt="Comrpovante de Residência"
                              height="100"
                              image={item.arquivo}
                              onClick={() => window.open(item.arquivo)}
                            />
                          )}
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ) : (
                    false
                  )
                )}
              </Grid>
            </Box>
          </Box>
          <Box className={classes.dropzoneContainer} boxShadow={3}>
            <Typography variant="h6">OUTROS</Typography>
            <Typography>Contrato social.</Typography>
            <DropzoneAreaBase
              dropzoneParagraphClass={classes.textoDropzone}
              maxFileSize={3145728}
              onDropRejected={() => {
                toast.error("Tamanho máximo: 3mb ");
                toast.error("Arquivos suportados: .pdf .png .jpg .jpeg");
              }}
              acceptedFiles={["image/*", "application/pdf"]}
              dropzoneClass={classes.dropzoneAreaBaseClasses}
              onAdd={onDropOutros}
              filesLimit={1}
              dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
              showPreviews={false}
              showPreviewsInDropzone={false}
            />
            <Box width="300px">
              <Grid container>
                {arquivoDocumento.map((item) =>
                  item.categoria === "OUTROS" || item.categoria === "cnpj" ? (
                    <Grid item xs={6}>
                      <Card className={classes.card}>
                        <CardActionArea>
                          <Box position="absolute">
                            <IconButton
                              onClick={() => handleExcluirArquivo(item)}
                              size="small"
                              style={{ color: "white", backgroundColor: "red" }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Box>

                          {item.descricao === "application/pdf" ? (
                            <PictureAsPdf
                              style={{ height: "100px", width: "100%" }}
                              onClick={() => window.open(item.arquivo)}
                            />
                          ) : (
                            <CardMedia
                              component="img"
                              alt="Comrpovante de Residência"
                              height="100"
                              image={item.arquivo}
                              onClick={() => window.open(item.arquivo)}
                            />
                          )}
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ) : (
                    false
                  )
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoggedUserDocuments;
