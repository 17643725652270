import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { Paper, TextField, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useActiveTotpMutation, useGetNewTotpMutation, useRemoveTotpMutation } from 'services/api';
import LoadingScreen from 'components/reusable/LoadingScreen/LoadingScreen';
import CustomBreadcrumbs from '../CustomBreadcrumbs/CustomBreadcrumbs';
import { authMe2 } from 'actions/actions';
import QRCode from 'react-qr-code';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
    flexDirection: 'column',
		backgroundColor: 'white',
		margin: '0px',
		padding: '0px',
	},
	contentArea: {
		display: 'flex',
		padding: '40px',
    flexDirection: 'column',
    animation: `$myEffect 1000ms ${theme.transitions.easing.easeInOut}`,
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
    flexDirection: 'column',
    padding: '20px',
	},
	'@keyframes myEffect': {
		'0%': {
			opacity: 0,
			transform: 'translateX(10%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateX(0)',
		},
	},
}));

export default function TotpOptions() {
	const classes = useStyles();
  const dispatch = useDispatch();
  const [loadding, setLoading] = useState(false);
  const [totpSecret, setTotpSecret] = useState("");
  const [totpQrCodeText, setTotpQrCodeText] = useState("");
  const [totpCode, setTotpCode] = useState("");
  const [remove, setRemove] = useState(false);
  const [totpCodeToRemove, setTotpCodeToRemove] = useState("");
  const [err, setErr] = useState(false);
  const [getNewTotp] = useGetNewTotpMutation();
  const [activeTotp] = useActiveTotpMutation();
  const [removeTotp] = useRemoveTotpMutation();
  const userData = useSelector((state) => state.authMe);

	const handleRemoveTotp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErr(false);
    try {
      await removeTotp({totpCode: totpCodeToRemove}).unwrap();
      toast.success('2FA removido com sucesso!');
      dispatch(authMe2());
    } catch (e) {
      setErr(true);
      if(e.data.success === false) {
        toast.error('Código de verificação invalido!');
      } else {
        toast.error("Erro ao remover 2FA!");
      }
      setLoading(false);
    }
	};

  const handleGetNewTotp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await getNewTotp().unwrap();
      setTotpSecret(response.secret);
      setTotpQrCodeText(response.qr_code_text);
      dispatch(authMe2());
  
    } catch (e) {
      toast.error("Erro ao solicitar novo 2FA!");
    }
    setLoading(false);
	};

  const handleActivateTotp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErr(false);
    try {
      await activeTotp({ totpCode }).unwrap();
      toast.success('2FA habilitado com sucesso!');
      dispatch(authMe2());
    } catch (e) {
      setErr(true);
      if(e.data.success === false) {
        toast.error('Código de verificação invalido!');
      } else {
        toast.error("Erro ao verificar!");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if(remove) {
      setTotpCodeToRemove(null);
      setRemove(false);
      setLoading(false);
    }
    if(totpCode) {
      setTotpSecret(null);
      setTotpQrCodeText(null);
      setTotpCode(null);
      setLoading(false);
    }
  }, [userData.totp_active]);

	return (
		<>
			<Box className={classes.root}>
        <LoadingScreen isLoading={loadding} />
        <CustomBreadcrumbs
          path1="Segurança"
          to1="/dashboard/menu-opcoes"
          path2="2FA"
          to2="#"
        />
        <Typography style={{ marginTop: "8px" }} variant="h4">
          Autentificação de dois fatores - 2FA
        </Typography>

        <Box className={classes.contentArea}>       
          <Paper className={classes.paper}>
            { userData.totp_active ?
            <>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">Remover autentificação em dois fatores?</Typography>
                <Button
                  component={Link}
                  onClick={() => setRemove(true)}
                  style={{ height: '28px', borderRadius: '27px' }}
                >
                  Remover
                </Button>
              </Box>
              <Typography>Última autentificação: {userData.last_2fa_date 
                ? new Date(userData.last_2fa_date).toLocaleDateString(
                "pt-br", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric"
                }) : ""}
              </Typography>
              <Typography>Último ip autentificado: {userData.last_2fa_ip ?? ""}</Typography>

              {remove ?
              <>
                <Box marginTop={"20px"} display="flex" flexDirection="column">
                  <Typography>Verificar codigo de autentificação</Typography>
                  <form onSubmit={(e) => handleRemoveTotp(e)}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="totp_code_to_remove"
                      label="Digite seu código de verificação"
                      name="totpCodeToRemove"
                      autoFocus
                      value={totpCodeToRemove}
                      onChange={(e) => setTotpCodeToRemove(e.target.value)}
                      error={err}
                    />
                    <Button
                      size="large"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      type="submit"
                    >
                      Remover
                    </Button>
                  </form>
                </Box>
              </>
              : null}
            </>
            : null}

            {!userData.totp_active ?
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">Ativar autentificação em dois fatores</Typography>
                {!totpSecret ?
                  <Button
                    component={Link}
                    onClick={handleGetNewTotp}
                    style={{ height: '28px', borderRadius: '27px' }}
                  >
                    Ativar
                  </Button>
                : null}
              </Box>
            : null}

            {totpSecret ?
              <>
                <Box>
                  <Typography>
                    Cadastre sua chave de segurança para autentificação em dois fatores
                  </Typography>
                  <Box display="flex" alignItems="center" marginBottom={"40px"}>
                    <TextField value={totpSecret} />
                    <Tooltip title="Copiar chave de segurança">
                      <CopyToClipboard text={totpSecret}>
                        <Button
                          aria="Copiar"
                          style={{
                            marginLeft: "6px",
                            width: "60px",
                            height: "20px",
                            alignSelf: "center",
                            color: "green",
                          }}
                          onClick={() =>
                            toast.success(
                              "Copiado para area de transferência",
                              {
                                autoClose: 2000,
                              }
                            )
                          }
                        >
                          <FontAwesomeIcon
                            style={{
                              width: "60px",
                              height: "20px",
                            }}
                            icon={faCopy}
                          />
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    {totpQrCodeText && <QRCode value={totpQrCodeText} />}
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column">
                  <Typography>Verificar codigo de autentificação</Typography>
                  <form onSubmit={(e) => handleActivateTotp(e)}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="totp_code"
                      label="Digite seu código de verificação"
                      name="totpCode"
                      autoFocus
                      value={totpCode}
                      onChange={(e) => setTotpCode(e.target.value)}
                      error={err}
                    />
                    <Button
                      size="large"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      type="submit"
                    >
                      Ativar
                    </Button>
                  </form>
                </Box>
              </>
            :
              null
            }
          </Paper>
        </Box>
      </Box>
		</>
	);
}
