import { Typography } from "@material-ui/core";
import DateTimeColumn from "components/reusable/TableColumns/DateTimeColumn";
import React from "react";
import { formatMoney } from "utils/money";

const splitsZoopColumns = [
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (created_at) => {
      return <DateTimeColumn dateTime={created_at} />;
    },
  },
  {
    headerText: "Recebedor",
    key: "recipient",
    CustomValue: (recipient) => {
      return <Typography>{recipient}</Typography>;
    },
  },
  {
    headerText: "Valor",
    key: "amount",
    CustomValue: (amount) => {
      return <Typography>{formatMoney(amount)}</Typography>;
    },
  },
  {
    headerText: "Porcentagem",
    key: "percentage",
    CustomValue: (percentage) => {
      return <Typography>{formatMoney(percentage)}</Typography>;
    },
  },
];

export default splitsZoopColumns;
